import * as React from 'react';
import PriceAlarmTable from '../containers/priceAlarmsTable';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import { openPriceAlarmForm } from '../actions/priceAlarms';
import { PriceAlarmFormData } from '../models/priceAlarms';
import { connect } from 'react-redux';
import { createPriceAlarmFormData } from '../helper/priceAlarms';
import { config } from 'src/js/main/config';
interface Props {
  newPriceAlarm: (formData: PriceAlarmFormData) => void;
}

interface State {

}

export class PriceAlarmOverviewComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (!config.priceAlarmsEnabled) {
      return <></>;
    }
    return (
      <>
        <button className="btn btn-primary w-100 create-price-alarm" onClick={e => this.props.newPriceAlarm(createPriceAlarmFormData('', 0, ''))}>
          <MemoTranslate value="priceAlarm.create" />
        </button>
        <PriceAlarmTable {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state: State) => ({
});

const mapDispatchToProps = {
  newPriceAlarm: openPriceAlarmForm
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(PriceAlarmOverviewComponent);