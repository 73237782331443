import * as React from 'react';
import { connect } from 'react-redux';
import { triggerFilter, triggerSearch, remove } from '../../ui/actions/tab';
import { I18n } from 'react-redux-i18n';
import { ComponentType } from '../../ui/models/component';
import DownloadLink from '../../ui/components/table/components/uiTableDownloadLink';
import { getTableForTab } from '../../ui/selectors/ui';
import { ITable } from '../../ui/models/table';
import Dragger from '../../dragger/container/Dragger';
import { isProfileModificationAllowed } from '../../../authentication/selectors/authetication';

export interface TabPaneProps {
  tabId: string;
  dockId: string;
  type: ComponentType;
  title: string;
  isActive: boolean;
  tabIndex: number;
  displayActions: boolean;
  displayFilter: boolean;
  displaySearch: boolean;
  table: ITable;
  profileModificationEnabled: boolean;
  onClick: (tabIndex: number) => void;
  onRemove: (tabIndex: number) => void;
  triggerFilter: (id: string, displayFilter: boolean) => void;
  triggerSearch: (id: string, displaySearch: boolean) => void;
  remove: (id: string, dockId: string, type: ComponentType) => void;
  onDragComplete: (e: any, title: string) => void;
}

export class TabPane extends React.Component<TabPaneProps, any> {
  private timeouts: any[];
  constructor(props: TabPaneProps) {
    super(props);

    this.onFilter = this.onFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onDragComplete = this.onDragComplete.bind(this);
    this.timeouts = [];
  }

  onFilter(e: any) {
    e.preventDefault();
    const { tabId, displayFilter } = this.props;
    this.props.triggerFilter(tabId, !displayFilter);
  }

  onSearch(e: any) {
    e.preventDefault();
    const { tabId, displaySearch } = this.props;
    this.props.triggerSearch(tabId, !displaySearch);
  }

  onRemove(e: any) {
    e.preventDefault();
    const { tabId, dockId, type } = this.props;
    this.props.remove(tabId, dockId, type);
  }

  onDragComplete(e: any) {
    const { title } = this.props;
    this.props.onDragComplete(e, title);
  }

  render(): JSX.Element {
    const {
      tabId,
      title,
      tabIndex,
      type,
      onClick,
      onRemove,
      isActive,
      displayActions,
      displayFilter,
      displaySearch,
      table,
      profileModificationEnabled
    } = this.props;

    return (
      <li className={`nav-item ${isActive ? 'active' : 'inactive'}`}>
        <Dragger
          tag="div"
          className="dragger"
          onDragComplete={this.onDragComplete}
        />
        <span
          className="nav-link tabs__link"
          onClick={event => {
            event.preventDefault();
            this.timeouts = [
              ...this.timeouts,
              setTimeout(() => onClick(tabIndex), 1)
            ];
          }}
        >
          <div className="d-flex flex-direction-row justify-content-between">
            <h3 className="text-nowrap" title={title}>
              {title}
            </h3>
            {displayActions ? (
              <ul className="tabs__actions">
                <li onClick={this.onSearch}>
                  <i
                    className={`oi oi-magnifying-glass hover-primary ${
                      displaySearch || (table && table.tags.length) ? `active` : `inactive`
                    }`}
                    title={I18n.t('modal.search')}
                  />
                </li>
                <li onClick={e => e.preventDefault()}>
                  <DownloadLink
                    parentId={tabId}
                    componentType={type}
                    name={type}
                  />
                </li>
                <li onClick={this.onFilter}>
                  <i
                    className={`oi oi-wrench hover-primary ${
                      displayFilter ? `active` : `inactive`
                    }`}
                    title={I18n.t('modal.filter')}
                  />
                </li>
                {profileModificationEnabled ?
                  <li
                    onClick={e => {
                      this.onRemove(e);
                      onRemove(tabIndex);
                    }}
                  >
                    <i className={`oi oi-x`} title={I18n.t('modal.close')} />
                  </li> : null}
              </ul>
            ) : null}
          </div>
        </span>
      </li>
    );
  }

  componentWillUnmount() {
    this.timeouts.map(t => clearTimeout(t));
  }
}

const mapDispatchToProps = {
  triggerSearch: triggerSearch,
  triggerFilter: triggerFilter,
  remove: remove
};

const mapStateToProps = (state: any, props: TabPaneProps) => ({
  table: getTableForTab(state, props.tabId, props.type),
  profileModificationEnabled: isProfileModificationAllowed(state)
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(TabPane);
