import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import AnalyticsPanelComponent from '../components/AnalyticsPanelComponent';
import {
  setQuadrant,
  updateQuadrant,
  quadrantsResize,
  triggerPanelLock,
  resetAnalytics
} from '../actions/analyticPanel';

import {
  remove as tabRemove
} from '../../shared/ui/actions/tab';
import {
  getAnalyticsQuadrants,
  getAnalyticsAxises,
  getAnalyticsIsLocked,
  getAnalyticsPanelHeight,
  getAnalyticsQuadrantLayout
} from '../selectors/anaylticsPanel';
import withAutoClose from '../../shared/autoclose/components/Autoclose';
import { isQuoteRequestEnabled } from '../../requests/selectors/requests';
import { isTradeReportingUser } from '../../authentication/selectors/authetication';
import { withResizer } from '../components/resizerHOC';

const mapStateToProps = (state: State) => ({
  quadrants: getAnalyticsQuadrants(state),
  axises: getAnalyticsAxises(state),
  isLocked: getAnalyticsIsLocked(state),
  height: getAnalyticsPanelHeight(state),
  quoteRequestsEnabled: isQuoteRequestEnabled(state),
  isTradeReportingUser: isTradeReportingUser(state),
  activeQuadrants: getAnalyticsQuadrants(state),
  quadrantLayout: getAnalyticsQuadrantLayout(state)
});

const mapDispatchToProps = {
  setQuadrant: setQuadrant,
  updateQuadrant: updateQuadrant,
  onQuadrantsResize: quadrantsResize,
  triggerPanelLock: triggerPanelLock,
  tabRemove: tabRemove,
  reset: resetAnalytics
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withAutoClose(withResizer(AnalyticsPanelComponent)));
