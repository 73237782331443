import { ActionsObservable, combineEpics } from 'redux-observable';
import { ActionTypes, triggeredPriceAlarm, deletePriceAlarms } from '../actions/priceAlarms';
import * as Authentication from '../../authentication/actions/authentication';
import { filter, map, switchMap, catchError } from 'rxjs/operators';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import PriceAlarmsService from '../services/priceAlarms';
import { loadPriceAlarms } from '../actions/priceAlarms';

import { of, empty } from 'rxjs';
import { PriceAlarmFormData, PriceAlarm, PriceAlarmCreateRequest, PriceAlarmFormMode, PriceAlarmDeleteRequest } from '../models/priceAlarms';

const service = new PriceAlarmsService();

export const createPriceAlarm: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.CREATE),
    map(action => action.payload),
    switchMap((payload) => {
      return empty();
    }),
    catchError(error => {
      return of(receiveMessage('', error, true));
    })
  );
};

export const loadPriceAlarm: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS),
    map(action => action.payload),
    switchMap((payload) => {
      return service.getPriceAlarms().pipe(
        map(response => loadPriceAlarms(response.priceAlarms))
      );
    }),
    catchError(error => {
      return of(receiveMessage('', error, true));
    })
  );
};

export const triggerPriceAlarm: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS),
    map(action => action.payload),
    switchMap((payload) => {
      return service.subscribePriceTriggerAlarm().pipe(
        map(response => triggeredPriceAlarm(response.priceAlarm))
      );
    }),
    catchError(error => {
      return of(receiveMessage('', error, true));
    })
  );
};

export const subscribePriceAlarms: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS),
    map(action => action.payload),
    switchMap((payload) => {
      return service.subscribePriceAlarms().pipe(
        map(response => loadPriceAlarms(response.priceAlarms))
      );
    }),
    catchError(error => {
      return of(receiveMessage('', error, true));
    })
  );
};

export const submitPriceAlarm: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.SUBMIT_FORM),
    switchMap((action: any) => {
      const payload: PriceAlarmFormData = action.payload;
      const correlationId = action.correlationId;
      if (payload.mode === PriceAlarmFormMode.CREATE) {
        const priceAlarm: PriceAlarm = {
          id: '',
          contract: payload.contractId,
          created: 0,
          expires: payload.expires ? payload.expires.getTime() : 0,
          priceAlarmType: payload.type,
          priceLevel: payload.price,
          expiry: undefined,
          triggeredBy: 0,
          triggered: false,
          customer: payload.customer,
          description: payload.description
        };
        service.sendPriceAlarmCreateRequest(<PriceAlarmCreateRequest> {priceAlarm, correlationId});
      } else if (payload.mode === PriceAlarmFormMode.MODIFY) {
        const priceAlarm: PriceAlarm = {
          id: payload.priceAlarmId,
          contract: payload.contractId,
          created: 0,
          expires: payload.expires ? payload.expires.getTime() : 0,
          priceAlarmType: payload.type,
          priceLevel: payload.price,
          expiry: undefined,
          triggeredBy: 0,
          triggered: false,
          customer: payload.customer,
          description: payload.description
        };
        service.sendPriceAlarmModifyRequest(<PriceAlarmCreateRequest> {priceAlarm, correlationId});
      } else if (payload.mode === PriceAlarmFormMode.CANCEL) {
        const priceAlarm: PriceAlarm = {
          id: payload.priceAlarmId,
          contract: payload.contractId,
          created: 0,
          expires: payload.expires ? payload.expires.getTime() : 0,
          priceAlarmType: payload.type,
          priceLevel: payload.price,
          expiry: undefined,
          triggeredBy: 0,
          triggered: false,
          customer: payload.customer,
          description: payload.description
        };
        service.sendPriceAlarmDeleteRequest(<PriceAlarmDeleteRequest> {priceAlarm, correlationId});
      }
      return empty();
    }),
    catchError(error => {
      return of(receiveMessage('', error, true));
    })
  );
};

export const priceAlarmsDelete: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS),
    map(action => action.payload),
    switchMap((payload) => {
      return service.subscribeDeletePriceAlarm().pipe(
        map(response => deletePriceAlarms(response.priceAlarms))
      );
    }),
    catchError(error => {
      return of(receiveMessage('', error, true));
    })
  );
};

export const priceAlarmsEpics = combineEpics(
  createPriceAlarm,
  loadPriceAlarm,
  triggerPriceAlarm,
  submitPriceAlarm,
  subscribePriceAlarms,
  priceAlarmsDelete
);