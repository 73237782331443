import * as React from 'react';
import { BasketOrder } from '../models/model';
import { Translate, I18n } from 'react-redux-i18n';

export interface BulkProps {
    errors: { [row: number]: string[] };
    bulkOrders: BasketOrder[];
    parsingRunning: boolean;
}

export interface BulkState {

}

export class BulkErrorsTable extends React.Component<
    BulkProps,
    BulkState
    > {
    constructor(props: BulkProps) {
        super(props);
    }

    createRow(index: number, errorNumber: number, error: string) {
        return (
            <tr
                key={index + '__' + errorNumber}
            >
                <td colSpan={1} className="bulkRow">
                    {index}
                </td>
                <td colSpan={1} className="error">
                    <label>{error}</label>
                </td>
            </tr>
        );
    }

    getRowsForRowNumber(index: number) {
        const errors = this.props.errors[index];
        const rows = [];
        for (let i = 0; i < errors.length; i++) {
            rows.push(this.createRow(index, i, I18n.t('bulk.error.' + errors[i])));
        }
        return rows;
    }

    getParsingRunningRow() {
        return this.getEmptyWithText('bulk.parsing');
    }

    getEmptyRow() {
        let displayText = this.props.bulkOrders.length === 0 ? 'bulk.noUpload' : 'bulk.noErrors';
        return this.getEmptyWithText(displayText);
    }

    getEmptyWithText(displayText: string) {
        return (
            <tr><td key={'empty'} colSpan={1} className="text-center"><Translate value={displayText} /></td></tr>
        );
    }

    render() {
        const errors = this.props.errors;
        const errorKeys = Object.keys(errors);
        let empty = this.getEmptyRow();
        const table = errorKeys.map(key => (
            this.getRowsForRowNumber(parseInt(key, 0))
        ));

        const colgroup = (
            <colgroup>
                <col key="bulkRow" className="bulkRow" />
                <col key="error" className="error" />
            </colgroup>
          );

        return (
            this.props.parsingRunning 
            ? (
                <table>
                    <tbody>
                        {this.getParsingRunningRow()}
                    </tbody>
                </table>
            )
            : (
                <div className="sidebar__bulk-views">
                    <table className="table BulkError">
                        {colgroup}
                        <thead>
                            <tr>
                                <th className="bulkRow" scope={'col'}>
                                    <Translate value="bulk.row" />
                                </th>
                                <th className="error" scope={'col'}>
                                    <Translate value="bulk.errors" />
                                </th>
                            </tr>
                        </thead>
                            <tbody className="scrollable unpinnedTable">
                                {errorKeys.length === 0 ? empty : table}
                            </tbody>
                    </table>
                </div>
            )
        );
    }
}