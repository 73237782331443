import '../../../shared/utils/wdyr';
import * as React from 'react';
import "chartiq/js/advanced";
import Chart, { CIQ } from "@chartiq/react-components/Chart/Advanced"
import "../scripts/components";
import { map } from 'rxjs/operators';
import { convertToChartIqInitialData, convertToChartIqUpdateData, getPeriodSeconds, adjustDOM, setupNameValueStore } from '../helpers/chartIQHelper';
import '../styles/estar.css'
import ChartService from '../../../orderbook/services/charting';
import store from '../../../main/store/store';
import { chartSetDrawings, chartSetLayout, chartUnsubscribe, setContract } from '../../ui/actions/chart';
import { Contract } from '../../../orderbook/models/contracts';
import { PartialUpdateChartDataResponse } from '../../../orderbook/models/charts';
import { v1 } from 'uuid';
import { Subscription } from 'rxjs';
import { config } from '../../../main/config';

interface ChartProps {
    activity: boolean;
    dockId: string;
    siblings: number;
    setOhlcPeriod: (contractId: string, period: number, dockId: string) => void;
    chart: Chart;
    contracts: { [key: string]: Contract };
    contractIds: string[];
    contractNameToIdMap: {[name: string]: string};
    drawings: {[contractName: string]: any};
}

interface ChartState {
    visibility: {
        volume: boolean;
    };
    hasError: boolean;
    elemHeight: number;
    quoteFeed: any;
    chartEngine: any;
    nameValueStore: any;
    chartConfig: any;
    chartReady: boolean;
}
  
export default class ChartIQComponent extends React.Component<ChartProps, ChartState> {
    private chartService = new ChartService();
    private chartRef;
    private subscriptions: Subscription[];
    constructor(props: ChartProps) {
        super(props);
        this.chartRef = React.createRef();
        this.chartService = new ChartService();
        const nameValueStore = setupNameValueStore(CIQ, props);

        const contractId = this._getContractName(props);

        this.state = {
          visibility: {
            volume: true
          },
          hasError: false,
          elemHeight: 100,
          quoteFeed: {},
          chartEngine: undefined,
          nameValueStore: nameValueStore,
          chartConfig:  {
                chartId: 'chart'+v1(),
                initialSymbol: {
                    symbol: contractId,
                    name: null,
                    exchDisp: 'IM'
                },
                themes: {
                    defaultTheme: config.theme === 'theme-dark' ? 'DefaultDark' : 'day'
                },
                onEngineReady : this.onEngineReady,
                onChartReady: this.onChartReady,
                nameValueStore: nameValueStore,
                chartEngineParams: {
                    layout: {
                        crosshair: true,
                        periodicity: 1,
                        interval: 1,
                        timeUnit: 'hour'
                    }
                },
                enabledAddOns: {
                    fullScreen: true
                },
                menuPeriodicity: [
                    { type: "item", label: "1 D", cmd: "Layout.setPeriodicity(1,1,'day')", value: { period: 1, interval: 1, timeUnit: 'day'} },
                    { type: "item", label: "1 W", cmd: "Layout.setPeriodicity(1,1,'week')", value: { period: 1, interval: 1, timeUnit: 'week' } },
                    { type: "item", label: "1 Mo", cmd: "Layout.setPeriodicity(1,1,'month')", value: { period: 1, interval: 1, timeUnit: 'month' } },
                    { type: "separator", },
                    { type: "item", label: "1 Min", cmd: "Layout.setPeriodicity(1,1,'minute')", value: { period: 1, interval: 1, timeUnit: 'minute' } },
                    { type: "item", label: "5 Min", cmd: "Layout.setPeriodicity(1,5,'minute')", value: { period: 1, interval: 5, timeUnit: 'minute' } },
                    { type: "item", label: "10 Min", cmd: "Layout.setPeriodicity(2,5,'minute')", value: { period: 2, interval: 5, timeUnit: 'minute' } },
                    { type: "item", label: "15 Min", cmd: "Layout.setPeriodicity(1,15,'minute')", value: { period: 1, interval: 15, timeUnit: 'minute' } },
                    { type: "item", label: "30 Min", cmd: "Layout.setPeriodicity(2,15,'minute')", value: { period: 2, interval: 15, timeUnit: 'minute' } },
                    { type: "item", label: "1 Hour", cmd: "Layout.setPeriodicity(1,1,'hour')", value: { period: 1, interval: 1, timeUnit: 'hour' } },
                    { type: "item", label: "4 Hour", cmd: "Layout.setPeriodicity(4,1,'hour')", value: { period: 4, interval: 1, timeUnit: 'hour' } },
                    { type: "separator", },
                    { type: "item", label: "1 Sec", cmd: "Layout.setPeriodicity(1,1,'second')", value: { period: 1, interval: 1, timeUnit: 'second' } },
                    { type: "item", label: "10 Sec", cmd: "Layout.setPeriodicity(1,10,'second')", value: { period: 1, interval: 10, timeUnit: 'second' } },
                    { type: "item", label: "30 Sec", cmd: "Layout.setPeriodicity(1,30,'second')", value: { period: 1, interval: 30, timeUnit: 'second' } },
                    { type: "separator", },
                    { type: "item", label: "Tick", cmd: "Layout.setPeriodicity(1,1,'tick')", value: { period: 1, timeUnit: 'tick' } }
                ]
            },
            chartReady: false
        };
        this.subscriptions = [];
    }

    onEngineReady  = (engine) => { 
        this.setState((prevState) => {
            return {
                ...prevState,
                chartEngine: engine
            };
        });
        if (engine.layout.studies && Object.keys(engine.layout.studies).map(name => engine.layout.studies[name]).filter(study => study.type === 'volume').length === 0) {
            CIQ.Studies.addStudy(engine, 'volume');
        }
        engine.addEventListener('newChart', this.newChartListener);
        engine.addEventListener('drawing', this.drawingListener);
    };

    onChartReady = (chartEngine) => {
        const restoreLayout = this.props.chart.layout;
        const chartId = this.props.chart.id;
        if (restoreLayout) {
            chartEngine.importLayout(restoreLayout, {managePeriodicity: true, preserveTicksAndCandleWidth: true});
        } else { 
            store.dispatch(chartSetLayout(chartId, chartEngine.exportLayout(true)));
        }
        const layoutListener = function(parameters) {
            store.dispatch(chartSetLayout(chartId, chartEngine.exportLayout(true)));
        };
        chartEngine.addEventListener('layout', layoutListener);
        this.setState((prevState) => {
            return {
                ...prevState, chartReady: true
            };
        });
        adjustDOM();
    };

    newChartListener = (parameters) => {
        const currentContractId = this.props.chart?.contractId;
        const newContractId = this.getContractIdFromName(parameters.symbol);
        if (currentContractId && (newContractId !== currentContractId)) {
            store.dispatch(setContract(this.props.chart.id, this.props.contracts[newContractId], this.props.dockId));
        }
    } 

    drawingListener = (parameters) => {
		const drawings = this.state.chartEngine.exportDrawings();
		store.dispatch(chartSetDrawings(parameters.symbol, drawings));
	};

    componentDidUpdate(prevProps, prevState, snapshot) {
        const allInitialized = !!(this.state.chartEngine && this.props?.contracts && this.props.chart && this.props.contracts[this.props.chart.contractId]);
        if (allInitialized) {
            // TODO check if drawings in prevProps are equals to drawings in this.props. If yes, do not applyDrawings
            if (this.props?.drawings && this.state.chartReady){
                const drawings = this.props.drawings[this.getContractNameFromId(this.props?.chart?.contractId)];
                if (drawings) {
                    this.applyDrawings(this.state, drawings);
                }
            }

            
            if ((prevProps?.chart?.contractId !== this.props?.chart?.contractId 
                    || this.state.chartEngine.chart?.symbol !== this.getContractNameFromId(this.props.chart.contractId))) {
                this.state.chartEngine.loadChart(this.getContractNameFromId(this.props?.chart?.contractId));
            }
        }
    }

    getContractIdFromName = (name: string) => {
        if (this.props?.contractNameToIdMap) {
            return this.props?.contractNameToIdMap[name];
        }
        return undefined;
    }

    getContractNameFromId = (id: string) => {
        if (id && this.props.contracts[id]) {
            return this.props.contracts[id].name;
        }
        return undefined;
    }

    applyDrawings = (state, drawings) => {
        if (state.chartEngine) {
            try {
                state.chartEngine.removeEventListener("drawing", this.drawingListener);
                state.chartEngine.clearDrawings(true, true);
                state.chartEngine.importDrawings(drawings);
                state.chartEngine.draw();
                state.chartEngine.addEventListener("drawing", this.drawingListener);
            } catch (e) {
                console.error(e);
            }
        }
	}
    
    componentDidMount() {
        this.state.quoteFeed.fetchInitialData = (symbol, startDate, endDate, params, cb) => {
            const contractId = this.props.contractNameToIdMap[symbol];
            const periodSeconds = getPeriodSeconds(params.period, params.interval);
            const subscription = this.chartService.inquireChartData({
                contract: contractId,
                period: periodSeconds,
                chartId: this.state.chartConfig.chartId
            }).pipe(map(response => {
                cb(!!response.data[contractId] 
                    ? convertToChartIqInitialData(response.data[contractId], params.interval, periodSeconds)
                    : {quotes: [], moreAvailable: false}
                );
            })).subscribe();
            this.subscriptions.push(subscription);
        };

        this.state.quoteFeed.subscribe = (symbolObject => {
            const contractId = this.props.contractNameToIdMap[symbolObject.symbol];
			const periodSeconds = getPeriodSeconds(symbolObject.period, symbolObject.interval);
            if (contractId) {
				const subscription = this.chartService.subscribeChart(contractId, periodSeconds).pipe(map((data: PartialUpdateChartDataResponse) => {
                    if (data?.aggregates) {
						const contract = this.props.contracts[data.contract];
                        if (data.periodSeconds == getPeriodSeconds(this.state.chartEngine.getPeriodicity().interval, this.state.chartEngine.getPeriodicity().timeUnit)) {
                            if (contract?.name === this.getContractNameFromId(this.props?.chart?.contractId)) {
                                let updates = convertToChartIqUpdateData(data.aggregates, data.periodSeconds);
                                this.state?.chartEngine?.updateChartData(
                                    updates, null, { allowReplaceOHL: true }
                                );
                            
                            } else {
                                this.state?.chartEngine?.updateChartData(
                                    convertToChartIqUpdateData(data.aggregates, data.periodSeconds), null, { secondarySeries: contract?.name, allowReplaceOHL: true }
                                );
                            }
                        }
					}
				})).subscribe();
                this.subscriptions.push(subscription);
			}
        });

        this.state.quoteFeed.unsubscribe = (symbolObject => {
            const periodSeconds = getPeriodSeconds(symbolObject.period, symbolObject.interval);
            store.dispatch(chartUnsubscribe(this.props.contractNameToIdMap[symbolObject.symbol], periodSeconds, this.state.chartConfig.chartId));
        });

        const props = this.props;

        CIQ.ChartEngine.Driver.Lookup.ChartIQ.prototype.acceptText = function (
            text,
            filter,
            maxResults,
            cb
        ) {
            const words: string[] = text?.split(/\s+/g);
            cb(
                props.contractIds
                .filter(id => words.reduce(
                    (res: boolean, w: string) => { 
                        res = res && props.contracts[id] && props.contracts[id].name.toLowerCase().indexOf(w.toLowerCase()) > -1; 
                        return res;
                    }, true)
                )
                .slice(0, 100)
                .map(id => ({display: 
                [props.contracts[id].name, null, "IM"], data: {symbol: props.contracts[id].name, name: null, exchange: "IM"}})
                )
            );
        };   
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        if (this.state.chartEngine) {
            const periodSeconds = getPeriodSeconds(this.state.chartEngine.getPeriodicity().interval, this.state.chartEngine.getPeriodicity().timeUnit);
            store.dispatch(chartUnsubscribe(this.props.contractNameToIdMap[this.getContractNameFromId(this.props.chart.contractId)], periodSeconds, this.props.dockId));

            this.state.chartEngine.container.remove();
            this.state.chartEngine.destroy();
        }
    }

    _getContractName = (props: ChartProps) => {
        let contractId = props.chart.contractId;
        
        if (props.contracts[contractId]) {
            return props.contracts[props.chart.contractId].name;
        } 
        return '-';
    }

    render() {
        return (
            <div style={{position: 'absolute', height: '100%', width: 'calc(' + (100  / this.props.siblings) + '% - 15px)'}} ref={this.chartRef}>
                <Chart config={this.state.chartConfig} resources={{ quoteFeed: this.state.quoteFeed }} /> 
            </div>
        );
    }
}   