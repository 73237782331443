import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import {
  ActionTypes,
  tradeFetch,
  tradeFetchSuccess,
  tradeFetchFailure,
  removeTrades,
  ownTradeFetchSuccess,
  tradeInquireNotify,
  lastPricesFetchSuccess
} from '../actions/trade';

import { ActionTypes as Authentication } from '../../authentication/actions/authentication';
import { ActionTypes as Connection } from '../../authentication/actions/connection';
import { ActionTypes as Contracts } from '../../orderbook/actions/contracts';
import { ActionTypes as Tables, paginatedRequest } from '../../shared/ui/actions/table';
import * as Rx from 'rxjs';
import TradeService from '../services/trade';
import { GenericRequest } from '../../main/models/application';
import ITrade from '../models/trade';
import { State } from '../../main/reducers/rootReducer';
import { isStaticDataLoaded } from '../../orderbook/selectors/orderbooks';
import { SubscribeExternalTradesRequest } from '../models/InquireTradesRequest';
import {
  filter,
  map,
  switchMap,
  catchError,
  mergeMap,
  takeUntil
} from 'rxjs/operators';
import orderBookStore from '../../orderbook/store/orderbooks';
import { ComponentType } from '../../shared/ui/models/component';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import { getTableEntites } from '../../shared/ui/selectors/ui';
import { ITable } from '../../shared/ui/models/table';
import { Observer } from 'rxjs';
import { config } from '../../main/config';

const tradeService = new TradeService();

/**
 * Create map of trades, add self trades
 * @param trades 
 */
const getOwnTradesMap: any = (trades: ITrade[]) => {
  return trades.reduce((acc: { [key: string]: ITrade }, trade: ITrade) => {
    if (trade.selfTrade) {
      acc[trade.tradeId + 'X'] = {...trade, tradeId: trade.tradeId + 'X', buy: !trade.buy };
    }
    acc[trade.tradeId] = trade;
    return acc;
  }, {});
};

/**
 * Create map of trades, add self trades
 * @param trades 
 */
const getAllTradesMap: any = (trades: ITrade[]) => {
  return trades.reduce((acc: { [key: string]: ITrade }, trade: ITrade) => {
    if (trade.tradeId.indexOf('X') === -1) {
      acc[trade.tradeId] = trade;
    }
    
    return acc;
  }, {});
};

/**
 * divide trades to allTrades and ownTrade tables when trades are received together in one response
 * @param tradeMap map of trades (together all and own)
 */
export const divideAllAndOwnTradesMaps = (tradeMap: {[id: string]: ITrade}) => {
  let allTrades: {[id: string]: ITrade} = {}, ownTrades: {[id: string]: ITrade} = {};
  const keys = Object.keys(tradeMap);
  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];
    if (tradeMap[id].ownTrade) {
      ownTrades[id] = tradeMap[id];
    }
    // add to all trades except of double self trades market with X in ID 
    if (id.indexOf('X') === -1) {
      allTrades[id] = tradeMap[id];
    }
  }
  return {allTrades, ownTrades};
};

export const loadTrades: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_FETCH),
    map(action => action.payload),
    switchMap(() => {
      if (isStaticDataLoaded(orderBookStore.getState())) {
        tradeService.sendTrade(<GenericRequest> {
          requestType: 'SUBSCRIBE_TRADES_REQUEST'
        });
      }

      return tradeService.inquireTrades().pipe(
        mergeMap((content: any) => {
          if (content.replace && (!content.tradeMap || !Object.keys(content.tradeMap).length)) {
            return Rx.of(tradeInquireNotify());
          }
          const updatedTradeMap = Object.keys(content.tradeMap).reduce(
            (acc: { [tradeId: string]: ITrade }, id) => {
              acc[id + (content.tradeMap[id].selfTrade ? 'X' : '')] =
                content.tradeMap[id];
              return acc;
            },
            {}
          );
          return Rx.of(tradeFetchSuccess(updatedTradeMap, !!content.replace));
        }),
        catchError(error => Rx.of(tradeFetchFailure(error))),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        )
      );
    })
  );
};

export const loadTradeTables: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_FETCH),
    map(action => action.payload),
    switchMap(() => {
      return Rx.Observable.create((observer: Observer<any>) => {
          getTableEntites(state.value).filter((t: ITable) => t.type === ComponentType.Owntrade || t.type === ComponentType.Trade || t.type === ComponentType.TradeReport).forEach(t => observer.next(paginatedRequest(0, t.sorting, t.type, t.id)));
        });
      }),
      catchError(error => Rx.of(tradeFetchFailure(error))),
      takeUntil(
        actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
      )
    );
};

export const updateTradesOnNotify: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_INQUIRE_NOTIFY),
    map(action => action.payload),
    switchMap(() => {
      return tradeService.updateTrades().pipe(
        mergeMap((content: any) => {
          const {allTrades} =  divideAllAndOwnTradesMaps(content.tradeMap);

          return  Rx.merge(
            Rx.of(tradeFetchSuccess(allTrades, content.replace))/*,
            Rx.of(ownTradeFetchSuccess(ownTrades, true))*/
          );

        }),
        catchError(error => Rx.of(tradeFetchFailure(error))),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        )
      );
    })
  );
};

export const subscribeTrades: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_FETCH),
    map(action => action.payload),
    switchMap(() => {
      return tradeService.subscribeTrades().pipe(
        takeUntil(
          actions$.pipe(
            filter(
              action =>
                action.type === ActionTypes.TRADE_FETCH_COMPLETE ||
                action.type === Connection.DISCONNECT  || 
                action.type === Connection.CONNECTION_LOST
            )
          )
        ),
        map((content: any) => {
          return tradeFetchSuccess(getAllTradesMap(content.trades));
        }),
        catchError(error => Rx.of(tradeFetchFailure(error)))
      );
    })
  );
};

export const subscribeOwnTrades: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_FETCH),
    map(action => action.payload),
    switchMap(() => {
      return tradeService.subscribeOwnTrades().pipe(
        takeUntil(
          actions$.pipe(
            filter(
              action =>
                action.type === ActionTypes.TRADE_FETCH_COMPLETE ||
                action.type === Connection.DISCONNECT  || 
                action.type === Connection.CONNECTION_LOST
            )
          )
        ),
        map((content: any) => {
          return ownTradeFetchSuccess(getOwnTradesMap(content.trades));
        }),
        catchError(error => Rx.of(tradeFetchFailure(error)))
      );
    })
  );
};

export const sendExternalTradeSubscritpion: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS ||
      action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    switchMap(() => {
      tradeService.sendExternalTradeSubscription(<
        SubscribeExternalTradesRequest
        > {
          eurexEnabled: true,
          iceEnabled: true,
          nasdaqEnabled: true,
          trayportEnabled: true,
          epexEnabled: true
        });
      return Rx.empty();
    }),
    takeUntil(
      actions$.pipe(filter(action => action.type === Connection.DISCONNECT || action.type === Connection.CONNECTION_LOST))
    ),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const subscribeExternalTrades: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_FETCH),
    map(action => action.payload),
    switchMap(() => {
      return tradeService.subscribeExternalTrades().pipe(
        takeUntil(
          actions$.pipe(
            filter(
              action =>
                action.type === ActionTypes.TRADE_FETCH_COMPLETE ||
                action.type === Connection.DISCONNECT  || 
                action.type === Connection.CONNECTION_LOST
            )
          )
        ),
        map(trade => {
          return tradeFetchSuccess(trade);
        }),
        catchError(error => Rx.of(tradeFetchFailure(error)))
      );
    })
  );
};

export const subscribeLastPrices: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TRADE_FETCH),
    map(action => action.payload),
    switchMap(() => {
      return tradeService.subscribeLastPrices().pipe(
        takeUntil(
          actions$.pipe(
            filter(
              action =>
                action.type === ActionTypes.TRADE_FETCH_COMPLETE ||
                action.type === Connection.DISCONNECT  || 
                action.type === Connection.CONNECTION_LOST
            )
          )
        ),
        map((content: any) => {
          return lastPricesFetchSuccess(content.lastPrices);
        }),
        catchError(error => Rx.of(tradeFetchFailure(error)))
      );
    })
  );
};

export const loadedLastPrices: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LAST_PRICES_SUCCESS),
    map(action => action.payload),
    switchMap((payload: any) => {
      orderBookStore.dispatch(lastPricesFetchSuccess(payload.lastPrices));
      return Rx.empty();
    })
  );
};

export const connection: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Contracts.STATIC_DATA_LOADED ||
        action.type === Authentication.RELOGIN_SUCCESS /*||
        action.type === Authentication.AUTHENTICATION_SUCCESS*/
    ),
    map(actions => actions.payload),
    mergeMap(() => {
      return Rx.of(tradeFetch()).pipe(
        takeUntil(
          actions$.pipe(
            filter(
              action => action.type === Authentication.AUTHENTICATION_LOGOUT
            )
          )
        )
      );
    })
  );
};

export const loadPaginated: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Tables.PAGINATED_RESULT
    ),
    mergeMap((data: {payload: any, componentType: ComponentType, replace: boolean} ) => {
      if (!!data.payload.tradeMap && 
        (data.componentType === ComponentType.Trade 
          || data.componentType === ComponentType.Owntrade 
          || data.componentType === ComponentType.TradeReport
          )) {
        const {allTrades, ownTrades} =  divideAllAndOwnTradesMaps(data.payload.tradeMap);
        if (data.componentType === ComponentType.Owntrade) {
          return Rx.of(ownTradeFetchSuccess(ownTrades, data.replace));
        } else {
          return Rx.of(tradeFetchSuccess(allTrades, data.replace));
        }
        
      }
      return Rx.empty();
    })
  );
};

export const tradeEpic = combineEpics(
  connection,
  loadTrades,
  loadTradeTables,
  subscribeTrades,
  subscribeExternalTrades,
  subscribeOwnTrades,
  updateTradesOnNotify,
  loadPaginated,
  sendExternalTradeSubscritpion,
  subscribeLastPrices,
  loadedLastPrices
);
