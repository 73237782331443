import { Validation, ValidationResult } from './model';
import { Observable, Observer, of } from 'rxjs';
import { I18n } from 'react-redux-i18n';
import { decimalValidation } from './validators';

export default class ValidationService {
    
    format(rules: any[], value: any): ValidationResult {
        var resultSummary = new ValidationResult(value, true, '');
        if (rules) {
            for (let j = 0; j < rules.length; j++) {
                const result: ValidationResult = rules[j](value);
                if (!result.valid) {
                    resultSummary.valid = false;
                    resultSummary.message = resultSummary.message != null && result.message != null ? (resultSummary.message + result.message) : '';
                }
                resultSummary.value = result.value;
            }
        }
        return resultSummary;
    }

    formatWithDecimals(rules: any[], value: any, maxDecimals: number): any {
        if (value === '' || value === '-') {
            return new ValidationResult(value, true, null);
        }
        var formattedValue = this.format(rules, value);
        if (!formattedValue.valid) {
            return formattedValue;
        }
        return decimalValidation(formattedValue.value, maxDecimals);
    }

    validate(formData: any, validations: Validation): Observable<any> {
        const translationPrefix = validations.translationPrefix;
        const inputs = Object.keys(validations.rules);
        let errors: any[] = [];
        for (let i = 0; i < inputs.length; i++) {
            const rules = validations.rules[inputs[i]];
            for (let j = 0; j < rules.length; j++) {
                if (formData[inputs[i]] === undefined) {
                    continue;
                }
                const result: ValidationResult = rules[j](formData[inputs[i]], formData);
                if (!result.valid && result.message !== null) {
                    errors.push(I18n.t(translationPrefix + '.' + inputs[i]) + ': ' + result.message);
                }
                formData[inputs[i]] = result.value;
            }
        }
        if (errors.length > 0) {
            return Observable.create((observer: Observer<any>) => {
                errors.filter(e => e !== null).forEach(e => observer.error(e));
            });
        }
        return of(true);
    }
}