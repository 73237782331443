import { format } from "date-fns";
import store from "../../../main/store/store";
import { ChartUpdate, OhlcAggregate, OhlcPeriod } from "../../../orderbook/models/charts";
import { chartSetCurrentTheme, chartSetThemes, chartSetViews } from "../../ui/actions/chart";
import { getChartThemes, getChartViews, getTabIds } from "../../ui/selectors/ui";
import Chart, { CIQ } from "@chartiq/react-components/Chart/Advanced"
const DefaultDark = require("./ciq-night.json");
const ChartTitle = CIQ.UI.components('cq-chart-title')[0].classDefinition;

class CustomChartTitle extends ChartTitle {
    update() {
        const originalTitle = document.title;
        super.update();
        document.title = originalTitle;
    }
}
if (!customElements.get('cq-chart-title')) {
    CIQ.UI.addComponentDefinition('cq-chart-title', CustomChartTitle);
}

export function getPeriodSeconds(periodLength: number, timeUnit: 'minute' | 'second' | 'hour' | 'day' | 'week' | 'month'): number {
    switch(timeUnit) {
        case 'second': return periodLength;
        case 'minute': return periodLength * 60;
        case 'hour': return periodLength * 60 * 60;
        case 'day': return periodLength * 60 * 60 * 24;
        case 'week': return periodLength * 60 * 60 * 24 * 7;
        case 'month': return periodLength * 60 * 60 * 24 * 30;
        default: return 0;
    }
}

export function convertToChartIqInitialData(contractChartData: {trades: {[key: string]: []}, dataBefore: boolean}, timeUnit: string, periodSeconds: number) {
    const result = [];
    for (let i = 0; i < contractChartData?.trades?.ohlc?.length; i++) {
        const ohlc = contractChartData.trades.ohlc[i];
        const volume = contractChartData.trades.volume?.length > i && contractChartData.trades.volume[i][0] == ohlc[0] 
            ? contractChartData.trades.volume[i][1] 
            : 0;
        if (timeUnit === 'tick') {
            result.push(
                {
                    'Date': format(new Date(ohlc[0]), 'yyyy-MM-dd HH:mm:ss.SSS'), 
                    'Value': ohlc[1],
                    'Volume':  volume
                }
            );
        } else {
            result.push(
                {
                    'Date': format(new Date(ohlc[0]), 'yyyy-MM-dd HH:mm:ss.SSS'), 
                    'Open': ohlc[1],
                    'High': ohlc[2],
                    'Low': ohlc[3],
                    'Close': ohlc[4],
                    'Volume':  volume
                }
            );
        }
    }

    return {
        quotes: result
    };
}

export function convertToChartIqUpdateData(aggregates: OhlcAggregate[], period: OhlcPeriod) {
    if (period.toString() === 'tick') {
        return aggregates.map(aggregate => { return {
            'Date': format(new Date(aggregate.closeDateTime), 'yyyy-MM-dd HH:mm:ss.SSS'), 
            'Value': aggregate.close,
            'Volume':  aggregate.volume
        }; });
    }
    return aggregates.map((aggregate: OhlcAggregate) => { 
        return {
        'Date': format(new Date(aggregate.dateTime), 'yyyy-MM-dd HH:mm:ss.SSS'), 
        'Open': aggregate.open,
        'High': aggregate.high,
        'Low': aggregate.low,
        'Close': aggregate.close,
        'Volume':  aggregate.volume
    }; });
    
}

export function setupNameValueStore(CIQ: any, props: any) {
    const origValueStore = CIQ.NameValueStore && new CIQ.NameValueStore();

    // views storage
    const MyNameValueStore = function () { };
    MyNameValueStore.prototype.set = function (name, value, cb) {
        if (name == 'stx-views') {
            store.dispatch(chartSetViews(value));
            if (cb) cb(null);
        } else if (name === 'CIQ.Themes.prototype.custom') {
            const themeObject = Object.keys(value).reduce((acc: any, key: string) => { acc[key] = value[key]; return acc;}, {});
            store.dispatch(chartSetThemes(themeObject));
        } else if (name != null && name.endsWith('CIQ.Themes.prototype.current')) {
            store.dispatch(chartSetCurrentTheme(props.chart.id, value.theme));
        } else {
            origValueStore.set(name, value, cb);
        }
    };

    MyNameValueStore.prototype.get = function (name, cb) {
        if (name == 'stx-views') {
            let views = getChartViews(store.getState());
            cb(null, views);
        } else if (name === 'CIQ.Themes.prototype.custom') {
            let chartThemes = getChartThemes(store.getState());
            if (!chartThemes['DefaultDark']) {
                chartThemes = {...chartThemes, ...DefaultDark};
            }
            cb(null, chartThemes);
        } else if (name != null && name.endsWith('CIQ.Themes.prototype.current')) {
            cb(null, {theme: props.chart.currentTheme});
        } else {
            origValueStore.get(name, cb);
        }
    };

    MyNameValueStore.prototype.remove = function (name, cb) {
        if (name == 'stx-views') {
            if (cb) cb(null);
        } else if (name === 'CIQ.Themes.prototype.custom') {
            if (cb) cb(null);
        } else
            origValueStore.remove(name, cb);
    };

    return MyNameValueStore;
}


const fullscreenListener = (cqContext) => {
    if (document.querySelector('body').classList.contains('full-screen')) {
        document.querySelector('body').classList.remove('full-screen');
    } else {
        document.querySelector('body').classList.add('full-screen');
    }
    if (cqContext.classList.contains('full-screen')) {
        cqContext.classList.remove('full-screen');
        document.exitFullscreen();

    } else {
        cqContext.classList.add('full-screen');
        cqContext.requestFullscreen();
    }
};

export function adjustDOM() {
    const fullScreenButtons = document.getElementsByClassName('stx-full-screen');
    for (let i = 0; i < fullScreenButtons.length; i++) {
        const button = (fullScreenButtons[i] as HTMLElement);
        
        const parent = button.parentElement; 
        const html = button.outerHTML;
        button.remove();
        parent.innerHTML = parent.innerHTML + html;
        const cqContext = parent.closest('cq-context');

        parent.querySelector('.stx-full-screen').addEventListener('click', (e) => fullscreenListener(cqContext));
    }
    const symbolSearch = document.querySelector('.symbol-search');
    if (symbolSearch) {
        symbolSearch.querySelector('cq-tooltip').innerHTML = 'Contract search';
    }
}
