import { StompService, StompClient } from '../../main/services/stompService';
import { GenericRequest } from '../../main/models/application';
import { SubscribeExternalTradesRequest } from '../models/InquireTradesRequest';
import RestService from '../../main/services/restService';
import { MarketsheetRequest } from '../../orderbook/models/orderbooks';
import { ComponentType } from '../../shared/ui/models/component';
import { Observable, empty, of } from 'rxjs';

export default class TradeService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public sendTrade(message: GenericRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/generic', JSON.stringify(message))
    );
  }

  public sendExternalTradeSubscription(
    message: SubscribeExternalTradesRequest
  ): Observable<any> {
    return of(
      this.sendMessage('/app/im/externaltrades', JSON.stringify(message))
    );
  }

  public inquireTrades(): Observable<any> {
    return this.subscribe('/user/topic/im/trades');
  }

  public updateTrades(): Observable<any> {
    return this.restService.restPost('/data/trades/update', {});
  }

  public inquireOwnTrades(): Observable<any> {
    return this.subscribe('/user/topic/im/owntrades');
  }

  public subscribeTrades(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/trades');
  }

  public subscribeOwnTrades(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/owntrades');
  }

  public subscribeExternalTrades(): Observable<any> {
    return this.subscribe('/user/topic/im/externaltrades');
  }

  public subscribeLastPrices(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/lastprices');
  }

  getInstrumentTrades(request: MarketsheetRequest, componentType: ComponentType): any {
    if (componentType === ComponentType.Instrument || 
        componentType === ComponentType.InstrumentIntraday ||
        componentType === ComponentType.MarketChart) {
      return this.restService.restPost('/data/trades/instruments', request);
    } else if (componentType === ComponentType.Product) {
      return this.restService.restPost('/data/trades/products', request);
    }
    return empty();
  }
}
