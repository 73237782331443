import * as React from 'react';
import { connect } from 'react-redux';
import { openOrderForm } from '../../../../../orders/actions/orderForm';
import { triggerExpandedExpiry } from '../../../actions/market';
import OrderFormData from '../../../../../orders/models/formData';
import MarketActions from './MarketAction';
import UIMarketInstrumentTable from './uiMarketInstrumentTable';
import { UIMarketExpiryTable } from './uiMarketExpiryTable';
import { DockType } from '../../../../dock/models/dock';
import { DashboardComponent } from '../../../../../dashboard/models/dashboard';
import { fatal } from '../../../../logger/actions/logger';
import { IMarket } from '../../../models/market';
import {
  getExpiries,
  isStaticDataLoaded,
  getOrderbookPricesForDock,
  getContractMatrixesForDock
} from '../../../../../orderbook/selectors/orderbooks';
import { OrderbooksState } from '../../../../../orderbook/reducers/combinedReducer';
import { Translate } from 'react-redux-i18n';
import store from '../../../../../main/store/store';
import { getOrderbookDepthLimit } from '../../../selectors/ui';
import { openQuoteRequest, declineQuoteRequest, acceptQuoteRequest } from '../../../../../requests/actions/requests';
import { QuoteRequestFormData, AcceptQuoteRequestRequest, RejectQuoteRequestRequest } from '../../../../../requests/models/request';
import { getAvailableFavorites } from '../../../../../shared/favorite/selectors/favorite';
import { IFavorite } from '../../../../../shared/favorite/models/favorite';
import { PriceAlarmFormData } from '../../../../../priceAlarm/models/priceAlarms';
import { openPriceAlarmForm } from '../../../../../priceAlarm/actions/priceAlarms';
import { getOrderbookContracts } from '../../../../../orderbook/selectors/contracts';
import { Contract } from '../../../../../orderbook/models/contracts';

interface BookViewProps {
  dockId: string;
  dockType: DockType;
  markets: IMarket[];
  hiddenExpiries: string[];
  expandedExpiries: { [key: string]: any };
  expiryRowsLength: { [key: string]: number };
  favoriteName: string;
  isTableBorderActive: boolean;
  isSeparateCells: boolean;
  colors: { [key: string]: string };
  triggerExpandedExpiry: (
    marketIds: string[],
    expandedExpiry: any,
    dockId: string
  ) => void;
  onCreateChart: (e: any, component: DashboardComponent) => void;
  /// From Store
  matrixes: { [key: string]: any };
  expiries: { [key: string]: any[] };
  prices: {
    [key: string]: {
      orders: { [key: string]: { [group: string]: any[] } };
      trades: { [key: string]: any[] };
    };
  };
  orderbookLoaded: boolean;
  matchPriceFeedOrdersEnabled: boolean;
  anonymizeMarketDataEnabled: boolean;
  matchOnlyModeEnabled: boolean;
  quoteRequestsEnabled: boolean;
  permissions: string[];
  isHeadlinesVisible: boolean;
  presetDepths: { [expiryCode: string]: number };
  modificationAllowed: boolean;
  user: any;
  acceptQuoteRequest: (request: AcceptQuoteRequestRequest) => void;
  declineQuoteRequest: (request: RejectQuoteRequestRequest) => void;
  favorites: IFavorite[];
  columnWidth: string;
  dockSize: {width: number; height: number, top: number; left: number};
  columnCount: number;
  settings: { [key: string]: any };
  contracts: {[key: string]: Contract};
}

interface BookViewState { }

export class UIBookView extends React.Component<BookViewProps, BookViewState> {
  subscribed: string[];
  boxRef: any;
  start: number;
  constructor(props: BookViewProps) {
    super(props);
    this.boxRef = React.createRef();
    this._openOrder = this._openOrder.bind(this);
    this._openQuoteRequest = this._openQuoteRequest.bind(this);
    this._acceptQuoteRequest = this._acceptQuoteRequest.bind(this);
    this._declineQuoteRequest = this._declineQuoteRequest.bind(this);
    this._openPriceAlarm = this._openPriceAlarm.bind(this);

  }

  _openOrder(formData: OrderFormData) {
    store.dispatch(openOrderForm(formData));
  }

  _openQuoteRequest(formData: QuoteRequestFormData) {
    store.dispatch(openQuoteRequest(formData));
  }

  _acceptQuoteRequest(req: AcceptQuoteRequestRequest) {
    store.dispatch(acceptQuoteRequest(req));
  }

  _declineQuoteRequest(req: RejectQuoteRequestRequest) {
    store.dispatch(declineQuoteRequest(req));
  }

  _openPriceAlarm(formData: PriceAlarmFormData) {
    store.dispatch(openPriceAlarmForm(formData));
  }

  /**
   * This method allows merging product marketsheets into one uiMarketInstrumentTable
   * 
   * For regular marketsheets everything remains unchanged, adjustedPrices is a copy of prices map and adjustedMatrices is a copy of matrixes map
   * 
   * For product marketsheets from the same instrument (e.g. Germany Base Month and Germany Base Year) creates common matrix and common prices map
   * which contains all the data from both product marketsheets.
   */
  _transformUiMarketInstrumentParameters() {
    const {
      markets,
      matrixes,
      prices
    } = this.props;
    const instrumentToMarket: {[instrumentId: string]: IMarket} = {};
    const adjustedMatrices = {...matrixes};
    const adjustedPrices = {...prices};

    for (let i = 0; i < markets.length; i++) {
      const alreadyCheckedMarket = instrumentToMarket[markets[i].instrumentId];
      if (!alreadyCheckedMarket) {
        instrumentToMarket[markets[i].instrumentId] = {...markets[i]};
      } else {
        if (matrixes[markets[i].itemId]) {
          adjustedMatrices[alreadyCheckedMarket.itemId] = Object.assign({}, matrixes[alreadyCheckedMarket.itemId],
            {
              expiries: Object.assign({}, adjustedMatrices[alreadyCheckedMarket.itemId].expiries, matrixes[markets[i].itemId].expiries),
              expiryToContract:  Object.assign({}, adjustedMatrices[alreadyCheckedMarket.itemId].expiryToContract, matrixes[markets[i].itemId].expiryToContract),
              name: matrixes[alreadyCheckedMarket.itemId].instrumentName
            }
          );
          adjustedMatrices[markets[i].itemId] = undefined; 
          adjustedPrices[alreadyCheckedMarket.itemId].orders = {...adjustedPrices[alreadyCheckedMarket.itemId].orders, ...prices[markets[i].itemId].orders };
          adjustedPrices[alreadyCheckedMarket.itemId].trades = {...adjustedPrices[alreadyCheckedMarket.itemId].trades, ...prices[markets[i].itemId].trades };
          instrumentToMarket[markets[i].instrumentId] = {...alreadyCheckedMarket, ...markets[i], itemId: alreadyCheckedMarket.itemId};
        }
      }
    }
    return {instrumentToMarket, adjustedPrices, adjustedMatrices};
  }

  setFontSize = (timestamp: number) => {
    if (timestamp - this.start > 1000) {
      const width = document.querySelector(`#instrument-wrapper-${this.props.dockId}`)?.clientWidth || 0;
      const fontSize = Math.max(9, Math.min(13, Math.round(width / this.props.columnCount / 7.)));
      if (fontSize === 0) {
        // could not acquire real width, try again
        requestAnimationFrame(this.setFontSize);
      }
      document.documentElement.style.setProperty('--font-size-'+this.props.dockId, fontSize + 'px');
      this.start = timestamp;
    } else {
      requestAnimationFrame(this.setFontSize);
    }
  }

  componentDidMount(): void {
    requestAnimationFrame((timestamp) => {
        this.start = timestamp;
        this.setFontSize(timestamp);
      }
    );
  }

  componentDidUpdate(prevProps: Readonly<BookViewProps>, prevState: Readonly<BookViewState>, snapshot?: any): void {
    if (prevProps.dockSize.width !== this.props.dockSize.width 
      || prevProps.columnCount !== this.props.columnCount 
      || prevProps.columnWidth !== this.props.columnWidth
      ) {
      requestAnimationFrame((timestamp) => {
          this.start = timestamp;
          this.setFontSize(timestamp);
        }
      );
    }
  }

  render() {
    const {
      dockId,
      markets,
      matrixes,
      expiries,
      prices,
      orderbookLoaded,
      expandedExpiries,
      hiddenExpiries,
      expiryRowsLength,
      favoriteName,
      isTableBorderActive,
      isSeparateCells,
      colors,
      isHeadlinesVisible,
      presetDepths,
      modificationAllowed,
      favorites,
      columnWidth,
      dockSize
    } = this.props;

    if (!markets.length || !orderbookLoaded) {
      return (
        <h2 className="text-center">
          <Translate value="market.profileLoading" />
        </h2>
      );
    }
    
    const visibleExpiries = Object.keys(expiries)
      .filter(e => hiddenExpiries.indexOf(e) === -1);

    let filteredExpiries: { [key: string]: any } = {};
    const isIntraday = visibleExpiries.reduce((acc: boolean, key: string) => acc && !isNaN(Number(key)), true);
    let expiryLengthCounter = { ...expiryRowsLength };

    for (let i = 0; i < visibleExpiries.length; i++) {
      let expiryItems = expiries[visibleExpiries[i]];
      if (isIntraday) {
        expiryItems = expiries[visibleExpiries[i]].filter((expiry: any) => {
          let inLengthLimit = false;
          if (expiryLengthCounter[expiry.periodType] === undefined) {
            inLengthLimit = true;
          }
          if (expiryLengthCounter[expiry.periodType] > 0) {
            inLengthLimit = true;
            expiryLengthCounter[expiry.periodType]--;
          }

          return inLengthLimit && hiddenExpiries.indexOf(expiry.periodType) === -1;

        });
      }
      filteredExpiries[visibleExpiries[i]] = expiryRowsLength[visibleExpiries[i]]
        ? expiryItems.slice(0, expiryRowsLength[visibleExpiries[i]])
        : expiryItems;
    }
   
    const {instrumentToMarket, adjustedPrices, adjustedMatrices} = this._transformUiMarketInstrumentParameters();

    const instrumentTables = Object.keys(instrumentToMarket).map((instrumentId, i) => {
      let m = instrumentToMarket[instrumentId];
      return (
        <UIMarketInstrumentTable
          key={'market-' + m.itemId}
          market={m}
          matrix={adjustedMatrices[m.itemId]}
          expiries={filteredExpiries}
          prices={adjustedPrices[m.itemId]}
          isTableBorderActive={isTableBorderActive}
          isSeparateCells={isSeparateCells}
          onOpenOrder={this._openOrder}
          onCreateChart={this.props.onCreateChart}
          onOpenQuoteRequest={this._openQuoteRequest}
          openPriceAlarmForm={this._openPriceAlarm}
          logFatal={fatal}
          matchPriceFeedOrdersEnabled={this.props.matchPriceFeedOrdersEnabled}
          anonymizeMarketDataEnabled={this.props.anonymizeMarketDataEnabled}
          colors={colors}
          isHeadlinesVisible={isHeadlinesVisible}
          matchOnlyModeEnabled={this.props.matchOnlyModeEnabled}
          quoteRequestsEnabled={this.props.quoteRequestsEnabled}
          permissions={this.props.permissions}
          user={this.props.user}
          acceptQuoteRequest={this._acceptQuoteRequest}
          declineQuoteRequest={this._declineQuoteRequest}
          blockIndex={i}
          instrumentCount={Object.keys(instrumentToMarket).length}
          columnWidth={columnWidth}
          settings={this.props.settings}
          contracts={this.props.contracts}
          dockSize={dockSize}
        />
      );
    });

    return (
      <div data-test="orderbook" key="marketsheet" className="orderbook">
        <React.Fragment>
          <div className="market__actions ml-auto">
            <MarketActions
              dockId={dockId}
              markets={markets}
              expiries={expiries}
              hiddenExpiries={hiddenExpiries}
              favoriteName={favoriteName}
              isHeadlinesVisible={isHeadlinesVisible}
              modificationAllowed={modificationAllowed}
              favorites={favorites}
            />
          </div>
          <div className="books-view" ref={this.boxRef} style={{
                fontSize: `var(--font-size-${dockId})`
          }}>
            <UIMarketExpiryTable
              dockId={dockId}
              data={filteredExpiries}
              expandedExpiries={expandedExpiries}
              onExpiryClick={(expiryRow: any) =>
                store.dispatch(
                  triggerExpandedExpiry(
                    markets.map(m => m.id),
                    expiryRow,
                    dockId
                  )
                )
              }
              presetDepths={presetDepths}
              isTableBorderActive={isTableBorderActive}
              isHeadlinesVisible={isHeadlinesVisible}
              compactColumnsEnabled={this.props.settings.compactColumns?.value}
              dockSize={dockSize}
            />
            <div 
              id={`instrument-wrapper-${dockId}`} 
              key={`instruments-wrapper-${dockId}`} 
              style={{
                display: 'table', 
                fontSize: `var(--font-size-${dockId})`
              }}>
                {instrumentTables}
              </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state: OrderbooksState, props: BookViewProps) => ({
  matrixes: getContractMatrixesForDock(state, props.dockId),
  prices: getOrderbookPricesForDock(state, props.dockId),
  expiries: getExpiries(state, props.dockId),
  orderbookLoaded: isStaticDataLoaded(state),
  presetDepths: getOrderbookDepthLimit(store.getState(), props.dockId),
  favorites: getAvailableFavorites(store.getState()),
  contracts: getOrderbookContracts(state)
});

const mapDispatchToProps = {
  triggerExpandedExpiry: triggerExpandedExpiry,
  openOrder: openOrderForm,
  declineQuoteRequest: declineQuoteRequest,
  acceptQuoteRequest: acceptQuoteRequest
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UIBookView);
