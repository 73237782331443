import { Position, Size } from '../models/grid';
import { Pinning } from '../../dock/models/dock';

export enum Side {
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTON',
  LEFT = 'LEFT'
}

export interface Bounds {
  x: number;
  y: number;
  xMax: number;
  yMax: number;
  xMid: number;
  yMid: number;
  width: number;
  height: number;
}

export interface Box {
  position: Position;
  size: Size;
}

export interface DropBox extends Box {
  id: string;
}

export class Collider {
  /**
   * Detect if active object is in collision with inactive objects
   *
   * @static
   * @template T
   * @param {Bounds} bounds
   * @param {number} [boundsOffset=0]
   * @param {T[]} inactiveObjects
   * @returns {T[]}
   * @memberof Collider
   */
  static detectCollisions<T extends Box>(
    bounds: Bounds,
    inactiveObjects: T[],
    boundsOffset: number = -5
  ): T[] {
    let inCollision: T[] = [];
    for (var k = 0; k < inactiveObjects.length; k++) {
      if (
        inactiveObjects[k].position.x - boundsOffset < bounds.xMax &&
        inactiveObjects[k].position.x +
          inactiveObjects[k].size.width +
          boundsOffset >
          bounds.x &&
        inactiveObjects[k].position.y - boundsOffset < bounds.yMax &&
        inactiveObjects[k].position.y +
          boundsOffset +
          inactiveObjects[k].size.height >
          bounds.y
      ) {
        inCollision.push(inactiveObjects[k]);
      }
    }
    
    return inCollision;
  }

  static detectUpperOrRightOvelap(
    id: string,
    bounds: Bounds,
    inactiveObjects: any[],
  ): {x: number, y: number} {
    let yDiffMAx = 0, xDiffMax = 0;
    for (var k = 0; k < inactiveObjects.length; k++) {
      if (inactiveObjects[k].id === id) {
        continue;
      }
      const yMax = inactiveObjects[k].position.y + inactiveObjects[k].size.height;
      const xMax = inactiveObjects[k].position.x + inactiveObjects[k].size.width;
      let yDiff = 0, xDiff = 0;
      if (
        inactiveObjects[k].position.x < bounds.xMax && xMax > bounds.x &&
        inactiveObjects[k].position.y < bounds.yMax && yMax > bounds.y
      ) {
        if (bounds.yMax > inactiveObjects[k].position.y && bounds.yMax < yMax) {
          yDiff =  bounds.yMax - inactiveObjects[k].position.y;
          if (yDiff > yDiffMAx) {
            yDiffMAx = yDiff;
          }
        }
        if (bounds.xMax < xMax && bounds.xMax > inactiveObjects[k].position.x) {
          xDiff =  bounds.xMax - inactiveObjects[k].position.x;
          if (xDiff > xDiffMax) {
            xDiffMax = xDiff;
          }
        }
      }
    }
    return {x: xDiffMax, y: yDiffMAx};
  }

  static pinCalculation(pinning: Pinning | null, position: Position): Position {
    if (pinning) {
      switch (pinning.axis) {
        case 'x':
          return { x: pinning.value - position.x, y: 0 };
        case 'y':
          return { x: 0, y: pinning.value - position.y };
        default:
          return { x: 0, y: 0 };
      }
    } else {
      return { x: 0, y: 0 };
    }
  }

  static pinObject(pinning: Pinning | null, position: Position): Position {
    if (pinning) {
      switch (pinning.axis) {
        case 'x':
          return { x: pinning.value, y: position.y };
        case 'y':
          return { x: position.x, y: pinning.value };
        default:
          return position;
      }
    } else {
      return position;
    }
  }

  /**
   * Return colliding side of inactive object
   *
   * @static
   * @param {Bounds} obj1 Inactive object
   * @param {Bounds} obj2 Active object
   * @returns {(Side | undefined)}
   * @memberof Collider
   */
  static getCollideSide(obj1: Bounds, obj2: Bounds): Side | undefined {
    let bCollision = obj1.yMax - obj2.y;
    let tCollision = obj2.yMax - obj1.y;
    let lCollision = obj2.xMax - obj1.x;
    let rCollision = obj1.xMax - obj2.x;

    if (
      tCollision < bCollision &&
      tCollision < lCollision &&
      tCollision < rCollision
    ) {
      return Side.BOTTOM;
    }
    if (
      bCollision < tCollision &&
      bCollision < lCollision &&
      bCollision < rCollision
    ) {
      return Side.TOP;
    }
    if (
      lCollision < rCollision &&
      lCollision < tCollision &&
      lCollision < bCollision
    ) {
      return Side.RIGHT;
    }
    if (
      rCollision < lCollision &&
      rCollision < tCollision &&
      rCollision < bCollision
    ) {
      return Side.LEFT;
    }

    return undefined;
  }
  /**
   * Create bounds object from parameters
   *
   * @static
   * @param {number} x
   * @param {number} y
   * @param {number} widht
   * @param {number} height
   * @returns {Bounds}
   * @memberof Collider
   */
  static createBounds(
    x: number,
    y: number,
    width: number,
    height: number
  ): Bounds {
    const xMax = x + width;
    const yMax = y + height;
    const xMid = xMax / 2;
    const yMid = yMax / 2;
    return {
      x: x,
      y: y,
      xMax: xMax,
      yMax: yMax,
      xMid: xMid,
      yMid: yMid,
      width: width,
      height: height
    };
  }

  /**
   * Create bounds object from Box
   *
   * @static
   * @param {Box} box
   * @returns {Bounds}
   * @memberof Collider
   */
  static createBoundsFromBox(box: Box): Bounds {
    const x = box.position.x;
    const y = box.position.y;
    const xMax = x + box.size.width;
    const yMax = y + box.size.height;
    const xMid = xMax / 2;
    const yMid = yMax / 2;
    return {
      x: x,
      y: y,
      xMax: xMax,
      yMax: yMax,
      xMid: xMid,
      yMid: yMid,
      width: box.size.width,
      height: box.size.height
    };
  }

  /**
   * Create box object from Div
   *
   * @static
   * @returns {DropBox}
   * @memberof Collider
   */
  static createDropBoxArgs(
    id: string,
    position: Position,
    size: Size
  ): DropBox {
    return {
      id: id,
      position: position,
      size: size
    };
  }
}
