import { Action, ActionTypes } from '../actions/trade';
import ITrade from '../models/trade';
import { processTradesMap } from './tradeHelper';

export interface State {
  ids: string[];
  entities: { [id: string]: ITrade };
  error: any;
  dataVersion: string;
  lastPrices: {[contract: string]: number[]};
  limit: number;
  days: number;
}

export const initialState: State = {
  ids: [],
  entities: {},
  error: null,
  dataVersion: 'init',
  lastPrices: {},
  limit: 0,
  days: 0
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.TRADE_REMOVE_ALL: {
      return {
        ...state,
        ...initialState,
        dataVersion: 'init'
      };
    }
    case ActionTypes.TRADE_FETCH_SUCCESS: {
      const tradesMap = action.payload;
      return processTradesMap(action, tradesMap, state);
    }

    case ActionTypes.TRADE_FETCH_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }

    case ActionTypes.LAST_PRICES_SUCCESS: {
      return {
        ...state,
        lastPrices: {...state.lastPrices, ...action.payload}
      };
    }

    case ActionTypes.TRADE_LIMIT: {
      if (!action.own) {
        return {
          ...state,
          limit: action.limit === -1 ? 0 : action.limit + state.limit,
          days: action.days
        };
      }
      return state;
    }

    default:
      return state;
  }
}
