import { createSelector } from 'reselect';
import { OrderbooksState } from '../reducers/combinedReducer';

const getOrderbookProductsState = (state: OrderbooksState) => state.orderbook.products;

export const getOrderbookProductIds = createSelector(
  [getOrderbookProductsState],
  s => {
    return s.ids;
  }
);

export const getOrderbookProducts = createSelector(
  [getOrderbookProductsState],
  s => {
    return s.entities;
  }
);

export const getOrderbookProductEntities = createSelector(
  [getOrderbookProductIds, getOrderbookProducts],
  (ids, entities) => ids.map(id => entities[id])
);
