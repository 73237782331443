import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../../main/reducers/rootReducer';
import { getChartDrawings, getChartsForDock } from '../../../selectors/ui';
import UIChartBookView from './uiChartBookComponent';
import { DockType } from '../../../../dock/models/dock';
import { getDockFavoriteName } from '../../../../../dashboard/selectors/dashboard';
import { isProfileModificationAllowed } from '../../../../../authentication/selectors/authetication';
import { getAvailableFavorites } from '../../../../favorite/selectors/favorite';
import { IFavorite } from '../../../../../shared/favorite/models/favorite';

interface UITradesChartViewProps {
  dockId: string;
  dockType: DockType;
  instrumentId: string;
  expiryType: number;
  charts: any[];
  favoriteName: string;
  activity: boolean;
  orderbookLoaded: boolean;
  modificationAllowed: boolean;
  favorites: IFavorite[];
  drawings: {[contractName: string]: any};
}

interface UITradesChartViewState {}

export class UITradesChartView extends React.Component<
  UITradesChartViewProps,
  UITradesChartViewState
> {
  render() {
    const { dockId, dockType, charts, favoriteName, modificationAllowed, favorites, drawings } = this.props;
    const instrumentIds = charts.reduce((ids: string[], chart): string[] => {
      return ids.concat(chart.instrumentId);
    }, []);
    return (
      <UIChartBookView
        dockId={dockId}
        dockType={dockType}
        charts={charts}
        instrumentIds={instrumentIds}
        favoriteName={favoriteName}
        modificationAllowed={modificationAllowed}
        favorites={favorites}
        drawings={drawings}
      />
    );
  }
}

const mapStateToProps = (state: State, props: UITradesChartViewProps) => ({
  charts: getChartsForDock(state, props.dockId),
  favoriteName: getDockFavoriteName(state, props.dockId),
  modificationAllowed: isProfileModificationAllowed(state),
  favorites: getAvailableFavorites(state),
  drawings: getChartDrawings(state)
});

const mapDispatchToProps = {};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITradesChartView);
