import { Action, ActionTypes } from '../actions/market';
import { IMarket, Market } from '../models/market';
import * as OrderbookActions from '../../../orderbook/actions/orderbooks';
import * as TradeActions from '../../../trade/actions/trade';
export interface State {
  ids: string[];
  entities: { [id: string]: IMarket };
  toUnsubscribe: string[];
}

export const initialState: State = {
  ids: [],
  entities: {},
  toUnsubscribe: []
};

export function reducer(
  state: State = initialState,
  action: Action | OrderbookActions.Action | TradeActions.Action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const markets = action.payload;
      const newMarketEntities = markets.reduce(
        (entities: { [id: string]: IMarket }, market: IMarket) => {
          market = {
            ...new Market(
              market.dockId,
              market.title,
              market.type,
              market.itemId,
              market.instrumentId
            ),
            ...market
          };

          return Object.assign(entities, {
            [market.id]: market
          });
        },
        {}
      );

      return {
        ...state,
        ids: Object.keys(newMarketEntities),
        entities: newMarketEntities
      };
    }

    case ActionTypes.CREATE: {
      const book: IMarket = action.payload;

      return {
        ...state,
        ids: [...state.ids, book.id],
        entities: {
          ...state.entities,
          [book.id]: book
        }
      };
    }

    // add instrument to dock
    case ActionTypes.MOVE: {
      const { ids, toDockId } = action.payload;
      const toDockMarkets = state.ids
        .map(id => state.entities[id])
        .filter(market => market.dockId === toDockId);

      const expandedExpiries = toDockMarkets[0] ? toDockMarkets[0].expandedExpiries : [];
      const depths = toDockMarkets[0] ? toDockMarkets[0].depths : [];
      let lowestPrio = Math.max(...toDockMarkets.map(m => m.priority));

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          lowestPrio++;
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              dockId: toDockId,
              priority: lowestPrio,
              expandedExpiries: expandedExpiries,
              depths: depths
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }
    case ActionTypes.REMOVE: {
      const { id } = action.payload;
      const newMarketIds = state.ids.filter(marketId => marketId !== id);
      const removed = state.entities[id];
      const newMarketEntities = newMarketIds.reduce(
        (entities: { [id: string]: IMarket }, marketId: string) => {
          return Object.assign(entities, {
            [marketId]: state.entities[marketId]
          });
        },
        {}
      );

      return {
        ...state,
        ids: newMarketIds,
        entities: newMarketEntities,
        toUnsubscribe: removed ? state.toUnsubscribe.concat([removed.itemId]) : state.toUnsubscribe
      };
    }

    case ActionTypes.TRIGGER_COLUMN_NAMES: {
      const { id, hiddenColumnNames } = action.payload;
      const marketsWithSameInstrument = Object.keys(state.entities).map(k => state.entities[k]).filter(m => m.instrumentId === state.entities[id].instrumentId && m.dockId === state.entities[id].dockId);
      
      const newState = Object.assign({}, state);
      for (let i = 0; i < marketsWithSameInstrument.length; i++) {
        newState.entities = Object.assign({}, newState.entities, {
          [marketsWithSameInstrument[i].id]: Object.assign({}, state.entities[marketsWithSameInstrument[i].id], {
            hiddenColumnNames: hiddenColumnNames
          })
        });
      }
      return { ...state, ...newState };
    }

    case ActionTypes.TRIGGER_EXPANDED_EXPIRY: {
      const { ids, expiryRow } = action.payload;
      const marketsExpandedExpiries = ids
        .map(id => state.entities[id].expandedExpiries)
        .reduce((acc: any, expiry: any) => {
          return {
            ...acc,
            ...Object.keys(expiry).reduce((inAcc: any, key: string) => {
              return {
                ...inAcc,
                [key]: expiry[key]
              };
            }, {})
          };
        }, {});

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return {
            ...entities,
            [id]: {
              ...state.entities[id],
              expandedExpiries:
                expiryRow.code in marketsExpandedExpiries
                  ? {
                      ...Object.keys(marketsExpandedExpiries).reduce(
                        (accumulator, key) => {
                          return marketsExpandedExpiries[key].code ===
                            expiryRow.code
                            ? accumulator
                            : {
                                ...accumulator,
                                [key]: marketsExpandedExpiries[key]
                              };
                        },
                        {}
                      )
                    }
                  : {
                      ...marketsExpandedExpiries,
                      [expiryRow.code]: expiryRow
                    }
            }
          };
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.TRIGGER_EXPIRIES: {
      const { ids, hiddenExpiryKeys, allExpiryKeys } = action.payload;

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              hiddenExpiryKeys: hiddenExpiryKeys,
              allExpiryKeys: allExpiryKeys
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.CHANGE_HEADLINES_VISIBLE: {
      const { ids, isHeadlinesVisible } = action.payload;

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              isHeadlinesVisible: isHeadlinesVisible
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case OrderbookActions.ActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS: {
      const { componentId, contractMatrixItem } = action.payload;
      const expiryKeys = Object.keys(contractMatrixItem)
        .map((key: any) => contractMatrixItem[key])
        .map((item: any) => Object.keys(item.expiries))
        .filter((value, index, arr) => {
          return arr.indexOf(value) === index;
        });

      const targetComponents = state.ids
        .filter(id => state.entities[id].dockId === componentId)
        .reduce((entities: { [id: string]: IMarket }, id: string) => {
          if (state.entities[id].allExpiryKeys.length === 0) {
            return Object.assign(entities, {
              [id]: Object.assign({}, state.entities[id], {
                allExpiryKeys: expiryKeys
              })
            });
          } else {
            return Object.assign(entities, {
              [id]: Object.assign({}, state.entities[id])
            });
          }
        }, {});

      return {
        ...state,
        entities: {
          ...state.entities,
          ...targetComponents
        }
      };
    }

    case ActionTypes.TRIGGER_EXPIRY_ROWS: {
      const { ids, expiryRowsLength } = action.payload;
      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              expiryRowsLength: expiryRowsLength
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.SET_ORDERBOOK_DEPTH: {
      const { depth, expiryCode, dockId } = action.payload;
      const newState = {...state};
      for (let i = 0; i < newState.ids.length; i++) {
        if (newState.entities[newState.ids[i]].dockId === dockId) {
          newState.entities[newState.ids[i]].depths[expiryCode] = depth;
        }
      }
      
      return {
        ...state,
        ...newState
      };
    }

    default:
      return state;
  }
}
