import { State, initialState } from '../reducers/rootReducer';

export const loadState = (): any => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return initialState;
        }
        let stateObject = JSON.parse(serializedState);
        return stateObject;
    } catch (err) {
        return initialState;
    }
};

export const saveState = (state: any) => {
    try {
        // do not save state authorized=true if application is currently reconnecting 
        // (after refresh loginform should be displayed in this case)
        const authorized = state.connection.connecting ? false : (state.auth.authorized && state.connection.connected);
        const loginData = authorized ? {...state.auth.loginData} : null;
        // do not save errors
        const serializedState = JSON.stringify({
            ...state, 
            auth: {...state.auth, error: null, loginPending: false, isButtonTimeoutRunning: false, authorized: authorized, loginData: loginData}, 
            connection: {...state.connection, error: null, attempt: 0, takeoverConfirmShow: false, ssoCodeSent: false}
                });
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        console.log(err);
    }
};

export const removeState = () => {
    try {
        sessionStorage.removeItem('state');
    } catch (err) {
        console.log(err);
    }
};