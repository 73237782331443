import ITrade from "../models/trade";
import { v1 } from "uuid";

/**
 * trade reducer function for fetch own and all trades
 * @param action TRADE_FETCH_SUCCESS or OWN_TRADE_FETCH_SUCCESS
 * @param tradesMap map of trades {id: trade}
 * @param state own or all trades redux state
 */
export function processTradesMap(action: any, tradesMap: any, state: any) {
  const deletedIds: any[] = [];
  
  const tradeIds = Object.keys(tradesMap);
  const newTradeIds: any[] = [];
  const newTradeEntities: { [id: string]: ITrade } = {};
  // create IDs array from new trde map
  // create map of entities based on new IDs
  for (let tradeIdx = 0; tradeIdx < tradeIds.length; tradeIdx++) {
    const key = tradeIds[tradeIdx];
    if (tradesMap[key].deleted) {
      deletedIds.push(key);
    } else if (state.ids.indexOf(key) === -1 || !!action.replace) {
      newTradeIds.push(key);
      newTradeEntities[key] = {
          ...tradesMap[key],
          $index: key
      };
    }
  }
  if (action.replace) {
    return {
      ...state,
      ids: newTradeIds,
      entities: newTradeEntities,
      error: null,
      dataVersion: v1()
    };
  }

  const existingTradeIds = Object.keys(state.entities);
  const filteredEntities: { [id: string]: ITrade } = {};
  // remove deleted trades from existing entities
  for (let tradeIdx = 0; tradeIdx < existingTradeIds.length; tradeIdx++) {
    const tradeId = existingTradeIds[tradeIdx];
    if (deletedIds.indexOf(tradeId) === -1) {
      filteredEntities[tradeId] = {
        ...state.entities[tradeId],
        $index: tradeId
      };
    }
  }

  const notDeletedIds = [];
  for (let i = 0; i < state.ids.length; i++) {
    const id = state.ids[i];
    if (deletedIds.indexOf(id) === -1) {
      notDeletedIds.push(id);
    }
  }
  const ids = notDeletedIds.concat(newTradeIds);
  return {
    ...state,
    ids: ids,
    entities: {
      ...filteredEntities,
      ...newTradeEntities,
    },
    error: null,
    dataVersion: v1()
  };
}