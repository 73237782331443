import React from "react";

const width = (coef) => (16 * coef) + (coef - 0.5) * 4 + 'px';
const height = (coef) => (16 * coef) + (coef - 0.5) * 4 + 'px';

export const GridIcon = ({layout, activeLayout, onIconClick}: {layout: number[]; activeLayout: number[]; onIconClick: () => void;}) => {
  let active = false;
  if (activeLayout && activeLayout.length === layout.length) {
    active = true;
    for (let i = 0; i < layout.length; i++) {
      active = active && layout[i] === activeLayout[i];
    }
  }
  return (
    <div className={`grid-icon${active ? ' grid-active' : ''}`} onClick={(e) => onIconClick()}>
      <div style={layout.indexOf(1) > -1 ? {display: 'block', width: width(layout[1] == 1 ? 1 : 0.5), height: height(layout[2] == 1 ? 1 : 0.5),  float: 'left'} : {display: 'none'}}></div>
      <div style={layout.indexOf(2) > -1 ? {display: 'block', width: width(layout[0] == 2 ? 1 : 0.5), height: height(layout[3] == 2 ? 1 : 0.5),  float: 'right'} : {display: 'none'}}></div>
      <div style={layout.indexOf(3) > -1 ? {display: 'block', width: width(layout[3] == 3 ? 1 : 0.5), height: height(layout[0] == 3 ? 1 : 0.5),  float: 'left'} : {display: 'none'}}></div>
      <div style={layout.indexOf(4) > -1 ? {display: 'block', width: width(layout[2] == 4 ? 1 : 0.5), height: height(layout[1] == 4 ? 1 : 0.5),  float: 'right'} : {display: 'none'}}></div>
    </div>
  );
  };