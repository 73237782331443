import * as React from 'react';
import { Route } from 'react-router-dom';
import App from './containers/App';

import Login from '../authentication/containers/Login';
import { Translate } from 'react-redux-i18n';
import { config } from './config';
import { LogoutPage } from '../authentication/components/LogoutPage';

const NotFound: () => JSX.Element = () => (
  <div>
    <h1><Translate value="error.Access denied" /></h1>
    <p><Translate value="error.page.proceed" /> <a href={config.subfolder + '/login'}><Translate value="error.page.login" /></a></p>
  </div>
);

const BrowserError: () => JSX.Element = () => (
  <div className="h-100">
    <div className="login-container row justify-content-center h-100">
      <div className="login-box center-block" style={{ width: '60%' }}>
        <Translate value="error.Browser not supported" />
        <br/>
      <a
          href={
            'https://techcommunity.microsoft.com/t5/Windows-IT-Pro-Blog/The-perils-of-using-Internet-Explorer-as-your-default-browser/ba-p/331732'
          }
      >
      <Translate value="error.Click here for more information"/>
      </a>
      </div>
    </div>
  </div>
);

export default (
  <>
    <Route exact={true} path="/" component={App} />
    <Route path="/login" component={Login} />
    <Route path="/logout" component={LogoutPage} />
    <Route path="/error" component={NotFound} />
    <Route path="/browserError" component={BrowserError} />
    <Route path="*" component={NotFound} />
  </>
);
