import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { ITable, ITableSort } from '../../shared/ui/models/table';
import ITrade from '../models/trade';
import { sortTable } from '../../shared/utils/helper/sorter';
import { tableVersionSelectorCreator } from '../../shared/utils/selectors/agrSelector';
import { FilterType } from '../../shared/ui/models/tableSpecificFilters';
import { config } from '../../main/config';
import { ComponentType } from '../../shared/ui/models/component';

const getTradesState = (state: State) => state.trades;
const getOwnTradesState = (state: State) => state.ownTrades;

export const getTradeEntities = createSelector(
  getTradesState,
  s => s.entities
);
export const getTradeDataVersion = createSelector(
  getTradesState,
  s => ({ version: s.dataVersion })
);
export const getTradeIds = createSelector(
  getTradesState,
  s => s.ids
);
export const getOwnTradeEntities = createSelector(
  getOwnTradesState,
  s => s.entities
);
export const getOwnTradeDataVersion = createSelector(
  getOwnTradesState,
  s => ({ version: s.dataVersion })
);
export const getOwnTradeIds = createSelector(
  getOwnTradesState,
  s => s.ids
);
export const getAllTrades = createSelector(
  getTradeEntities,
  getTradeIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  }
);

export const getOwnTrades = createSelector(
  getOwnTradeEntities,
  getOwnTradeIds,
  (entities, ids) => ids.map((id: string) => entities[id])
);

export const getLastPrices = createSelector(
  getTradesState,
  (state) => state.lastPrices
);

function applyFilters(trades: ITrade[], filters: { [key: string]: boolean }) {
  let startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  const timestampStartOfDay = startOfDay.getTime();
  let filtered = [...trades];
  const activeFilterKeys = Object.keys(filters).filter(k => filters[k]);
  if (activeFilterKeys.indexOf(FilterType.TRADES_OF_THE_DAY) !== -1) {
    filtered = filtered.filter(r => r.timestamp >= timestampStartOfDay);
  }
  return filtered;
}

const tradeRowsFn = (trades: any[], scoped: {table: ITable | undefined, parentId: string}) => {
  const tradeTable = scoped.table;
  if (tradeTable) {
    
    const filtered = applyFilters(trades, tradeTable.filters);
    const _trades = [];
    for (let tradeIdx = 0; tradeIdx < filtered.length; tradeIdx++) {
      const trade = filtered[tradeIdx];
      const colAccumulator = { id: trade.tradeId };
      for (let colIdx = 0; colIdx < tradeTable.columns.length; colIdx++) {
        const col = tradeTable.columns[colIdx];
        if (col.name === 'date') {
          const d = new Date(trade[col.originalName]);
          d.setHours(0, 0, 0, 0);
          colAccumulator[col.name]= d.getTime();
        } else if (col.name === 'time') {
          const d = new Date(trade[col.originalName]);
          d.setFullYear(9999, 0, 0);
          colAccumulator[col.name] = d.getTime();
        }
        colAccumulator[col.name] = trade[col.originalName];
      }
      _trades.push(colAccumulator);
    }
    let defaultSorting: ITableSort[];
    switch (tradeTable.type) {
      case ComponentType.Trade: defaultSorting = config.ui.tradeTable.defaultSorting; break;
      case ComponentType.Owntrade: defaultSorting = config.ui.ownTradeTable.defaultSorting; break;
      case ComponentType.TradeReport: defaultSorting = config.ui.tradeReportingTable.defaultSorting; break;
      default: defaultSorting = config.ui.tradeTable.defaultSorting;
    }
    return sortTable(_trades, tradeTable.sorting.length === 0 ? defaultSorting : tradeTable.sorting, tradeTable.hiddenColumnNames);
  }
  return [];
};

export const getTradeRows = createSelector(
  [
    getAllTrades,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getTradeDataVersion
  ],
  tradeRowsFn
);

export const getOwnTradeRows = createSelector(
  [
    getOwnTrades,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getOwnTradeDataVersion
  ],
  tradeRowsFn
);

export const getTradeReportRows = tableVersionSelectorCreator(
  [
    getAllTrades,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getTradeDataVersion
  ],
  tradeRowsFn
);

export const getLimitOwnTrades = createSelector(
  getOwnTradesState,
  (state) => {
     return {
       limit: state.limit, 
       days: state.days
     };
    }
);

export const getLimitAllTrades = createSelector(
  getTradesState,
  (state) => {
     return {
       limit: state.limit, 
       days: state.days
     };
    }
);
