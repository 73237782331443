import * as React from 'react';
import { openPriceAlarmForm } from '../actions/priceAlarms';
import { PriceAlarmFormData } from '../models/priceAlarms';
import store from '../../main/store/store';
import UIMarketContextMenu from '../../shared/ui/components/market/components/uiMarketContextMenu';
import { MarketActionType } from '../../shared/ui/models/market';
import { createModifyPriceAlarmFormData, createCancelPriceAlarmFormData } from '../helper/priceAlarms';

export const PriceAlarmTablePopover = (params: any) => {
    const { data, hidePopover, visible, position } = params;
    if (!data || !data.id) {
        return (<div>Error occurred</div>);
    }

    const openForm = (formData: PriceAlarmFormData | null) => {
        if (formData) {
            store.dispatch(openPriceAlarmForm(formData));
        }
        hidePopover();
    };

    const onActionSelect = (e: any, type: MarketActionType) => {
        if (data) {
            switch (type) {
                case MarketActionType.MODIFY_PRICE_ALARM:
                    openForm(createModifyPriceAlarmFormData(data));
                    break;
                case MarketActionType.CANCEL_PRICE_ALARM:
                    openForm(createCancelPriceAlarmFormData(data));
                    break;
                default:
                    break;
            }
        }
    };

    const required: { [x: string]: boolean } = {};
    required[MarketActionType.MODIFY_PRICE_ALARM] = true;
    required[MarketActionType.CANCEL_PRICE_ALARM] = true;

    return ( visible ? (
        <UIMarketContextMenu
            position={position}
            isOpen={visible}
            onAction={onActionSelect}
            dismiss={hidePopover}
            requiredActions={required}
            optinalActions={[]}
        />
    ) : null);
};