import { combineEpics } from 'redux-observable';
import { authEpic } from '../../authentication/epics/authentication';
import { dashboardEpic } from '../../dashboard/epics/dashboard';
import { connectionEpic } from '../../authentication/epics/connection';
import { ssoConnectionEpic } from '../../authentication/epics/ssoConnection';
import { tradeEpic } from '../../trade/epics/trade';
import { ordersEpic } from '../../orders/epics/orders';
import { requestsEpic } from '../../requests/epics/requests';
import { contractsEpic } from '../../orderbook/epics/contracts';
import { instrumentsEpic } from '../../instruments/epics/instruments';
import { uiEpics } from '../../shared/ui/epics/ui';
import { orderbooksEpic } from '../../orderbook/epics/orderbooks';
import { messengerEpic } from '../../shared/messenger/epics/messenger';
import { notificationsEpic } from '../../shared/notifications/epics/notifications';
import { i18nEpic } from '../../shared/i18n/epics/translations';
import { profileEpic } from '../../dashboard/epics/profile';
import { favoriteEpic } from '../../shared/favorite/epics/favorite';
import { loggerEpics } from '../../shared/logger/epics/logger';
import { settingsEpic } from '../../shared/settings/epics/settings';
import { productsEpic } from '../../orderbook/epics/products';
import { passwordEpics } from '../../shared/passwordChange/epics/passwordChange';
import { analyticsEpic } from '../../analyticsPanel/epics/analyticsPanel';
import { basketEpic } from '../../bulkOrders/epics/bulkOrders';
import { paginationEpics } from '../../shared/ui/epics/pagination';
import { tableEpics } from '../../shared/ui/epics/table';
import { chartEpics } from '../../orderbook/epics/chart';
import { priceAlarmsEpics } from '../../priceAlarm/epics/priceAlarms';
import { businessUnitsPortfoliosEpic } from '../../businessUnitPortfolios/epics/businessUnit';
import { quadrantEpic } from '../../dashboard/epics/quadrant';

const rootEpic = combineEpics(
  connectionEpic,
  ssoConnectionEpic,
  loggerEpics,
  settingsEpic,
  messengerEpic,
  notificationsEpic,
  authEpic ,
  dashboardEpic,
  ordersEpic,
  tradeEpic,
  requestsEpic,
  instrumentsEpic,
  uiEpics,
  contractsEpic,
  productsEpic,
  orderbooksEpic,
  i18nEpic,
  profileEpic,
  favoriteEpic,
  passwordEpics,
  analyticsEpic,
  basketEpic,
  paginationEpics,
  tableEpics,
  chartEpics,
  businessUnitsPortfoliosEpic,
  quadrantEpic,
  priceAlarmsEpics
);

export default rootEpic;
