import * as React from 'react';
import { Translate } from 'react-redux-i18n';

interface Props {
  isHeadlinesVisible: boolean;
  toggleHeadlinesCheckbox: (event: any) => void;
  filters: {[key: string]: boolean};
  toggleTableSpecificFilter: (filterType: string, toggled: boolean) => void;
  dockId: string;
}

export default class UITableSpecificFilter extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tableSpecificFilters = this.tableSpecificFilters.bind(this);
  }

  _findDockParent(ele: HTMLElement | null): number {
    if (ele) {
      if (ele.classList.contains('dock__wrapper')) {
        return ele.clientHeight;
      } else if (ele.classList.contains('analytics-quadrant')) {
        return ele.clientHeight - 40; // usable space is reduced in analytics panel
      } else {
        return this._findDockParent(ele.parentElement);
      }
    } else {
      return 100;
    }
  }

  tableSpecificFilters() {
    const { filters } = this.props;
    return Object.keys(filters).map(k => 
      (
          <div className="col-6">
            <input
              type="checkbox"
              name={k}
              id={k}
              key={k + '-input'}
              className="form-check-input"
              checked={filters[k]}
              onChange={(event) => this.props.toggleTableSpecificFilter(k, event.target.checked)}
            />
            <label className="form-check-label" htmlFor={k}>
              <Translate value={`table.${k}`} />
            </label>
          </div>
      )
    );
  }

  render() {
    const {
      isHeadlinesVisible,
      dockId
    } = this.props;
    return (
      <div className="row pt-3">
        <div className="col-12">
              <input
                type="checkbox"
                name={`${dockId}-headlines`}
                id={`${dockId}-headlines`}
                className="form-check-input"
                defaultChecked={isHeadlinesVisible}
                onChange={this.props.toggleHeadlinesCheckbox}
              />
              <label className="form-check-label" htmlFor={`${dockId}-headlines`}>
                <Translate value="table.showHeadlines" />
              </label>
        </div>
        {this.tableSpecificFilters()}
        </div>
    );
  }
}
