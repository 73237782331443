import * as React from 'react';
import { IDashboardQuadrant } from '../models/quadrants';
import { DockType } from '../../shared/dock/models/dock';
import UITradesChartComponent from '../../shared/ui/components/chart/components/uiTradesChartComponent';
import UIMarketView from '../../shared/ui/components/market/components/uiMarketComponent';
import UITabView from '../../shared/ui/components/table/components/uiTabComponent';
import { DropBox, Collider } from '../../shared/utils/helper/collider';
import { I18n, Translate } from 'react-redux-i18n';
import { Position } from '../../shared/utils/models/grid';
import { Grid } from '../../shared/utils/components/grid';
import { Debouncer } from '../../shared/utils/components/debounce';
import store from '../../main/store/store';
import { scrollContent } from '../../shared/dock/actions/dock';
import { getContentNamesForDockId } from '../selectors/dashboard';
const ONE_HUNDRED_PERCENT = 100;

interface DashboardQuadrantProps {
  quadrant: IDashboardQuadrant;
  axises: any;
  onDockDrag: () => void;
  onDockDragFinish: () => void; // XXX
  onDockResize: () => void; 
  onDocksConnect: () => void;
  onSingleDragFromSidemenu: () => void;
  quadrantRefs: React.RefObject<DashboardQuadrantComponent>[];
  quadrantLayout: number[];
}
interface DashboardQuadrantState {
  box: DropBox;
}
const boxSize = 50;

export default class DashboardQuadrantComponent extends React.Component<DashboardQuadrantProps, DashboardQuadrantState> {
  grid: Grid;
  debouncer: Debouncer;
  constructor(props: DashboardQuadrantProps) {
    super(props);

    this.state = {
      box: {
        id: 'quadrant-box' + props.quadrant.id,
        size: {width: boxSize, height: boxSize}, // pixels - box is a small area inside the quadrant
        position: { 
          x: (props.quadrant.id == 1 || props.quadrant.id == 3 ? 0 : 1) * props.quadrant.size.width, 
          y: (props.quadrant.id < 3 ? 0 : 1) * props.quadrant.size.height 
        }
      }
    };

    this.debouncer = new Debouncer();
  }

  componentDidMount() {
    const { quadrant } = this.props;
    const newPosition: Position = this.getBoxPositionFromAxis(this.props);

    this.setState({
      ...this.state,
      box: Collider.createDropBoxArgs('quadrant-box' + quadrant.id, newPosition, quadrant.size)
    });
    this.createGrid();
  }

  componentWillReceiveProps(newProps: DashboardQuadrantProps) {
    const { quadrant } = newProps;
    const newPosition: Position = this.getBoxPositionFromAxis(newProps);
    this.createGrid();
    this.setState({
      ...this.state,
      box: Collider.createDropBoxArgs('quadrant-box' + quadrant.id, newPosition, quadrant.size)
    });
  }

  createGrid = () => {
    if (document.getElementsByClassName('dashboard-quadrant-panel').length) {
      this.grid = new Grid(document.getElementsByClassName('dashboard-quadrant-panel').item(0)?.clientWidth, document.getElementsByClassName('dashboard-quadrant-panel').item(0)?.clientHeight);
    }
  }

  isTop = () => {
    const { quadrant, quadrantLayout } = this.props;
    const pos = quadrantLayout.indexOf(quadrant.id)
    return pos === 0 || pos === 1;
  }

  isLeft = () => {
    const { quadrant, quadrantLayout } = this.props;
    const pos = quadrantLayout.indexOf(quadrant.id)
    return pos === 0 || pos === 2;
  }

  getBoxPositionFromAxis(props: DashboardQuadrantProps): Position {
    const { quadrant } = props;
    if (!this.grid) {
      return {x: 0, y: 0};
    }
    // set position to the center of quadrant area
    return {
      x: (this.isLeft() ? (quadrant.size.width / 2) : ((ONE_HUNDRED_PERCENT - quadrant.size.width) + quadrant.size.width / 2)) / ONE_HUNDRED_PERCENT * (this.grid.fullWidth  - boxSize / 2),
      y: (this.isTop() ? (quadrant.size.height / 2) : ((ONE_HUNDRED_PERCENT - quadrant.size.height) + quadrant.size.height / 2)) / ONE_HUNDRED_PERCENT * (this.grid.fullHeight - boxSize / 2)
    };
  }

  getFullQuadrantBoxPosition() {
    const {quadrant} = this.props;
    const position = {
      x: this.isLeft() ? 0: (ONE_HUNDRED_PERCENT - quadrant.size.width) / ONE_HUNDRED_PERCENT * (this.grid.fullWidth),
      y: this.isTop() ? 0 : (ONE_HUNDRED_PERCENT - quadrant.size.height) / ONE_HUNDRED_PERCENT * (this.grid.fullHeight)
    };
    const size = {
      width: (quadrant.size.width / (ONE_HUNDRED_PERCENT * 1.0)) * this.grid.fullWidth,
      height: (quadrant.size.height / (ONE_HUNDRED_PERCENT * 1.0)) * this.grid.fullHeight
    };
    return Collider.createDropBoxArgs('quadrant-box' + quadrant.id, position, size)
  }

  _handleScroll = (e) => {
    const dockId = this.props.quadrant.dock.id;
    const quadrant = this.props.quadrant;
    this.debouncer.debounce(() => {
      store.dispatch(scrollContent(dockId, {width: quadrant.size.width, height: quadrant.size.height, scrollLeft: e.target?.scrollLeft, scrollTop: e.target?.scrollTop}));
    }, 500);
  };

  render() {
    const quadrant: IDashboardQuadrant = this.props.quadrant;
    let style = {
      height: `${quadrant.size.height}%`,
      width: `${quadrant.size.width}%`,
      transform: 'translate(0)',
      color: 'inherit',
      float: this.isLeft() ? 'left': 'right'
    } as React.CSSProperties;
    
    let classes = 'dashboard-quadrant d-flex justify-content-center align-items-center'; // d-flex
    if (quadrant.isColliding) {
      classes += ' isColliding allowed';
    }
    if (!quadrant.dock) {
      classes += ' isEmpty';
      return (<div className={classes} style={style}><Translate value="dashboard.quadrant" id={quadrant.id}/></div>)
    }
    let dock = <></>;
    if (quadrant.dock.disableRendering && quadrant.dock.type !== DockType.ChartMarket) {
      const dockInfo = getContentNamesForDockId(store.getState(), quadrant.dock.id, quadrant.dock.type)
      dock = (
        quadrant.dock.isRemoving ? (
          <div className="align-center text-center">
            <h4>{I18n.t('notifications.Dock will be removed')}</h4>
          </div>
        ) : (<div className="align-center text-center">
          <h3>{dockInfo}</h3>
        </div>)
      );
    } else {
      dock = (<React.Fragment key={`dashboardDock-${quadrant.dock.id}`}>
          {quadrant.dock.type === DockType.Tabs ? (
            <UITabView dockId={quadrant.dock.id} />
          ) : quadrant.dock.type === DockType.Market ||
            quadrant.dock.type === DockType.MarketIntraday ||
            quadrant.dock.type === DockType.ProductMarket ? (
            <UIMarketView
              dockId={quadrant.dock.id}
              dockType={quadrant.dock.type}
              onCreateChart={this.props.onSingleDragFromSidemenu}
            />
          ) : quadrant.dock.type === DockType.ChartMarket ? (
            <UITradesChartComponent dockId={quadrant.dock.id} dockType={quadrant.dock.type} />
          ) : null}
        </React.Fragment>
      );
    }
    return (
      <div className={`dashboard-quadrant q-${quadrant.id} dock__wrapper${quadrant.isColliding ? ' isColliding' + (quadrant.collidingAllowed ? ' allowed' : ' disallowed') : ''}`} id={`dock-${quadrant.dock?.id || quadrant.id}`} style={style}>
        <div className="box react-resizable" onScroll={this._handleScroll}>
          {dock}
        </div>
      </div>
    );
  }
}