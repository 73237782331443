import * as React from 'react';
import { ITab, Tab } from '../../shared/ui/models/tab';
import { ComponentType } from '../../shared/ui/models/component';
import UITabView from '../../shared/ui/components/table/components/uiTabComponent';
import { I18n } from 'react-redux-i18n';
import Dragger from '../../shared/dragger/container/Dragger';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import { config } from 'src/js/main/config';

interface RecentActionsProps {
  quoteRequestsEnabled: boolean;
  isTradeReportingUser: boolean;
  tabCreate: (tab: ITab) => void;
  onDragActions: (
    e: any,
    components: { type: ComponentType; title: string; args?: any[] }[]
  ) => void;
}
interface State {
  filteredTabTypes: ComponentType[];
}

const recentComponents = [
  {
    type: ComponentType.Order,
    title: 'sidebar.orders'
  },
  {
    type: ComponentType.Trade,
    title: 'sidebar.trades'
  },
  {
    type: ComponentType.Owntrade,
    title: 'sidebar.owntrades'
  },
  {
    type: ComponentType.TradeReport,
    title: 'sidebar.tradereports'
  },
  {
    type: ComponentType.Request,
    title: 'sidebar.requests'
  },
  {
    type: ComponentType.Log,
    title: 'sidebar.logs'
  },
  {
    type: ComponentType.PriceAlarm,
    title: 'sidebar.priceAlarms'
  }
];

export default class RecentActions extends React.Component<
  RecentActionsProps,
  State
  > {
  private activeComponents: any[];

  constructor(props: RecentActionsProps) {
    super(props);

    this.state = {
      filteredTabTypes: []
    };

    this.onDragComplete = this.onDragComplete.bind(this);
  }

  componentWillReceiveProps(newProps: RecentActionsProps) {
    const filtered = recentComponents.map(c => c.type).
      filter(
        comp =>
          newProps.quoteRequestsEnabled || comp !== ComponentType.Request
      )
      .filter(c => (c !== ComponentType.TradeReport || newProps.isTradeReportingUser))
      .filter(c => (c !== ComponentType.Owntrade || !newProps.isTradeReportingUser));
    this.setState({
      ...this.state,
      filteredTabTypes: filtered
    });
  }

  componentDidMount() {
    this.activeComponents = recentComponents;
    for (let component of this.activeComponents) {
      if (!config.priceAlarmsEnabled && component.type === ComponentType.PriceAlarm) {
        continue;
      }
  
      let meta = {
        displayFilter: false,
        displaySearch: false,
        isActive: component.type === ComponentType.Order,
        ordering: 0
      };
      const newTab: ITab = new Tab(
        'recentActions',
        component.title,
        component.type,
        false,
        'recent-' + component.type,
        meta
      );
      
      this.props.tabCreate(newTab);
    }
  }

  onDragSelectedComponent(e: any, title: string) {
    let selectedComponent = this.activeComponents.filter(
      comp =>
        I18n.t(comp.title).localeCompare(title) === 0
    );
    this.props.onDragActions(e, selectedComponent);
  }

  onDragComplete(e: any) {
    this.props.onDragActions(e, this.activeComponents);
  }

  render(): JSX.Element {
    return (
      <div>
        <div className="subtitle">
          <MemoTranslate value="sidebar.recent" tag={'h2'} />
        </div>
        <div className={'sidebar__recent-actions'}>
          <Dragger
            tag="div"
            className="dragger"
            onDragComplete={this.onDragComplete}
          />
          <UITabView dockId={'recentActions'} filteredTabTypes={this.state.filteredTabTypes} onTabDragComplete={(e: any, title: string) => this.onDragSelectedComponent(e, title)} />
        </div>
      </div>
    );
  }
}
