import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './js/main/store/store';
import routes from './js/main/routes';
import { ConnectedRouter } from 'connected-react-router';
import history from './js/main/history';
import { applicationStarted } from './js/authentication/actions/connection';
import serviceWorker from './js/firebase/serviceWorker';

store.dispatch(applicationStarted());
serviceWorker();

ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {routes}
      </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);