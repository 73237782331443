import { Size } from '../../shared/utils/models/grid';
import { Grid } from '../../shared/utils/components/grid';
import * as Dashboard from '../actions/dashboard';
import * as Profile from '../actions/profile';
import * as Dock from '../../shared/dock/actions/dock';
import * as dockReducer from '../../shared/dock/reducers/dock';
import * as quadrantsReducer from './quadrants';
import * as DashboardQuadrant from '../actions/quadrants';

export interface State {
  ids: string[];
  entities: dockReducer.State;
  activeDockId: string | null;
  error: any;
  meta: {
    size: Size;
    cols: number;
    colWidth: number;
  };
  grid: quadrantsReducer.State;
  locked: boolean;
}

export const initialState: State = {
  ids: [],
  entities: {},
  activeDockId: null,
  error: null,
  meta: {
    size: {
      width: 0,
      height: 0
    },
    cols: 24,
    colWidth: 0
  },
  grid: quadrantsReducer.initialState,
  locked: false
};

export function reducer(
  state: State = initialState,
  action: Dashboard.Action | Dock.Action | Profile.Action | DashboardQuadrant.Action
) {
  switch (action.type) {

    case Dashboard.ActionTypes.LOAD: {
      const { size, cols } = action.payload;

      return {
        ...state,
        meta: {
          size: size,
          cols: cols,
          colWidth: Math.floor(size.width / cols)
        },
        entities: dockReducer.reducer(state.entities, action)
      };
    }

    case Dashboard.ActionTypes.LOAD_SUCCESS: {
      const docks = action.payload;
      const dockIds = docks.filter(d => !d.isBeingDragged).map(dock => dock.id);

      return {
        ...state,
        ids: dockIds,
        entities: dockReducer.reducer(
          state.entities,
          Dock.load(docks.filter(d => !d.isBeingDragged), state.meta)
        ),
        activeDockId: state.activeDockId
      };
    }

    case Dock.ActionTypes.CREATE: {
      const newDock = action.payload;

      return {
        ...state,
        ids: [...state.ids, newDock.id],
        entities: dockReducer.reducer(state.entities, action),
        activeDockId: newDock.id
      };
    }

    case Dock.ActionTypes.REMOVE: {
      const dockId = action.payload;

      return Object.assign({}, state, {
        ...state,
        ids: state.ids.filter(id => id !== dockId),
        entities: dockReducer.reducer(state.entities, action),
        activeDockId: null
      });
    }

    case Dock.ActionTypes.ACTIVE: {
      return {
        ...state,
        entities: dockReducer.reducer(state.entities, action),
        activeDockId: action.payload.id
      };
    }
    case Dock.ActionTypes.INACTIVE: {
      return {
        ...state,
        entities: dockReducer.reducer(state.entities, action),
        activeDockId: null,
      };
    }

    case Dashboard.ActionTypes.RESIZE: {
      const { size, cols } = action.payload.meta;
      const oldGrid = new Grid(state.meta.size.width, state.meta.size.height);
      return {
        ...state,
        meta: {
          size: size,
          cols: cols,
          colWidth: Math.floor(size.width / cols)
        },
        entities: dockReducer.reducer(state.entities, Dashboard.resize(action.payload.meta, oldGrid))
      };
    }

    case DashboardQuadrant.ActionTypes.LOAD:
    case DashboardQuadrant.ActionTypes.QUADRANT_GRID_TOGGLE:
    case DashboardQuadrant.ActionTypes.QUADRANT_RESIZE:
    case DashboardQuadrant.ActionTypes.QUADRANT_COLLIDE_START:
    case DashboardQuadrant.ActionTypes.QUADRANT_COLLIDE_STOP:
    case DashboardQuadrant.ActionTypes.QUADRANT_ADD_DOCK:
    case DashboardQuadrant.ActionTypes.QUADRANT_REMOVE_DOCK:
    case DashboardQuadrant.ActionTypes.QUADRANT_TOGGLE:
      return {
        ...state,
        grid: quadrantsReducer.reducer(state.grid, action)
      };

    case Dashboard.ActionTypes.CLEAN: {
      return {...initialState, grid: {...state.grid}, meta: {...state.meta}};
    }
    
    default:
      return {
        ...state,
        entities: dockReducer.reducer(state.entities, action)
      };
  }
}
