import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { DockType } from '../../dock/models/dock';
import { IFavorite, IFavoriteMarkets } from '../models/favorite';
import { config } from '../../../main/config';
import Dragger from '../../dragger/container/Dragger';
import { MemoTranslate } from '../../i18n/components/memoTranslate';

function isBlocked(favorite: IFavorite, activeMarketInstruments: string[], activeMarketProducts: string[], activeChartInstruments: string[]): boolean {
  if (favorite.entityType === DockType.Market || favorite.entityType === DockType.MarketIntraday) {
    for (let entity of favorite.entities) {
      if (activeMarketInstruments.indexOf(entity.instrumentId || '') > -1) {
        return true;
      }
    }
  } else if (favorite.entityType === DockType.ProductMarket) {
    for (let i = 0; i < favorite.entities.length; i++) {
      let entity = favorite.entities[i];
      if (activeMarketProducts.indexOf(entity.itemId || '') > -1) {
        return true;
      }
    }
  } else {
    for (let entity of favorite.entities) {
      if (activeChartInstruments.indexOf(entity.instrumentId || '') > -1) {
        return true;
      }
    }
  }
  return false;
}

interface FavoriteItem {
  favorite: IFavorite;
  blocked: boolean; // Favorite that can't be dragged to dashboard because it's already there
  visibleBefore: boolean;
  visibleAfter: boolean;
  deleteAfter: boolean;
}

interface FavoriteSelectableListProps {
  favorites: IFavorite[];
  activeMarketInstruments: string[];
  activeChartInstruments: string[];
  activeMarketProducts: string[];

  onSelect: (e: any, favorite: IFavorite) => void;
  onClose: () => void;
  onFavoriteRemove: (favorite: IFavorite) => void;
  onFavoriteChangeVisibility: (favoriteId: string, isVisible: boolean) => void;
}

interface FavoriteSelectableListState {
  items: FavoriteItem[];
}

export class FavoriteSelectableList extends React.Component<FavoriteSelectableListProps, FavoriteSelectableListState> {

  constructor(props: FavoriteSelectableListProps) {
    super(props);

    const { favorites, activeMarketInstruments, activeChartInstruments, activeMarketProducts } = this.props;
    this.state = {
      items: favorites.map(favorite => ({
        favorite,
        blocked: isBlocked(favorite, activeMarketInstruments, activeMarketProducts, activeChartInstruments),
        visibleBefore: favorite.isVisible,
        visibleAfter: favorite.isVisible,
        deleteAfter: false
      }))
    };
  }

  handleToggleVisible(favoriteId: string) {
    this.setState(prevState => ({
      ...prevState,
      items: prevState.items.map(item => item.favorite.id === favoriteId ? {
        ...item,
        visibleAfter: !item.visibleAfter
      } : item)
    }));
  }

  handleDelete(favoriteId: string) {
    this.setState(prevState => ({
      ...prevState,
      items: prevState.items.map(item => item.favorite.id === favoriteId ? {
        ...item,
        deleteAfter: true
      } : item)
    }));
  }

  handleSave() {
    this.state.items.forEach(item => {
      if (item.deleteAfter) {
        this.props.onFavoriteRemove(item.favorite);
      } else if (item.visibleBefore !== item.visibleAfter) {
        this.props.onFavoriteChangeVisibility(item.favorite.id, item.visibleAfter);
      }
    });
    this.props.onClose();
  }

  render(): JSX.Element {
    const { items } = this.state;
    const tableBody = items
      .filter(item => !item.deleteAfter)
      .map(item => (
        <tr key={item.favorite.id}>
          <td>
            <Dragger
              tag="div"
              className={'dragger-item' + (item.blocked ? ' blocked' : '')}
              onDragComplete={(e: any) => this.props.onSelect(e, item.favorite)}
            > <div className="left-container">
              {item.favorite.entityType === DockType.Market || item.favorite.entityType === DockType.MarketIntraday || item.favorite.entityType === DockType.ProductMarket ? (
                <span className="oi oi-list" />
              ) : (
                  <span className="oi oi-graph" />
                )}
              <span className="favorite-name" > <label>{item.favorite.name}</label> </span>
              </div>
            </Dragger>
          </td>
          <td className="text-center">
            <input
              type="checkbox"
              id={item.favorite.id}
              checked={item.visibleAfter}
              disabled={!item.visibleAfter && items.filter(i => i.visibleAfter && !i.deleteAfter).length >= config.sidebarFavoritesListLimit}
              onChange={() => this.handleToggleVisible(item.favorite.id)}
            />
            <label htmlFor={item.favorite.id}>&nbsp;</label>
          </td>
          <td className="text-center">
            <i
              className="oi oi-trash ml-auto failed"
              onClick={() => this.handleDelete(item.favorite.id)}
              title={I18n.t('modal.favoriteRemove')}
            />
          </td>
        </tr>
      ));

    return (
      <div className="sidebar__favorite-views__borderless-list" >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>
                <Translate value="favorite.form.name" />
              </th>
              <th className="text-center">
                <Translate value="favorite.listed" />
              </th>
              <th className="text-center">
                <Translate value="modal.favoriteRemove" />
              </th>
            </tr>
          </thead>
          <tbody>
            {tableBody}
          </tbody>
        </table>
        <div className="d-flex">
          <button
            className="btn btn-approval w150 ml-auto"
            onClick={() => this.handleSave()}
          >
            <Translate value="favorite.form.save" />
          </button>
        </div>
      </div>
    );
  }
}

/** Short list - on the dashboard. */
export const FavoriteList = ({ favorites, onSelect, activeMarketInstruments, activeChartInstruments, activeMarketProducts, openMore }
  : {
    favorites: IFavorite[];
    onSelect: (e: any, favorite: IFavorite) => void;
    activeMarketInstruments: string[];
    activeChartInstruments: string[];
    activeMarketProducts: string[],
    openMore: () => void;
  }) => {

  let favoritesList = favorites
    .filter(favorite => favorite.isVisible)
    .map((favorite: IFavorite) => (
      <Dragger
        tag="li"
        key={favorite.id}
        className={'dragger-item' + (isBlocked(favorite, activeMarketInstruments, activeChartInstruments, activeMarketProducts) ? ' blocked' : '')}
        onDragComplete={(e: any) => onSelect(e, favorite)}
      > <div className="left-container">
        {favorite.entityType === DockType.Market || favorite.entityType === DockType.MarketIntraday || favorite.entityType === DockType.ProductMarket ? (
          <span className="oi oi-list" />
        ) : (
            <span className="oi oi-graph" />
          )}
        <label className="favorite-name">{favorite.name}</label>
        </div>
      </Dragger>)
    ).slice(0, config.sidebarFavoritesListLimit);

  return (
    <div className="sidebar__favorite-views__list">
      <ul data-test="favorite-markets-list">
        {favoritesList}
        <li className="more" key="favorites-more" onClick={() => openMore()}>
          <MemoTranslate value="sidebar.more" />
        </li>
      </ul>
    </div>
  );
};
