import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import {
  ActionTypes as Authentication,
  loginFailure,
  logout as logoutAction,
  imLoginRequest,
  refreshToken
} from '../actions/authentication';
import {
  ActionTypes as Connection,
  ssoConnectionStart
} from '../actions/connection';
import { filter, mapTo, map, mergeMap, catchError, switchMap, flatMap } from 'rxjs/operators';
import * as Rx from 'rxjs';
import { State } from '../../main/reducers/rootReducer';

import LoginFormData from '../models/login';
import {
  connectionStart,
  disconnect,
  reconnectionSuccess
} from '../actions/connection';
import { StompService, StompClient } from '../../main/services/stompService';
import { LOCATION_CHANGE } from 'connected-react-router';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import { readSSOData, redirectAndReadData } from '../keycloak/helper';
import { config } from '../../main/config';
import { getLoginData } from '../selectors/authetication';
import { heartbeatSender } from '../helper/heartbeatSender';

const stompService = new StompService(StompClient);

export const login: any = (
  actions$: ActionsObservable<any>,
  store: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_REQUEST),
    map(action => action.payload),
    mergeMap((userFormData: LoginFormData) => {
      return Rx.of(connectionStart(userFormData));
    })
  );
};

export const relogin: any = (
  actions$: ActionsObservable<any>,
  store: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_VERIFY),
    map(action => action.payload),
    flatMap((userFormData: LoginFormData) => {
        stompService.disconnect(); 
        return stompService.connect(userFormData).pipe(
          map((frame: any) => {
            if (frame.username) {
              stompService.sendMessage('/app/login', JSON.stringify(frame));
              return imLoginRequest(frame);
            } else {
              return reconnectionSuccess();
            }
          }),
          catchError((error: any) =>
            Rx.of(receiveMessage('', error, true))
          )
        );
      }
    )
  );
};

export const logout: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CLOSE_CLEAN),
    map(action => action.payload),
    switchMap((content: any) => {
      stompService.sendLogout();
      return Rx.of(disconnect());
    })
  );
};

export const logoutOnErrorPage: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === LOCATION_CHANGE),
    map(action => action.payload),
    switchMap((action: any) => {
      if (action.pathname === '/error') {
        return Rx.of(
          logoutAction(new Error('error.Error occurred'))
        );
      }
      return Rx.empty();
    })
  );
};

export const logoutError: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_LOGOUT),
    map(action => action.payload),
    switchMap((action: any) => {
      heartbeatSender.stop();
      if (action) {
        return Rx.of(loginFailure(action));
      }
      return Rx.empty();
    })
  );
};

export const failAuthentication: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_FAILURE),
    map(actions => actions.payload),
    switchMap(content => {
      stompService.sendLogout();
      return Rx.empty();
    })
  );
};

export const tokenRefresh: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      return stompService.subscribe('/user/topic/im/token').pipe(
        map((content: any) => {
          return refreshToken(content);
        }),
        catchError(error => {
          return Rx.of(loginFailure(error));
        })
      );
    })
  );
};

export const authEpic = combineEpics(
  login,
  logout,
  logoutError,
  logoutOnErrorPage,
  relogin,
  failAuthentication,
  tokenRefresh
);
