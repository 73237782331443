import { IMarket } from '../models/market';
import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { ComponentType } from '../models/component';

export enum ActionTypes {
  LOAD = '[UI][Market] Load',
  CREATE = '[UI][Market] Create',
  MOVE = '[UI][Market] Move',
  REMOVE = '[UI][Market] Remove',
  TRIGGER_FILTER = '[UI][Market] Trigger filter',
  TRIGGER_COLUMN_NAMES = '[UI][Market] Show/hide column names',
  TRIGGER_EXPIRIES = '[UI][Market] Show/hide Expiries cols',
  TRIGGER_EXPANDED_EXPIRY = '[UI][Market] Show/hide Expanded expiries',
  TRIGGER_EXPIRY_ROWS = '[UI][Market] Show/hide Expiries rows',
  CHANGE_HEADLINES_VISIBLE = '[UI][Market] Show/hide headlines',
  SET_ORDERBOOK_DEPTH = '[UI][Market] Set orderbook depth'
}

export interface MarketLoadAction {
  type: ActionTypes.LOAD;
  payload: IMarket[];
}

export interface MarketCreateAction extends LogAction {
  type: ActionTypes.CREATE;
  payload: IMarket;
}

export interface MarketMoveAction extends LogAction {
  type: ActionTypes.MOVE;
  payload: { ids: string[]; dockId: string; toDockId: string, componentType: ComponentType };
}

export interface MarketRemoveAction extends LogAction {
  type: ActionTypes.REMOVE;
  payload: { id: string; dockId: string };
}

export interface MarketTriggerColumnNamesAction {
  type: ActionTypes.TRIGGER_COLUMN_NAMES;
  payload: { id: string; hiddenColumnNames: string[]; dockId: string };
}

export interface MarketTriggerExpandedExpiryAction {
  type: ActionTypes.TRIGGER_EXPANDED_EXPIRY;
  payload: { ids: string[]; expiryRow: any; dockId: string };
}

export interface MarketChangeHeadlinesVisibilityAction {
  type: ActionTypes.CHANGE_HEADLINES_VISIBLE;
  payload: { ids: string[]; isHeadlinesVisible: boolean, dockId: string };
}

export interface MarketTriggerExpiriesAction {
  type: ActionTypes.TRIGGER_EXPIRIES;
  payload: {
    ids: string[];
    hiddenExpiryKeys: string[];
    allExpiryKeys: string[];
    dockId: string;
  };
}

export interface MarketTriggerExpiryRowsAction {
  type: ActionTypes.TRIGGER_EXPIRY_ROWS;
  payload: { ids: string[]; expiryRowsLength: {}; dockId: string };
}

export interface SetOrderbookDepthAction {
  type: ActionTypes.SET_ORDERBOOK_DEPTH;
  payload: {depth: number; expiryCode: string; dockId: string};
}

export function load(markets: IMarket[]): MarketLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: markets
  };
}

export function create(market: IMarket): MarketCreateAction {
  return {
    type: ActionTypes.CREATE,
    payload: market,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('market.logs.create', {instrument: market.itemId, dock: market.dockId})
  };
}

export function move(
  marketIds: string[],
  dockId: string,
  toDockId: string,
  componentType: ComponentType
): MarketMoveAction {
  return {
    type: ActionTypes.MOVE,
    payload: { ids: marketIds, dockId: dockId, toDockId: toDockId, componentType: componentType },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('market.logs.move', {fromDock: dockId, toDock: toDockId})
  };
}

export function remove(marketId: string, dockId: string): MarketRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: { id: marketId, dockId: dockId },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('market.logs.remove', {dock: dockId})
  };
}

export function triggerColumnNames(
  id: string,
  hiddenColumnNames: string[],
  dockId: string
): MarketTriggerColumnNamesAction {
  return {
    type: ActionTypes.TRIGGER_COLUMN_NAMES,
    payload: { id: id, hiddenColumnNames: hiddenColumnNames, dockId: dockId }
  };
}

export function triggerExpiries(
  ids: string[],
  hiddenExpiryKeys: string[],
  allExpiryKeys: string[],
  dockId: string
): MarketTriggerExpiriesAction {
  return {
    type: ActionTypes.TRIGGER_EXPIRIES,
    payload: {
      ids: ids,
      hiddenExpiryKeys: hiddenExpiryKeys,
      allExpiryKeys: allExpiryKeys,
      dockId: dockId
    }
  };
}

export function triggerExpandedExpiry(
  ids: string[],
  expiryRow: any,
  dockId: string
): MarketTriggerExpandedExpiryAction {
  return {
    type: ActionTypes.TRIGGER_EXPANDED_EXPIRY,
    payload: {
      ids,
      expiryRow,
      dockId
    }
  };
}

export function triggerExpiryRows(
  ids: string[],
  expiryRowsLength: {},
  dockId: string
): MarketTriggerExpiryRowsAction {
  return {
    type: ActionTypes.TRIGGER_EXPIRY_ROWS,
    payload: { ids: ids, expiryRowsLength: expiryRowsLength, dockId: dockId }
  };
}

export function changeHeadlinesVisible(
  ids: string[],
  isHeadlinesVisible: boolean,
  dockId: string
): MarketChangeHeadlinesVisibilityAction {
  return {
    type: ActionTypes.CHANGE_HEADLINES_VISIBLE,
    payload: { ids: ids, isHeadlinesVisible: isHeadlinesVisible, dockId: dockId }
  };
}

export function setOrderbookDepth(
  depth: number,
  expiryCode: string,
  dockId: string
): SetOrderbookDepthAction {
  return{
    type: ActionTypes.SET_ORDERBOOK_DEPTH,
    payload: {depth, expiryCode, dockId}
  };
}

export type Action =
  | MarketLoadAction
  | MarketCreateAction
  | MarketMoveAction
  | MarketRemoveAction
  | MarketTriggerColumnNamesAction
  | MarketTriggerExpandedExpiryAction
  | MarketTriggerExpiriesAction
  | MarketTriggerExpiryRowsAction
  | MarketChangeHeadlinesVisibilityAction
  | SetOrderbookDepthAction;
