import { PriceAlarm, PriceAlarmFormData, PriceAlarmFormMode, PriceAlarmType } from '../models/priceAlarms';
import { Action, ActionTypes } from '../actions/priceAlarms';

export interface State {
  priceAlarms: {[id: string]: PriceAlarm};
  ids: string[];
  formVisible: boolean;
  formData: PriceAlarmFormData | undefined;
}

export const initialState: State = {
  priceAlarms: {},
  ids: [],
  formVisible: false,
  formData: { mode: PriceAlarmFormMode.CREATE, priceAlarmId: '', contractId: '', price: 0, type: PriceAlarmType.LAST_PRICE_LESS, expires: undefined, customer: '', description: ''  }
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const priceAlarms = action.payload;
      const newEntities = priceAlarms.reduce((acc: {[id: string]: PriceAlarm}, priceAlarm: PriceAlarm) => {
        acc[priceAlarm.id] = {...priceAlarm, $index: priceAlarm.id };
        return acc;
      }, {});
      const entities = {...state.priceAlarms, ...newEntities};
      return {
        ...state,
        ids: Object.keys(entities),
        priceAlarms: entities
      };
    }

    case ActionTypes.TRIGGER: {
      const priceAlarm = action.payload;
      
      return {
        ...state,
        priceAlarms: {...state.priceAlarms, [priceAlarm.id]: priceAlarm}
      };
    }

    case ActionTypes.DELETE: {
      const priceAlarms = action.payload;
      const newState = {...state};
      priceAlarms.forEach((alarm: PriceAlarm) => {
        delete newState.priceAlarms[alarm.id];
      });
      newState.ids = Object.keys(newState.priceAlarms);
      return {
        ...state,
        priceAlarms: newState.priceAlarms,
        ids: newState.ids
      };
    }

    case ActionTypes.OPEN_FORM: {
      return {
        ...state,
        formVisible: true,
        formData: action.payload
      };
    }

    case ActionTypes.CLOSE_FORM: {
      return {
        ...state,
        formVisible: false
      };
    }

    default: return state;
  }
}