import * as React from 'react';
import { Translate } from 'react-redux-i18n';

interface ToggleSwitchState {
    value: boolean;
 }
 
interface ToggleSwitchProps {
    value: boolean;
    trueTitle: string;
    falseTitle: string;
    disabled: boolean;
    toggle: (value: boolean) => void;
 }
 
export class ToggleSwitch extends React.Component<ToggleSwitchProps, ToggleSwitchState> {
    constructor(props: ToggleSwitchProps) {
        super(props);
    }
    render() {
        const {toggle, value, disabled, trueTitle, falseTitle} = this.props;
        
        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                    className={value ? 'btn  active bid' : 'btn  bid'}
                    onClick={() =>
                    disabled ? null : toggle(true)
                    }
                >
                    <input
                        type="radio"
                        name="options"
                        id="option1"
                        autoComplete="off"
                    />
                    <Translate value={trueTitle} />
                </label>
                <label
                    className={!value ? 'btn active ask' : 'btn ask'}
                    onClick={() =>
                    disabled ? null : toggle(false)
                    }
                >
                    <input type="radio" name="buy" id="buy0" autoComplete="off" />
                    <Translate value={falseTitle} />
                </label>
            </div>
        );
    }
}