import { StompService, StompClient } from '../../main/services/stompService';
import { MarketsheetRequest, MarketsheetContractDepthRequest } from '../models/orderbooks';
import { Observable, of, empty } from 'rxjs';
import RestService from '../../main/services/restService';

export default class OrderbooksService extends StompService {
    private restService: RestService;

    constructor() {
      super(StompClient);
      this.restService = new RestService();
    }

    public sendInstrumentContractMatrixRequest(message: any): any {
        return of(this.sendMessage('/app/im/marketsheet/instrument', JSON.stringify(message)));
    }

    public sendProductContractMatrixRequest(message: any): any {
        return of(this.sendMessage('/app/im/marketsheet/product', JSON.stringify(message)));
    }
    
    public inquireContractMatrixWs(): Observable<any> {
        return this.subscribe('/user/topic/im/marketsheet');
    }
    /** not used yet */
    public inquireContractMatrix(request: MarketsheetRequest):  Observable<any> {
        return this.restService.restPost('/data/contractMatrix', request);
    }

    public inquireOrderbooks(): Observable<any> {
        return this.subscribe('/user/topic/im/orderbooks');
    }

    public subscribeOrderbooks(): Observable<any> {
        return this.subscribe('/user/topic/im/sub/orderbooks');
    }

    public sendUnsubscribe(message: any): Observable<any> {
        return of(this.sendMessage('/app/im/unsub/orderbooks', JSON.stringify(message)));
    }

    public sendMarketsheetContractDepthRequest(contractDepths: {[contract: string]: number}, instrumentIds?: string[], componentId?: string): Observable<any> {
      const request = <MarketsheetContractDepthRequest> {
        contractDepths, componentId, instrumentIds
      };
      return this.restService.restPost('/data/marketsheet/depths', request);
    }

}