import * as React from 'react';
import { Favorite, IFavorite } from '../../../../favorite/models/favorite';
import { toggleFavorite } from '../../../../favorite/actions/favorite';
import { Chart } from '../../../models/chart';
import { DockType } from '../../../../dock/models/dock';
import FavoriteIcon from '../../actionIcons/FavouriteIcon';
import store from '../../../../../main/store/store';

interface ChartFavoriteProps {
  dockId: string;
  dockType: DockType;
  charts: any[];
  favoriteName: string;
  favorites: IFavorite[];
}

export default class ChartFavorite extends React.Component<ChartFavoriteProps> {

  handleFavoriteSubmit(name: string) {
    const { dockId, dockType, charts, favoriteName } = this.props;
    const newFavorite = new Favorite(
      name || favoriteName,
      dockId,
      charts.map((chart: Chart) => ({
        componentType: chart.type,
        title: chart.title,
        instrumentId: chart.instrumentId,
        selectedPeriodType: chart.selectedPeriodType,
        contractId: chart.contractId,
        groupTypes: chart.groupTypes,
        itemId: chart.contractId
      })),
      dockType
    );

    document.body.click(); // Workaround for destroy popover object
    return store.dispatch(toggleFavorite(newFavorite, !!!favoriteName));
  }

  render() {
    const { favoriteName, favorites } = this.props;
    const favoriteNames = favorites ? favorites.map(f => f.name) : [];
    return (
      <FavoriteIcon
        favoriteName={favoriteName}
        isFavorite={!!favoriteName && favoriteNames.indexOf(favoriteName) > -1}
        onSubmit={(name: string) => this.handleFavoriteSubmit(name)}
        favorites={favorites}
      />
    );
  }
}
