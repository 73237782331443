import * as React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { EnterOrderRequest, Restriction } from '../../orders/models/orders';
import { FileUpload } from './FileUpload';
import {
  getUploadedBulkOrders,
  isParsingCurrentlyInProgess,
  getUploadErrors,
  getFirstSubmitTime
} from '../selectors/bulkOrders';
import { State } from '../../main/reducers/rootReducer';
import {
  submitBulkOrders,
  parseBinaryStringFromFile,
  basketOrderSuccess,
  basketOrderFailure,
  selectBulkOrder,
  clearBasketOrders,
  requestSampleBasketFile,
  basketOrderBulkSuccess,
  basketOrderBulkFailure
} from '../actions/bulkOrders';
import { v1 } from 'uuid';
import { ITable } from '../../shared/ui/models/table';
import { BulkTable } from '../models/BulkTable';
import { ComponentType } from '../../shared/ui/models/component';
import BasketErrorsTable from '../containers/BasketErrorsTable';
import BasketOrdersTable from '../containers/BasketOrdersTable';
import { orderEnter, bulkEnterOrders } from '../../orders/actions/orders';
import Messenger from '../../shared/messenger/components/messenger';
import { BasketOrder, BasketOrderStatus } from '../models/model';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { Tab, ITab } from '../../shared/ui/models/tab';
import { create } from '../../shared/ui/actions/table';
import { create as createTab } from '../../shared/ui/actions/tab';
import {error} from "../../shared/logger/actions/logger";
import {config} from "../../main/config";

export interface BulkProps {
  modalVisible: boolean;
  closeModal: () => void;
  sendParseRequest: (file: any, correlationId: string) => void;
  enterBulkOrders: (orders: any[]) => void;
  onClickCell: (id: string, key: string) => void;
  createTable: (tables: ITable[]) => void;
  enterOrder: (request: EnterOrderRequest) => void;
  bulkEnterOrder: (request: EnterOrderRequest[]) => void;
  handleSuccess: (message: any) => void;
  handleFailure: (message: any) => void;
  handleBulkSuccess: (message: any) => void;
  handleBulkFailure: (message: any) => void;
  clearBasket: () => void;
  fakeTabCreate: (tab: ITab) => void;
  displayFilter: boolean;
  bulkOrders: BasketOrder[];
  errors: { [row: number]: string[] };
  parsingRunning: boolean;
  firstSubmitTime: number;
  downloadSample: () => void;
  error: (message) => void;
}

export interface BulkState {
  phrase: string;
  modalVisible: boolean;
  confirmationPopupVisible: boolean;
  displayFilter: boolean;
}

const ConfirmationBlock = ({ onSuccess, onCancel, timestamp }: any) => {
  let text = I18n.t('bulk.resubmitQuestion', { time: new Date(timestamp) });
  return (
    <div className="confirmation-block">
      <div>
        <p>{text}</p>
      </div>
      <div>
        <div className="modal-footer">
          <button
            className="btn btn-success ml-2 w150"
            onClick={onSuccess}
            autoFocus={true}
          >
            <Translate value="bulk.resubmit" />
          </button>
        </div>
      </div>
    </div>
  );
};

export class BulkOrderUploadModalComponent extends React.Component<
  BulkProps,
  BulkState
  > {
  constructor(props: BulkProps) {
    super(props);
    this.search = this.search.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this._createComponent = this._createComponent.bind(this);
    this.enterBulkOrders = this.enterBulkOrders.bind(this);
    this.handleOrderSuccess = this.handleOrderSuccess.bind(this);
    this.handleOrderFailure = this.handleOrderFailure.bind(this);
    this.cancelSubmit = this.cancelSubmit.bind(this);
    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.clearBasket = this.clearBasket.bind(this);
    this.setConfirmationPopupVisible = this.setConfirmationPopupVisible.bind(
      this
    );
    this.forceSubmit = this.forceSubmit.bind(this);
    this.onFilter = this.onFilter.bind(this);

    this.state = {
      phrase: '',
      modalVisible: props.modalVisible,
      confirmationPopupVisible: false,
      displayFilter: false
    };
  }

  componentDidMount() {
    const newTab: ITab = new Tab(
      'bulkModal',
      ComponentType.BulkOrder,
      ComponentType.BulkOrder,
      false,
      'modal-' + ComponentType.BulkOrder,
      {
        displayFilter: this.state.displayFilter,
        displaySearch: false,
        isActive: true,
        ordering: 0
      }
    );
    this.props.fakeTabCreate(newTab);
  }

  hideModal() {
    this.props.closeModal();
    this.props.clearBasket();
  }

  componentWillMount() {
    let tables: ITable[] = [];
    tables.push(
      new BulkTable(ComponentType.BulkOrder, ComponentType.BulkOrder)
    );
    this.props.createTable(tables);
  }

  search(event: any) {
    const target = event.target;
    const value = target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        phrase: value
      };
    });
  }

  _createComponent(component: any, type: ComponentType) {
    return React.createElement(component, {
      key: 'modal-' + type,
      parentId: 'modal-' + type,
      ...{...this.props, displayFilter: this.state.displayFilter}
    });
  }

  enterBulkOrders() {
    let orders = this.props.bulkOrders;
    let notYetEnteredOrders = orders.filter(
      order =>
        order.status !== BasketOrderStatus.SUCCESS &&
        order.status !== BasketOrderStatus.PENDING
    );
    this.props.enterBulkOrders(notYetEnteredOrders);
    const enterOrderRequests = notYetEnteredOrders.map(order => {
      const request: EnterOrderRequest = {
        correlationId: order.correlationId,
        limit: order.limit,
        quantity: order.quantity,
        restriction: Restriction.NONE,
        contractId: order.contract,
        buy: order.buy
      };
      return request;
    });
    this.props.bulkEnterOrder(enterOrderRequests);
  }

  handleOrderSuccess(messages: any[]) {
    this.props.handleBulkSuccess(messages);
  }

  handleOrderFailure(messages: any[]) {
    this.props.handleBulkFailure(messages);
  }

  cancelSubmit() {
    this.setConfirmationPopupVisible(false);
  }

  forceSubmit() {
    this.enterBulkOrders();
    this.setConfirmationPopupVisible(false);
  }

  setConfirmationPopupVisible(visible: boolean) {
    this.setState(prevState => {
      return {
        ...prevState,
        confirmationPopupVisible: visible
      };
    });
  }

  handleSubmitClicked() {
    if (this.props.firstSubmitTime > -1) {
      this.setConfirmationPopupVisible(true);
    } else {
      this.enterBulkOrders();
    }
  }

  clearBasket() {
    this.setConfirmationPopupVisible(false);
    this.props.clearBasket();
  }

  onFilter() {
    this.setState(prevState => {
      return {
        ...prevState,
        displayFilter: !prevState.displayFilter
      };
    });
  }

  render() {
    // const { phrase } = this.state.phrase;
    const { modalVisible, bulkOrders } = this.props;
    const correlationIds = bulkOrders.map(order => order.correlationId);
    const submitButtonDisabled = this.state.confirmationPopupVisible;

    const popoverComponent = (
      <ModalWindow
        id="bulk-order-upload"
        onModalClose={() => this.setConfirmationPopupVisible(false)}
        isOpen={this.state.confirmationPopupVisible}
        title="bulk.resubmit"
      >
        <ConfirmationBlock
          onCancel={() => this.setConfirmationPopupVisible(false)}
          onSuccess={this.forceSubmit}
          timestamp={this.props.firstSubmitTime}
        />
      </ModalWindow>
    );

    return (
      <ModalWindow
        id="bulk"
        onModalClose={() => this.hideModal()}
        isOpen={modalVisible}
        title={I18n.t('bulk.title')}
      >
        <React.Fragment>
          <div style={{ display: 'none' }}>
            <Messenger
              messageIds={correlationIds}
              onSuccessMessages={(messages: any) => {
                this.handleOrderSuccess(messages);
              }}
              onErrorMessages={(messages: any) => {
                this.handleOrderFailure(messages);
              }}
            />
          </div>
          <div className="d-flex align-items-end">
            <FileUpload
              onUpload={(binary: any) => {
                this.props.sendParseRequest(binary, v1());
              }}
              error={error}
            />
            {/* <div className="align-self-start">
              <i
                onClick={(e: any) => {
                  if (this.state.displayFilter) {
                    e.persist();
                    this.onFilter();
                  }
                }}
                className={`oi oi-wrench ${
                  this.state.displayFilter ? `active` : ``
                }`}
              />
            </div> */}
          </div>

          <div className={Object.keys(this.props.errors).length ? 'bulktable-half' : 'bulktable-full'}>
            <div className="scrollable">
              {this._createComponent(
                BasketOrdersTable,
                ComponentType.BulkOrder
              )}
            </div>
          </div>
          <div className="bulktable-errors uitable">
            <div className="scrollable meet-table">
              {this._createComponent(
                BasketErrorsTable,
                ComponentType.BulkError
              )}
            </div>
          </div>
          <div className="modal-footer">
            {/* <div className="d-flex align-items-end ml-auto"> */}
            <button
              className="btn btn-secondary w150"
              onClick={() => window.open(config.serverURL + '/data/bulk/sample')}
            >
              <Translate value="bulk.downloadSample" />
            </button>
            <button
              className="btn btn-secondary w150"
              onClick={() => this.clearBasket()}
              disabled={bulkOrders.length === 0}
            >
              <Translate value="bulk.clear" />
            </button>
            <button
              className="btn btn-approval w150"
              onClick={() => this.handleSubmitClicked()}
              disabled={submitButtonDisabled || bulkOrders.length === 0}
            >
              <Translate value="bulk.submit" />
            </button>
            {/* </div> */}
          </div>
          {submitButtonDisabled ? popoverComponent : null}
        </React.Fragment>
      </ModalWindow>
    );
  }
}

const mapStateToProps = (s: State) => ({
  bulkOrders: getUploadedBulkOrders(s),
  parsingRunning: isParsingCurrentlyInProgess(s),
  errors: getUploadErrors(s),
  firstSubmitTime: getFirstSubmitTime(s)
});

const mapDispatchToProps = {
  enterBulkOrders: submitBulkOrders,
  onClickCell: selectBulkOrder,
  sendParseRequest: parseBinaryStringFromFile,
  createTable: create,
  enterOrder: orderEnter,
  bulkEnterOrder: bulkEnterOrders,
  handleSuccess: basketOrderSuccess,
  handleFailure: basketOrderFailure,
  handleBulkSuccess: basketOrderBulkSuccess,
  handleBulkFailure: basketOrderBulkFailure,
  clearBasket: clearBasketOrders,
  fakeTabCreate: createTab,
  downloadSample: requestSampleBasketFile,
  error: error
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(BulkOrderUploadModalComponent);
