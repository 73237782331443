import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import {
  ActionTypes,
  loadSuccess,
  loadFailure,
  loadAvaiable,
  loadVisible,
  setProfileVersion
} from '../actions/profile';
import { loadSuccess as dashboardLoadDocks } from '../actions/dashboard';
import { load as tabsLoad } from '../../shared/ui/actions/tab';
import { load as tablesLoad } from '../../shared/ui/actions/table';
import { load as marketsLoad } from '../../shared/ui/actions/market';
import { chartRestoreDrawings, chartSetThemes, chartSetViews, load as chartsLoad } from '../../shared/ui/actions/chart';
import * as Connection from '../../authentication/actions/connection';
import * as Authentication from '../../authentication/actions/authentication';
import * as ContractActions from '../../orderbook/actions/contracts';
import * as SettingsActions from '../../main/actions/settings';
import * as Analytics from '../../analyticsPanel/actions/analyticPanel';
import * as Rx from 'rxjs';
import { State, rootReducer, initialState } from '../../main/reducers/rootReducer';
import ProfileService from '../services/profile';
import {
  DashboardView,
  LoadDashboardViewRequest,
  RemoveDashboardViewRequest,
  ViewStatus
} from '../models/profile';
import { getAll as getAllDocks } from '../selectors/dashboard';
import { Grid } from '../../shared/utils/components/grid';
import { IDock } from '../../shared/dock/models/dock';
import * as uiSelector from '../../shared/ui/selectors/ui';
import {
  LoadSettingsResponse, SaveSettingsRequest
} from '../../main/models/application';
import { getViewStatus, getVisibleViewIds, getServerProfileVersion } from '../selectors/profile';
import { loadSettings } from '../../main/actions/settings';
import { getAuthorizedStatus } from '../../authentication/selectors/authetication';
import { StompService, StompClient } from '../../main/services/stompService';
import { Tab } from '../../shared/ui/models/tab';
import { ITable } from '../../shared/ui/models/table';
import { IMarket } from '../../shared/ui/models/market';
import { globalLoad } from '../../shared/ui/actions/global';
import { map, filter, switchMap, catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { I18n } from 'react-redux-i18n';
import { getAnalyticsState } from '../../analyticsPanel/selectors/anaylticsPanel';
import { loadAnalytics } from '../../analyticsPanel/actions/analyticPanel';
import { store } from '../../main/store/store';
import { isObject, isArray } from 'util';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import { getGeneralSettings } from '../../shared/settings/selectors/selector';
import { ComponentType } from '../../shared/ui/models/component';
import { getDashboardGridState } from '../selectors/quadrantPanel';
import { loadDashboardQuadrants } from '../actions/quadrants';

const profileService = new ProfileService();

const stompService = new StompService(StompClient);

const getDocksForSave = (state: State) => {
  const grid = new Grid();
  return getAllDocks(state).reduce((docks: IDock[], dock: IDock) => {
    return docks.concat({
      ...dock,
      position: grid.convertDockPositionToPercentage(dock.position),
      size: grid.convertDockSizeToPercentage(dock.size)
    });
  }, []);
};

const getUIForSave = (state: State) => {
  const tabsToSave = uiSelector.getTabsEntites(state)
    .filter((tab: Tab) => tab.id.indexOf('recent-') === -1);

  const tablesToSave = uiSelector.getTableEntites(state)
    .filter((table: ITable) => table.id.indexOf('recent-') === -1)
    .map((table: ITable) => { return { ...table, columns: undefined, hiddenRows: [], pinnedRows: [], cellActions: undefined, actions: undefined, cellActionClick: undefined }; });
  const marketsToSave = uiSelector.getMarketsToProfileSave(state)
    .map((market: IMarket) => { return { ...market, columns: undefined }; });

  return {
    analytics: getAnalyticsState(state),
    dashboardQuadrants: {...getDashboardGridState(state), emptyView: false},
    tabs: tabsToSave,
    tables: tablesToSave,
    markets: marketsToSave,
    charts: uiSelector.getChartsToProfileSave(state),
    global: uiSelector.getGlobals(state),
    relative: true,
    chartThemes: uiSelector.getChartThemes(state),
    chartDrawings: uiSelector.getChartDrawings(state),
    chartViews: uiSelector.getChartViews(state)
  };
};

export const loadView: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD),
    map(action => action.payload),
    switchMap((viewId: string) => {
      profileService.loadView(<LoadDashboardViewRequest> { viewId: viewId });

      return Rx.empty();
    })
  );
};

export const saveView: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.SAVE),
    map(action => action.payload),
    switchMap(data => {
      const view: DashboardView = data;
      let docksToSave = getDocksForSave(state.value);
      let uiToSave = getUIForSave(state.value);
      docksToSave = docksToSave.map(dock => {
        dock.position.x = Math.round(dock.position.x * 10) / 10;
        dock.position.y = Math.round(dock.position.y * 10) / 10;
        return dock;
      });

      return profileService
        .saveView({
          jsonData: JSON.stringify({ docks: docksToSave, ui: uiToSave }),
          defaultView: state.value.profile.views.length < 1,
          viewId: view.viewId,
          name: view.name
        })
        .pipe(
          map((content: LoadSettingsResponse) => {
            return loadSettings(content);
          }
          ),
          catchError(
            (error: Error) => Rx.of(receiveMessage('', new Error('profile.log.saveFailure'), true))
          )
        );
    })
  );
};

const repairProfile = (parsedViewObj: any) => {
  const ui = getUIForSave(initialState);
  const docks = getDocksForSave(initialState);
  let viewObj: {[key: string]: any} = {ui: ui, docks: docks};
  for (let key in viewObj) {
    if (viewObj.hasOwnProperty(key)) {
      if (isObject(viewObj[key]) && !isArray(viewObj[key])) {
        for (let subKey in viewObj[key]) {
          if (viewObj[key].hasOwnProperty(subKey)) {
            viewObj[key][subKey] = Object.assign(viewObj[key][subKey], parsedViewObj[key] ? parsedViewObj[key][subKey] : undefined);
          }
        }
      } else {
        viewObj[key] = !!parsedViewObj[key] ? parsedViewObj[key] : viewObj[key];
      }
    }
  }
  // repair missing itemId in older profiles
  if (viewObj.hasOwnProperty('ui') && viewObj.ui.hasOwnProperty('markets')) {
    viewObj.ui.markets.forEach((market: any) => {
      if (market.type === ComponentType.Instrument || market.type === ComponentType.InstrumentIntraday) {
        market.itemId = !market.itemId ? market.instrumentId : market.itemId;
      } 
    });
  }
  return viewObj;
};

export const saveViewOnLogout: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_LOGOUT),
    map(action => action.payload),
    switchMap(() => {
      let docksToSave = getDocksForSave(state.value);
      let uiToSave = getUIForSave(state.value);
      docksToSave = docksToSave.map(dock => {
        dock.position.x = Math.round(dock.position.x * 10) / 10;
        dock.position.y = Math.round(dock.position.y * 10) / 10;
        return dock;
      });

      const viewId = !state.value.profile.activeViewId ? 'lastview' : state.value.profile.activeViewId;
      const name = state.value.profile.views.filter(v => v.viewId === viewId)[0]?.name;
      return profileService
      
        .saveView({
          jsonData: JSON.stringify({ docks: docksToSave, ui: uiToSave }),
          defaultView: state.value.profile.views.length < 1,
          viewId,
          name: !name ? I18n.t('profile.views.Last Dashboard') : name
        })
        .pipe(map(() => {
          return Connection.closeAndClean();
        }),
          catchError((error) => Rx.merge(
            Rx.of(receiveMessage('', error, true)), 
            Rx.of(Connection.closeAndClean())
          )
        ));
    })
  );
};

export const saveViewOnActions: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Analytics.ActionTypes.TRIGGER_PANEL_LOCK),
    map(action => action.payload),
    switchMap(() => {
      let docksToSave = getDocksForSave(state.value);
      let uiToSave = getUIForSave(state.value);
      docksToSave = docksToSave.map(dock => {
        dock.position.x = Math.round(dock.position.x * 10) / 10;
        dock.position.y = Math.round(dock.position.y * 10) / 10;
        return dock;
      });

      profileService
        .saveView({
          jsonData: JSON.stringify({ docks: docksToSave, ui: uiToSave }),
          defaultView: state.value.profile.views.length < 1,
          viewId: 'lastview',
          name: I18n.t('profile.views.Last Dashboard')
        }).pipe(catchError(
          (error: Error) => Rx.of(receiveMessage('', new Error('profile.log.saveFailure'), true))
        ));
        
      return Rx.of();
    })
  );
};

export const saveEmptyViewsOnStart: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS),
    map(action => action.payload),
    switchMap(() => {
      if (getAuthorizedStatus(state.value)) {
        let docksToSave = getDocksForSave(state.value);
        let uiToSave = getUIForSave(state.value);
        docksToSave = docksToSave.map(dock => {
          dock.position.x = Math.round(dock.position.x * 10) / 10;
          dock.position.y = Math.round(dock.position.y * 10) / 10;
          return dock;
        });

        return profileService
          .saveView({
            jsonData: JSON.stringify({ docks: docksToSave, ui: uiToSave }),
            defaultView: false,
            viewId: 'emptyView',
            name: I18n.t('profile.views.New Dashboard')
          })
          .pipe(
            switchMap(() => Rx.empty()),
            catchError(
              (error: Error) => Rx.of(receiveMessage('', new Error('profile.log.saveFailure'), true))
            ));
      }
      return Rx.of();
    })
  );
};

export const visibleViewsSave: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TOGGLE_VISIBILITY),
    map(action => action.payload),
    switchMap(data => {
      const visibleViews = getVisibleViewIds(state.value);
      stompService.saveSettings(<SaveSettingsRequest> {
        settings: {
          visibleViewsJson: JSON.stringify(visibleViews)
        }
      });
      return Rx.of();
    })
  );
};

export const visibleViewsLoad: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === SettingsActions.ActionTypes.LOAD_SETTINGS),
    map(action => action.payload),
    switchMap((content: LoadSettingsResponse) => {
      if (
        !!content &&
        !!content.settings &&
        !!content.settings.visibleViewsJson
      ) {
        return Rx.of(
          loadVisible(JSON.parse(content.settings.visibleViewsJson))
        );
      }
      return Rx.empty();
    })
  );
};

export const removeView: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.REMOVE),
    map(actions => actions.payload),
    switchMap((viewId: string) => {
      profileService.removeView(<RemoveDashboardViewRequest> { viewId: viewId });
      return Rx.empty();
    })
  );
};

export const subscribeView: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.ActionTypes.CONNECTION_SUCCESS || action.type === Connection.ActionTypes.RECONNECTION_SUCCESS),
    map(action => action.payload),
    switchMap(() => profileService.subscribeView().pipe(
      map(content => content.view),
      map(view => {
        if (view) {
          return view;
        }
        return loadFailure(new Error('error.noProfileData'));
      }),
      catchError(error => {
        return Rx.of(loadFailure(error));
      }),
      takeUntil(
        actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT  || action.type === Connection.ActionTypes.CONNECTION_LOST))
      )
    )),
    mergeMap((view: any) => {
      if (
        !view.jsonString ||
        view.jsonString === '' ||
        getViewStatus(state.value) === ViewStatus.LOADED
      ) {
        return Rx.empty();
      }
      
      const parsedViewObj = JSON.parse(view.jsonString);
      const requiredProfileVersion = getServerProfileVersion(state.value);
      let viewObj: {[key: string]: any} = {ui: {}, docks: {}};
      if (requiredProfileVersion !== view.profileVersion) {
        viewObj = repairProfile(parsedViewObj);
      } else {
        viewObj = Object.assign(viewObj, parsedViewObj);
      }

      return [
        loadSuccess(view),
        loadAnalytics(viewObj.ui.analytics),
        loadDashboardQuadrants(viewObj.ui.dashboardQuadrants, view.viewId === 'emptyView'),
        tablesLoad(viewObj.ui.tables),
        tabsLoad(viewObj.ui.tabs),
        marketsLoad(viewObj.ui.markets),
        chartsLoad(viewObj.ui.charts),
        dashboardLoadDocks(viewObj.docks),
        globalLoad(viewObj.ui.global),
        chartSetThemes(viewObj.ui.chartThemes),
        chartRestoreDrawings(viewObj.ui.chartDrawings),
        chartSetViews(viewObj.ui.chartViews)
      ];
    })
  );
};

export const subscribeAvailableViews: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === SettingsActions.ActionTypes.LOAD_SETTINGS),
    map(action => action.payload),
    mergeMap((content: LoadSettingsResponse) => {
      return [
        setProfileVersion(content.serverProfileVersion),
        loadAvaiable(content.availableViews)];
    })
  );
};

export const loadDefaultOnLogin: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === ContractActions.ActionTypes.STATIC_DATA_LOADED),
    map(action => action.payload),
    switchMap((content: LoadSettingsResponse) => {
      profileService.loadView(<LoadDashboardViewRequest> { viewId: null });

      return Rx.empty();
    })
  );
};

export const saveViewPeriodically: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS || action.type === SettingsActions.ActionTypes.LOAD_SETTINGS),
    map(action => action.payload),
    switchMap(() => {
      let settings: any = getGeneralSettings(store.getState());
      return Rx.interval(settings.savingInterval.value).pipe(
        takeUntil(
          actions$.pipe(
            filter(action => [
              Authentication.ActionTypes.AUTHENTICATION_LOGOUT,
              Connection.ActionTypes.DISCONNECT,
              Connection.ActionTypes.CLOSE_CLEAN,
              action.type === Connection.ActionTypes.CONNECTION_LOST,
              action.type === SettingsActions.ActionTypes.LOAD_SETTINGS
            ].indexOf(action.type) > -1
            )
          )
        ),
        switchMap(() => {
          let docksToSave = getDocksForSave(state.value);
          let uiToSave = getUIForSave(state.value);
          docksToSave = docksToSave.map(dock => {
            dock.position.x = Math.round(dock.position.x * 10) / 10;
            dock.position.y = Math.round(dock.position.y * 10) / 10;
            return dock;
          });

          return profileService
            .saveView({
              jsonData: JSON.stringify({ docks: docksToSave, ui: uiToSave }),
              defaultView: state.value.profile.views.length < 1,
              viewId: 'lastview',
              name: I18n.t('profile.views.Last Dashboard')
            })
            .pipe(
              map((content: LoadSettingsResponse) => {
                return loadSettings(content);
              }
              ),
              catchError((error: Error) => Rx.of(receiveMessage('', new Error('profile.log.saveFailure'), true))));
        }
        )
      );
    }));
};

export const profileEpic = combineEpics(
  loadView,
  saveView,
  saveViewOnLogout,
  saveViewOnActions,
  removeView,
  subscribeView,
  subscribeAvailableViews,
  saveViewPeriodically,
  saveEmptyViewsOnStart,
  visibleViewsLoad,
  visibleViewsSave,
  loadDefaultOnLogin
);
