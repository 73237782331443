import { createSelectorCreator,  } from 'reselect';
import nanomemoize from 'nano-memoize';
import { ITable } from '../../ui/models/table';
import sum from 'hash-sum';
const {v3: uuidv3, v1: uuidv1} =  require('uuid');

const memoize: any = nanomemoize;
const customMemoize: any = nanomemoize;

export const hashFn = (...args: any[]) => {
  const json = JSON.stringify(args);
  /* const res = uuidv3(
    args.reduce((acc, val) => acc + '-' + JSON.stringify(val), ''),
    '1b671a64-40d5-491e-99b0-da01ff1f3341'
  ); */

  const res2 = sum(json);
  return res2;
};

/**
 *
 * @param args
 */
const tableVersionHashFn = (
  data: any,
  scoped: { table: ITable[]; parentId: string },
  version: { [key: string]: string }
) => {
  const res = scoped.parentId +
    '-' +
    version.version +
    '-' +
    (typeof scoped.table === 'object'
      ? uuidv3(
          JSON.stringify(scoped.table),
          '1b671a64-40d5-491e-99b0-da01ff1f3341'
        )
      : uuidv1());
  return res;
};

export const argsSelectorCreator = createSelectorCreator(memoize, hashFn);
export const argsCustomSelectorCreator = createSelectorCreator(customMemoize, hashFn);

export const tableVersionSelectorCreator = createSelectorCreator(
  memoize,
  tableVersionHashFn
);