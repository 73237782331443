import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { ConnectionState } from '../reducers/connectionQueue';

const getConnectionState = ((state: State) => state.connection);
const getAuthState = ((state: State) => state.auth);
const getSeparateConnectionState = ((state: ConnectionState) => state);

/**
 * Getting connected status (bool)
 */
export const getConnectedStatus = createSelector([getConnectionState], s => s.connected);

/**
 * Getting connected status (bool)
 */
export const getConnectingStatus = createSelector([getConnectionState], s => s.connecting);

/**
 * Get connection errors
 */
export const getConnectionError = createSelector([getConnectionState], s => s.error);

/**
 * Get authentication errors
 */
export const getAuthError = createSelector([getAuthState], s => s.error);

/**
 * Get connection attempts number
 */
export const getReconnectionAttempts = createSelector([getConnectionState], s => s.attempt);

export const isSsoCodeSent = createSelector([getConnectionState], s => s.ssoCodeSent);

/**
 * Get last heartbeat
 */
export const getLastHeartbeat = createSelector([getSeparateConnectionState], s => s.lastHeartbeat);

export const getMessageQueue = createSelector([getSeparateConnectionState], s => s.messageQueue);

export const getTakeoverConfirmDisplayed = createSelector([getConnectionState], s => s.takeoverConfirmShow);
export const getSSOExpiration = createSelector([getConnectionState], s => s.ssoExpiration);
