import * as React from 'react';
import { ITableColumn, ITableSort } from '../../ui/models/table';
import { I18n } from 'react-redux-i18n';
import { MemoTranslate } from '../../i18n/components/memoTranslate';

const MeetTableHeadColumn = ({ col, activeSort, onSort }: any) => {
  if (col.name === '$index') {
    return null;
  }
  return (
    <th
      className={`position-relative user-select-none ${col.name}`}
      scope={'col'}
      colSpan={1}
      onClick={(e: any) =>
        onSort({
          columnName: col.name,
          direction: activeSort ? (activeSort.direction === 1 ? -1 : 1) : 1
        }, col.sortWith,)
      }
    >
      <span
        className="d-inline-flex flex-direction-row align-items-center mw-100 user-select-none"
        tabIndex={0}
        title={I18n.t(col.title)}
      >
        <MemoTranslate
          value={col.title}
          className="table-header-title user-select-none"
        />
        {activeSort ? (
          activeSort.direction === -1 ? (
            <span
              className="oi mx-2 oi-arrow-thick-bottom"
              style={{ fontSize: '8.25px', top: 0 }}
            />
          ) : (
              <span
                className="oi mx-2 oi-arrow-thick-top"
                style={{ fontSize: '8.25px', top: 0 }}
              />
            )
        ) : null}
      </span>
    </th>
  );
};

interface MeetTableHeadProps {
  cols: ITableColumn[];
  sorting: ITableSort[];
  onSelectCol: (sorting: ITableSort[]) => void;
}

interface MeetTableHeadState { }

class MeetTableHeadComponent extends React.Component<
  MeetTableHeadProps,
  MeetTableHeadState
  > {
  constructor(props: MeetTableHeadProps) {
    super(props);

    this.onSelectCol = this.onSelectCol.bind(this);
  }

  onSelectCol(sortColumn: ITableSort, sortWith: string) {
    let sorting = this._updateSorting(sortColumn, this.props.sorting);
    if (!!sortWith) {
      sorting = this._updateSorting({columnName: sortWith, direction: sortColumn.direction}, sorting);
    }

    this.props.onSelectCol(sorting);
  }

  _updateSorting = (sortColumn: ITableSort, sorting: ITableSort[]) => {
    let _sorting = [];

    if (sorting.find(sort => sort.columnName === sortColumn.columnName)) {
      _sorting = sorting.reduce((acc: ITableSort[], sort: ITableSort) => {
        if (sort.columnName === sortColumn.columnName) {
          if (
            sort.direction !== sortColumn.direction &&
            sortColumn.direction === 1
          ) {
            return acc;
          }

          return [
            ...acc,
            {
              ...sort,
              direction: sortColumn.direction
            }
          ];
        }
        return [...acc, sort];
      }, []);
    } else {
      _sorting = [...sorting, sortColumn];
    }
    return _sorting;
  }

  render() {
    const { cols, sorting } = this.props;
    return (
      <thead>
        <tr>
          {cols.filter(col => col.rowVisible).map(col => (
            <MeetTableHeadColumn
              key={col.name}
              col={col}
              activeSort={sorting.find(s => col.name === s.columnName)}
              onSort={this.onSelectCol}
            />
          ))}
        </tr>
      </thead>
    );
  }
}

function isEqual(a, b) {
  return a && b && typeof a === 'object' && typeof a === typeof b 
    ? (Object.keys(a).length === Object.keys(b).length && Object.keys(a).every(key => isEqual(a[key], b[key]))) 
    : (a === b);
}

export const MeetTableHead = React.memo(MeetTableHeadComponent, isEqual);