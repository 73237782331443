import * as React from 'react';
import { IQuadrant, Quadrant } from '../models/analyticsPanel';
import { ComponentType } from '../../shared/ui/models/component';
import { Translate, I18n } from 'react-redux-i18n';
import withAutoClose from '../../shared/autoclose/components/Autoclose';
import { config } from '../../main/config';

const QuadrantTable = ({
  type,
  exists,
  setTable,
  removeTable
}: {
  type: ComponentType;
  exists: boolean | undefined;
  setTable: (type: ComponentType) => void;
  removeTable: (type: ComponentType) => void;
}) => {
  return (
    <div
      className={`panel-column ${exists ? `exists` : ``}`}
      onClick={() => (exists ? removeTable(type) : setTable(type))}
    >
      <Translate value={`sidebar.${type.toLowerCase()}s`} />
    </div>
  );
};

const QuadrantTablesPanel = ({
  activeQuadrantId,
  quadrant,
  setQuadrantTable,
  removeQuadrantTable,
  quoteRequestsEnabled,
  isTradeReportingUser
}: {
  activeQuadrantId: number;
  quadrant: IQuadrant | undefined;
  setQuadrantTable: (quadrantId: number, type: ComponentType) => void;
  removeQuadrantTable: (quadrantId: number, type: ComponentType) => void;
  quoteRequestsEnabled: boolean;
  isTradeReportingUser: boolean;
}) => {
  const tablesList = [
    ComponentType.Order,
    ComponentType.Log,
    ComponentType.Owntrade,
    ComponentType.Trade,
    ComponentType.Request,
    ComponentType.TradeReport,
    ComponentType.PriceAlarm
  ].filter(c => (c !== ComponentType.Request  || quoteRequestsEnabled))
  .filter( c => (c !== ComponentType.TradeReport || isTradeReportingUser))
  .filter(c => (c !== ComponentType.Owntrade || !isTradeReportingUser))
  .filter(c => (c !== ComponentType.PriceAlarm || config.priceAlarmsEnabled))
  .map((component, index) => (
    <QuadrantTable
      key={component}
      type={component}
      exists={quadrant && quadrant.components.indexOf(component) !== -1}
      setTable={type => setQuadrantTable(activeQuadrantId, type)}
      removeTable={type => removeQuadrantTable(activeQuadrantId, type)}
    />
  ));

  return (
    <div className="panels">
      <div className="panel-row d-flex">{tablesList.slice(0, tablesList.length / 2)}</div>
      <div className="panel-row d-flex">{tablesList.slice(tablesList.length / 2, tablesList.length)}</div>
    </div>
  );
};

interface AnalyticsSettingsProps {
  quadrants: IQuadrant[];
  isLocked: boolean;
  reset: () => void;
  setQuadrant: (quadrant: IQuadrant) => void;
  updateQuadrant: (quadrant: IQuadrant) => void;
  triggerPanelLock: (locked: boolean) => void;
  tabRemove: (tabId: string, dockId: string, type: ComponentType) => void;
  onVisibilityChange: (visible: boolean) => void;
  // Autoclose
  expanded: boolean; // autoclose
  handleTriggerVisibility: (visible: boolean) => void;
  quoteRequestsEnabled: boolean;
  isTradeReportingUser: boolean;
}
interface AnalyticsSettingsState {
  activeQuadrantId: number;
}

class AnalyticsSettings extends React.Component<
  AnalyticsSettingsProps,
  AnalyticsSettingsState
> {
  constructor(props: AnalyticsSettingsProps) {
    super(props);

    this.state = {
      activeQuadrantId: 1
    };

    this.setActiveQuadrant = this.setActiveQuadrant.bind(this);
    this.onSetQuadrantTable = this.onSetQuadrantTable.bind(this);
    this.onRemoveQuadrantTable = this.onRemoveQuadrantTable.bind(this);
  }

  componentWillReceiveProps(newProps: AnalyticsSettingsProps) {
    if (this.props.expanded !== newProps.expanded) {
      this.props.onVisibilityChange(newProps.expanded);
    }
  }

  setActiveQuadrant(quadrantId: number) {
    this.setState({
      ...this.state,
      activeQuadrantId: quadrantId
    });
  }

  onSetQuadrantTable(quadrantId: number, type: ComponentType) {
    const { quadrants } = this.props;
    const targetQuadrant = quadrants.find(q => q.id === quadrantId);
    if (targetQuadrant) {
      const quadrant = new Quadrant(
        quadrantId,
        [...targetQuadrant.components, type],
        targetQuadrant.size
      );
      this.props.updateQuadrant(quadrant);
    } else {
      const quadrant = new Quadrant(quadrantId, [type]);
      this.props.setQuadrant(quadrant);
    }
  }

  onRemoveQuadrantTable(quadrantId: number, type: ComponentType) {
    this.props.tabRemove(
      'analytics-Q' + quadrantId + '-' + type,
      'analyticsPanel-Q' + quadrantId,
      type
    );
  }

  render() {
    const { quadrants, expanded, isLocked } = this.props;
    const { activeQuadrantId } = this.state;
    const quadrantsList = [1, 2, 3, 4].map(index => (
      <div
        key={`quadrant-${index}`}
        className={`quadrant ${activeQuadrantId === index ? ` active` : ``}`}
        onClick={() => this.setActiveQuadrant(index)}
      >
        Q{index}
      </div>
    ));
    return (
      <div
        className={`analytics-panel__settings ${expanded ? `expanded` : ``}`}
      >
        <div className="quadrants d-flex">
          {quadrantsList}
          <div
            className="action-block"
            title={I18n.t('analytics.lock')}
            onClick={() => this.props.triggerPanelLock(!isLocked)}
          >
            {isLocked ? (
              <i className={'oi oi-lock-locked active'} />
            ) : (
              <i className={'oi oi-lock-unlocked'} />
            )}
          </div>
          <div
            className="action-block"
            title={I18n.t('analytics.reset')}
            onClick={() => this.props.reset()}
          >
            <i className="oi oi-action-undo active" />
          </div>
        </div>
        <QuadrantTablesPanel
          activeQuadrantId={activeQuadrantId}
          quadrant={quadrants.find(q => q.id === activeQuadrantId)}
          setQuadrantTable={this.onSetQuadrantTable}
          removeQuadrantTable={this.onRemoveQuadrantTable}
          quoteRequestsEnabled={this.props.quoteRequestsEnabled}
          isTradeReportingUser={this.props.isTradeReportingUser}
        />
      </div>
    );
  }
}

export default withAutoClose(AnalyticsSettings);
