import * as React from 'react';

export interface NumberSpinnerProps {
    [key: string]: any;
    value: number;
    step: number;
    disabled?: boolean;
    min?: number;
}

export interface NumberSpinnerState {
    value: string;
}

export class NumberSpinner extends React.Component<NumberSpinnerProps, NumberSpinnerState> {
    constructor(props: NumberSpinnerProps) {
        super(props);
        const val = isNaN(props.value) ? '0' : props.value + '';
        this.state = {
            value: val
        };
        this.handlePlus = this.handlePlus.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleKey = this.handleKey.bind(this);
    }
    
    componentWillReceiveProps(newProps: NumberSpinnerProps) {
        if (newProps.value !== this.props.value) {
            const val = isNaN(newProps.value) ? '0' : newProps.value + '';

            this.setState((prevState) => {
                return {
                    ...prevState,
                    value: val
                };
            });
        }
    }

    handlePlus(e: any, sign: number) {
        const key = e.charCode || e.keyCode;
        if (key === 13) {
            return;
        }
        if (this.props.disabled || (this.props.min !== undefined && Number(this.state.value) <= this.props.min) && sign < 0) {
            return;
        }
        let newValue = this.state.value;
        if (this.props.step === undefined) {
            newValue = (Number(this.state.value) + (sign) * 1).toFixed(0);
        } else {
            newValue = (Number(this.state.value) + (sign) * Math.pow(10, -this.props.step)).toFixed(this.props.step);
        }
        this.props.onChange({...e, preventDefault: () => false, target: {name: this.props.name, value: newValue}});
    }

    handleScroll(e: any) {
        let delta = 0;
        if ( e.deltaY < 0) { 
            delta = +1;
        } else if (e.deltaY > 0) { 
            delta = -1;
        }

        this.handlePlus(e, delta);
    }

    handleKey(e: any) {
        const key = e.charCode || e.keyCode;
        if (key === 38 || key === 107) {
            this.handlePlus(e, +1);
        } else if (key === 40 || key === 109) {
            this.handlePlus(e, -1);
        } else if (key === 13) {
            return;
        }
    }

    render() {
        return (
            <div className="number-spinner input-group">
                <input
                  {...this.props}
                  value={this.state.value}
                  onWheel={(e) => this.handleScroll(e)}
                  onKeyUp={(e) => this.handleKey(e)}
                  autoComplete="off"
                />
                <div className="spinner-vertical">
                    <a
                        onClick={(e) => { e.preventDefault(); return this.handlePlus(e, +1); }}
                        tabIndex={-1}
                    >
                        <span className="oi oi-caret-top" />
                    </a>
                    <a
                        onClick={(e) => { e.preventDefault(); this.handlePlus(e, -1); }}
                        tabIndex={-1}
                    >
                    <span className="oi oi-caret-bottom" />
                    </a>
                </div>
            </div>
    
        );
    }
}