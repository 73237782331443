import * as React from 'react';
import { Instrument } from '../../../instruments/models/instruments';
import { ComponentType } from '../../../shared/ui/models/component';
import { I18n } from 'react-redux-i18n';
import { getDockType } from '../../../shared/dock/models/dock';
import { DashboardComponent } from '../../../dashboard/models/dashboard';
import withAutoClose from '../../../shared/autoclose/components/Autoclose';
import Dragger from '../../../shared/dragger/container/Dragger';
import { MemoTranslate } from '../../../shared/i18n/components/memoTranslate';
import { Product } from '../../../orderbook/models/contracts';
import { Result } from '../models/results';

interface State {
  results: any[];
  phrase: string;
  listType: 'instrument' | 'product' | 'chart';
  displayLimit: number;
}

interface Props {
  instruments: Instrument[];
  products: Product[];
  isConnected: boolean;
  loaded: boolean;
  locale: string;
  onSelectComponent: (e: any, component: DashboardComponent) => void;
  activeChartInstruments: string[];
  activeMarketInstruments: string[];
  activeMarketProducts: string[];
  expanded: boolean; // From withAutoclose
  modificationEnabled: boolean;
}

export class MarketsheetSelectComponent extends React.Component<Props, State> {
  private selectedComponent: Result | null = null;
  private displayLimitDefault = 100;
  constructor(props: Props) {
    super(props);

    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.onTypeChanged = this.onTypeChanged.bind(this);
    this._filterResults = this._filterResults.bind(this);
    this._getFilteredResults = this._getFilteredResults.bind(this);

    this.state = {
      results: [],
      phrase: '',
      listType: 'instrument',
      displayLimit: this.displayLimitDefault
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.phrase === '' && this.props !== undefined 
     && (prevProps.instruments.length === 0 && this.state.results.length === 0)
     && this.props.instruments.length > 0) {
      this.setState(prevState => {
        return {
          ...prevState,
          results: this._getFilteredResults('instrument', '')
        };
      }); 
    }
  }

  _getFilteredResults(type: 'instrument' | 'product' | 'chart', phrase: string): Result[] {
    let filteredResults: Result[] = []; 
    if (type === 'instrument' || type === 'chart') {
      filteredResults = this.props.instruments.map(i => { return {name: i.name, id: i.id, instrument: i, product: null, type: 'instrument'}; });
      
    } else if (type === 'product') {
      filteredResults = this.props.products.map(p => { return {name: p.name, instrument: null, id: p.id, product: p, type: 'product'}; });
    }
    return this._filterResults(phrase, filteredResults);
  }

  onSelect(e: any) {
    if (this.selectedComponent) {
      let componentType = this._getComponentType(this.selectedComponent);
      this.props.onSelectComponent(e, {
        type: componentType,
        dockType: getDockType(componentType),
        name: this.selectedComponent.name,
        args: [this.selectedComponent]
      });
    }
  }

  search(event: any) {
    const target = event.target;
    const value = target.value;
    this.showDropdown();
    this.setState(prevState => {
      return {
        ...prevState,
        results: this._getFilteredResults(this.state.listType, value),
        phrase: value
      };
    });
  }

  _filterResults(phrase: string, results: Result[]) {
    if (!phrase) {
      return [...results];
    }
    let phraseLowerCase = phrase.toLowerCase();
    let res = [];
    for (let i = 0; i < results.length; i++) {
      if (results[i].name.toLowerCase().indexOf(phraseLowerCase) > -1) {
        res.push(results[i]);
      }
    }
    return res;
  }

  select(e: any, result: Result) {
    let componentType = this._getComponentType(result);
    this.props.onSelectComponent(e, {
      type: componentType,
      dockType: getDockType(componentType),
      name: result.name,
      args: [result]
    });
  }

  onTypeChanged(e: any) {
    const target = e.target;
    const type = target.name.replace('list-type-', '');
    this.setState(prevState => {
      return {
        ...prevState,
        listType: type,
        results: this._getFilteredResults(type, this.state.phrase)  
      };
    });
  }

  showDropdown() {
    this.setState(prevState => {
      return {
        ...prevState,
        expanded: true,
        results: this._getFilteredResults(prevState.listType, prevState.phrase)  
      };
    });
  }

  _getComponentType(result: Result) {
    const { listType } = this.state;
    if (listType === 'instrument' && result.instrument) {
      return result.instrument.isIntraday
        ? ComponentType.InstrumentIntraday
        : ComponentType.Instrument;
    }  else if (listType === 'product' && result.product) {
          return ComponentType.Product;
    } else {
      return ComponentType.MarketChart;
    }
  }

  getDropdownItems(listItems: Result[]) {
    const { listType } = this.state;
    const iconClass = listType === 'instrument' || listType === 'product' ? 'oi-list' : 'oi-graph';
    return listItems.slice(0, this.state.displayLimit).map(result => {
      let className = '';
      if (this.state.listType === 'instrument') {
        className =
          this.props.activeMarketInstruments.indexOf(result.id) === -1
            ? ''
            : ' blocked';
      } else if (this.state.listType === 'product') {
        className =
          this.props.activeMarketProducts.indexOf(result.id) === -1
            ? ''
            : ' blocked';
      }
      return (
        <Dragger
          tag="li"
          className={className}
          onDragComplete={(e: any) => {
            this.selectedComponent = result;
            return this.onSelect(e);
          }}
          key={result.id}
        >
          <span className={`oi ${iconClass}`} />
          &nbsp;
          {result.name}
        </Dragger>
      );
    });
  }

  createRadioOption(type: 'instrument' | 'product' | 'chart') {
    const { listType } = this.state;
    return (
      <div className="form-group form-check">
        <input
          type="radio"
          id={`list-type-${type}`}
          name={`list-type-${type}`}
          className="form-check-input"
          checked={listType === type}
          onChange={this.onTypeChanged}
        />
        <label className="form-check-label" htmlFor={`list-type-${type}`}>
          <MemoTranslate value={`instrument.show${type[0].toUpperCase() + type.slice(1)}s`} />
        </label>
      </div>
    );
  }

  _increaseLimit = (e: any) => {
    this.setState(prevState => {
      return {
        ...prevState,
        displayLimit: prevState.displayLimit + this.displayLimitDefault
      };
    });
  }

  render() {
    const { listType } = this.state;
    const { expanded } = this.props;
    const listItems = this.state.results.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    
    let dropdownItems: any = [];
    if (listItems.length === 0) {
      dropdownItems = (
        <li className="p-2">
          <MemoTranslate value={listType === 'instrument' ? 'instrument.empty' : 'product.empty'} />
        </li>
      );
    } else {
      if (this.state.listType === 'product' && this.state.phrase.length < 3) {
        dropdownItems = (
          <li className="p-2">
            <MemoTranslate value="product.searchTooShort" />
          </li>
        );
      } else {
        dropdownItems = this.getDropdownItems(listItems);    
        if (dropdownItems.length < listItems.length) {
          dropdownItems.push((
          <li className="p-2" key="marketsheetselect-showmore"  onClick={e => this._increaseLimit(e)}>
            <MemoTranslate value="general.dropdown.showMore" />
          </li>));
        }    
      }
    }  

    const placeholder: string = this.props.loaded
      ? I18n.t(this.state.listType === 'product' ? 'product.select' : 'instrument.select')
      : I18n.t('instrument.loading');

    return this.props.modificationEnabled ? (
      <div className="instruments">
        <div className="subtitle">
          <MemoTranslate value={this.state.listType === 'product' ? 'product.title' : 'instrument.title'} tag={'h2'} />
        </div>
        <div className="instruments__search">
          <input
            data-test="instruments-search-input"
            disabled={!this.props.loaded}
            className={`form-control ${expanded ? `active` : ``}`}
            placeholder={placeholder}
            type="text"
            value={this.state.phrase}
            onChange={this.search}
          />
        </div>
        <div className={'instruments__dropdown ' + (expanded ? 'visible' : '')}>
          <div className="instruments__results">
            <ul data-test="instruments-search-results">{dropdownItems}</ul>
          </div>
          <div className={'type-trigger'}>
            {this.createRadioOption('instrument')}
            {this.createRadioOption('chart')}
            {this.createRadioOption('product')}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withAutoClose(MarketsheetSelectComponent);
