import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../main/reducers/rootReducer';
import * as Actions from '../actions/messenger';
import { getMessages } from '../selectors/messenger';
import {I18n} from "react-redux-i18n";

interface MessengerProps {
  messageIds: string[];
  messages: any[];
  removeMessages: (ids: string[]) => void;
  onSuccessMessages: (messages: string[]) => void;
  onErrorMessages: (messages: string[]) => void;
}
interface MessengerState {
  errorMessages: any[];
}

export class Messenger extends React.Component<MessengerProps, MessengerState> {
  constructor(props: MessengerProps) {
    super(props);

    this.state = {
      errorMessages: []
    };

    this.onRemoveButton = this.onRemoveButton.bind(this);
  }

  componentWillReceiveProps(newProps: Readonly<MessengerProps>) {
    const { messages, messageIds } = newProps;
    const errorMessages = messages.filter(message => message.isError && messageIds.indexOf(message.correlationId) >= 0);
    const successMessages = messages.filter(message => !message.isError && messageIds.indexOf(message.correlationId) >= 0);

    if (errorMessages.length) {
      this.setState(prevState => {
        return {
          ...prevState,
          errorMessages: errorMessages,
          messageIds: messageIds
        };
      });

      this.props.onErrorMessages(errorMessages);
      errorMessages.forEach(message => {
        this.props.removeMessages([message.correlationId]);
      });
    } 

    if (messageIds.length === 0 && this.props.messageIds.length !== 0) {
      this.setState(prevState => {
        return {
          ...prevState,
          errorMessages: [],
          messageIds: []
        };
      });

      this.props.onErrorMessages(errorMessages);
      
      this.props.removeMessages(errorMessages.map(msg => msg.correlationId));
      
    } 

    if (successMessages.length) {
      this.props.onSuccessMessages(successMessages);
      this.setState(prevState => {
        return {
          ...prevState,
          errorMessages: []
        };
      });
      this.props.removeMessages(successMessages.map(msg => msg.correlationId));
    }
}

onRemoveButton(correlationId: string) {
  this.setState(prevState => {
    return {
      ...prevState,
      errorMessages: prevState.errorMessages.filter(
        msg => msg.correlationId !== correlationId
      )
    };
  });
}

render() {
  const { children } = this.props;
  const { errorMessages } = this.state;

  const messages = errorMessages.map((message, index) => {
    const customClass = message.isError ? 'error' : '';
    return (
      <div key={index} className={`messages__content ${customClass}`}>
        {message.translated ? message.message : I18n.t(message.message, message.translationParams)}
        <span
          className="close-btn"
          onClick={e => {
            e.preventDefault();
            return this.onRemoveButton(message.correlationId);
          }}
        >
          &times;
        </span>
      </div>
    );
  });

  return (
    <div className="messages">
      {messages}
      {children ? <div className="messages__content">{children}</div> : null}
    </div>
  );
}
}

const mapStateToProps = (state: State, props: MessengerProps) => ({
  messages: getMessages(state, props.messageIds)
});

const mapDispatchToProps = {
  removeMessages: Actions.removeMessages,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Messenger);
