export interface BasketOrder {
[correlationId: string]: any;
$index: number;
date: string;
timeShort: string;
periodShort: string;
grid: string;
buy: boolean;
quantity: number;
limit: number;
contract: string;
status: BasketOrderStatus;
correlationId: string;
statusMessage: string;
}

export interface BulkOrderParsingResponse {
    orders: BasketOrder[];
    rowsWithError: { [row: number]: string[] };
}

export enum BasketOrderStatus {
    OPEN = 'open',
    SUCCESS = 'success',
    FAILED = 'failed',
    PENDING = 'pending'
}

export interface ParseBulkOrdersRequest {
    file: any;
    correlationId: string;
}
