import { pushDockLower } from '../../../../utils/helper/eventUtils';
import * as React from 'react';
import { Popover } from 'react-bootstrap';
import { config } from '../../../../../main/config';
import { connect } from 'react-redux';
import { getOrderbookDepthLimit } from '../../../selectors/ui';
import { State } from '../../../../../main/reducers/rootReducer';
import { setOrderbookDepth } from '../../../actions/market';
import { I18n } from 'react-redux-i18n';

interface Props {
    expiryCode: string;
    isOpen: boolean;
    position: {x: number; y: number};
    save: (depth: number, expiryCode: string, dockId: string) => void;
    dismiss: () => void;
    dockId: string;
    depths: {[expiryCode: string]: number};
}

interface DepthPopoverState {
    depth: number;
}

export class UIDepthPopover extends React.Component<Props, DepthPopoverState> {
    constructor(props: Props) {
        super(props);
        this.dismiss = this.dismiss.bind(this);
        
        const depth = this.props.depths[this.props.expiryCode];
        
        this.state = {
            depth: depth ? depth : config.orderbookDepthSteps[config.orderbookDepthSteps.length - 1]
        };
    }

    dismiss(e: any) {
        let element = e.target.closest('#popover-positioned-right-' + this.props.dockId);
        if (element) {
            return;
        }
        e.preventDefault();
        pushDockLower();
        this.props.dismiss();
        document.removeEventListener('mousedown', this.dismiss);
    }

    onSave = (event: any) => {
        this.props.save(this.state.depth, this.props.expiryCode, this.props.dockId);
        this.props.dismiss();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.isOpen && !this.props.isOpen) {
            document.addEventListener('mousedown', this.dismiss);
        }
        this.setState((prevState) => {
            const depth = nextProps.depths[nextProps.expiryCode];
            return {
                ...prevState,
                depth: depth ? depth : config.orderbookDepthSteps[config.orderbookDepthSteps.length - 1]
            };
        });
    }

    step = (event: any, size: number) => {
        event.stopPropagation();
        const current = config.orderbookDepthSteps.indexOf(this.state.depth);
        this.setState((prevState) => {
            return {
                ...prevState,
                depth: current === -1 ? config.orderbookDepthSteps[0] : config.orderbookDepthSteps[current + size]
            };
        });
    }

    render() {
        const { position, isOpen, expiryCode, depths, dockId } = this.props;
        const { depth } = this.state;
        const disabledMinus = config.orderbookDepthSteps.indexOf(depth) === 0;
        const disabledPlus = config.orderbookDepthSteps.indexOf(depth) === config.orderbookDepthSteps.length - 1;
        
        return (
            <div
                id={`popover-positioned-right-${dockId}`}        
                style={{
                    top: position.y,
                    left: position.x
                }}
                className="expiry-popover"
                hidden={!isOpen}
                onClick={(e) => { e.stopPropagation(); return false; }}
            >
                <span>{I18n.t('marketsheet.depth.title')}: {depth === Infinity ? I18n.t('marketsheet.depth.all') : depth}</span>
                <div className="arrow" style={{top: '3px'}} />
                <div
                    id={'depth-popover-right-' + this.props.dockId}
                    className="d-flex"
                >
                    <div 
                        className="btn-group btn-group-toggle" 
                        data-toggle="buttons"
                    >
                        <label className={`btn ${disabledMinus ? '' : 'active'} minus`} >
                            <input 
                                type="radio" 
                                name="options" 
                                id="option1" 
                                disabled={disabledMinus}
                                onClick={(e) => this.step(e, -1)}
                            /><span>{I18n.t('marketsheet.depth.minus')}</span>
                        </label>
                        <label className={`btn ${disabledPlus ? '' : 'active'} plus`}>
                        <input 
                            type="radio" 
                            name="buy" 
                            id="option0" 
                            disabled={disabledPlus}
                            onClick={(e) => this.step(e, +1)}
                        />
                        <span>{I18n.t('marketsheet.depth.plus')}</span></label>
                    </div>
            
                    <button 
                        className="ml-auto btn btn-approval" 
                        onClick={this.onSave}
                    >{I18n.t('marketsheet.depth.ok')}
                    </button>
                </div>
            </div>
        );
    }
    
}

const mapStateToProps = (state: State, props: Props) => ({
});
  
const mapDispatchToProps = {
    save: setOrderbookDepth
};
  
export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(UIDepthPopover);