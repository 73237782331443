import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { Instrument } from '../models/instruments';

const getInstrumentsState = ((state: State) => state.instruments);

export const getAllInstruments = createSelector([getInstrumentsState], s => s.instruments);

export const getTradesError = createSelector([getInstrumentsState], s => s.error);

export const isLoaded = createSelector([getInstrumentsState], s => s.loaded);