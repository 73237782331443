import * as React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {v1} from 'uuid';
import {DashboardComponent} from '../../../../../dashboard/models/dashboard';
import {config} from '../../../../../main/config';
import {Contract, PeriodType, UserPermission} from '../../../../../orderbook/models/contracts';
import {Price} from '../../../../../orderbook/models/orderbooks';
import {
  createDeleteOrderFormDataFromPrice,
  createEnterOrderFormDataFromPrice,
  createModifyOrderFormDataFromPrice,
  createVolumeMatchOrderFormDataFromPrice
} from '../../../../../orders/helper/orders';
import OrderFormData from '../../../../../orders/models/formData';
import {createPriceAlarmFormData} from '../../../../../priceAlarm/helper/priceAlarms';
import {PriceAlarmFormData} from '../../../../../priceAlarm/models/priceAlarms';
import {
  createAnswerFormDataFromQuoteRequest,
  createCancelFormDataFromQuoteRequest,
  openEnterQuoteRequestForm
} from '../../../../../requests/helper/helper';
import {
  AcceptQuoteRequestRequest,
  QuoteRequestFormData,
  QuoteRequestState,
  RejectQuoteRequestRequest
} from '../../../../../requests/models/request';
import {DockType} from '../../../../../shared/dock/models/dock';
import {MemoTranslate} from '../../../../../shared/i18n/components/memoTranslate';
import {getLocalizationSettings} from '../../../../../shared/utils/formatters';
import {Position} from '../../../../../shared/utils/models/grid';
import {ComponentType} from '../../../models/component';
import {IMarket, MarketActionType} from '../../../models/market';
import {getColumns, getMarketContextMenuPosition, getWidthFromColumnWidthCode} from '../helper/helper';
import {ExpiryRow} from './TableRows';
import UIMarketContextMenu from './uiMarketContextMenu';

/** Memoized components to avoid unnecessary re-render */
const InstumentHeader = React.memo((props: { name: string }) => {
  const popoverFullName = (
    <Popover id="popover-full-name" className="show">
      <b>{props.name}</b>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={'bottom'}
      overlay={popoverFullName}
    >
      <h3>{props.name}</h3>
    </OverlayTrigger>
  );
});

interface UIMarketInstrumentTableProps {
  market: IMarket;
  matrix: any;
  expiries: { [key: string]: any };
  prices: {
    orders: { [key: string]: { [group: string]: any[] } };
    trades: { [key: string]: any[] };
  };
  isTableBorderActive: boolean;
  isSeparateCells: boolean;
  logFatal: (message: string) => void;
  onOpenOrder: (formData: any) => void;
  onCreateChart: (e: any, component: DashboardComponent) => void;
  openPriceAlarmForm: (formData: any) => void;
  onOpenQuoteRequest: (formData: any) => void;
  matchPriceFeedOrdersEnabled: boolean;
  anonymizeMarketDataEnabled: boolean;
  matchOnlyModeEnabled: boolean;
  quoteRequestsEnabled: boolean;
  permissions: string[];
  colors: { [key: string]: string };
  isHeadlinesVisible: boolean;
  user: any;
  acceptQuoteRequest: (request: AcceptQuoteRequestRequest) => void;
  declineQuoteRequest: (request: RejectQuoteRequestRequest) => void;
  blockIndex: number;
  columnWidth: string;
  instrumentCount: number;
  settings: { [key: string]: any };
  contracts: {[key: string]: Contract};
  dockSize: {width: number, height: number, top: number; left: number};
}
interface UIMarketInstrumentTableState {
  contextMenu: {
    position: Position;
    isOpen: boolean;
    hasData: boolean;
    readonly: boolean;
    disableMatch: boolean;
    disableModify: boolean;
    disableDelete: boolean;
    disableCancelQuoteRequest: boolean;
    disableAnswerQuoteRequest: boolean;
    disableAcceptQuoteRequest: boolean;
  };
  actionData: {
    column: any;
    row: any;
    instrumentData: any;
    periodType: string;
    index: number;
  } | null;
  colors: { [key: string]: string };
}

export default class UIMarketInstrumentTable extends React.PureComponent<
  UIMarketInstrumentTableProps,
  UIMarketInstrumentTableState
> {
  localizeOptions: any;

  constructor(props: UIMarketInstrumentTableProps) {
    super(props);
    this.localizeOptions = getLocalizationSettings();
    this.state = {
      contextMenu: {
        position: { x: 0, y: 0 },
        isOpen: false,
        hasData: false,
        readonly: false,
        disableMatch: false,
        disableModify: false,
        disableDelete: false,
        disableCancelQuoteRequest: false,
        disableAnswerQuoteRequest: false,
        disableAcceptQuoteRequest: false
      },
      actionData: null,
      colors: {...this.props.colors, colorsVersion: v1()}
    };

    this.handleNewOrder = this.handleNewOrder.bind(this);
    this.handleNewGraph = this.handleNewGraph.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.onClickAction = this.onClickAction.bind(this);
    this.onContextMenuDismiss = this.onContextMenuDismiss.bind(this);
    this.onActionSelect = this.onActionSelect.bind(this);
    this.handleNewPriceAlarm = this.handleNewPriceAlarm.bind(this);
  }



  componentDidUpdate(prevProps: Readonly<UIMarketInstrumentTableProps>, prevState: Readonly<UIMarketInstrumentTableState>, snapshot?: any): void {
    if (!this.areEqualColors(prevProps, this.props)) {
      this.setState((prevState => {
        return {
          ...prevState,
          colors: {...this.props.colors, colorsVersion: v1()}
        };
      }));
    }
  }

  onOpenOrder(formData: OrderFormData | null) {
    if (formData) {
      this.props.onOpenOrder(formData);
    }
  }

  onOpenQuoteRequest(formData: QuoteRequestFormData | null) {
    if (formData) {
      this.props.onOpenQuoteRequest(formData);
    }
  }

  onOpenPriceAlarmForm(formData: PriceAlarmFormData | null) {
    if (formData) {
      this.props.openPriceAlarmForm(formData);
    }
  }

  handleNewOrder(column: any, row: any) {
    const { matrix, prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    if (_prices) {
      this.onOpenOrder(createEnterOrderFormDataFromPrice(_prices[0], matrix.expiryToContract[row.code], column.group === 'bidPrices', matrix.associatedContracts ? matrix.associatedContracts[matrix.expiryToContract[row.code]] : []));
    }
  }

  handleNewQuoteRequest(column: any, row: any) {
    const { matrix, prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];

    this.onOpenQuoteRequest(openEnterQuoteRequestForm(matrix.expiryToContract[row.code], column.group === 'bidPrices', 0, matrix.associatedContracts ? matrix.associatedContracts[matrix.expiryToContract[row.code]] : []));
  }

  handleAcceptQuoteRequest(column: any, row: any, index: number) {
    const { prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    const quoteRequest = _prices[index].quoteRequest;
    this.props.acceptQuoteRequest({
      id: quoteRequest.id,
      businessUnitId: quoteRequest.requesterBusinessUnitId,
      portfolioId: quoteRequest.requesterPortfolioId,
      correlationId: v1()
    });
  }

  handleCancelQuoteRequest(column: any, row: any, index: number) {
    const { prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    const quoteRequest = _prices[index].quoteRequest;
    this.onOpenQuoteRequest(createCancelFormDataFromQuoteRequest(quoteRequest));
  }

  handleAnswerQuoteRequest(column: any, row: any, index: number) {
    const { prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    const quoteRequest = _prices[index].quoteRequest;
    this.onOpenQuoteRequest(createAnswerFormDataFromQuoteRequest(quoteRequest));
  }

  handleDeclineQuoteRequest(column: any, row: any, index: number) {
    const { prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    const quoteRequest = _prices[index].quoteRequest;
    this.props.declineQuoteRequest({
      id: quoteRequest.id,
      correlationId: v1()
    });
  }

  handleModifyOrder(column: any, row: any, index: number) {
    const { prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    this.onOpenOrder(createModifyOrderFormDataFromPrice(_prices[index]));
  }

  handleMatchOrder(column: any, row: any, index: number) {
    const { prices, matchPriceFeedOrdersEnabled, anonymizeMarketDataEnabled, matchOnlyModeEnabled } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    this.onOpenOrder(createVolumeMatchOrderFormDataFromPrice(_prices, index, matchPriceFeedOrdersEnabled, anonymizeMarketDataEnabled, matchOnlyModeEnabled));
  }

  handleCancelOrder(column: any, row: any, index: number) {
    const { prices } = this.props;
    const _prices = !!prices.orders[row.code]
      ? prices.orders[row.code][column.group]
      : [];
    this.onOpenOrder(createDeleteOrderFormDataFromPrice(_prices[index]));
  }

  handleNewGraph(e: any, column: any, row: any) {
    const { matrix, market } = this.props;
    const contract = !!matrix.expiryToContract[row.code]
      ? matrix.expiryToContract[row.code]
      : '';
    this.props.onCreateChart(e, {
      name: market.title,
      dockType: DockType.ChartMarket,
      type: ComponentType.MarketChart,
      args: [market.instrumentId, row.periodType, contract, [column.group]]
    });
  }

  handleNewPriceAlarm(column: any, row: any, index: number) {
    const { matrix, prices } = this.props;
    const contract = !!matrix.expiryToContract[row.code]
    ? matrix.expiryToContract[row.code]
    : '';
    const _prices = !!prices.orders[row.code]
    ? prices.orders[row.code][column.group]
    : [];
    this.onOpenPriceAlarmForm(createPriceAlarmFormData(contract, _prices[index], column.group));
  }

  onActionSelect(e: any, type: MarketActionType) {
    if (this.state.actionData) {
      const { column, row, index } = this.state.actionData;
      switch (type) {
        case MarketActionType.NEW_ORDER:
          this.handleNewOrder(column, row);
          break;
        case MarketActionType.MATCH_ORDER:
          this.handleMatchOrder(column, row, index);
          break;
        case MarketActionType.MODIFY_ORDER:
          this.handleModifyOrder(column, row, index);
          break;
        case MarketActionType.CANCEL_ORDER:
          this.handleCancelOrder(column, row, index);
          break;
        case MarketActionType.NEW_GRAPH:
          this.handleNewGraph(e, column, row);
          break;
        case MarketActionType.NEW_QUOTE_REQUEST:
          this.handleNewQuoteRequest(column, row);
          break;
        case MarketActionType.ACCEPT_QUOTE_REQUEST:
          this.handleAcceptQuoteRequest(column, row, index);
          break;
        case MarketActionType.REJECT_QUOTE_REQUEST:
          this.handleDeclineQuoteRequest(column, row, index);
          break;
        case MarketActionType.CANCEL_QUOTE_REQUEST:
          this.handleCancelQuoteRequest(column, row, index);
          break;
        case MarketActionType.ANSWER_QUOTE_REQUEST:
          this.handleAnswerQuoteRequest(column, row, index);
          break;
        case MarketActionType.NEW_PRICE_ALARM:
          this.handleNewPriceAlarm(column, row, index);
          break;
        default:
          break;
      }
    }
  }

  onContextMenu(e: any, actionData: any, hasData: boolean) {
    const { prices, logFatal, user } = this.props;
    const { row, column, index, contractId, contract } = actionData;
    const readonlyMarketsheet: boolean = config.readonlyMarketsheet;
    const _prices: Price[] = !!prices.orders[row.code] && prices.orders[row.code][column.group]
      ? prices.orders[row.code][column.group]
      : [];
    const contractIsReadonly = contract?.userPermission === UserPermission.READ_ONLY;
    const showOrderOperations = !!prices && !!_prices && !!_prices[index] && !readonlyMarketsheet && !contractIsReadonly;
    const order = showOrderOperations ? _prices[index].order : null;
    const quoteRequest = showOrderOperations ? _prices[index].quoteRequest : null;
    const disableCancelQR = quoteRequest === null ||
      !this.props.quoteRequestsEnabled ||
      !(user.currentUserId === quoteRequest.requesterId || user.currentUserGroupId === quoteRequest.requesterGroupId) || quoteRequest.state !== QuoteRequestState.ACTIVE;
    const disableAnswerQR = quoteRequest === null ||
      !this.props.quoteRequestsEnabled ||
      user.currentUserId === quoteRequest.requesterId || quoteRequest.state !== QuoteRequestState.ACTIVE;
    const disableAcceptQR = quoteRequest === null ||
      !this.props.quoteRequestsEnabled ||
      user.currentUserId !== quoteRequest.requesterId || quoteRequest.state !== QuoteRequestState.ANSWERED;
    const position = getMarketContextMenuPosition(e, logFatal, actionData);
    if (position) {
      this.setState({
        ...this.state,
        contextMenu: {
          position: { x: position.x, y: position.y },
          isOpen:
            !!contractId &&
            (column.group === 'bidPrices' || column.group === 'askPrices'),
          hasData: hasData,
          readonly: contractIsReadonly,
          disableMatch: 
            readonlyMarketsheet || 
            order === null || 
            quoteRequest !== null || 
            (order.readOnly && !this.props.matchPriceFeedOrdersEnabled) || 
            order.suspended,
          disableModify:
            order === null ||
            order.readOnly ||
            order.suspended ||
            !order.ownOrder,
          disableDelete:
            order === null ||
            order.readOnly ||
            order.suspended ||
            !order.ownOrder,
          disableCancelQuoteRequest: disableCancelQR,
          disableAnswerQuoteRequest: disableAnswerQR,
          disableAcceptQuoteRequest: disableAcceptQR

        },
        actionData: actionData
      });
    }
    return false; // prevent Edge to open default context menu on right click
  }

  onClickAction(e: any, actionData: any, hasData: boolean) {
    const { prices, user, matchPriceFeedOrdersEnabled } = this.props;
    const { row, column, index, contractId, contract } = actionData;
    const _prices: Price[] = !!prices.orders[row.code] && prices.orders[row.code][column.group]
      ? prices.orders[row.code][column.group]
      : [];

    const priceExists = !!prices && !!_prices && !!_prices[index];
    const isOrder = priceExists && _prices[index].order && !_prices[index].quoteRequest;
    const isPriceFeedOrder = isOrder && _prices[index].order.readOnly;
    const readonlyMarketsheet: boolean = config.readonlyMarketsheet;
    const contractIsReadonly = contract?.userPermission === 'READ_ONLY';
    if (!contractId) {
      return false;
    }
    if (!contractIsReadonly && !readonlyMarketsheet && (!hasData || (isPriceFeedOrder && !matchPriceFeedOrdersEnabled))) {
      this.handleNewOrder(actionData.column, actionData.row);
      return false;
    } else if (!contractIsReadonly && !readonlyMarketsheet && isOrder && _prices[index].order.ownOrder) {
      this.handleModifyOrder(actionData.column, actionData.row, index);
      return false;
    } else if (!contractIsReadonly && !readonlyMarketsheet && isOrder) {
      this.handleMatchOrder(actionData.column, actionData.row, index);
      return false;
    }

    const quoteRequest = priceExists ? _prices[index].quoteRequest : null;
    const isAnswerQR = quoteRequest !== null && this.props.quoteRequestsEnabled
      && user.currentUserId !== quoteRequest.requesterId && quoteRequest.state === QuoteRequestState.ACTIVE;

    if (!contractIsReadonly && isAnswerQR) {
      this.handleAnswerQuoteRequest(actionData.column, actionData.row, index);
      return false;
    }
    return false;
  }

  onContextMenuDismiss() {
    this.setState({
      ...this.state,
      contextMenu: {
        ...this.state.contextMenu,
        isOpen: false,
        hasData: false
      },
      actionData: null
    });
  }

  areEqualColors = (prevProps, nextProps) => {
    if (!prevProps?.colors || !nextProps?.colors) {
      return false;
    } else {
      for (let property in nextProps.colors) {
        if (nextProps.colors?.hasOwnProperty(property)) {
          if (prevProps.colors[property] !== nextProps.colors[property]) {
            return false;
          }
        }
      }
    }
    return true
  }

  render() {
    const {
      market,
      matrix,
      prices,
      expiries,
      isTableBorderActive,
      colors,
      isHeadlinesVisible,
      blockIndex,
      dockSize
    } = this.props;
    const { contextMenu } = this.state;

    if (!matrix) {
      return null;
    }
    const compactColumnsEnabled = this.props.settings.compactColumns.value;
    const columnMap = getColumns(market, compactColumnsEnabled);
    const columns = Object.keys(columnMap).map((columnTitle: string) => columnMap[columnTitle][0]);
    const headerRow = Object.keys(columnMap).map((columnTitle: any, index: any) => {
      let column = columnMap[columnTitle][0];
      return (
        <th key={matrix.instrumentId + column.group + column.name + index} className={`column ${column.originalName}`}>
          <label className="entry"><MemoTranslate value={(compactColumnsEnabled && column.combine ? 'columns.market.' : '' ) + columnTitle} /></label>
        </th>
      );
    });

    let records: any[] = [];
    const sortedPeriodTypes = Object.keys(PeriodType);
    const whichPeriodType = (s: string) => sortedPeriodTypes.indexOf(s.replace(/\d+$/, ''));
    const expiryKeys = Object.keys(expiries).sort((e1: string, e2: string) => whichPeriodType(e1) - whichPeriodType(e2));
    let absoluteRowIdx = 0;
    
    let columnsAttribute = [];
    const colKeys = Object.keys(columnMap);
    for (let colIdx = 0; colIdx < colKeys.length; colIdx++) {
      columnsAttribute = columnsAttribute.concat(columnMap[colKeys[colIdx]]);
    }
    
    for (let expiryIdx = 0; expiryIdx < expiryKeys.length; expiryIdx++) {
      const period = expiryKeys[expiryIdx];
      const expiryGroup: any[] = expiries[period];
      if (expiryGroup.length === 0) {
        continue;
      }
      
        const tableBody = [];
        for (let rowIdx = 0; rowIdx < expiries[period].length; rowIdx++) {
          const row = {...expiries[period][rowIdx], index: absoluteRowIdx++};
          
          const askPrices = prices.orders[row.code] ? prices.orders[row.code].askPrices : undefined;
          const bidPrices = prices.orders[row.code] ? prices.orders[row.code].bidPrices : undefined;
          const trades = prices.trades[row.code] ? prices.trades[row.code] : [];
          let isExpanded = !!market.expandedExpiries[row.code];
          
          tableBody.push(<ExpiryRow
            key={period + row.code + matrix.instrumentId}
            instrumentId={matrix.instrumentId}
            market={market}
            columns={columnsAttribute}
            askPrices={askPrices}
            bidPrices={bidPrices}
            trades={trades}
            contractId={matrix.expiryToContract[row.code]}
            contract={this.props.contracts[matrix.expiryToContract[row.code]]}
            period={period}
            row={row}
            expiriesExpanded={isExpanded}
            hasData={contextMenu.hasData}
            onContextMenu={this.onContextMenu}
            onClickAction={this.onClickAction}
            localizeOptions={this.localizeOptions}
            colors={this.state.colors}
            presetDepths={market.depths}
            compactColumnsEnabled={compactColumnsEnabled}
            dockSize={dockSize}
          />);
        }
        records.push(
          <React.Fragment key={period+'-'+matrix.instrumentId}>
            <tbody>{tableBody}</tbody>
            {expiryIdx < Object.keys(expiries).filter(key => expiries[key].length > 0).length - 1 ? (
              <tbody className="empty-row">
                <tr>
                  <td colSpan={Object.keys(columns).length}>&nbsp;</td>
                </tr>
              </tbody>
            ) : null}
          </React.Fragment>
        );
      }

    const popoverFullName = (
      <Popover id="popover-full-name" className="show">
        <b>{matrix.name}</b>
      </Popover>
    );
    const readonlyMarketsheet: boolean = config.readonlyMarketsheet;
    const required: { [x: string]: boolean } = {};

    if (!readonlyMarketsheet) {
      if (!this.props.matchOnlyModeEnabled && !this.state.contextMenu.readonly) {
        required[MarketActionType.NEW_ORDER] = true;
      }
      if (!this.state.contextMenu.disableMatch && !this.state.contextMenu.readonly) {
        required[MarketActionType.MATCH_ORDER] = true;
      }
      if (!this.state.contextMenu.disableModify && !this.state.contextMenu.readonly) {
        required[MarketActionType.MODIFY_ORDER] = true;
      }
      if (!this.state.contextMenu.disableDelete && !this.state.contextMenu.readonly) {
        required[MarketActionType.CANCEL_ORDER] = true;
      }
    }
    if (config.priceAlarmsEnabled) {
      required[MarketActionType.NEW_PRICE_ALARM] = true;
    }

    const optional: MarketActionType[] = [];
    if (!readonlyMarketsheet && this.props.quoteRequestsEnabled && !this.state.contextMenu.readonly) {
      optional.push(MarketActionType.NEW_QUOTE_REQUEST);
    }
    if (!this.state.contextMenu.disableCancelQuoteRequest && !this.state.contextMenu.readonly) {
      optional.push(MarketActionType.CANCEL_QUOTE_REQUEST);
    }
    if (!this.state.contextMenu.disableAnswerQuoteRequest && !this.state.contextMenu.readonly)  {
      optional.push(MarketActionType.ANSWER_QUOTE_REQUEST);
    }
    if (!this.state.contextMenu.disableAcceptQuoteRequest && !this.state.contextMenu.readonly) {
      optional.push(MarketActionType.ACCEPT_QUOTE_REQUEST);
      optional.push(MarketActionType.REJECT_QUOTE_REQUEST);
    }
    optional.push(MarketActionType.NEW_GRAPH);

    const MarketContextMenu = (
      <UIMarketContextMenu
        position={contextMenu.position}
        isOpen={contextMenu.isOpen}
        onAction={this.onActionSelect}
        dismiss={this.onContextMenuDismiss}
        requiredActions={required}
        optinalActions={optional}
      />
    );

    const colWidth = getWidthFromColumnWidthCode(this.props.columnWidth);
    const style = {};
    if (colWidth > 0) {
      style['width'] = (Object.keys(columns).length * getWidthFromColumnWidthCode(this.props.columnWidth)) + 'px';
    }

    return (
      <div data-test="orderbook-instrument" 
        style={{width: (100 / (this.props.instrumentCount)).toFixed(1) + '%', minWidth: (Object.keys(columnMap).length * 60) + 'px' }} 
        className={`${this.props.columnWidth} instrument${blockIndex % 2 ? ' block-even' : ' block-odd'}`}
      >
        <div className="instrument-header">
          <InstumentHeader name={matrix.name} />
        </div>
        <table
          className={`table ${isTableBorderActive ? `table-custom-border ` : ` `}
            ${isHeadlinesVisible ? '' : `hidden-headlines`}`}
          style={style}
        >
          <thead>
            <tr>{headerRow}</tr>
          </thead>
          {records}
        </table>
        {contextMenu.isOpen ? MarketContextMenu : null}
      </div>
    );
  }
}
