import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Contract } from '../../orderbook/models/contracts';
import { ToggleSwitch } from '../../shared/form/components/ToggleSwitch';
import { ValidationResult, Validation } from '../../shared/validation/model';
import { getMaxDecimalsIncludingStepSizes, formatQuoteRequestFormData, getLocalizationSettings } from '../../shared/utils/formatters';
import { QuoteRequestFormData, QuoteRequestMode } from '../models/request';
import { numberValidation } from '../../shared/validation/validators';
import { initialState } from '../reducers/quoteRequestForm';

import { IFormComponent } from '../../shared/form/components/FormComponent';
import { NumberSpinner } from '../../shared/form/components/NumberSpinner';
import { BusinessUnit, PortfolioClass } from '../../businessUnitPortfolios/models/businessUnits';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getOrderbookContracts } from '../../orderbook/selectors/contracts';
import orderBookStore from '../../orderbook/store/orderbooks';
import DropdownList from '../../shared/form/components/DropdownList';
// import * as enGB from 'date-fns/locale/en-GB';

interface QuoteRequestState {
    formData: QuoteRequestFormData & {associatedContractObjects: Contract[]};
}

interface QuoteRequestProps {
    contract: Contract;
    form: any;
    uuid: string;
    businessUnits: {[key: string]: BusinessUnit};
    handleInvalidInput: (result: ValidationResult) => void;
    handleNumberChange: (event: any, decimals: number) => void;
    handleChange: (event: any) => void;
    handleSuccess: (message: string) => void;
}

const getBusinessUnitOptions = (businessUnits: { [key: string]: BusinessUnit }) => {
    let businessUnitOptions: any[] = [];
    if (businessUnits) {
        Object.keys(businessUnits).forEach(bu => {
            businessUnitOptions.push(
                <option key={bu} value={businessUnits[bu].id} >
                    {businessUnits[bu].name}
                </option>
            );
        });
    }
    return businessUnitOptions;
};

const getPortfolioOptions = (businessUnits: { [key: string]: BusinessUnit }, selectedBusinessUnit: string | null) => {
    let portfolioOptions: any[] = [];
    if (selectedBusinessUnit && businessUnits && businessUnits[selectedBusinessUnit] && businessUnits[selectedBusinessUnit].portfolios) {
        const portfolios: PortfolioClass[] = businessUnits[selectedBusinessUnit].portfolios;
        portfolios.forEach(p => {
            portfolioOptions.push(
                <option key={p.id} value={p.id} >
                    {p.name}
                </option>
            );
        });
    }
    return portfolioOptions;
};

export class QuoteRequestFormComponent extends React.Component<QuoteRequestProps, QuoteRequestState>  implements IFormComponent {
    validations: Validation;
    constructor(props: QuoteRequestProps) {
        super(props);
        
        // registerLocale('en-GB', enGB);
        // setDefaultLocale('enGB');
        this.state = {
            formData: {
                ...initialState.data,
                contractId: this.props.contract.id,
                associatedContractObjects: []
            }
        };

        this.validations = {
            translationPrefix: 'quoteRequest.form',
            rules: {
                quantity: [numberValidation],
                quote: [numberValidation]
            }
        };
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    _getContracts = () => getOrderbookContracts(orderBookStore.getState());

    componentDidMount() {
        return this.setState(prevState => {
            return {
                ...prevState,
                ...this.props.form,
                formData: {...formatQuoteRequestFormData(this.props.form.data, true,  this.props.contract.qtyDecimals, this.props.contract.qtyStepSize, this._getContracts())} 
            };
        });
    }

    setFormState = (formState: any) => {
        return this.setState(prevState => {
            return {
                    ...prevState,
                    formData: {...prevState.formData, ...formState }
                };
            });
    }

    getFormState = () => {
        return formatQuoteRequestFormData(this.state.formData, false, this.props.contract.qtyDecimals, this.props.contract.qtyStepSize, this._getContracts());
    }

    toggleBuySell = (value: boolean) => {
        return this.setState(prevState => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    buy: value
                }
            };
        });
    }

    getValidations = () => {
        return this.validations;
    }

    formatFormDataToDisplay = (formData: QuoteRequestFormData) => {
        return formatQuoteRequestFormData(this.state.formData, true, this.props.contract.qtyDecimals, this.props.contract.qtyStepSize, this._getContracts());
    }

    handleDateChange = (date: Date | null, input: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [input]: date
                }
            };
        });
    }

    handleSuccess = () => {
        return this.props.handleSuccess(I18n.t('quoteRequest.form.success' + this.state.formData.mode));
    }
  
    _selectContract = (contractId: string) => {
        this.setState((prevState) => {
          return {
            ...prevState,
            formData: {...prevState.formData, contractId}
          };
        });
    }
    _contractSelection = (): JSX.Element => {
        const { formData } = this.state;
        if (!!formData.associatedContractObjects && formData.associatedContractObjects.length > 0) {
            return (
            <DropdownList
                list={formData.associatedContractObjects}
                itemName={(c: Contract) => c.name}
                itemId={(c: Contract) => c.id}
                itemValue={(c: Contract) => c}
                selectedItem={formData.associatedContractObjects[0]}
                onSelect={this._selectContract}
                visibleDropdown={true}
            />
            );
        } else {
            return (<>{this.props.contract.name}</>);
        }
    }

    render() {
        const { formData } = this.state;
        const { businessUnits } = this.props;
        const buySellDisabled = formData.mode !== QuoteRequestMode.ENTER;
        const dateFormat = getLocalizationSettings().dateFormat + ' HH:mm';

        const showPrice = formData.mode === QuoteRequestMode.ANSWER;
        const formCancel = formData.mode === QuoteRequestMode.CANCEL;
        const disableOnAnswer = formData.mode === QuoteRequestMode.ANSWER;

        const businessUnitOptions: any[] = getBusinessUnitOptions(businessUnits);
        const portfolioOptions: any[] = getPortfolioOptions(businessUnits, formData.businessUnit);

        return (
            <React.Fragment>
                <form key="quoteRequestForm">
                    <div>
                        <h4>
                            {this._contractSelection()}
                        </h4>
                        <div className="format-info">{dateFormat}</div>
                    </div>
                    {formData.quoteRequestId ? (
                        <div>
                            <h4>
                                <Translate value="quoteRequest.form.quoteRequestId" />: {formData.quoteRequestId}
                            </h4>
                        </div>
                    ) : null}

                    <div className="container" hidden={formCancel}>
                        <div className="mt-2 d-flex align-items-center">
                            <div className="buy mr-auto">
                                <ToggleSwitch
                                    value={formData.buy}
                                    trueTitle="quoteRequest.form.buy"
                                    falseTitle="quoteRequest.form.sell"
                                    disabled={buySellDisabled}
                                    toggle={this.toggleBuySell}
                                />
                            </div>
                            <div className={'quantity mr-auto'}>
                                <label className="mr-2">
                                    <Translate value="quoteRequest.form.quantity" />{' '}
                                </label>
                                <NumberSpinner
                                    className="form-control"
                                    name="quantity"
                                    onChange={(event: any) =>
                                        this.props.handleNumberChange(
                                            event,
                                            getMaxDecimalsIncludingStepSizes(this.props.contract.qtyDecimals, this.props.contract.qtyStepSize)
                                        )
                                    }
                                    type="text"
                                    value={formData.quantity}
                                    size={5}
                                    autoFocus={true}
                                    disabled={disableOnAnswer}
                                    step={getMaxDecimalsIncludingStepSizes(this.props.contract.qtyDecimals, this.props.contract.qtyStepSize)}
                                    min={0}
                                />
                            </div>
                            {showPrice ? (
                                <React.Fragment>
                                    <div style={{display: showPrice ? 'block' : 'none'}} className="price mr-auto">
                                        <label className="mr-2">
                                            <Translate value="quoteRequest.form.price" />{' '}
                                        </label>
                                    
                                        <NumberSpinner
                                            className="form-control"
                                            name="quote"
                                            onChange={(event: any) =>
                                                this.props.handleNumberChange(
                                                    event, this.props.contract.priceDecimals
                                                )
                                            }
                                            type="text"
                                            value={formData.quote}
                                            size={5}
                                            autoFocus={true}
                                            disabled={false}
                                            step={this.props.contract.priceDecimals}
                                        />
                                    </div>
                                </React.Fragment>
                                ) : (<React.Fragment />)} 

                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-6">
                                <label>
                                    <Translate value="quoteRequest.form.businessUnits" />{' '}
                                </label>
                                <div className="form-group businessUnit">
                                    <select
                                        value={formData.businessUnit ? formData.businessUnit : ''}
                                        onChange={this.props.handleChange}
                                        name="businessUnit"
                                        className="form-control"
                                    >
                                        {businessUnitOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label>
                                    <Translate value="quoteRequest.form.portfolios" />{' '}
                                </label>
                                <div className="form-group portfolios">
                                    <select
                                        value={formData.portfolio ? formData.portfolio : ''}
                                        onChange={this.props.handleChange}
                                        name="portfolio"
                                        className="form-control"
                                    >
                                        {portfolioOptions}
                                    </select>
                                </div>
                            </div>
                        </div>
                         {/* ---quote request maintanance--- */}
                            {/* <div className="row">
                                <div className="col-sm-5"></div>
                                <div className="col-sm-7"></div>
                            </div> */}
                       
                        <div className="row mt-4">
                            <div className="col-sm-6 text-center">
                                <label>
                                    <Translate value="quoteRequest.form.validFrom" />{' '}
                                </label>
                                <DatePicker 
                                    name="validFrom"
                                    className="form-control" 
                                    selected={this.state.formData.validFrom} 
                                    onChange={(date: any) => this.handleDateChange(date, 'validFrom')} 
                                    autoComplete="off"
                                    showTimeSelect={true}
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat={dateFormat}
                                    disabled={disableOnAnswer}
                                    timeCaption={I18n.t('quoteRequest.form.time')}
                                    // locale="en-GB"
                                />                              
                            </div>
                            <div className="col-sm-6 text-center">
                                <label>
                                    <Translate value="quoteRequest.form.validTo" />{' '}
                                </label>
                                <DatePicker 
                                    name="validTo" 
                                    className="form-control" 
                                    selected={this.state.formData.validTo} 
                                    onChange={(date: any) => this.handleDateChange(date, 'validTo')}  
                                    autoComplete="off"
                                    showTimeSelect={true}
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat={dateFormat}
                                    disabled={formCancel}
                                    timeCaption={I18n.t('quoteRequest.form.time')}
                                    // locale="en-GB"
                                />                              
                            </div>
                        </div>
                    <div className="row mt-2" hidden={disableOnAnswer}>
                        <div className="col-sm-5 d-flex align-items-end">
                            <label>
                                <Translate value="quoteRequest.form.reference" />
                            </label>
                        </div>
                        <div className="col-sm-7">
                            <input
                                className="form-control"
                                name="reference"
                                onChange={event =>
                                    this.props.handleChange(event)
                                }
                                type="text"
                                value={formData.reference}
                                autoFocus={true}
                                disabled={false}
                            />     
                        </div>
                    </div>
                    <div className="row mt-2" hidden={disableOnAnswer}>
                        <div className="col-sm-5 d-flex align-items-end">
                            <label>
                                <Translate value="quoteRequest.form.comment" />
                            </label>
                        </div>
                        <div className="col-sm-7">
                            <input
                                className="form-control"
                                name="comment"
                                onChange={event =>
                                    this.props.handleChange(event)
                                }
                                type="text"
                                value={formData.comment}
                                autoFocus={true}
                                disabled={false}
                            />   
                        </div>
                    </div>
                </div>
              </form>
            </React.Fragment >
        );
    }
}