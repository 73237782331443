import { IOrder, Restriction } from '../../orders/models/orders';
import { IRequest } from '../../requests/models/request';
import { Expiry } from './contracts';

export interface SubscribeCombinedOrderbooksRequest {
  instrumentIds: string[];
}

export interface MarketsheetResponse {
    contractMatrixItem: any;
    componentId: string;
}

export interface MarketsheetRequest {
    correlationId: string;
    itemIds: string[];
    componentId: string;
}

export interface MarketsheetContractDepthRequest {
  correlationId: string;
  contractDepths: {[contract: string]: number};
  instrumentIds?: string[];
  componentId?: string;
}

export interface Orderbooks {
  orderbook: Orderbook[];
}

export interface Orderbook {
  contractId: string;
  bidPrices: Price[];
  askPrices: Price[];
  realDepth: number;
}

export interface Price {
  buy: boolean;
  order: IOrder;
  quote: Quote;
  quoteRequest: IRequest;
  tradable: boolean;
  id: number;
  type: OrderQuoteIndicator;
  price: number;
  quantity: number;
  aon: boolean;
  restriction: Restriction;
  timestamp: number;
  contractId: string;
  traderId: string;
  counterparty: string;
  businessUnitId: string;
  portfolioId: string;
}

export enum OrderQuoteIndicator {
  QUOTE,
  ORDER,
  MANUAL_EXECUTION,
  ORDER_ROUTING,
  QUOTE_REQUEST,
  MASS_ORDER_STATE_CHANGE,
  TRADE_EXTERNAL_ORDER_INTERNALLY,
  EXTERNAL_EXECUTION,
  MANUAL_ROUTING_PERMISSION,
  UNSOLICITED_ROUTING_REVOCATION,
  UNSOLICITED_ROUTING_ERROR,
  UNSOLICITED_ROUTING_SUCCESS,
  MARKET_NEUTRAL_UPDATE,
  UNSOLICITED_EXTERNAL_QUANTITY_REDUCED,
  UNSOLICITED_ROUTING_MODIFY_SUCCESS,
  UNSOLICITED_ROUTING_DELETE_SUCCESS,
  ORDER_ROUTING_STATE_CHANGE
}

export interface TrayportOrderId {
  engineID: number;
  orderId: string;
}

// move to separate models for quote
export interface Quote {
  quoteId: number;
  contractId: string;
  userId: string;
  userName: string;
  userGroupId: string;
  userGroupName: string;
  bidPrc: number;
  askPrc: number;
  bidQty: number;
  askQty: number;
  timestamp: number;
  businessUnitId: string;
  portfolioId: string;
  portfolioClassId: number;
}

export const periodTypes: { [key: string]: any } = {
  QUARTER_HOUR: { index: 0, label: 'Quarter Hour', key: 'periodTypes.quarterHour' },
  HALF_HOUR: { index: 1, label: 'Half Hour', key: 'periodTypes.halfHour' },
  HOUR: { index: 2, label: 'Hour', key: 'periodTypes.hour' },
  BLOCK_2_H: {index: 3, label: '2 Hour Block', key: 'periodTypes.block2H'},
  BLOCK_4_H: {index: 4, label: '4 Hour Block', key: 'periodTypes.block4H'},
  NAMED: { index: 5, label: 'Named', key: 'periodTypes.named' },
  DAY: { index: 6, label: 'Day', key: 'periodTypes.day' },
  BALANCE_OF_WEEK: { index: 7, label: 'Balance of Week', key: 'periodTypes.balanceOfWeek' },
  WEEKEND: { index: 8, label: 'Weekend', key: 'periodTypes.weekend' },
  WORKING_DAYS: { index: 9, label: 'Working Days', key: 'periodTypes.workingDays' },
  WEEK: { index: 10, label: 'Week', key: 'periodTypes.week' },
  SPLIT: { index: 11, label: 'Split', key: 'periodTypes.split' },
  BALANCE_OF_MONTH: { index: 12, label: 'Balance of Month', key: 'periodTypes.balanceOfMonth' },
  MONTH: { index: 13, label: 'Month', key: 'periodTypes.month' },
  QUARTER: { index: 14, label: 'Quarter', key: 'periodTypes.quarter' },
  SUM_WIN_SEASON: { index: 15, label: 'Season', key: 'periodTypes.sumWinSeason' },
  EMISSION: { index: 16, label: 'Emission', key: 'periodTypes.emission' },
  YEAR: { index: 17, label: 'Year', key: 'periodTypes.year' },
  GAS_YEAR: { index: 18, label: 'Gas Year', key: 'periodTypes.gasYear' }
};

export const isIntraday = (periodType: string): boolean => {
  switch (periodType) {
    case 'QUARTER_HOUR':
    case 'HALF_HOUR':
    case 'BLOCK_2_H':
    case 'BLOCK_4_H':
    case 'HOUR': {
      return true;
    }
    default:
      return false;
  }
};

export enum LoadedData {
  CONTRACTS = 2,
  INSTRUMENTS = 3,
  PRODUCTS = 5,
  ALL = 30
}

export interface ContractMatrix {
  [instrumentId: string]: any;
}
