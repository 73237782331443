import {
    ActionsObservable,
    combineEpics,
} from 'redux-observable';
import { filter, map, switchMap, flatMap, mergeMap, catchError } from 'rxjs/operators';
import { ActionTypes as BasketActions, invalidSampleBasketFile, finishedDownloadSampleBasketFile } from '../actions/bulkOrders';
import { ActionTypes as OrderActions } from '../../orders/actions/orders';
import BasketService from '../services/bulkOrders';
import * as Rx from 'rxjs';
import * as XLSX from 'xlsx';

const basketService = new BasketService();


export const bulkEnter: any = (
    actions$: ActionsObservable<any>
) => {
    return actions$.pipe(
        filter(action => action.type === OrderActions.BULK_ENTER_ORDER),
        map(action => action.payload),
        switchMap((content) => {
            return basketService.sendBulkOrders({orders: content}).pipe(
                catchError((error: any) => { return Rx.empty(); })
            );
        })
    );
};

export const basketEpic = combineEpics(
    bulkEnter
);