import { Expiry } from '../../orderbook/models/contracts';

export interface PriceAlarm {
  [key: string]: any;
  id: string;
  created: number;
  contract: string;
  expiry: Expiry | undefined;
  priceAlarmType: PriceAlarmType;
  priceLevel: number;
  triggeredBy: number;
  expires: number;
  triggered: boolean;
  customer: string;
  description: string;
}

export interface PriceAlarmCreateRequest {
  priceAlarm: PriceAlarm;
  correlationId: string;
}

export interface PriceAlarmModifyRequest {
  priceAlarm: PriceAlarm;
  correlationId: string;
}

export interface PriceAlarmDeleteRequest {
  priceAlarm: PriceAlarm;
  correlationId: string;
}

export interface PriceAlarmTriggerResponse {
  priceAlarm: PriceAlarm;
}

export interface PriceAlarmResponse {
  priceAlarms: PriceAlarm[];
}

export enum PriceAlarmFormMode {
  CREATE, MODIFY, CANCEL
}

export enum PriceAlarmType {
  ASK_PRICE = 'ASK_PRICE', 
  BID_PRICE = 'BID_PRICE', 
  LAST_PRICE_LESS = 'LAST_PRICE_LESS', 
  LAST_PRICE_MORE = 'LAST_PRICE_MORE'
}

export interface PriceAlarmFormData {
  priceAlarmId: string;
  mode: PriceAlarmFormMode;
  type: PriceAlarmType;
  price: number;
  contractId: string;
  expires: Date | undefined;
  customer: string;
  description: string;
}