import * as React from 'react';
import { changeTab } from '../../ui/actions/tab';
import { connect } from 'react-redux';

interface TabsProps {
  dockId: string;
  defaultActiveTabIndex?: number;
  onChangeTab: (selectedId: string, unselectedId: string) => void;
}
interface TabsState {
  activeTabIndex: number;
}

export class Tabs extends React.Component<TabsProps, TabsState> {
  private ref: any;
  private timeouts: any[];
  private observer: MutationObserver | null;
  constructor(props: TabsProps) {
    super(props);

    this.state = {
      activeTabIndex: this.props.defaultActiveTabIndex || 0
    };

    this._handleTabClick = this._handleTabClick.bind(this);
    this._handleTabRemove = this._handleTabRemove.bind(this);

    this.ref = React.createRef();
    this.timeouts = [];
    this.observer = null;
  }

  componentDidMount() {
    const { dockId } = this.props;
    const ele = this.ref.current!;
    const parentEle = document.querySelector('#dock-' + dockId + ' .box');

    if (parentEle) {
      const ul = ele.querySelector('ul');

      this._addDynamicClass(parentEle, ele);
      if (ul) {        
        const content = ele?.querySelector('.tab-content');
        this._setContentHeightAccordingToTabListHeight(content, ul)

        this.observer = new MutationObserver(() => {
          this.timeouts = [
            ...this.timeouts,
            setTimeout(() => {
              this._addDynamicClass(parentEle, ul);
              
              this._setContentHeightAccordingToTabListHeight(content, ul)
            }, 100)
          ];
        });
      }

      this.observer.observe(parentEle, {
        attributes: true
      });
    }
    if (this.state.activeTabIndex > -1) {
      this._handleTabClick(this.state.activeTabIndex);
    }
  }

  _setContentHeightAccordingToTabListHeight(content, ul) {
    if (content) {
      const height = ul.clientHeight || 48.75;
      content.style.height = 'calc(100% - ' + (height + 5) + 'px)'; 
    }
  }

  _addDynamicClass(parentEle: Element, ele: HTMLUListElement) {
    if (parentEle.clientWidth < 375) {
      ele.classList.add('narrow');
    } else {
      ele.classList.remove('narrow');
    }
  }

  // Toggle currently active tab
  _handleTabClick(tabIndex: number) {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    const _children: any = children;

    let currentlySelected = _children[activeTabIndex].props.tabId;
    let toBeSelected = _children[tabIndex].props.tabId;

    this.props.onChangeTab(toBeSelected, currentlySelected);

    this.setState({
      activeTabIndex: tabIndex
    });
  }

  _handleTabRemove(tabIndex: number) {
    const { activeTabIndex } = this.state;
    let newTabIndex = activeTabIndex === tabIndex && activeTabIndex > 0 ? (activeTabIndex - 1) : activeTabIndex;
    this.setState({
      activeTabIndex: newTabIndex
    });
  }

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  _renderChildrenWithTabsApiAsProps() {
    return React.Children.map(
      this.props.children,
      (child: React.ReactElement<any>, index: number) => {
        return React.cloneElement(child, {
          onClick: this._handleTabClick,
          onRemove: this._handleTabRemove,
          tabIndex: index,
          isActive: index === this.state.activeTabIndex
        });
      }
    );
  }

  // Render current active tab content
  _renderActiveTabContent() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    const _children: any = children;

    if (_children && _children.length) {
      if (_children[activeTabIndex]) {
        return _children[activeTabIndex].props.children;
      } else {
        return _children[0].props.children;
      }
    } else {
      return _children.props.children;
    }
  }

  render(): JSX.Element {
    const _children: any = this.props.children;
    return (
      <div className="tabs" ref={this.ref}>
        <ul className={`nav nav-tabs ${'nav-tabs-' + _children.length}`} >
          {this._renderChildrenWithTabsApiAsProps()}
        </ul>
        <div className="tab-content">
          {this._renderActiveTabContent()}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.timeouts.map(t => clearTimeout(t));
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

const mapDispatchToProps = {
  onChangeTab: changeTab
};

export default connect<any, any, any>(null, mapDispatchToProps)(Tabs);