import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../../main/reducers/rootReducer';
import { I18n } from 'react-redux-i18n';

import store from '../../../../../main/store/store';
import UIDropdownMenu from '../../actionIcons/DropdownMenu';
import FavoriteIcon from '../../actionIcons/FavouriteIcon';
import ActionIcon from '../../actionIcons/ActionIcon';
import UIMarketFilter from './uiMarketFilter';
import { getDockById } from '../../../../../dashboard/selectors/dashboard';
import {
  triggerExpiries,
  triggerExpiryRows,
  triggerColumnNames,
  remove,
  changeHeadlinesVisible
} from '../../../actions/market';
import { setBlockWidth } from '../../../../../shared/dock/actions/dock';
import { toggleFavorite } from '../../../../favorite/actions/favorite';
import { Favorite, IFavorite } from '../../../../favorite/models/favorite';
import { IDock } from '../../../../../shared/dock/models/dock';
import { IMarket } from '../../../models/market';
import { ComponentType } from '../../../models/component';
import { PeriodType } from '../../../../../orderbook/models/contracts';

interface MarketActionsProps {
  dockId: string;
  markets: IMarket[];
  expiries: { [key: string]: any[] };
  hiddenExpiries: string[];
  favoriteName: string;
  isHeadlinesVisible: boolean;
  modificationAllowed: boolean;
  favorites: IFavorite[];
}
interface MarketActionsState {}

class MarketActions extends React.Component<
  MarketActionsProps,
  MarketActionsState
  > {
  showFilter: boolean = true;
  showResize: boolean = true;
  showClose: boolean = true;

  constructor(props: MarketActionsProps) {
    super(props);

    this.handleFavoriteSubmit = this.handleFavoriteSubmit.bind(this);
    this._triggerColumnNames = this._triggerColumnNames.bind(this);
    this._triggerExpiries = this._triggerExpiries.bind(this);
    this._triggerExpiryRows = this._triggerExpiryRows.bind(this);
    this._onToggleFavorite = this._onToggleFavorite.bind(this);
    this._setColumnWidth = this._setColumnWidth.bind(this);
    this._remove = this._remove.bind(this);
    this._toggleHeadlinesVisible = this._toggleHeadlinesVisible.bind(this);
  }

  handleFavoriteSubmit(name: string) {
    const { dockId, markets, favoriteName } = this.props;
    const dock: IDock = getDockById(store.getState(), dockId);
    const newFavorite = new Favorite(
      name || favoriteName,
      dockId,
      markets.map(market => {
        return {
          componentType: market.type,
          itemId: market.itemId,
          instrumentId: market.instrumentId,
          instrumentTitle: market.title,
          columns: market.columns,
          hiddenColumnNames: market.hiddenColumnNames,
          hiddenExpiryKeys: market.hiddenExpiryKeys,
          isHeadlinesVisible: market.isHeadlinesVisible
        };
      }),
      dock.type
    );

    document.body.click(); // Workaround for destroy popover object
    return this._onToggleFavorite(newFavorite, !!!this.props.favoriteName);
  }

  _triggerColumnNames(
    marketId: string,
    hiddenColumnNames: string[],
    dockId: string
  ) {
    store.dispatch(triggerColumnNames(marketId, hiddenColumnNames, dockId));
  }

  _triggerExpiries(
    marketIds: string[],
    hiddenExpiryKeys: string[],
    allExpiryKeys: string[],
    dockId: string
  ) {
    store.dispatch(
      triggerExpiries(marketIds, hiddenExpiryKeys, allExpiryKeys, dockId)
    );
  }

  _triggerExpiryRows(
    marketIds: string[],
    expiryRowsLength: any,
    dockId: string
  ) {
    store.dispatch(triggerExpiryRows(marketIds, expiryRowsLength, dockId));
  }

  _onToggleFavorite(favorite: IFavorite, isFavorite: boolean) {
    store.dispatch(toggleFavorite(favorite, isFavorite));
  }

  _setColumnWidth(dockId: string, columnWidth: string) {
    store.dispatch(setBlockWidth(dockId, columnWidth));
  }

  _remove(id: string, dockId: string) {
    store.dispatch(remove(id, dockId));
  }

  _toggleHeadlinesVisible(ids: string[], isHeadlinesVisible: boolean, dockId: string) {
    store.dispatch(changeHeadlinesVisible(ids, isHeadlinesVisible, dockId));
  }

  render() {
    const {
      dockId,
      markets,
      hiddenExpiries,
      favoriteName,
      expiries,
      isHeadlinesVisible,
      favorites
    } = this.props;
    const isIntraday = markets.reduce((acc: boolean, m: IMarket) => acc && m.type === ComponentType.InstrumentIntraday, true);
    let filterExpiries = expiries;
    // get unique expiry period types to display in intraday market filter
    // set length of expiry type array to total number of expiries of given period type
    if (isIntraday) {
      let allExpiryTypes: string[] = [];
      const expiryKeys = Object.keys(expiries);

      for (let i = 0; i < expiryKeys.length; i++) {
        allExpiryTypes = allExpiryTypes.concat(
          expiries[expiryKeys[i]].map(expiry => expiry.periodType)
        );
      }
      filterExpiries = allExpiryTypes
        .filter((v, i, a) => a.indexOf(v) === i)
        .reduce((acc: {[type: string]: any}, type: string) => { 
            let exp: any[] = [];
            for (let i = 0; i < expiryKeys.length; i++) {
              exp = exp.concat(expiries[expiryKeys[i]].filter(expiry => expiry.periodType === type));
            }
            return { ...acc, 
              [type]: exp
            }; 
          }, {});
    }
    const sortedPeriodTypes = Object.keys(PeriodType);
    const whichPeriodType = (s: string) => sortedPeriodTypes.indexOf(s.replace(/\d+$/, ''));
    const filterComponent = (
      <UIMarketFilter
          dockId={dockId}
          markets={markets}
          expiries={Object.keys(filterExpiries)
            .sort((e1: string, e2: string) => whichPeriodType(e1) - whichPeriodType(e2))
            .map(k => ({
              name: k,
              length: filterExpiries[k].length
            }))}
          hiddenExpiries={hiddenExpiries}
          triggerColumnNames={this._triggerColumnNames}
          triggerExpiries={this._triggerExpiries}
          triggerExpiryRows={this._triggerExpiryRows}
          isHeadlinesVisible={isHeadlinesVisible}
          changeHeadlinesVisibility={this._toggleHeadlinesVisible}
        />
    );
    

    const resizeComponent = (
      <UIDropdownMenu
        items={[
          {
            key: 'def',
            caption: I18n.t('general.Default Width'),
            onClick: () =>
              this._setColumnWidth(
                this.props.dockId,
                'instrument-w-default'
              )
          },
          {
            key: '60',
            caption: I18n.t('general.Width', { width: '60' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-60')
          },
          {
            key: '70',
            caption: I18n.t('general.Width', { width: '70' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-70')
          },
          {
            key: '80',
            caption: I18n.t('general.Width', { width: '80' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-80')
          },
          {
            key: '90',
            caption: I18n.t('general.Width', { width: '90' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-90')
          },
          {
            key: '100',
            caption: I18n.t('general.Width', { width: '100' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-100')
          },
          {
            key: '120',
            caption: I18n.t('general.Width', { width: '120' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-120')
          },
          {
            key: '140',
            caption: I18n.t('general.Width', { width: '140' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-140')
          },
          {
            key: '160',
            caption: I18n.t('general.Width', { width: '160' }),
            onClick: () =>
              this._setColumnWidth(this.props.dockId, 'instrument-w-160')
          }
        ]}
        dockId={dockId}
      />
    );

    const closeItems = this.props.markets.map(market => ({
      key: market.id,
      caption: I18n.t('market.closeMarket', { title: market.title }),
      onClick: () => this._remove(market.id, market.dockId)
    }));

    if (this.props.markets.length > 1) {
      closeItems.push({
        key: 'all',
        caption: I18n.t('market.closeAll'),
        onClick: () =>
          this.props.markets.forEach(market =>
            this._remove(market.id, market.dockId)
          )
      });
    }
    const closeComponent = (
      <UIDropdownMenu items={closeItems} dockId={dockId} />
    );
    return (
      <ul>
        {
          this.props.modificationAllowed ?
            <ActionIcon
              iconName="oi-x"
              title={I18n.t('modal.close')}
              component={closeComponent}
            /> : null
        }
        <ActionIcon
          iconName="oi-resize-width"
          title={I18n.t('modal.width')}
          component={resizeComponent}
        />
        <ActionIcon
          iconName="oi-wrench"
          title={I18n.t('modal.filter')}
          component={filterComponent}
        />
        {
          this.props.modificationAllowed ?
            <FavoriteIcon
              favoriteName={favoriteName}
              isFavorite={!!favoriteName}
              onSubmit={this.handleFavoriteSubmit}
              favorites={favorites}
            /> : null
        }
      </ul>
    );
  }
}

const mapStateToProps = (state: State, props: MarketActionsProps) => ({
});

const mapDispatchToProps = {
  onToggleFavorite: toggleFavorite,
  setBlockWidth: setBlockWidth,
  remove: remove
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(MarketActions);
