export interface IOrder {
    [key: string]: any;
    $index: number;
    orderId: number;
    state: State;
    userId: string;
    userName: string;
    userGroupId: string;
    userGroupName: string;
    contractId: string;
    contractTitle: string;
    buy: boolean;
    remainingQuantity: number;
    orderedQuantity: number;
    qtyUnit: string;
    limit: number;
    restriction: Restriction;
    expiryType: ExpiryType;
    expiryDate: Date;
    traderId: string;
    traderName: string;
    counterparty: string;
    timestamp: number;
    businessUnitId: string;
    portfolioId: string;
    inactiveReason: string;
    routed: boolean;
    ownOrder: boolean;
    action: Action;
    company: string;
    revisionNo: number;
    readOnly: boolean;
    suspended: boolean;
}

export interface ModifyOrderRequest {
    correlationId: string;
    orderId: number;
    quantity: number;
    limit: number;
    restriction: Restriction;
    revisionNo: number;
    seenOrderToBeModified: any;
}

export interface EnterOrderRequest {
    correlationId: string;
    contractId: string;
    quantity: number;
    limit: number;
    restriction: Restriction;
    buy: boolean;
}

export interface CancelOrderRequest {
    correlationId: string;
    orderId: number;
}

export interface InquireOrdersRequest {

}

export interface InquireOrdersResponse {
}

export enum State {
    OPEN = 'OPEN',
    FULLY_EXECUTED = 'FULLY_EXECUTED',
    INACTIVE = 'INACTIVE',
    CANCELED = 'CANCELED',
    SUSPENDED = 'SUSPENDED',
    IMPLIED = 'IMPLIED'
}

export enum Restriction {

    NONE = 'NONE',
    IOC = 'IOC',
    FOK = 'FOK',
    AON = 'AON',
    INT = 'INT',
    AON_INT = 'AON_INT'
}

export enum ExpiryType {

    GOOD_FOR_DAY,
    GOOD_TILL_DATE,
    GOOD_TILL_CANCELED
}

export enum Action {

    INSERT,
    UPDATE,
    DELETE,
    EXECUTE,
    EXECUTE_ROUTING,
    REMOVE_ROUTING,
    RESERVE,
    CONTRACT_STATE_CHANGE,
    CONTRACT_AUTO_GENERATION
}

export interface BlockOrderBookId {

    productId: string;
    day: Day;
    startTime: string;
    endTime: string;
}

export interface Day {

    day: number;
    month: number;
    year: number;
    code: number;
}