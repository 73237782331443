import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { IFavorite } from '../models/favorite';

interface FavoriteFormProps {
  entities: IFavorite[];
  onSubmit: (selectedEntity: IFavorite) => void;
}

interface FavoriteFormState {
  inputValue: string;
  selectedEntity?: IFavorite;
}

export default class FavoriteForm extends React.Component<
  FavoriteFormProps,
  FavoriteFormState
> {
  constructor(props: FavoriteFormProps) {
    super(props);

    this.state = {
      inputValue: ''
    };

    this.handleEntityClick = this.handleEntityClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEntityClick(event: any, selectedEntity: IFavorite) {
    event.preventDefault();
    this.setState({
      ...this.state,
      selectedEntity: selectedEntity
    });
  }

  handleChange(event: any) {
    event.preventDefault();
    const target = event.target;
    let value = target.value;
    this.setState({
      ...this.state,
      inputValue: value
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    const { inputValue, selectedEntity } = this.state;
    if (selectedEntity) {
      const namedEntity = { ...selectedEntity, name: inputValue };
      this.props.onSubmit(namedEntity);
      this.setState({
        inputValue: ''
      });
    }
  }

  handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  render() {
    const { inputValue, selectedEntity } = this.state;
    const valid = inputValue.length > 0 && !!selectedEntity;
    return (
      <form>
        <div className="form-group row">
          <div className="col">
            <input
              className="form-control"
              name="price"
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              type="text"
              value={inputValue}
              required={true}
              autoFocus={true}
            />
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-dark ml-auto"
            onClick={this.handleSubmit}
            disabled={!valid}
          >
            <Translate value="favorite.form.save" />
          </button>
        </div>
      </form>
    );
  }
}
