import * as React from 'react';
import orderBookstore from '../../../../../orderbook/store/orderbooks';
import store from '../../../../../main/store/store';
import { ColumnEntry } from './TableColumns';
import { ITableColumn } from '../../../models/table';
import { getOrderbookContracts } from '../../../../../orderbook/selectors/contracts';
import {
  Contract,
  ContractState
} from '../../../../../orderbook/models/contracts';
import { isAnonymizeMarketDataEnabled } from '../../../../../requests/selectors/requests';
import { Restriction, State } from '../../../../../orders/models/orders';
import { connect } from 'react-redux';
import { hashFn } from '../../../../../shared/utils/selectors/agrSelector';
import { IMarket } from '../../../models/market';
import { getColumns } from '../helper/helper';
import { formatCell } from '../uiOrderbookFormatters';
import { Cell } from '../../../models/market';

const getColorOpacity = (color: string): number => {
  try {
    const a = color.substring(5, color.length - 1).split(',');
    return Number(a[a.length - 1]);
  } catch (e) {
    return 0;
  }
};

const createColumnClass = (
  column: ITableColumn,
  hasValue: boolean | number,
  contract?: Contract | undefined,
): string => {
  const { group, name, insideInfo } = column;

  let result = '';
  result +=
    group === 'trades'
      ? 'trade'
      : group === 'askPrices'
      ? 'order ask ' + name
      : 'order bid ' + name;
  result += !!contract ? (' ' + contract.expiry.type) : '';
  result += hasValue || hasValue === 0 ? ' with-value' : '';

  if (insideInfo) {
    result += insideInfo ? ' inside-info' : '';
  }

  return result;
};
/**
 * Sets font color to a cell
 * Does not combine colors of different types but set the color according to hierarchy
 * @param column - column object
 * @param anonymizeMarketDataEnabled - configuration flag
 * @param colors - font colors from config.ts
 * @param isQuoteRequest - cell displayes value from quote request
 * @param isOwnOrder - cell displayes value from own order
 * @param isReadOnly - cell displayes value from readonly order
 * @param isSuspended - cell displayes value from suspended order
 * @param isPreview - fictional order displayed in order preview
 * @param isImplied - implied price
 * @return style object with color attribute
 */
const createColumnStyle = (
  column: ITableColumn,
  anonymizeMarketDataEnabled: boolean,
  colors: { [key: string]: string },
  isQuoteRequest: boolean,
  isOwnOrder?: boolean,
  isReadOnly?: boolean,
  isSuspended?: boolean,
  isPreview?: boolean,
  isImplied?: boolean,
  isRouted?: boolean,
  counterparty?: string
): { color: string } => {
  
  let result = {color: colors['order']};
  if (isOwnOrder && getColorOpacity(colors['orderOwn']) >= 0.5) {
    result.color = colors['orderOwn'];
  }

  if (isReadOnly  && !anonymizeMarketDataEnabled && getColorOpacity(colors['orderReadonly']) >= 0.5) {
    result.color = colors['orderReadonly'];
  }

  if (isSuspended && getColorOpacity(colors['orderSuspended']) >= 0.5) {
    result.color = colors['orderSuspended'];
  }

  if (isQuoteRequest && getColorOpacity(colors['quoteRequest']) >= 0.5) {
    result.color = colors['quoteRequest'];
  }

  if (isPreview && getColorOpacity(colors['orderPreview']) >= 0.5) {
    result.color = colors['orderPreview'];
  }

  if (isImplied && getColorOpacity(colors['implied']) >= 0.5) {
    result.color = colors['implied'];
  }

  if (isRouted && getColorOpacity(colors['routed']) >= 0.5) {
    result.color = colors['routed'];
  }

  if (counterparty && colors['counterparty-' + counterparty] && getColorOpacity(colors['counterparty-' + counterparty]) > 0.5) {
    result.color = colors['counterparty-' + counterparty];
  }

  return result;
};
/**
 * Creates colored background for a cell
 * Result is an array of div elements with different bg colors
 * These divs are layered and lower opacities of bg colors make colors visually combine
 * @param column - column object
 * @param anonymizeMarketDataEnabled - configuration flag 
 * @param isQuoteRequest - cell displayes value from quote request
 * @param isOwnOrder - cell displayes value from own order
 * @param isReadOnly - cell displayes value from read only order
 * @param isSuspended - cell displayes value from suspended order
 * @param isAon - cell displayes value from  aon order
 * @param isPreview - fictional order displayed in orderbook preview 
 * @return array of div elements with assigned css classes
 */

const createColumnLayers = (
  column: ITableColumn,
  anonymizeMarketDataEnabled: boolean,
  isQuoteRequest: boolean,
  isOwnOrder?: boolean,
  isReadOnly?: boolean,
  isSuspended?: boolean,
  isAon?: boolean,
  isPreview?: boolean,
  isImplied?: boolean,
  isRouted?: boolean,
  periodType?: string,
  counterparty?: string
): (cellValuePresent: boolean) => JSX.Element[] => {
  const { name } = column;
  let resultIfCellHasValue: JSX.Element[] = [];
  let resultAlways: JSX.Element[] = [];

  if (isOwnOrder !== undefined && !isQuoteRequest) {
    // preview order is always own order therefore its applied with own order style
    resultIfCellHasValue.push(
      <div
        key="order"
        className={`layer ${isOwnOrder ? `own-order` : `non-own-order`}${isPreview ? ` preview-order` : ``}`}
      />
    );
  }

  if (isReadOnly !== undefined && isReadOnly && !anonymizeMarketDataEnabled) {
    resultIfCellHasValue.push(<div key="readonly" className="layer readonly" />);
  }

  if (!!isSuspended) {
    resultIfCellHasValue.push(<div key="suspended" className="layer suspended" />);
  }

  if (!!isAon && name === 'quantity') {
    resultIfCellHasValue.push(<div key="aon-order" className="layer aon-order" />);
  }

  if (!!isQuoteRequest) {
    resultIfCellHasValue.push(<div key="quoteRequest" className="layer quote-request" />);
  }

  if (!!isImplied) {
    resultIfCellHasValue.push(<div key="implied" className="layer implied" />);
  }

  if (!!isRouted) {
    resultIfCellHasValue.push(<div key="routed" className="layer routed" />);
  }

  if (!!periodType) {
    resultAlways.push(<div key="periodType" className={`layer periodType-${periodType.toLowerCase()}`} />);
  }

  if (!!counterparty) {
    resultAlways.push(<div key="counterparty" className={`layer counterparty-${counterparty}`} style={{backgroundColor: 'var(--counterparty-' + counterparty + '-background)'}} />);
  }

  return (cellValuePresent: boolean) => {
    if (cellValuePresent) {
      return [...resultIfCellHasValue, ...resultAlways];
    } else {
      return resultAlways;
    }
  };
};

const generateKey = (...args: any[]) => {
  return args.join('-');
};

export const EmptyRowsComponent = ({
  count,
  latestIndex,
  className,
  onContextMenu,
  onClickAction,
  layers,
  compactColumnsEnabled
}: {
  count: number;
  latestIndex: number;
  className?: string;
  onContextMenu: any;
  onClickAction: any;
  layers: ((cellValuePresent: boolean) => JSX.Element[]) | null;
  compactColumnsEnabled: boolean;
}) => {
  if (count < 1) {
    return null;
  }
  let rows = [];
  for (let i = 1; i <= count; i++) {
    rows.push(
      <tr key={'empty-row-instrument' + i}>
        <td
          className={`${className ? className : ''}${compactColumnsEnabled ? ' compact' : ''}`}
          onContextMenu={e => onContextMenu(e, latestIndex - 1 + i)}
          onClick={e => onClickAction(e, latestIndex + i, true)}
        > <label className="entry"></label>
          {layers === null ? [] : layers(false)}
        </td>

      </tr>
    );
  }
  return <React.Fragment>{rows}</React.Fragment>;
};

function areEqualEmptyRows (prevProps: any, nextProps: any) {
  return prevProps.count === nextProps.count;
}

const EmptyRows = React.memo(EmptyRowsComponent, areEqualEmptyRows);

export interface AdditionalExpiryProps {
  entries: any[];
  column: any;
  depth: number;
  onContextMenu: any;
  onClickAction: any;
  contractId: string;
  localizeOptions: any;
  anonymizeMarketDataEnabled: boolean;
  colors: any;
  compactColumnsEnabled: boolean;
  market: IMarket;
}
export interface AdditionalExpiryState {}

export class AdditionalExpiryRowsComponent extends React.Component<AdditionalExpiryProps, AdditionalExpiryState> {
  constructor(props: AdditionalExpiryProps) {
    super(props);

    this.state = {
    };
  }

  shouldComponentUpdate(nextProps: AdditionalExpiryProps) {
    if (nextProps.depth !== this.props.depth || hashFn(nextProps.entries) !== hashFn(this.props.entries)) {
      return true;
    }
    if (hashFn(nextProps.colors) !== hashFn(this.props.colors)) {
      return true;
    }
    return false;
  }

  render():  JSX.Element {
    const contract = getOrderbookContracts(orderBookstore.getState())[
      this.props.contractId
    ];

    const generalLayers = createColumnLayers(
      this.props.column,
      this.props.anonymizeMarketDataEnabled,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      contract ? contract.expiry.type : undefined,
      undefined
    );

    const rows = this.props.entries.map((entry: any, index: number) => {
      const entryValue = entry[this.props.column.name];
      // Don't use tradeId, use $index. Two trades may have same tradeId if one is buy and other sell.
      const id = this.props.column.group !== 'trades' ? entry.id : entry.$index;
      const ownOrder =
        this.props.column.group !== 'trades' ? entry.order.ownOrder : undefined;
      const readOnly =
        this.props.column.group !== 'trades' ? entry.order.readOnly : undefined;
      const suspended =
        this.props.column.group !== 'trades' ? entry.order.suspended : undefined;
      const isAON =
        this.props.column.group !== 'trades'
          ? entry.order.restriction === Restriction.AON
          : undefined;
      const isPreview = !!entry.order && entry.order.orderId === 0;
      const isQuoteRequest = entry.quoteRequest;
      const isImplied = !!entry.order && entry.order.state === State.IMPLIED;
      const isRouted = !!entry.order?.routed;
      const counterparty = entry.order?.counterparty;
      const columnClass = createColumnClass(
        this.props.column,
        entryValue,
        contract
      );
      const columnStyle = createColumnStyle(
        this.props.column,
        this.props.anonymizeMarketDataEnabled,
        this.props.colors,
        isQuoteRequest,
        ownOrder,
        readOnly,
        suspended,
        isPreview,
        isImplied,
        isRouted,
        counterparty?.toLowerCase()
      );
      const columnLayers = createColumnLayers(
        this.props.column,
        this.props.anonymizeMarketDataEnabled,
        isQuoteRequest,
        ownOrder,
        readOnly,
        suspended,
        isAON,
        isPreview,
        isImplied,
        isRouted,
        contract ? contract.expiry.type : undefined,
        counterparty?.toLowerCase()
      );
      
      const columnMap = getColumns(this.props.market, this.props.compactColumnsEnabled);
      const combinedColumns = this.props.column.combine ? columnMap[this.props.column.group] : undefined;

      let defaultCell: Cell = {
        className:  `entry`,
        onContextMenu: (e: any) => this.props.onContextMenu(e, index),
        onClick: (e: any) => {},
        style: { marginBottom: 0 },
        value: '',
        localizeOptions: {
          // this is used as the default. Within formatCell these options may be exchanged for a type specific ones.
          style: 'decimal'
        },
        symbol: ''
      };
      const contextData = {
        contract: contract,
        index: index,
        entries: this.props.entries,
        source: entry.order,
        localizeOptions: this.props.localizeOptions,
        anonymizeMarketDataEnabled: this.props.anonymizeMarketDataEnabled
      };

      const cells = [];
      const columns = combinedColumns ? combinedColumns : [this.props.column];
      columns.forEach(column => {
        const cell = formatCell({...defaultCell, value: entry[column.name]}, { ...contextData, column: column });
        cell.localizeOptions = Object.assign(cell.localizeOptions, this.props.localizeOptions);

        cells.push(cell);
      });

      
      return (
        <React.Fragment key={`additional-${this.props.column.name}-${this.props.contractId}-${index}`}>
          <tr>
            <td className={`${columnClass}${this.props.compactColumnsEnabled ? ' compact' : ''}`} style={columnStyle}>
              <ColumnEntry
                onContextMenu={(e: any) => this.props.onContextMenu(e, index)}
                onClick={(e: any) => this.props.onClickAction(e, index)}
                value={entry[this.props.column.name]}
                layers={columnLayers}
                combinedColumns={combinedColumns}
                cells={cells}
              />
            </td>
          </tr>
          {index === this.props.entries.length - 1 && this.props.depth > this.props.entries.length ? (
            <React.Fragment key={id + '-empty'}>
              <EmptyRows
                count={this.props.depth - this.props.entries.length}
                latestIndex={index}
                className={createColumnClass(
                  this.props.column,
                  false
                )}
                onContextMenu={this.props.onContextMenu}
                onClickAction={this.props.onClickAction}
                layers={columnLayers}
                compactColumnsEnabled={this.props.compactColumnsEnabled}
              />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    }).filter((entry: any, index: number, array: any[]) => index < this.props.depth);

    return (
      <table className="inner">
        <tbody>
          {rows.length ? (
            rows
          ) : (
            <EmptyRows
              latestIndex={0}
              count={this.props.depth}
              className={createColumnClass(
                this.props.column,
                false
              )}
              onContextMenu={this.props.onContextMenu}
              onClickAction={this.props.onClickAction}
              layers={generalLayers}
              compactColumnsEnabled={this.props.compactColumnsEnabled}
            />
          )}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state: AdditionalExpiryState) => ({
});

const mapDispatchToProps = {
};

export const AdditionalExpiryRows = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalExpiryRowsComponent);

interface ExpiryRowProps {
  instrumentId: string;
  market: IMarket;
  columns: ITableColumn[];
  askPrices: any;
  bidPrices: any;
  trades: any[]; 
  period: any;
  row: any;
  contractId: string;
  onContextMenu: any;
  onClickAction: any;
  expiriesExpanded: any;
  localizeOptions: any;
  colors: any;
  presetDepths: any;
  hasData: boolean;
  compactColumnsEnabled: boolean;
  contract: Contract;
  dockSize: {width: number; height: number, top: number; left: number};
}

interface ExpiryRowState {

}

class ExpiryRowComponent extends React.Component<
  ExpiryRowProps, ExpiryRowState
> {
  ref: any;
  constructor(props: ExpiryRowProps) {
    super(props);

    this.ref = React.createRef();
  }
  render() {
    const {
      instrumentId,
      market,
      askPrices,
      bidPrices,
      trades,
      period,
      row,
      contractId,
      onContextMenu,
      onClickAction,
      expiriesExpanded,
      localizeOptions,
      colors,
      presetDepths,
      compactColumnsEnabled,
      contract
    } = this.props;
       const anonymizeMarketDataEnabled: boolean = isAnonymizeMarketDataEnabled(
      store.getState()
    );
    const isExpanded = expiriesExpanded && row.depth > 1;

    let defaultCell: Cell = {
      className:  `entry`,
      onContextMenu: onContextMenu,
      onClick: (e: any) => {},
      style: { marginBottom: 0 },
      value: '',
      localizeOptions: {
        // this is used as the default. Within formatCell these options may be exchanged for a type specific ones.
        style: 'decimal'
      },
      symbol: ''
    };

    const contextData = {
      contract: contract,
      source: undefined,
      localizeOptions: localizeOptions,
      anonymizeMarketDataEnabled: anonymizeMarketDataEnabled,
      entries: [],
      index: 0
    };

    const columnMap = getColumns(market, compactColumnsEnabled);

    const columnEntries = Object.keys(columnMap).map((colTitle: any) => {
      const col = columnMap[colTitle][0];
      let entry: any = null;
      let additionalEntries = [];
      let columnClass: string = '';
      let columnStyle = { };
      let columnLayers: ((cellValuePresent: boolean) => JSX.Element[]) | null = null;
      
      let isQuoteRequest = false;
      const orders: any = {askPrices: askPrices ? askPrices : [], bidPrices: bidPrices ? bidPrices : []};
      const combinedColumns = col.combine ? columnMap[col.group] : undefined;
      if (col.group === 'askPrices' || col.group === 'bidPrices') {
        const expiryRowCode = orders[col.group];
        if (expiryRowCode && expiryRowCode.length) {
          const price = expiryRowCode[0];
          const { ownOrder, readOnly, suspended, restriction } = price.order;
          const isPreview = !!price.order && price.order.orderId === 0;
          const isImplied = !!price.order && price.order.state === State.IMPLIED;
          const isRouted = !!price.order?.routed;

          isQuoteRequest = !!price.quoteRequest;
          entry = expiryRowCode[0];
          additionalEntries = expiryRowCode;
          columnClass = createColumnClass(
            col,
            anonymizeMarketDataEnabled,
          );
          columnLayers = createColumnLayers(
            col,
            anonymizeMarketDataEnabled,
            isQuoteRequest,
            ownOrder,
            readOnly,
            suspended,
            undefined,
            isPreview,
            isImplied,
            isRouted,
            contract ? contract.expiry.type : undefined,
            price.order?.counterparty?.toLowerCase()
          );
          columnStyle = createColumnStyle(
            col,
            anonymizeMarketDataEnabled,
            colors,
            isQuoteRequest,
            ownOrder,
            readOnly,
            undefined,
            isPreview,
            isImplied,
            isRouted,
            price.order?.counterparty?.toLowerCase()
          );
        } else {
          columnClass = createColumnClass(col, false, contract);
          columnLayers = createColumnLayers(
            col,
            anonymizeMarketDataEnabled,
            false,
            false,
            false,
            false,
            undefined,
            false,
            false,
            false,
            contract ? contract.expiry.type : undefined,
            undefined
          );
        }
      } else if (col.group === 'trades') {
        const expiryRowCode = trades ? trades : [];
        const hasValue = expiryRowCode && expiryRowCode.length;
        if (expiryRowCode && expiryRowCode.length) {
          entry = {'execPrice': expiryRowCode[0]};
          additionalEntries = expiryRowCode.map((v: number) => { return {'execPrice': v}; });
        }

        columnClass = createColumnClass(
          col,
          hasValue,
          contract
        );

        columnLayers = createColumnLayers(
          col, 
          anonymizeMarketDataEnabled, 
          false, 
          false,
          false,
          false,
          undefined,
          false,
          false,
          false,
          contract ? contract.expiry.type : undefined,
          undefined);
      }
      const cells = [];
      const columns = combinedColumns ? combinedColumns : [col];
      columns.forEach(column => {
        const cell = formatCell(
          {...defaultCell, 
            value: entry ? entry[column.name] : null}, 
          { ...contextData, 
            column: column, 
            entries: additionalEntries 
          }
        );
        cell.localizeOptions = Object.assign(cell.localizeOptions, localizeOptions);
        cells.push(cell);
      });
      

      const contextMenuFn = (e: any, depthIndex: number) => {
        e.preventDefault();
        return onContextMenu(
          e,
          {
            column: col,
            row: row,
            contractId: contractId,
            periodType: period,
            index: depthIndex,
            contract: contract
          },
          !!entry
        );
      };

      const clickActionFn = (e: any, depthIndex: number, empty?: boolean) => {
        e.preventDefault();
        return onClickAction(
          e,
          {
            column: col,
            row: row,
            contractId: contractId,
            periodType: period,
            index: depthIndex,
            contract: contract
          },
          !!entry && !empty
        );
      };

      const key = generateKey(
        instrumentId,
        period,
        row.code,
        col.group,
        col.name
      );

      const presetDepth = presetDepths[row.code];
      let depth = expiriesExpanded ? row.depth : 0;
      let presentedEntries = [...additionalEntries];
      if (presetDepth) {
        if (depth > presetDepth) {
          depth = presetDepth;
          presentedEntries.splice(depth);
        }
      }
      if (isExpanded) {
        return (
            <td
              key={key}
              className={`parentTd expanded ${
                !!contractId ? '' : 'not-interactive'
              }${compactColumnsEnabled ? ' compact' : ''}`}
              style={columnStyle}
              onContextMenu={
                !entry || isQuoteRequest ? e => (!isExpanded ? contextMenuFn(e, 0) : null) : e => null
              }
            >
              <div className="expanded-row-wrapper">
                <AdditionalExpiryRows
                  key={`additional-${contractId}-${col.name}`}
                  entries={additionalEntries}
                  depth={depth}
                  column={col}
                  market={market}
                  onContextMenu={(e: any, i: number) => contextMenuFn(e, i)}
                  onClickAction={(e: any, i: number, empty?: boolean) => clickActionFn(e, i, empty)}
                  contractId={contractId}
                  localizeOptions={localizeOptions}
                  anonymizeMarketDataEnabled={anonymizeMarketDataEnabled}
                  colors={colors}
                  compactColumnsEnabled={this.props.compactColumnsEnabled}
                />
              </div>
            </td>
        );
      } else {
        return (
          <td
            key={key}
            className={`parentTd ${columnClass} ${
              !!contractId ? '' : 'not-interactive'
            }${compactColumnsEnabled && col.combine ? ' compact' : ''}`}
            style={columnStyle}
            onContextMenu={
              !entry || isQuoteRequest ? e => contextMenuFn(e, 0) : e => null
            }
          >
            <ColumnEntry
              onContextMenu={
                !!entry ? (e: any) => contextMenuFn(e, 0) : (e: any) => null
              }
              onClick={
                (e: any) => clickActionFn(e, 0)}
              value={entry ? entry[col.name] : null}
              layers={columnLayers}
              combinedColumns={combinedColumns}
              cells={cells}
            /> 
          </td>
        );
      }
  });

    return (
      <tr
        ref={this.ref}
        className={`
          orderbook-expiry-row${!contract || contract.state === ContractState.INACTIVE ? ' inactive' : ''}
          ${isExpanded ? ' expanded-row' : ''}
        `}
      >
        {columnEntries}
      </tr>
    );
  }

  shouldComponentUpdate(nextProps: ExpiryRowProps, nextState: ExpiryRowState) {
    return (
      this.props?.columns?.length !== nextProps?.columns?.length 
      || !areEqualColors(this.props, nextProps) 
      || nextProps.compactColumnsEnabled !== this.props.compactColumnsEnabled
      || this.isVisible(nextProps)
      ) 
      
      && !areEqual(this.props, nextProps);
  }
  
  isVisible(props: ExpiryRowProps) {
    return props.dockSize === undefined || this.ref.current?.offsetTop > props.dockSize.top && this.ref.current?.offsetTop < props.dockSize.top + props.dockSize.height;
  }
}

const isFirstOrderUnchanged = (older: any, newer: any) => {
  if (!!older !== !!newer) {
    return false;
  }
  return (!older && !newer) || (!older.length && !newer.length) || (older.length === newer.length && older[0].price === newer[0].price && older[0].quantity === newer[0].quantity);
};

const isFirstTradeUnchanged = (older: any, newer: any) => {
  if (!!older !== !!newer) {
    return false;
  }
  return  (!older && !newer) || (!older.length && !newer.length) || (older.length === newer.length && older[0] === newer[0]);
};

const isBidGroupVisible = (columns: any[]) => {
  const res = [];
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    if (columns[colIndex].group === 'bidPrices') {
      res.push(columns[colIndex]);
    }
  }
  return res;
};

const isAskGroupVisible = (columns: any[]) => {
  const res = [];
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    if (columns[colIndex].group === 'askPrices') {
      res.push(columns[colIndex]);
    }
  }
  return res;
};

const isTradeGroupVisible = (columns: any[]) => {
  const res = [];
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    if (columns[colIndex].group === 'trades') {
      res.push(columns[colIndex]);
    }
  }
  return res;
};

const areEqualColors = (prevProps, nextProps) => {
  if (!prevProps?.colors || !nextProps?.colors) {
    return false;
  } else {
    return prevProps.colors.colorsVersion === nextProps.colors.colorsVersion
  }
}

function areEqual(prevProps: any, nextProps: any) {
  if (!areEqualColors(prevProps, nextProps)) {
    return false;
  }
  // do not update when collapsed expandable row data did not change
  if ((!nextProps.expiriesExpanded && !prevProps.expiriesExpanded)  // it's not update because of collapsing/expanding
    && nextProps.columns.length === prevProps.columns.length // columns did not change
    && nextProps.compactColumnsEnabled === prevProps.compactColumnsEnabled 
    && nextProps.dockSize?.width === prevProps.dockSize?.width && nextProps.dockSize?.top === prevProps.dockSize?.top 
    && nextProps.dockSize?.left === prevProps.dockSize?.left && nextProps.dockSize?.height === prevProps.dockSize?.height
  ) {
    if (
      (!isAskGroupVisible(nextProps.columns) 
        || isFirstOrderUnchanged(
          prevProps.askPrices,  
          nextProps.askPrices)
      )
      && (!isBidGroupVisible(nextProps.columns) 
        ||  isFirstOrderUnchanged(
          prevProps.bidPrices,  
          nextProps.bidPrices)
        )
      && (!isTradeGroupVisible(nextProps.columns) 
        ||  isFirstTradeUnchanged(
          prevProps.trades, 
          nextProps.trades)
        )
    ) {
      return true;
    }
  }
  return false;
}

export const ExpiryRow = ExpiryRowComponent;