import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import * as Rx from 'rxjs';
import { v1 } from 'uuid';

import { ActionTypes as Tabs } from '../actions/tab';
import { ActionTypes as Markets } from '../actions/market';
import { ActionTypes as Charts } from '../actions/chart';
import { ActionTypes as Contract } from '../../../orderbook/actions/contracts';
import { State } from '../../../main/reducers/rootReducer';
import {
  getTabsCountInDocks,
  getTableIdByParentId,
  getMarketsCountInDocks,
  getChartsCountInDocks,
  getMarketsForDock,
  getContractDepths
} from '../selectors/ui';
import { remove as dockRemove } from '../../dock/actions/dock';
import { ITab } from '../models/tab';
import { create as tableCreate, remove as tableRemove } from '../actions/table';
import { ComponentType } from '../models/component';
import { TradeTable } from '../../../trade/models/tradesTable';
import { OwnTradeTable } from '../../../trade/models/ownTradesTable';
import { OrderTable } from '../../../orders/models/orderTable';
import { RequestTable } from '../../../requests/models/requestTable';
import { LogsTable } from '../../logger/models/logger';
import { filter, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { IMarket } from '../models/market';
import { orderbooksLoad, ActionTypes as OrderbookActionTypes } from '../../../orderbook/actions/orderbooks';
import { Chart } from '../models/chart';
import orderBookStore from '../../../orderbook/store/orderbooks';
import { getOrderbookContracts } from '../../../orderbook/selectors/contracts';
import { TradeReportingTable } from '../../../trade/models/tradeReportingTable';
import OrderbookService from '../../../orderbook/services/orderbooks';
import { getSubscribedDocksToInstruments } from '../../../orderbook/selectors/orderbooks';
import { PriceAlarmTable } from '../../../priceAlarm/models/priceAlarmTable';
const util = require('util');
const orderbookService = new OrderbookService();

// TABS
export const tabRemoveDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.REMOVE || action.type === Tabs.MOVE),
    map(actions => actions.payload),
    switchMap(data => {
      const { dockId } = data;
      const tabsInDocks: { [id: string]: any } = getTabsCountInDocks(
        state.value
      );
      if (!tabsInDocks[dockId]) {
        return Rx.of(dockRemove(dockId));
      }
      return Rx.EMPTY;
    })
  );
};

// Markets
export const marketRemoveDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Markets.REMOVE || action.type === Markets.MOVE
    ),
    map(actions => actions.payload),
    mergeMap(data => {
      const { dockId } = data;
      const marketsInDocks: { [id: string]: any } = getMarketsCountInDocks(
        state.value
      );
      if (!marketsInDocks[dockId]) {
        return [dockRemove(dockId),  orderBookStore.dispatch(dockRemove(dockId))];
      } else {
        const markets = getMarketsForDock(state.value, dockId);
        const itemIds = markets.map(market => market.itemId);
        return [orderbooksLoad(itemIds, dockId, markets ? markets[0].type : ComponentType.Instrument, v1())];
      }
    })
  );
};

// Charts
export const chartRemoveDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Charts.REMOVE || action.type === Charts.MOVE
    ),
    map(actions => actions.payload),
    mergeMap(data => {
      const { dockId } = data;
      const chartsInDocks: { [id: string]: any } = getChartsCountInDocks(
        state.value
      );
      if (!chartsInDocks[dockId]) {
        return [dockRemove(dockId), orderBookStore.dispatch(dockRemove(dockId))];
      } 

      return Rx.EMPTY;
    })
  );
};

export const tabCreate: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.CREATE),
    map(actions => actions.payload),
    switchMap((tab: ITab) => {
      const { id, type, dockId } = tab;
      const customId =
        dockId === 'recentActions' ? 'recent-' + type : undefined;

      switch (type) {
        case ComponentType.Trade:
          return Rx.of(tableCreate([new TradeTable(id, customId)]));
        case ComponentType.Order:
          return Rx.of(tableCreate([new OrderTable(id, customId)]));
        case ComponentType.Owntrade:
          return Rx.of(tableCreate([new OwnTradeTable(id, customId)]));
        case ComponentType.Request:
          return Rx.of(tableCreate([new RequestTable(id, customId)]));
        case ComponentType.Log:
          return Rx.of(tableCreate([new LogsTable(id, customId)]));
        case ComponentType.TradeReport:
          return Rx.of(tableCreate([new TradeReportingTable(id, customId)]));
        case ComponentType.PriceAlarm:
          return Rx.of(tableCreate([new PriceAlarmTable(id, customId)]));
        default:
          return Rx.EMPTY;
      }
    })
  );
};

// TABLES
export const tabRemoveTable: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.REMOVE),
    map(actions => actions.payload),
    switchMap(data => {
      const { id } = data;
      const tableId: string | null = getTableIdByParentId(state.value, id);
      if (tableId) {
        return Rx.of(tableRemove(tableId));
      }
      return Rx.EMPTY;
    })
  );
};

// Markets & Orderbooks

const marketCreate: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Markets.CREATE || action.type === Charts.CREATE
    ),
    map(actions => actions.payload),
    switchMap((data: IMarket | Chart) => {
      if (data.type !== ComponentType.MarketChart) {
        const markets = getMarketsForDock(state.value, data.dockId);
        let itemIds = [data.itemId];
        if (markets.length) {
          itemIds = markets.map(market => market.itemId).concat(itemIds)
          .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }
        return Rx.of(orderbooksLoad(itemIds, data.dockId, data.type, v1()));
      } else {
        return Rx.EMPTY;
      }
    })
  );
};

const marketChartCreate: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Charts.CREATE
    ),
    map(actions => actions.payload),
    switchMap((data: Chart) => {
      if (data.contractId) {
        const contract = getOrderbookContracts(orderBookStore.getState())[data.contractId];
        if (contract) {
          const instrumentId = contract.instrumentId;
          return Rx.of(orderbooksLoad([instrumentId], data.dockId, ComponentType.MarketChart, v1()));
        }
      } else {
        let id: any = data.itemId;
        
        const markets = getMarketsForDock(state.value, data.dockId);
        let instrumentIds = [id];
        if (markets.length) {
          
          instrumentIds = markets.map(market => {
            let marketInstrumentId: any = market.itemId;
            return marketInstrumentId; 
          })
          .concat(instrumentIds)
          .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }
        return Rx.of(orderbooksLoad(instrumentIds, data.dockId, ComponentType.MarketChart, v1()));
      }
      return Rx.EMPTY;
    })
  );
};

const marketLoad: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.LOAD),
    map(actions => actions.payload),
    mergeMap((data: IMarket[]) => {
      const dockToItemIds = data.reduce((acc: any, market: IMarket) => {
        const itemIdProperty = (!market.itemId && [ComponentType.InstrumentIntraday, ComponentType.Instrument].indexOf(market.type) > -1) ? 'instrumentId' : 'itemId';

        return {
          ...acc,
          [market.dockId]:
            market.dockId in acc
              ? [...acc[market.dockId], market[itemIdProperty]]
              : [market[itemIdProperty]]
        };
      }, {});
      const dockToComponentType = data.reduce((acc: any, market: IMarket) => {
        return {
          ...acc,
          [market.dockId]: market.type
        };
      }, {});
      return Rx.concat(
        Object.keys(dockToItemIds).map(key => {
          return orderbooksLoad(dockToItemIds[key], key, dockToComponentType[key], v1());
        })
      );
    })
  );
};

const marketChartLoad: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Charts.LOAD),
    map(actions => actions.payload),
    mergeMap((data: Chart[]) => {
      const dockToInstruments = data.filter(c => !!c).reduce((acc: any, chart: Chart) => {
        if (chart.contractId) {
          const contract = getOrderbookContracts(orderBookStore.getState())[chart.contractId];
          const instrumentId = contract ? contract.instrumentId : chart.instrumentId;
          return {
            ...acc,
            [chart.dockId]:
              chart.dockId in acc
                ? [...acc[chart.dockId], instrumentId]
                : [instrumentId]
          };
        } else {
          let instrumentId: any = chart.itemId;
          // legacy issue needs to be check because of charts saved in older profiles
          if (util.isObject(instrumentId)) {
            instrumentId = instrumentId.id;
          }
          return {
            ...acc,
            [chart.dockId]:
              chart.dockId in acc
                ? [...acc[chart.dockId], instrumentId]
                : [instrumentId]
          };
        } 
      }, {});
      if (Object.keys(dockToInstruments).length) {
        return Rx.concat(
          Object.keys(dockToInstruments).map(key => {
            return orderbooksLoad(dockToInstruments[key], key, ComponentType.MarketChart, v1());
          })
        );
      } else {
        return Rx.EMPTY;
      }
    })
  );
};

const marketMove: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.MOVE),
    map(actions => actions.payload),
    switchMap((data: { dockId: string; ids: string[]; toDockId: string, componentType: ComponentType }) => {
      const movedItemIds = state.value.ui.markets.entities ?
        Object.keys(state.value.ui.markets.entities)
          .filter(k => data.ids.indexOf(k) !== -1)
          .map(k => state.value.ui.markets.entities[k].itemId) || [] 
          : [];
      
      const items = orderBookStore.getState().orderbook.contractMatrixes.entities[data.toDockId];
      const itemIds = items ? Object.keys(items) : [];

      return Rx.of(
        orderbooksLoad(
          movedItemIds.concat(itemIds),
          data.toDockId,
          data.componentType,
          v1()
        )
      );
    })
  );
};

const chartsMove: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Charts.MOVE),
    map(actions => actions.payload),
    switchMap((data: { dockId: string; ids: string[]; toDockId: string }) => {
      const movedInstrumentIds =
        Object.keys(state.value.ui.charts.entities)
          .filter(
            k =>
              data.ids.indexOf(k) !== -1
          )
          .map(
            k => (state.value.ui.charts.entities[k] as Chart).instrumentId
          ) || [];

      if (movedInstrumentIds.length) {
        const dockEntity = orderBookStore.getState().orderbook?.contractMatrixes?.entities[
          data.toDockId
        ];
        if (dockEntity) {
          const instrumentIds = Object.keys(
            orderBookStore.getState().orderbook.contractMatrixes.entities[
              data.toDockId
            ]
          );

          return Rx.of(
            orderbooksLoad(
              movedInstrumentIds.concat(instrumentIds),
              data.toDockId,
              ComponentType.MarketChart,
              v1()
            )
          );
        }
        return Rx.EMPTY;
      } else {
        return Rx.EMPTY;
      }
    })
  );
};

const triggerMarketChange: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.TRIGGER_EXPIRIES 
      || action.type === Markets.TRIGGER_EXPANDED_EXPIRY 
      || action.type === Markets.TRIGGER_EXPIRY_ROWS
      || action.type === Markets.SET_ORDERBOOK_DEPTH
      || action.type === Markets.REMOVE 
      || action.type === Markets.MOVE),
    map(actions => actions.payload),
    switchMap((data: any) => {
        return orderbookService.sendMarketsheetContractDepthRequest(getContractDepths(state.value, data.dockId))
        .pipe(
          switchMap((ack: any) => Rx.EMPTY), 
          catchError((error: Error) => Rx.EMPTY)
        );
    }));
};

const triggerMarketCreated: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === OrderbookActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS ),
    map(actions => actions.payload),
    switchMap((data: any) => {
        return orderbookService.sendMarketsheetContractDepthRequest(getContractDepths(state.value, data.componentId), getSubscribedDocksToInstruments(state.value)[data.componentId], data.componentId)
        .pipe(
          switchMap((ack: any) => Rx.EMPTY), 
          catchError((error: Error) => Rx.EMPTY)
        );
    }));
};

export const uiEpics = combineEpics(
  tabRemoveTable,
  tabRemoveDock,
  tabCreate,
  marketRemoveDock,
  chartRemoveDock,
  marketLoad,
  marketCreate,
  marketChartCreate,
  marketChartLoad,
  marketMove,
  chartsMove,
  triggerMarketChange,
  triggerMarketCreated
);
