import { connect } from 'react-redux';
import { getConnectedStatus } from '../../../authentication/selectors/connection';
import { State } from '../../../main/reducers/rootReducer';
import { getAllInstruments, isLoaded } from '../../../instruments/selectors/instruments';
import { instrumentsLoadSuccess } from '../../../instruments/actions/instruments';
import MarketsheetSearchComponent from '../components/marketsheetSelect';
import { getLocale } from '../../../shared/i18n/selectors/translations';
import { getDashboardMarketInstruments, getDashboardChartInstruments, getDashboardMarketProducts } from '../../ui/selectors/ui';
import { isProfileModificationAllowed } from '../../../authentication/selectors/authetication';
import orderBookStore from '../../../orderbook/store/orderbooks';
import { getOrderbookProductEntities } from '../../../orderbook/selectors/products';

const mapStateToProps = (state: State) => ({
    isConnected: getConnectedStatus(state),
    instruments: getAllInstruments(state),
    products: getOrderbookProductEntities(orderBookStore.getState()),
    loaded: isLoaded(state),
    locale: getLocale(state),
    activeMarketInstruments: getDashboardMarketInstruments(state),
    activeMarketProducts: getDashboardMarketProducts(state),
    activeChartInstruments: getDashboardChartInstruments(state),
    modificationEnabled: isProfileModificationAllowed(state)
});

const mapDispatchToProps = {
    loadInstrumentsSuccess: instrumentsLoadSuccess
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(MarketsheetSearchComponent);