import * as React from 'react';

import { Translate } from 'react-redux-i18n';
import { Position } from '../../../../utils/models/grid';
import { MarketActionType } from '../../../models/market';
import { findRootElement, findRootElementMultipleClasses, pushDockLower } from '../../../../utils/helper/eventUtils';

interface Props {
  position: Position | null;
  isOpen: boolean;
  onAction: (e: any, type: MarketActionType) => void;
  dismiss: () => void;
  requiredActions: { [x: string]: boolean}; // MarketActionType : boolean (true: disabled)
  optinalActions: MarketActionType[];
}
interface State {
  yAdjustment: number;
  xAdjustment: number;
 }

export default class UIMarketContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);

    this.state = {
      yAdjustment: 0,
      xAdjustment: 0
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.dismiss);
    this._adjustContextMenuPosition();
  }

  _adjustContextMenuPosition = () => {
    const element = document.getElementById('market-context-menu');
    if (element) {
      let parent = findRootElementMultipleClasses(element, [
        'tab-content',
        'analytics-panel__wrapper',
        'react-resizable']);
      
      if (parent) {
        const parentHeight = parent.clientHeight || 0;
        const bottomPosition = element.clientHeight + this.props.position.y;
        let parentTop = parent.offsetTop;
        
        if (parent.classList.contains('tab-content')) {
          // recent actions position is different
          const context = findRootElement(parent, 'sidebar__recent-actions');
          if (context) {
            parentTop = 0;
          }
        }
        let diffY = parentHeight - bottomPosition + parentTop;

        if ((!parent.classList.contains('tab-content')) && parent.getBoundingClientRect()['y'] + diffY < 0) {
          // menu should not exceed top edge of the screen for docks and anlytics panel
          diffY = -parent.getBoundingClientRect()['y'];
        }
        const parentWidth = parent.clientWidth || 0;
        const rightPosition = element.clientWidth + this.props.position.x;
        const diffX = parentWidth - rightPosition;
        // if the bottom edge of the menu would be out of parent dimensions, menu is moved up
        if (diffY < 0 || diffX < 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              yAdjustment: diffY < 0 ? diffY : 0,
              xAdjustment: diffX < 0 ? diffX : 0
            };
          });
        }
      }
    }
  }

  dismiss(e: any) {
    e.preventDefault();
    let element = document.getElementById('market-context-menu');
    
    if (element && e.target !== element && !element.contains(e.target)) {
      pushDockLower();
      this.props.dismiss();
      
    }
  }

  handleOnClick(e: any, type: MarketActionType) {
    e.preventDefault();
    this.props.onAction(e, type);
    this.props.dismiss();
  }

  render(): JSX.Element {
    const { position, isOpen, requiredActions, optinalActions} = this.props;

    const style = position ? {
      top: position.y + this.state.yAdjustment + 'px',
      left: position.x + this.state.xAdjustment + 'px',
      display: isOpen ? 'block' : 'none'
    } : {};

    const requiredSelections = Object.keys(requiredActions).map((action: string) => {
      return (
      <li
        style={{ display: 'block' }}
        key={action}
        className={!requiredActions[action] ? 'disabled' : ''}
        onClick={e => !!requiredActions[action] && this.handleOnClick(e, MarketActionType[action as keyof typeof MarketActionType])}
      >
        <Translate value={'market.context.' + action} />
      </li>
      );
    });

    const optinalSelections = optinalActions.map((action: MarketActionType) => {
      return (
        <li
          key={action}
          style={{ display: 'block' }}
          onClick={e => this.handleOnClick(e, action)}
        >
          <Translate value={'market.context.' + action} />
        </li>
      );
    });

    return (
      <div id="market-context-menu" className="market-context-menu" onContextMenu={(e) => e.preventDefault()} style={style}>
        <ul>
          {requiredSelections}
          {optinalSelections}
        </ul>
      </div>
    );
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.dismiss);
  }
}
