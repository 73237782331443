import { createSelector } from 'reselect';
import ITrade from '../../trade/models/trade';
import {
  argsSelectorCreator
} from '../../shared/utils/selectors/agrSelector';
import { LoadedData } from '../models/orderbooks';
import { OrderbooksState } from '../reducers/combinedReducer';
import store from '../../main/store/store';
import { getLastPrices } from '../../trade/selectors/trade';
import { getUIState } from '../../shared/ui/selectors/ui';
import { State } from '../../main/reducers/rootReducer';
import { getChartVersion } from './charts';
import { PeriodType } from '../models/contracts';
import { ComponentType } from '../../shared/ui/models/component';

const getOrderbookState = (state: OrderbooksState) => state.orderbook;

export const getOrderbookInstruments = createSelector(
  [getOrderbookState],
  s => {
    return s.instruments;
  }
);

export const isOrderbookProcessed = createSelector(
  [getOrderbookState],
  s => {
    return s.processing;
  }
);

export const isStaticDataLoaded = createSelector(
  [getOrderbookState],
  state => {
    return state.isLoaded >= LoadedData.ALL;
  }
);

export const isContractDataLoaded = createSelector(
  [getOrderbookState],
  state => {
    return state.isLoaded % LoadedData.CONTRACTS === 0;
  }
);

export const isProductDataLoaded = createSelector(
  [getOrderbookState],
  state => {
    return state.isLoaded % LoadedData.PRODUCTS === 0;
  }
);

export const isInstrumentDataLoaded = createSelector(
  [getOrderbookState],
  state => {
    return state.isLoaded % LoadedData.INSTRUMENTS === 0;
  }
);

export const getOrderbookPrices = createSelector(
  [getOrderbookState],
  s => {
    return s.prices;
  }
);

export const getOrderbookTradesVersion = createSelector(
  [getOrderbookState],
  s => {
    return s.tradesVersion;
  }
);

export const getOrderbookPricesVersion = createSelector(
  [getOrderbookState],
  s => {
    return s.pricesVersion;
  }
);

export const getOrderbookOrderbooks = createSelector(
  [getOrderbookState],
  s => {
    return s.orderbooks;
  }
);

export const getContractMatrixes = createSelector(
  [getOrderbookState],
  s => {
    return s.contractMatrixes.entities;
  }
);

export const getSubscribedInstrumentsWithDocks = createSelector(
  [getUIState],
  s => {
    const marketInfo: any[] = s.markets.ids.map(id => {
      return { 'dockId': s.markets.entities[id].dockId, 'instrumentId': s.markets.entities[id].instrumentId, 'type': s.markets.entities[id].type, 'itemId': s.markets.entities[id].itemId };
    });
    const chartInfo: any[] = s.charts.ids.map(id => {
      return { 'dockId': s.charts.entities[id].dockId, 'instrumentId': s.charts.entities[id].instrumentId, 'type': s.charts.entities[id].type, 'itemId': s.charts.entities[id].itemId };
    });

    return marketInfo.concat(chartInfo);
  }
);

const getDocksToInstruments = createSelector(
  [(docksToInstrument: any[]) => docksToInstrument],
  (docksToInstrument) => {
    // map instrument ids to its dock id
    let dockIdsToInstrumentIds: { [dockId: string]: string[] } = {};
    for (let i = 0; i < docksToInstrument.length; i++) {
      const instrumentIdWithDock = docksToInstrument[i];
      if (!dockIdsToInstrumentIds[instrumentIdWithDock.dockId]) {
        dockIdsToInstrumentIds[instrumentIdWithDock.dockId] = [instrumentIdWithDock.instrumentId];
      } else {
        dockIdsToInstrumentIds[instrumentIdWithDock.dockId] = dockIdsToInstrumentIds[instrumentIdWithDock.dockId].concat(instrumentIdWithDock.instrumentId);
      }
    }
    return dockIdsToInstrumentIds;
  }
);

const getDocksToProducts = createSelector(
  [(docksToProduct: any[]) => docksToProduct],
  (docksToProduct) => {
    // map instrument ids to its dock id
    let dockIdsToProductIds: { [dockId: string]: string[] } = {};
    for (let i = 0; i < docksToProduct.length; i++) {
      const productIdWithDock = docksToProduct[i];
      if (!dockIdsToProductIds[productIdWithDock.dockId]) {
        dockIdsToProductIds[productIdWithDock.dockId] = [productIdWithDock.itemId];
      } else {
        dockIdsToProductIds[productIdWithDock.dockId] = dockIdsToProductIds[productIdWithDock.dockId].concat(productIdWithDock.itemId);
      }
    }
    return dockIdsToProductIds;
  }
);

export const getDocksToChangedInstruments = createSelector(
  [getSubscribedInstrumentsWithDocks, (state: State, changedInstruments: string[]) => changedInstruments],
  (docksToInstrument, changedInstruments) => {
    const instrumentIdsWithDocks = Array.from(docksToInstrument).filter(component => 
      [ComponentType.InstrumentIntraday, ComponentType.Instrument, ComponentType.MarketChart].indexOf(component.type) > -1
    );
    
    const docksToInstruments = getDocksToInstruments(instrumentIdsWithDocks);
    const changedDocks = Object.keys(docksToInstruments).filter(dockId => {
      const instrumentsForDock = docksToInstruments[dockId];
      return instrumentsForDock.some(r => changedInstruments.indexOf(r) >= 0);
    });
  
    let docksWithChangedInstruments = Object.assign({}, ...changedDocks.map(k => ({ [k]: docksToInstruments[k] })));
    return docksWithChangedInstruments;
  }
);

export const getDocksToChangedProducts = createSelector(
  [getSubscribedInstrumentsWithDocks, (state: State, changedProducts: string[]) => changedProducts],
  (docksToInstrument, changedProducts) => {
    const itemIdsIdsWithDocks = Array.from(docksToInstrument).filter(component => component.type === ComponentType.Product);
    const docksToItems = getDocksToProducts(itemIdsIdsWithDocks);
    const changedDocks = Object.keys(docksToItems).filter(dockId => {
      const productsForDock = docksToItems[dockId];
      return productsForDock.some(r => changedProducts.length === 0 || changedProducts.indexOf(r) >= 0);
    });
    
    let docksWithChangedProducts = Object.assign({}, ...changedDocks.map(k => ({ [k]: docksToItems[k] })));
    return docksWithChangedProducts;
  }
);

export const getSubscribedDocksToInstruments = createSelector(
  [getSubscribedInstrumentsWithDocks],
  (instrumentWithDocks) => {
    return getDocksToInstruments(instrumentWithDocks);
  }
);

const findContractMatrixForId = createSelector(
  [getContractMatrixes, (state: OrderbooksState, dockId: string) => dockId],
  (contractMatrixes, dockId) => {
    return contractMatrixes[dockId] || [];
  }
);

export const getContractMatrixesForDock = argsSelectorCreator(
  [findContractMatrixForId, (state: OrderbooksState, dockId: string) => dockId],
  matrixes => matrixes
);

// Charts
const getLatestPeriodStart = (date: Date, period: number) => {
  period = period * 1000 * 60;
  var minutes = Math.floor(date.getMinutes() / period) * period;
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    minutes,
    0
  );
};
const getPeriodStart = (timestamp: number, latest: number, period: number) => {
  period = period * 1000 * 60;
  var remain = latest % period;
  return timestamp - (timestamp % period) + remain;
};

const findPricesForChart = createSelector(
  getOrderbookPrices,
  (
    state: OrderbooksState,
    contractId: string,
    groupType: 'bidPrices' | 'askPrices'
  ) => {
    return { contractId, groupType };
  },

  (prices: any, args) => {
    // const args = JSON.parse(jsonString);
    const result = Object.keys(prices).length
      ? !!prices[args.contractId] && !!prices[args.contractId][args.groupType]
        ? prices[args.contractId][args.groupType]
        : []
      : [];
    const now = new Date();
    const period = 60;
    const data: any = {
      ohlc: [],
      volume: []
    };
    const latestPeriod = getLatestPeriodStart(now, period);

    return result.reduce(
      (acc: { ohlc: any[]; volume: any[] }, entry: any) => {
        const periodStart = getPeriodStart(
          entry.timestamp,
          latestPeriod.getTime(),
          period
        );
        if (!data.ohlc[periodStart]) {
          data.ohlc[periodStart] = [
            periodStart,
            entry.price,
            entry.price,
            entry.price,
            entry.price
          ];
        } else {
          data.ohlc[periodStart][4] = entry.price;
          if (data.ohlc[periodStart][2] < entry.price) {
            data.ohlc[periodStart][2] = entry.price;
          }
          if (data.ohlc[periodStart][3] > entry.price) {
            data.ohlc[periodStart][3] = entry.price;
          }
        }

        if (!data.volume[periodStart]) {
          data.volume[periodStart] = [periodStart, entry.quantity];
        } else {
          data.volume[periodStart][1] += entry.quantity;
        }
        return {
          ...acc,
          ohlc: [...acc.ohlc, data.ohlc[periodStart]].sort(
            (a: any, b: any) => a[0] - b[0]
          ),
          volume: [...acc.volume, data.volume[periodStart]].sort(
            (a: any, b: any) => a[0] - b[0]
          )
        };
      },
      {
        ohlc: [],
        volume: []
      }
    );
  }
);

const findOhlcDataForChart = createSelector(
  [
    getOrderbookTradesVersion,
    getChartVersion,
    (
      state: OrderbooksState,
      dockId: string,
      contractId: string,
      groupTypes: string[],
      chartVersion: number
    ) => ({ state, contractId, dockId, groupTypes, chartVersion })
  ],
  (tradesVersion, chartVersion, args) => {
    if (args.contractId === null || args.contractId === '' || !args.dockId) {
      return {};
    }
    const chartData = args.state.charts.chartData[args.dockId];
    const contractData = chartData && Object.keys(chartData) ? chartData : {};
    const data = args.groupTypes
      ? args.groupTypes.reduce((allData: {}, groupType: string) => {
        if (groupType === 'lastPrices') {
          return {
            ...allData,
            'lastPrices': (contractData[args.contractId] && contractData[args.contractId]['lastPrices']) ? contractData[args.contractId]['lastPrices'] : {}
          };
        } else {
          return {
            ...allData,
            [groupType]: findPricesForChart(args.state, args.contractId, groupType)
          };
        }
      }, {})
      : {};
    return data;
  }
);

export const getOhlcDataForChart = createSelector(
  [findOhlcDataForChart, getChartVersion],
  data => data
);

const findOrderbookPricesForDock = createSelector(
  [findContractMatrixForId, getOrderbookPrices, getOrderbookTradesVersion],
  (instrumentsMatrix, orders) => {
    const lastPrices = getLastPrices(store.getState());
    const instrumentMatrixKeys = Object.keys(instrumentsMatrix);
    const result = {};
    for (let keyIdx = 0; keyIdx < instrumentMatrixKeys.length; keyIdx++) {
      const matrixItem = instrumentsMatrix[instrumentMatrixKeys[keyIdx]];
      
      const expiryToContract = matrixItem.expiryToContract;
      const expiriesToTrades = new Map();
      const prices = new Map();
      const expiries = Object.keys(expiryToContract);
      for (let expIdx = 0; expIdx < expiries.length; expIdx++) {
        const key = expiries[expIdx];
        const trades = lastPrices[expiryToContract[key]];
        if (trades && trades.length) {
          expiriesToTrades[key] = trades;
        }
        if (orders[expiryToContract[key]]) {
          prices[key] = orders[expiryToContract[key]];
        }
      }
      
      result[matrixItem.itemId] = {
        trades: expiriesToTrades,
        orders: prices
      }
    }
    return result;
  }
);
const pricesStatic: {
  [key: string]: {
    orders: { [key: string]: { bidPrices: any[]; askPrices: any[] } };
    trades: { [key: string]: { [key: string]: any } };
  }
} = {};
export const getOrderbookPricesForDock = createSelector(
  [
    findOrderbookPricesForDock,
    (state: OrderbooksState, dockId: string) => dockId
  ],
  prices => prices
);

const findDepthForExpiries = createSelector(
  [findOrderbookPricesForDock],
  (prices: {
    [key: string]: {
      orders: { [key: string]: { bidPrices: any[]; askPrices: any[], realDepth: number; } };
      trades: { [key: string]: { [key: string]: any } };
    };
  }) => {
    const result: { [key: string]: number } = {};
    const pricesKeys = Object.keys(prices);
    for (let pricesKeyIndex = 0; pricesKeyIndex < pricesKeys.length; pricesKeyIndex++) {
      const orders = prices[pricesKeys[pricesKeyIndex]].orders;

      const orderKeys = Object.keys(orders);
      for (let orderKeyIndex = 0; orderKeyIndex < orderKeys.length; orderKeyIndex++) {
        const order = orders[orderKeys[orderKeyIndex]];
        const depth = order.realDepth; // Math.max(order.bidPrices.length, order.askPrices.length);

        if (depth > (result[orderKeys[orderKeyIndex]] || -1)) {
          result[orderKeys[orderKeyIndex]] = depth;
        }
      }

      const trades = prices[pricesKeys[pricesKeyIndex]].trades;
      const tradeKeys = Object.keys(trades);
      for (let tradeKeyIndex = 0; tradeKeyIndex < tradeKeys.length; tradeKeyIndex++) {
        const trade = trades[tradeKeys[tradeKeyIndex]];
        // one extra price in array is for indicator calculation, this shall not be displayed
        const depth = trade.length === 0 ? 0 : trade.length - 1; 

        if (depth > (result[tradeKeys[tradeKeyIndex]] || -1)) {
          result[tradeKeys[tradeKeyIndex]] = depth;
        }
      }
    }
    return { ...pricesStatic, ...result };
  }
);

/**
 *
 * INSTRUMENTS EXPIRIES
 *
 */

const getBiggestPeriodType = (values: any[]) => {
  const valueTypes = values.map(v => v.type);
  const sortedPeriodTypes = Object.keys(PeriodType);
  for (let idx = sortedPeriodTypes.length - 1; idx >= 0; idx--) {
    if (valueTypes.indexOf(sortedPeriodTypes[idx]) > -1) {
      return sortedPeriodTypes[idx];
    }
  }
  // shouldn't happen
  return null;
};

const getNextExpiry = (values: any[]) => {
  // this is comparing date iso strings starting with a year, they are always less then 9999
  let minDate = '9999'; // initial comparing value
  const valuesByDate = new Map();
  for (let i = 0; i < values.length; i++) {
    const periodStart = values[i].periodStart;
    if (!!valuesByDate[periodStart]) {
      valuesByDate[periodStart].push(values[i]);
    } else {
      valuesByDate[periodStart] = [values[i]];
    }
    if (periodStart < minDate) {
      minDate = periodStart;
    }
  }
  
  const possibleMinValues = valuesByDate[minDate];
  const biggestPeriodType = getBiggestPeriodType(possibleMinValues);

  for (let i = 0; i < possibleMinValues.length; i++) {
    if (possibleMinValues[i].type === biggestPeriodType) {
      return possibleMinValues[i];
    }
  }
  return undefined;
};

const createUniqueExpiriesForIntraday = (expiries: any[], expiryKey: any, expiriesDepth: any) => {
  
  let uniqueExpiries: any[] = [];
  let stillRemainingExpiries = true;
  const uniqueExpiriesCodes = {};
  while (stillRemainingExpiries) {
    const firstValues = expiries.map(e => e[0]);
    const filtered = firstValues.filter(v => v !== undefined);
    if (filtered.length === 0) {
      stillRemainingExpiries = false;
      break;
    }
    const nextExpiry = getNextExpiry(filtered);
    
    for (let i = 0; i < firstValues.length; i++) {
      if (firstValues[i] && firstValues[i] === nextExpiry) {
        // add expiry if not already added
        if (!uniqueExpiriesCodes[firstValues[i].code]) {
          uniqueExpiries = [
            ...uniqueExpiries,
            {
              name: nextExpiry.name,
              code: nextExpiry.code,
              depth: expiriesDepth[nextExpiry.code] || 0,
              periodType: nextExpiry.type
            }
          ];
          uniqueExpiriesCodes[nextExpiry.code] = true;
        }
        // remove already added expiry 
        if (expiries[i]) {
          expiries[i].shift();
        }
      }
    }
  }
  return uniqueExpiries;
};

const findIntradayExpiries = (expiryKeyToInstrumentIndex: any, instruments: any, expiriesDepth: any) => {
  const result: { [key: string]: any } = {};
  const sizes: number[] = Object.keys(expiryKeyToInstrumentIndex).map(k => expiryKeyToInstrumentIndex[k].length);
  for (const expiryKey of Object.keys(expiryKeyToInstrumentIndex)) {
    let expiryKeyToExpiries: { [key: string]: any[] } = {};
    for (let instrumentIndex = 0; instrumentIndex < Math.max(...sizes); instrumentIndex++) {
      const expiries = instruments[instrumentIndex].expiries[expiryKey] ? [...instruments[instrumentIndex].expiries[expiryKey]] : [];
      if (expiryKeyToExpiries[expiryKey] === undefined) {
        expiryKeyToExpiries[expiryKey] = [expiries];
      } else {
        expiryKeyToExpiries[expiryKey] = [...expiryKeyToExpiries[expiryKey], expiries];
      }
    }
    result[expiryKey] = createUniqueExpiriesForIntraday(expiryKeyToExpiries[expiryKey], expiryKey, expiriesDepth);
  }
  return result;
};

const findTermExpiries = (expiryKeyToInstrumentIndex: any, instruments: any, expiriesDepth: any) => {
  const result: { [key: string]: any } = {};
  for (const expiryKey of Object.keys(expiryKeyToInstrumentIndex)) {
    const expiryCodeToExpiry: { [key: string]: any } = {};

    for (const instrumentIndex of expiryKeyToInstrumentIndex[expiryKey]) {
      const expiries = instruments[instrumentIndex].expiries[expiryKey];
      for (let i = 0; i < expiries.length; i++) {
        const expiry = expiries[i];
        expiryCodeToExpiry[expiry.code] = expiry;
      }
    }

    let uniqueExpiries: any[] = [];
    const keys = Object.keys(expiryCodeToExpiry);
    for (const expiryCode of keys) {
      const expiry = expiryCodeToExpiry[expiryCode];
      uniqueExpiries = [
        ...uniqueExpiries,
        {
          name: expiry.name,
          code: expiryCode,
          depth: expiriesDepth[expiryCode] || 0,
          periodType: expiry.type
        }
      ];
    }
    result[expiryKey] = uniqueExpiries;
  }
  return result;
};

const findExpiries = createSelector(
  [findContractMatrixForId, findDepthForExpiries],
  (contractMatrix, expiriesDepth: { [key: string]: number }) => {
    const instruments: { expiries: { [key: string]: any[] } }[] = Object.keys(
      contractMatrix
    ).map(key => contractMatrix[key]);

    const expiryKeyToInstrumentIndex: { [key: string]: number[] } = {};
    for (
      let instrumentIndex = 0;
      instrumentIndex < instruments.length;
      instrumentIndex++
    ) {
      const exp = instruments[instrumentIndex].expiries;
      for (const expiryKey of Object.keys(
        exp
      )) {
        if (expiryKeyToInstrumentIndex[expiryKey] === undefined) {
          expiryKeyToInstrumentIndex[expiryKey] = [instrumentIndex];
        } else {
          let instrumentIndexes = expiryKeyToInstrumentIndex[expiryKey];
          expiryKeyToInstrumentIndex[expiryKey] = [...instrumentIndexes, instrumentIndex];
        }
      }
    }
    const isIntraday = contractMatrix && contractMatrix[Object.keys(contractMatrix)[0]] && contractMatrix[Object.keys(contractMatrix)[0]].intraday;
    if (isIntraday) {
      return findIntradayExpiries(expiryKeyToInstrumentIndex, instruments, expiriesDepth);
    } else {
      return findTermExpiries(expiryKeyToInstrumentIndex, instruments, expiriesDepth);
    }
  }
);

export const getExpiries = argsSelectorCreator(
  [findExpiries, (state: OrderbooksState, dockId: string) => dockId],
  expiries => expiries
);

/**
 *
 * Chart Expiries
 *
 */

const findPeriodTypesForCharts = createSelector(
  [findContractMatrixForId],
  contractMatrix => {
    const instruments = Object.keys(contractMatrix).map(
      key => contractMatrix[key]
    );

    return instruments.reduce(
      (acc: { [key: string]: string[] }, matrixItem: any) => {
        let periodTypes: string[] = [];
        Object.keys(matrixItem.expiries).forEach(key => {
          if (matrixItem.expiries.hasOwnProperty(key)) {
            periodTypes.push(!Number(key)
              ? key
              : matrixItem.expiries[key].reduce(
                (expAcc: string[], expiry: any) => {
                  return periodTypes.indexOf(expiry.type) === -1
                    ? [...expAcc, expiry.type]
                    : expAcc;
                },
                []
              )
            );
          }
        });
        return {
          ...acc,
          [matrixItem.itemId]: periodTypes
        };
      },
      {}
    );
  }
);

export const gedPeriodTypesForCharts = argsSelectorCreator(
  [
    findPeriodTypesForCharts,
    (state: OrderbooksState, dockId: string) => dockId
  ],
  expiries => expiries
);
