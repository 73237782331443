import OrderFormData, { OrderFormMode } from '../models/formData';
import { Validation } from '../../shared/validation/model';
import { EnterOrderRequest, ModifyOrderRequest, CancelOrderRequest } from '../models/orders';
import { Contract } from '../../orderbook/models/contracts';
import { SanityCheck } from '../models/sanityChecks';

export enum ActionTypes {
    ORDER_FORM_SUBMIT = '[Orders] submit form',
    ORDER_FORM_SHOW = '[Orders] show order form',
    ORDER_FORM_HIDE = '[Orders] hide order form',
    ORDER_FORM_CLEAR = '[Orders] clear order form',
    ORDER_FORM_SUBSCRIBE_ORDERBOOKS = '[Orders] Subscribe orderbooks',
    ORDER_FORM_UNSUBSCRIBE_ORDERBOOKS = '[Orders] Unsubscribe orderbooks',
    UPDATE_PREVIEW_WITH_ORDER = '[Orders] Update orderbook preview with order',
    ORDERBOOK_PREVIEW_LOAD_SUCCESS = '[Orders] Orderbook preview load success',
    ORDER_FORM_SANITY_CHECK = '[Orders] Set sanity check'
  }
  
export interface OrderFormSubmitAction {
    type: ActionTypes.ORDER_FORM_SUBMIT;
    payload: OrderFormData;
    correlationId: string;
    validations: Validation;
}
  
export interface ShowOrderFormAction {
    type: ActionTypes.ORDER_FORM_SHOW;
    payload: OrderFormData;
}

export interface HideOrderFormAction {
    payload: OrderFormData;
    type: ActionTypes.ORDER_FORM_HIDE;
}

export interface OrderFormClearAction {
    type: ActionTypes.ORDER_FORM_CLEAR;
}

export interface OrderFormSubscribeAction {
    type: ActionTypes.ORDER_FORM_SUBSCRIBE_ORDERBOOKS;
    payload: string;
}

export interface OrderFormUnsubscribeAction {
    type: ActionTypes.ORDER_FORM_UNSUBSCRIBE_ORDERBOOKS;
    payload: string;
}

export interface UpdateOrderbookPreviewAction {
    type: ActionTypes.UPDATE_PREVIEW_WITH_ORDER;
    payload: {request: EnterOrderRequest | ModifyOrderRequest | CancelOrderRequest, mode: OrderFormMode};
}

export interface OrderbookPreviewLoadSuccess {
    type: ActionTypes.ORDERBOOK_PREVIEW_LOAD_SUCCESS;
    payload: any;
}

export interface SanityCheckLoad {
    type: ActionTypes.ORDER_FORM_SANITY_CHECK;
    payload: SanityCheck[];
}

export function clearOrderForm(payload: OrderFormData, correlationId: string): OrderFormClearAction {
    return {
        type: ActionTypes.ORDER_FORM_CLEAR
    };
}

export function submitOrderForm(payload: OrderFormData, correlationId: string, validations: Validation): OrderFormSubmitAction {
    return {
        type: ActionTypes.ORDER_FORM_SUBMIT,
        payload: payload,
        correlationId: correlationId,
        validations: validations
    };
}

export function openOrderForm(formData: OrderFormData): ShowOrderFormAction {
    return {
        type: ActionTypes.ORDER_FORM_SHOW,
        payload: formData
    };
}

export function closeOrderForm(formData?: OrderFormData): HideOrderFormAction {
    return {
        payload: formData,
        type: ActionTypes.ORDER_FORM_HIDE
    };
}

export function subscribeOrderbooks(contractId: string): OrderFormSubscribeAction {
    return {
        type: ActionTypes.ORDER_FORM_SUBSCRIBE_ORDERBOOKS,
        payload: contractId
    };
}

export function unsubscribeOrderbooks(contractId: string): OrderFormUnsubscribeAction {
    return {
        type: ActionTypes.ORDER_FORM_UNSUBSCRIBE_ORDERBOOKS,
        payload: contractId
    };
}

export function updateOrderbookPreview(request: EnterOrderRequest | ModifyOrderRequest | CancelOrderRequest, mode: OrderFormMode): UpdateOrderbookPreviewAction {
    return {
        type: ActionTypes.UPDATE_PREVIEW_WITH_ORDER,
        payload: {request, mode}
    };
}

export function orderbookPreviewLoadSuccess(orderbook: any, contract: Contract): OrderbookPreviewLoadSuccess {
    return {
        type: ActionTypes.ORDERBOOK_PREVIEW_LOAD_SUCCESS,
        payload: {orderbook, contract}
    };
}

export function orderFormSanityCheck(sanityChecks: SanityCheck[]): SanityCheckLoad {
    return {
        type: ActionTypes.ORDER_FORM_SANITY_CHECK,
        payload: sanityChecks
    };
}

export type Action = OrderFormSubmitAction
  | ShowOrderFormAction
  | HideOrderFormAction
  | OrderFormClearAction
  | OrderFormSubscribeAction
  | OrderFormUnsubscribeAction
  | UpdateOrderbookPreviewAction
  | OrderbookPreviewLoadSuccess
  | SanityCheckLoad
  ;
    