import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import * as Rx from 'rxjs';
import RequestsService from '../services/requests';
import { ActionTypes as Contracts } from '../../orderbook/actions/contracts';
import {
  ActionTypes,
  requestsLoadSuccess,
  requestsLoad,
  requestsLoadFailure,
  quoteRequestEnterComplete,
  quoteRequestsRemove
} from '../actions/requests';
import { ActionTypes as Authentication } from '../../authentication/actions/authentication';
import { ActionTypes as Connection } from '../../authentication/actions/connection';
import { GenericRequest } from '../../main/models/application';
import { isQuoteRequestEnabled } from '../selectors/requests';
import { State } from '../../main/reducers/rootReducer';
import { filter, map, mergeMap, takeUntil, switchMap, catchError, first } from 'rxjs/operators';
import { EnterQuoteRequestRequest, QuoteRequestFormData, QuoteRequestProfile, 
  QuoteRequestMode, AnswerQuoteRequestRequest, DeleteQuoteRequestRequest, IRequest, QuoteRequestState } from '../models/request';
import { createEnterQuoteRequest, createAnswerQuoteRequest } from '../helper/helper';
import { getUser } from '../../authentication/selectors/authetication';
import { createNotification } from '../../shared/notifications/actions/notifications';
import { INotification, NotificationType } from '../../shared/notifications/models/notification';
import { v1 } from 'uuid';
import { I18n } from 'react-redux-i18n';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import * as Settings from '../../main/actions/settings';

const requestsService = new RequestsService();

export const connection: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Settings.ActionTypes.LOAD_CONFIGURATION_SUCCESS),
    map(actions => actions.payload),
    first(),
    mergeMap((data: any) => {
      if (isQuoteRequestEnabled(state.value)) {
        return Rx.of(requestsLoad()).pipe(
          takeUntil(
            actions$.pipe(
              filter(action => action.type === Authentication.AUTHENTICATION_LOGOUT)
            )
          )
        );
      } else {
        return Rx.empty();
      }
    }
    )
  );
};

export const sendQuoteRequestSubscritpion: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Settings.ActionTypes.LOAD_CONFIGURATION_SUCCESS || action.type === Authentication.RELOGIN_SUCCESS
    ),    
    first(),
    switchMap(() => {
      if (isQuoteRequestEnabled(state.value)) {
        requestsService.sendRequestsInquiry(<GenericRequest> {
          requestType: 'SUBSCRIBE_QUOTE_REQUESTS_REQUEST'
        });
      
        return requestsService
          .inquireRequests().pipe(
            map((content: any) => {
              return requestsLoadSuccess(content.quoteRequests);
            }),
            catchError(error => {
              return Rx.of(requestsLoadFailure(error));
            })
        );
      } else {
        return Rx.empty();
      }
    
    }),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const subscribeRequests: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD || action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    switchMap(() => {
      if (!isQuoteRequestEnabled(state.value)) {
        return Rx.empty();
      }
      return requestsService
        .subscribeRequests().pipe(
          mergeMap((content: any) => {
              let qrs = content.quoteRequests;
              const canceled = qrs.filter((qr: IRequest) => qr.state === QuoteRequestState.DELETED );
              const created = qrs.filter((qr: IRequest) => qr.state !== QuoteRequestState.DELETED );
              return Rx.of(requestsLoadSuccess(created), quoteRequestsRemove(canceled));
          }),
          catchError(error => {
            return Rx.of(requestsLoadFailure(error));
          })
        );
    })
  );
};

export const notifyOnRequest: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD_SUCCESS),
    map(action => action.payload),
    switchMap((quoteRequests: IRequest[]) => {
      const user = getUser(state.value);
      if (!user) {
        return Rx.empty();
      }
      const userId = user.currentUserId;
      return Rx.Observable.create((observer: Rx.Observer<any>) => {
        for (let i = 0; i < quoteRequests.length; i++) {
          if (quoteRequests[i].requesterId === userId && quoteRequests[i].state === 'ANSWERED') {
            observer.next(quoteRequests[i]);
          }
        }
      }).pipe(
        switchMap((quoteRequest: IRequest) => {
          const notification: INotification = {
            id: v1(),
            type: NotificationType.INFO,
            message: I18n.t('quoteRequest.log.answered', {contract: quoteRequest.contractId, quote: quoteRequest.quote}),
            confirmationRequired: true
          };
          return Rx.of(createNotification(notification));
        })
      );
    })
  );
};

export const quoteRequestAccept: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.QUOTE_REQUEST_ACCEPT),
    switchMap((action) => {
      const userId = getUser(state.value).currentUserId;
      const correlationId: string = action.correlationId;
      return requestsService
          .sendAcceptQuoteRequest(action.payload).pipe(
            map((content: any) => {
              return quoteRequestEnterComplete(content, correlationId, {});
            }),
            catchError(error => {
              return Rx.of(error);
            }
          )
        );
    })
  );
};

export const quoteRequestDecline: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.QUOTE_REQUEST_DECLINE),
    switchMap((action) => {
      const userId = getUser(state.value).currentUserId;
      const correlationId: string = action.correlationId;
      return requestsService
          .sendDeclineQuoteRequest(action.payload).pipe(
            map((content: any) => {
              return quoteRequestEnterComplete(content, correlationId, {});
            }),
            catchError(error => {
              return Rx.of(error);
            }
          )
        );
    })
  );
};

export const enterRequest: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.QUOTE_REQUEST_FORM_SUBMIT),
    switchMap((action: any) => {
      const data: QuoteRequestFormData = action.payload;
      const correlationId: string = action.correlationId;

      if (data.mode === QuoteRequestMode.ENTER) {
        const request: EnterQuoteRequestRequest = createEnterQuoteRequest(data, correlationId);

        return requestsService
          .sendEnterQuoteRequest(request).pipe(
            map((content: any) => {
              return quoteRequestEnterComplete(content, correlationId, request);
            }),
            catchError(error => {
              return Rx.of(error);
            }
          )
        );

      } else if (data.mode === QuoteRequestMode.ANSWER) {
          const request: AnswerQuoteRequestRequest = createAnswerQuoteRequest(data, correlationId);
          return requestsService
          .answerQuoteRequest(request).pipe(
            map((content: any) => {
              return quoteRequestEnterComplete(content, correlationId, request);
            }),
            catchError(error => {
              return Rx.of(error);
            }
          )
        );
      } else if (data.mode === QuoteRequestMode.CANCEL) {
        const request: DeleteQuoteRequestRequest = {id: data.quoteRequestId, correlationId: correlationId};

        return requestsService
          .sendCancelQuoteRequest(request).pipe(
            map((content: any) => {
              return quoteRequestEnterComplete(content, correlationId, request);
            }),
            catchError(error => {
              return Rx.of(error);
            }
          )
        );
      }

      return Rx.empty();
    })
  );
};

export const requestsEpic = combineEpics(
  connection,  
  subscribeRequests, 
  enterRequest, 
  notifyOnRequest,
  quoteRequestDecline,
  quoteRequestAccept,
  sendQuoteRequestSubscritpion
);
