import * as React from 'react';
import { Collider, DropBox } from '../../shared/utils/helper/collider';
import { Size, Position } from '../../shared/utils/models/grid';

interface Props {
  id: string;
  position: Position;
  size: Size;
  visible: boolean;
}
interface State {
  box: DropBox;
}

export class CancelDragArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      box: {
        id: '',
        size: { width: 0, height: 0 },
        position: { x: -9999, y: 0 }
      }
    };
  }

  componentDidMount() {
    const { id, position, size, visible } = this.props;
    const newPosition = { ...position, x: visible ? position.x : -9999 };

    this.setState({
      ...this.state,
      box: Collider.createDropBoxArgs(id, newPosition, size)
    });
  }

  componentWillReceiveProps(newProps: Props) {
    const { id, position, size, visible } = newProps;
    const newPosition = { ...position, x: visible ? position.x : -9999 };
    this.setState({
      ...this.state,
      box: Collider.createDropBoxArgs(id, newPosition, size)
    });
  }

  render() {
    const { box } = this.state;
    const styles = {
      width: box.size.width + 'px',
      height: box.size.height + 'px',
      top: box.position.y + 'px',
      left: box.position.x + 'px'
    };

    return (
      <div id="cancel-drag-area" style={styles}>
        <i className="oi oi-trash failed" />
      </div>
    );
  }
}
