import * as React from 'react';
import withAutoClose from '../../../autoclose/components/Autoclose';

interface ActionIconProps {
  iconName: string;
  title: string;
  component: JSX.Element;
  expanded: boolean;
}

interface ActionIconState {
  isMouseOver: boolean;
}

class ActionIcon extends React.Component<ActionIconProps, ActionIconState> {
  private ref: any;
  private positioningClass: string;
  constructor(props: ActionIconProps) {
    super(props);

    this.ref = React.createRef();
    this.state = {
      isMouseOver: false
    };
  }

  render() {
    if (this.ref && this.ref.current && this.ref.current.getBoundingClientRect()) {
      if (this.ref.current.getBoundingClientRect().left < 0) {
        this.positioningClass='left-border';
      }
    }

    return (
      <div>
        <li
          onMouseEnter={() =>
            this.setState({
              ...this.state,
              isMouseOver: true
            })
          }
          onMouseLeave={() =>
            this.setState({
              ...this.state,
              isMouseOver: false
            })
          }
        >
          <i
            data-test={`${this.props.iconName}-icon`}
            className={`oi ${this.props.iconName} ${this.state.isMouseOver ? `active` : ``}`}
            title={this.props.title}
          />
        </li>
        <div style={{display: this.props.expanded ? 'block' : 'none', position: 'fixed'}} ref={this.ref} className={`icon-popover ${this.positioningClass}`}>{this.props.component}</div>
      </div>
    );
  }
}

export default withAutoClose(ActionIcon);
