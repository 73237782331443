import { ITableSort } from '../../ui/models/table';
import { firstBy } from 'thenby';

const proccessSort = (array: any[], sorting: ITableSort[], cmpFunctions?: {[columnName: string]: (o1: any, o2: any) => number}): any => {
  let result: any = [];
  
  if (sorting.length === 1) {
    return firstBy(sorting[0].columnName, {direction: sorting[0].direction, cmp: cmpFunctions ? cmpFunctions[sorting[0].columnName] : undefined});
  } else {
    sorting.forEach((s, i) => {
      if (i === 0) {
        result = firstBy(s.columnName, {direction: s.direction, cmp: cmpFunctions ? cmpFunctions[s.columnName] : undefined});
      } else {
        result = result.thenBy(s.columnName, {direction: s.direction, cmp: cmpFunctions ? cmpFunctions[s.columnName] : undefined});
      }
    });

    return result;
  }
};

export const sortTable = (tableData: any[], sorting: ITableSort[], hiddenColumnNames: string[], cmpFunctions?: {[columnName: string]: (o1: any, o2: any) => number}) => {
  if (sorting.length > 0) {
    const visibleSortingColumns: ITableSort[] = sorting.filter(s => hiddenColumnNames.indexOf(s.columnName) === -1);
    return tableData.sort(proccessSort(tableData, visibleSortingColumns.length ? visibleSortingColumns : sorting, cmpFunctions));
  } else {
    return tableData;
  }
};
