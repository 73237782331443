import * as React from 'react';
import FavoriteNameForm from '../../../favorite/components/favoriteNameForm';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { IFavorite } from '../../../../shared/favorite/models/favorite';
import withAutoClose from '../../../autoclose/components/Autoclose';

interface FavoriteIconProps {
  favoriteName: string;
  isFavorite: boolean;
  onSubmit: (name: string) => void;
  favorites: IFavorite[];
  expanded: boolean;
  triggerVisibility: (visible: boolean) => void;
}

interface FavoriteIconState {
  isMouseOver: boolean;
}

class FavoriteIcon extends React.PureComponent<
  FavoriteIconProps,
  FavoriteIconState
> {
  constructor(props: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      isMouseOver: false
    };
  }

  handleClose() {
    this.setState({ ...this.state});
    this.props.triggerVisibility(false);
    document.body.click();
  }

  render() {
    const { favoriteName, isFavorite, onSubmit, favorites } = this.props;
    const { isMouseOver } = this.state;
    const popoverComponent = (
      <div style={{display: this.props.expanded ? 'block' : 'none'}} className="favorite__form">
        <h3>
          {!!favoriteName
            ? I18n.t('favorite.form.removeTitle')
            : I18n.t('favorite.form.title')
          }
        </h3>
        <FavoriteNameForm
          onSubmit={(name: string) => {
            onSubmit(name);
            this.handleClose();
          }}
          isFavorite={isFavorite}
          favorites={favorites}
          onCancel={() =>
            this.handleClose()
          }
        />
      </div>
    );

    return (
      <div>
        <li
          onMouseEnter={() =>
            this.setState({
              ...this.state,
              isMouseOver: true
            })
          }
          onMouseLeave={() =>
            this.setState({
              ...this.state,
              isMouseOver: false
            })
          }
        >
          <i
            data-test="oi-star-icon"
            className={`oi oi-star ${isFavorite || isMouseOver ? 'active' : ''}`}
            title={I18n.t(isFavorite ? 'modal.favoriteRemove' : 'modal.favoriteAdd')}
          />
        </li>
        {popoverComponent}
      </div>
    );
  }
}
export default withAutoClose(FavoriteIcon);
