import * as React from 'react';

import { connect } from 'react-redux';
import { State } from '../../../main/reducers/rootReducer';
import { logout } from '../../../authentication/actions/authentication';
import { I18n, Translate } from 'react-redux-i18n';
import SidebarContextMenu from './SidebarContextMenu';
import { config } from '../../../main/config';
import withAutoClose from '../../autoclose/components/Autoclose';
import { saveClient } from '../../settings/actions/settings';
import { isBasketOrderUploadEnabled, isQuoteRequestEnabled } from '../../../requests/selectors/requests';
import RecentActions from '../../../dashboard/components/recentActions';
import MarketsheetSelect from '../../../shared/marketsheetSelect/containers/marketsheetSelect';
import FavoriteSelect from '../../favorite/containers/favoriteSelect';
import ProfileViews from '../../../dashboard/components/profileViews';
import { ITab } from '../../ui/models/tab';
import { ComponentType } from '../../ui/models/component';
import { DashboardComponent, DashboardMeta } from '../../../dashboard/models/dashboard';
import { IFavorite } from '../../favorite/models/favorite';
import { getSidebarIsLocked } from '../../settings/selectors/selector';
import { createNotification } from '../../notifications/actions/notifications';
import { INotification, NotificationType } from '../../notifications/models/notification';
import { v1 } from 'uuid';
import { isAtLeastOneConfigEnabled, isTradeReportingUser, isPasswordExpired, isProfileModificationAllowed } from '../../../authentication/selectors/authetication';
import { MemoTranslate } from '../../i18n/components/memoTranslate';
import QuoteRequestModal from '../../../requests/components/QuoteRequestModal';
import { resize } from '../../../dashboard/actions/dashboard';
import { Grid } from '../../utils/components/grid';
import { getQuadrantGridActive, getQuadrantLayout } from '../../../dashboard/selectors/quadrantPanel';
import PriceAlarmModal from '../../../priceAlarm/components/priceAlarmModal';
import { getSSOExpiration } from '../../../authentication/selectors/connection';
import { toggleQuadrant, toggleQuadrantGrid } from '../../../dashboard/actions/quadrants';
import { GridIcon } from './GridIcons';

interface SidebarState {
  ssoCountdown: number;
}

const sidebarWidth = 337;

interface SidebarProps {
  locked: boolean;
  quoteRequestsEnabled: boolean;
  bulkOrderUploadEnabled: boolean;
  atLeastOneSettingEnabled: boolean;
  passwordExpired: boolean;
  tradeReportingUser: boolean;
  expanded: boolean; // from autoclose
  triggerVisibility: (visible: boolean) => void; // from autoclose
  createNotification: (notification: INotification) => void;
  saveSidebar: (sidebar: any) => void;
  logout: () => void;
  onTabCreate: (tab: ITab) => void;
  onRecentActionsDrag: (
    e: any,
    components: { type: ComponentType; title: string; args?: any[] }[]
  ) => void;
  onSingleDragFromSidemenu: (e: any, component: DashboardComponent) => void;
  onFavoriteSelectDrag: (e: any, favorite: IFavorite) => void;
  dashboardResize: (dashboardMeta: DashboardMeta) => void;
  quadrantGridActive: boolean;
  ssoExpiration: number;
  ssoLatestToken: string;
  toggleGrid: (quadrantIds: number[]) => void;
  toggleQuadrant: (quadrantIds: number[]) => void;
  quadrantLayout: number[];
  modificationEnabled: boolean;
}

export class SidebarComponent extends React.Component<
  SidebarProps,
  SidebarState
  > {
  private countdown;

  constructor(props: SidebarProps) {
    super(props);

    this.state = {
      ssoCountdown: Math.floor((props.ssoExpiration - new Date().getTime()) / 1000)
    }
  }

  gridResize = () => {
    // simulate resize action for quadrant grid to rerender
    if (this.props.quadrantGridActive) {
      const grid = new Grid();
      
      const gridSize = {
        width: grid.fullWidth - (this.props.expanded ? sidebarWidth : 0),
        height: grid.fullHeight
      };

      this.props.dashboardResize({ size: gridSize, cols: Grid.cols });
    }
  }

  handleTriggerVisibility(locked: boolean, expanded: boolean) {
    if (locked) {
      this.props.createNotification({
        id: v1(),
        type: NotificationType.INFO,
        message: I18n.t('sidebar.closeLockedError')
      });
    } else {
      this.props.triggerVisibility(!expanded);

      this.gridResize();
    }
  }

  componentDidMount(): void {
    if (config.ssoEnabled && config.ssoTokenCountdown) {
      this.countdown = setInterval(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            ssoCountdown: Math.floor((this.props.ssoExpiration - new Date().getTime()) / 1000)
          };
        })
      }, 1000);
    }
  }

  componentDidUpdate(prevProps: Readonly<SidebarProps>, prevState: Readonly<SidebarState>, snapshot?: any): void {
    if (prevProps?.expanded !== this.props.expanded) {
      this.gridResize();
    }
  }

  componentWillUnmount(): void {
    if(this.countdown) {
      clearInterval(this.countdown);
    }
  }

  _getFormattedTime = (seconds) => {
    if (seconds < 0) {
      return '00:00';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    
    return (hours === 0 ? '' : ((hours < 10 ? '0' : '') + hours + ':')) + ((minutes < 10 ? '0' : '') + minutes) + ':' + ((sec < 10 ? '0' : '') + sec % 60);
  };

  render() {
    const { quoteRequestsEnabled, locked, expanded, tradeReportingUser } = this.props;
    const logoName = config.branding.logoSidebar;
    const logo = `${config.subfolder}/logo/${logoName}`;

    return (
      <React.Fragment>
        <div className={expanded ? 'sidebar' : 'sidebar hidden'} style={{ width: `${sidebarWidth}px` }}>
          <div
            className={expanded ? 'threebar cross' : 'threebar bar'}
            title={I18n.t(expanded ? 'sidebar.close' : 'sidebar.menu')}
            onClick={() => this.handleTriggerVisibility(locked, expanded)}
          >
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="innerSidebar">
            <div className="sidebar__header d-flex">
              <div className="title">
                <MemoTranslate value="sidebar.menu" tag={'h1'} />
              </div>
              <div className="ml-auto d-flex">
                <div className="align-self-center mr-2">
                  <i
                    className={`oi oi-pin white ${locked ? 'active' : ''}`}
                    title={I18n.t(locked ? 'sidebar.unlock' : 'sidebar.lock')}
                    onClick={() =>
                      this.props.saveSidebar({ sidebar: { locked: !locked }})
                    }
                  />
                </div>
                <div className="align-self-center">
                  <SidebarContextMenu
                    bulkOrderUploadEnabled={this.props.bulkOrderUploadEnabled}
                    atLeastOneSettingEnabled={this.props.atLeastOneSettingEnabled}
                    passwordExpired={this.props.passwordExpired}
                  />
                </div>
              </div>
            </div>
            <div className="sidebar__content">
              <div className="sidebar__topic">
                <RecentActions
                  tabCreate={this.props.onTabCreate}
                  quoteRequestsEnabled={quoteRequestsEnabled}
                  onDragActions={this.props.onRecentActionsDrag}
                  isTradeReportingUser={tradeReportingUser}
                />
              </div>
              <div className="sidebar__topic">
                <MarketsheetSelect
                  onSelectComponent={this.props.onSingleDragFromSidemenu}
                />
              </div>

              <div className="sidebar__topic">
                <FavoriteSelect onSelectFavorite={this.props.onFavoriteSelectDrag} />
              </div>

              <div className="sidebar__topic" hidden={!this.props.modificationEnabled}>
                <ProfileViews />
              </div>

              <div className="sidebar__topic" hidden={!this.props.modificationEnabled || !config.gridLayoutEnabled}>
              <div className="subtitle">
                <MemoTranslate value="sidebar.gridLayout" tag={'h2'} />
              </div>
                <div 
                  className={'grid-icon grid-off' + (this.props.quadrantGridActive ? '' : ' grid-active')} 
                  onClick={e => {
                  if (this.props.quadrantGridActive) { 
                    this.props.toggleGrid(undefined); 
                  }}}><div />
                </div>
                {[[1,2,3,4], [1,2,1,4], [1,1,3,4], [1,2,3,3], [1,2,3,2], [1,1,3,3], [1,2,1,2], [1,1,1,1]].map(layout => (
                  <GridIcon 
                    key={'grid-icon' + layout.reduce((acc, i) => acc+''+i, '')} 
                    layout={layout} 
                    activeLayout={this.props.quadrantGridActive ? this.props.quadrantLayout : []} 
                    onIconClick={() => { 
                      if (!this.props.quadrantGridActive) {
                        this.props.toggleGrid(layout);
                      } else {
                        this.props.toggleQuadrant(layout);
                      }
                    }}
                  ></GridIcon>
                ))}
              </div>
            </div>
            <div className="sidebar__topic logout">
              <div>
                <span hidden={!config.ssoTokenCountdown || !config.ssoEnabled} className="countdown" title={I18n.t("sidebar.ssoCountDown")}>{this._getFormattedTime(this.state.ssoCountdown)}</span>
                <a className="logout-text" onClick={() => this.props.logout()}><Translate value="sidebar.logout" /></a>
                <a
                  className="oi oi-account-logout active"
                  onClick={() => this.props.logout()}
                  title={I18n.t('sidebar.logout')}
                  data-test="logout-button"
                /> 
              </div>
            </div>
            <div className="sidebar__footer">
              <div className="sidebar__topic d-flex justify-content-between align-items-start">
                <div>
                  <img className="logo" src={logo} alt="Logo" />
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
        <QuoteRequestModal sideBarVisible={expanded} />
        <PriceAlarmModal sideBarVisible={expanded} />
      </React.Fragment>
    );
  }
}

const Color = (active: boolean) => {
  return active ? 'active' : 'white';
};

const mapStateToProps = (state: State) => ({
  locked: getSidebarIsLocked(state),
  quoteRequestsEnabled: isQuoteRequestEnabled(state),
  bulkOrderUploadEnabled: isBasketOrderUploadEnabled(state),
  atLeastOneSettingEnabled: isAtLeastOneConfigEnabled(state),
  tradeReportingUser: isTradeReportingUser(state),
  passwordExpired: isPasswordExpired(state),
  quadrantGridActive: getQuadrantGridActive(state),
  ssoExpiration: getSSOExpiration(state),
  quadrantLayout: getQuadrantLayout(state),
  modificationEnabled: isProfileModificationAllowed(state)
});

const mapDispatchToProps = {
  createNotification: createNotification,
  saveSidebar: saveClient,
  logout: logout,
  dashboardResize: resize,
  toggleGrid: toggleQuadrantGrid,
  toggleQuadrant: toggleQuadrant
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withAutoClose(SidebarComponent));
