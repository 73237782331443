import { Restriction } from './orders';

export default interface OrderFormData {
    [key: string]: any;
    price: number;
    quantity: number;
    restriction: Restriction;
    contractId: string;
    mode: OrderFormMode;
    buy: boolean;
    orderId: number;
    revisionNo: number;
    seenOrderToBeModified: any;
    associatedContracts: string[];
}

export enum OrderFormMode {
    ENTER,
    MODIFY,
    MATCH,
    CANCEL
}