import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import * as Rx from 'rxjs';
import * as Settings from '../../../main/actions/settings';
import {
  ActionTypes as DesignSettings,
  load,
  versionInfoLoad
} from '../actions/settings';
import { ActionTypes as Connection } from '../../../authentication/actions/connection';
import { State } from '../../../main/reducers/rootReducer';
import { config } from '../../../main/config';
import { StompService, StompClient } from '../../../main/services/stompService';
import {
  SaveSettingsRequest,
  LoadSettingsResponse
} from '../../../main/models/application';
import { filter, map, switchMap } from 'rxjs/operators';
import * as Authentication from '../../../authentication/actions/authentication';
import { I18n } from 'react-redux-i18n';
import { getSidebarIsLocked, getGeneralSettings, getSidebarSettings } from '../selectors/selector';

const stompService = new StompService(StompClient);

interface ConfigColor {
  background: string; 
  font: string;
}

interface ConfigFont {
  name: string;
  directory: string;
}

const createFontFaceStyles = (font: ConfigFont) => {
  const styles = ['', '-Bold', '-Italic', '-BoldItalic', '-Medium', '-MediumItalic'];
  const fontStyle = document.createElement('style');
  for (let i = 0; i < styles.length; i++) {
    fontStyle.appendChild(document.createTextNode('\
    @font-face {\
      font-family: \'' + font.name + styles[i] + '\';\
      src: ' + 'url(\'' + config.subfolder + '/' + font.directory + '/' + font.name + styles[i] + '.otf\');\
      font-weight: normal;\
      font-style: normal;\
    }'));
  }
  document.head.appendChild(fontStyle);
};

const setStyleProp = (
  prop: string,
  value: ConfigColor | string | ConfigFont,
  type: 'color' | 'string' | 'font'
) => {
  const style = (document.documentElement as HTMLElement).style;
  if (type === 'string') {
    style.setProperty('--' + prop + '-color', <string> value);
  } else if (type === 'color') {
    if ((<ConfigColor> value).background !== 'none') {
      style.setProperty('--' + prop + '-background', (<ConfigColor> value).background);
    }
    if ((<ConfigColor> value).font !== 'none') {
      style.setProperty('--' + prop + '-font', (<ConfigColor> value).font);
    }
  } else if (type === 'font') {
    style.setProperty('--' + prop + '-name', (<ConfigFont> value).name);
    createFontFaceStyles(<ConfigFont> value);
  }
};

const createVariableName = (k: string) => k.replace(/\.?([A-Z])/g, (x, y) => '-' + y.toLowerCase()).replace(/^_/, '');

const applyColors = (
  colors:
    | {
        [key: string]: ConfigColor;
      }
    | { [key: string]: string }
): void => {
  Object.keys(colors)
    .map(k => ({
      key: createVariableName(k),
      value: colors[k]
    }))
    .map(prop => setStyleProp(prop.key, prop.value, typeof prop.value === 'string' ? 'string' : 'color'));
};

const applyFonts = (
  fonts:
      {
        [key: string]: ConfigFont;
      } 
): void => {
  Object.keys(fonts)
    .map(k => ({
      key: createVariableName(k),
      value: fonts[k]
    }))
    .map(prop => setStyleProp(prop.key, prop.value, 'font'));
};

export const brandingInit: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.APPLICATION_START),
    map(action => action.payload),
    switchMap(() => {
      const colors: { [key: string]: { background: string; font: string } } =
        config.branding.colors;
      applyColors(colors);
      const clientColors: { [key: string]: string } =
        config.branding.clientColors;
      applyColors(clientColors);
      const clientFont: { [key: string]: ConfigFont} =
        config.branding.fonts;
      applyFonts(clientFont);
      return Rx.empty();
    })
  );
};

const restGet = function(url: string) {
  return Rx.Observable.create((observer: Rx.Observer<any>) => {
    fetch(url + '?t=' + new Date().getTime(), {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then((response: Response) => {
      if (response.ok) {
        response
          .text()
          .then((data: any) => {
            if (!data) {
              observer.next(I18n.t('info.version_no_data'));
            } else {
              observer.next(data);
            }
          })
          .catch(error => observer.error(error));
      } else {
        observer.next(I18n.t('info.version_no_data'));
      }
    });
  });
};

export const versionInfoInit: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS
    ),
    map(actions => actions.payload),
    switchMap(() => {
      return restGet('./gitInfoFrontend.txt').pipe(
        map((content: any) => {
          return versionInfoLoad(content);
        })
      );
    })
  );
};

export const settingsLoad: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Settings.ActionTypes.LOAD_SETTINGS),
    map(actions => actions.payload),
    switchMap((content: LoadSettingsResponse) => {
      if (
        !!content &&
        !!content.settings &&
        (!!content.settings.colorSettingsJson || !!content.settings.clientSettingsJson)
      ) {
        return Rx.concat(
          content.settings.colorSettingsJson ? Rx.of(load(JSON.parse(content.settings.colorSettingsJson))) : Rx.empty(), 
          content.settings.clientSettingsJson ? Rx.of(load(JSON.parse(content.settings.clientSettingsJson))) : Rx.empty());
      }
      return Rx.empty();
    })
  );
};

export const colorSettingSave: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === DesignSettings.SAVE_COLOR_SETTINGS),
    map(action => action.payload),
    switchMap((settings: any) => {
      stompService.saveSettings(<SaveSettingsRequest> {
        settings: { 
          colorSettingsJson: settings ? JSON.stringify(settings) : null
        }
      });
      return Rx.empty();
    })
  );
};

export const clientSettingSave: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === DesignSettings.SAVE_CLIENT_SETTINGS),
    map(action => action.payload),
    switchMap((settings: any) => {
      if (settings) {
        const clientSettings = {
          general: settings.general ? settings.general : getGeneralSettings(state.value),
          sidebar: settings.sidebar ? settings.sidebar : getSidebarSettings(state.value)
        };
        stompService.saveSettings(<SaveSettingsRequest> {
          settings: { 
            clientSettingsJson: settings ? JSON.stringify(clientSettings) : null
          }
        });
      }
      return Rx.empty();
    })
  );
};

export const settingsReload: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === DesignSettings.SAVE_COLOR_SETTINGS ||
        action.type === DesignSettings.LOAD_SETTINGS
    ),
    map(actions => actions.payload),
    switchMap((payload: any) => {
      if ('colors' in payload) {
        applyColors(payload.colors);
      }

      return Rx.empty();
    })
  );
};

export const settingsEpic = combineEpics(
  brandingInit,
  settingsLoad,
  clientSettingSave,
  colorSettingSave,
  settingsReload,
  versionInfoInit
);
