import * as React from 'react';
import { IQuadrant } from '../../analyticsPanel/models/analyticsPanel';
import { withResizer } from '../../analyticsPanel/components/resizerHOC';
import { connect } from 'react-redux';
import { getDashboardQuadrants, getDashboardQuadrantAxises, getDocksToDashboardQuadrants, getQuadrantLayout, getActiveDashboarQuadrants } from '../selectors/quadrantPanel';
import { State } from '../../main/reducers/rootReducer';
import { IDashboardQuadrant, DashboardQuadrant } from '../models/quadrants';
import { IDock } from '../../shared/dock/models/dock';
import DashboardQuadrantComponent from '../components/DashboardQuadrant';
import { Grid } from '../../shared/utils/components/grid';
import { dashboardQuadrantsResize, toggleQuadrantGrid, toggleQuadrant } from '../actions/quadrants';

interface QuadrantPanelProps {
  quadrantsToDocks: {[id: number]: IDock | undefined};
  quadrants: IDashboardQuadrant[];
  controlledQuadrants: IDashboardQuadrant[]; // from resizers HOC
  isResizing: boolean; // from resizers HOC
  axises: any;
  quadrantLayout: number[];
  activeQuadrants: IQuadrant[];
  isLocked: boolean;
  onDockDrag: () => void;
  onDockDragFinish: () => void; // XXX
  onDockResize: () => void; 
  onDocksConnect: () => void;
  onSingleDragFromSidemenu: () => void;
  onQuadrantsResize: (quadrants: IQuadrant[], axisX: number, axisY: number) => void;
  toggleQuadrantGrid: () => void;
  toggleQuadrant: (quadrantIds: number[]) => void;
  quadrantRefs: React.RefObject<DashboardQuadrantComponent>[];
  docks: any[];
}
interface QuadrantPanelState {
  controlledQuadrants: IDashboardQuadrant[];
  settingsShown: boolean;
}

export class QuadrantsPanelComponent extends React.Component<
  QuadrantPanelProps,
  QuadrantPanelState
> {
  containerWidth: number;
  grid: Grid;
  constructor(props: QuadrantPanelProps) {
    super(props);
    
    this.grid = new Grid();
    this.containerWidth = this.grid.fullWidth;

    for (let i = 0; i < 4; i++) {
      this.props.quadrantRefs[i] = React.createRef();
    }

    this.state = {
      controlledQuadrants: [],
      settingsShown: false
    };
  }

  toggleSettings = () => {
    this.setState((prevState: QuadrantPanelState) => {
      return {...prevState, settingsShown: !prevState.settingsShown};
    });
  }

  render() {
    const { quadrantsToDocks, quadrantLayout } = this.props;
    let quadrantsList: JSX.Element[] = [];
    if (this.props.controlledQuadrants.length === 0) {
      const quadrant: IDashboardQuadrant = new DashboardQuadrant(0, undefined);
      for (let i = 1; i <= 4; i++) {
        quadrantsList.push(<DashboardQuadrantComponent ref={this.props.quadrantRefs[i - 1]} key={`dashboard-quadrant-${i}`} quadrant={{...quadrant, id: i, dock: undefined}} {...this.props} />);
      }
    } else {
      quadrantsList = this.props.controlledQuadrants
      .filter(q => quadrantLayout && quadrantLayout.indexOf(q.id) > -1 )
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
      .map((quadrant) => (
        <DashboardQuadrantComponent 
          ref={this.props.quadrantRefs[quadrant.id - 1]} 
          key={`dashboard-quadrant-${quadrant.id}`} 
          quadrant={{...quadrant, 
            dock: quadrantsToDocks[quadrant.id] ? {...quadrantsToDocks[quadrant.id], 
              disableRendering: this.props.isResizing} : undefined
            }
          } {...this.props} 
        />
      ));
    }
    return (
      <React.Fragment>
        <div
          className="docks__wrapper"
          style={{ width: this.containerWidth + 'px' }}
        > 
          {this.props.docks.filter(d => d.props.data.autoDragging)}
        </div>  
        <div key="quadrantDashboardPanel" className="analytics-panel__wrapper">
          <div className="analytics-panel__content">
            <div className="info-panel">
                <div className="quadrants-preview">
                  {quadrantsList}
                  {this.props.children}
                </div>
              </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State) => ({
  quadrantsToDocks: getDocksToDashboardQuadrants(state),
  quadrants: getDashboardQuadrants(state),
  axises: getDashboardQuadrantAxises(state),
  quadrantLayout: getQuadrantLayout(state),
  activeQuadrants: getActiveDashboarQuadrants(state)
});

const mapDispatchToProps = {
  onQuadrantsResize: dashboardQuadrantsResize,
  toggleQuadrantGrid: toggleQuadrantGrid,
  toggleQuadrant: toggleQuadrant
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withResizer(QuadrantsPanelComponent));