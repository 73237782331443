import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { OhlcPeriod } from '../../../orderbook/models/charts';
import { Contract } from '../../../orderbook/models/contracts';

export enum ActionTypes {
  LOAD = '[UI][Chart] Load',
  CREATE = '[UI][Chart] Create',
  MOVE = '[UI][Chart] Move',
  REMOVE = '[UI][Chart] Remove',
  SET_PERIOD_TYPE = '[UI][Chart] Set period type',
  SET_CONTRACT = '[UI][Chart] Set contract',
  SET_CONTRACT_ID = '[UI][Chart] Set contract ID',
  SET_GROUP_TYPES = '[UI][Chart] Set group types',
  SUBSCRIBE_CHART = '[UI][Chart] Subscribe chart',
  UNSUBSCRIBE_CHART = '[UI][Chart] Unsubscribe chart',
  SET_VIEWS = '[UI][Chart] Load views',
  SET_CURRENT_THEME = '[UI][Chart] Set current theme',
  SET_THEMES = '[UI][Chart] Load themes',
  SET_LAYOUT = '[UI][Chart] Load layout',
  SET_DRAWINGS = '[UI][Chart] Set drawings',
  RESTORE_DRAWINGS = '[UI][Chart] Load drawings'
}

export interface ChartLoadAction {
  type: ActionTypes.LOAD;
  payload: any;
}

export interface ChartCreateAction extends LogAction {
  type: ActionTypes.CREATE;
  payload: any;
}

export interface ChartMoveAction extends LogAction {
  type: ActionTypes.MOVE;
  payload: { ids: string[]; dockId: string; toDockId: string };
}

export interface ChartRemoveAction extends LogAction {
  type: ActionTypes.REMOVE;
  payload: { id: string; dockId: string; contracts: string[] };
}

export interface ChartSetPeriodTypeAction {
  type: ActionTypes.SET_PERIOD_TYPE;
  payload: { id: string; periodType: string; dockId: string };
}

export interface ChartSetContractAction {
  type: ActionTypes.SET_CONTRACT;
  payload: { id: string; contract: Contract; dockId: string;};
}

export interface ChartSetContractIdAction {
  type: ActionTypes.SET_CONTRACT_ID;
  payload: { id: string; contractId: string; dockId: string; ohlcPeriod?: OhlcPeriod };
}

export interface ChartSetGroupTypesAction {
  type: ActionTypes.SET_GROUP_TYPES;
  payload: { id: string; groupTypes: string[]; dockId: string };
}

export interface ChartSubscribeAction {
  type: ActionTypes.SUBSCRIBE_CHART;
  payload: { 
    contractId: string,  
    periodSeconds: number;
    chartId: string
  };
}

export interface ChartUnsubscribeAction {
  type: ActionTypes.UNSUBSCRIBE_CHART;
  payload: { 
    contractId: string,  
    periodSeconds: number,
    chartId: string
  };
}

export interface ChartSetViewsAction {
  type: ActionTypes.SET_VIEWS;
  payload: { 
    views: any[];
  };
}

export interface ChartSetCurrentThemeAction {
  type: ActionTypes.SET_CURRENT_THEME;
  payload: { 
    id: string;
    theme: string;
  };
}

export interface ChartSetThemesAction {
  type: ActionTypes.SET_THEMES;
  payload: { 
    themes: any;
  };
}

export interface ChartSetLayoutAction {
  type: ActionTypes.SET_LAYOUT;
  payload: { 
    id: string;
    layout: any;
  };
}

export interface ChartSetDrawingsAction {
  type: ActionTypes.SET_DRAWINGS;
  payload: { 
    contractName: string;
    drawings: any;
  };
}

export interface ChartRestoreDrawingsAction {
  type: ActionTypes.RESTORE_DRAWINGS;
  payload: { 
    drawings: any;
  };
}


export function load(charts: any[]): ChartLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: charts
  };
}


export function create(chart: any): ChartCreateAction {
  return {
    type: ActionTypes.CREATE,
    payload: chart,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('charts.logs.create', {title: (chart.contractId === '' ? chart.title : chart.contractId), dock: chart.dockId})
  };
}

export function move(
  chartIds: string[],
  dockId: string,
  toDockId: string
): ChartMoveAction {
  return {
    type: ActionTypes.MOVE,
    payload: { ids: chartIds, dockId: dockId, toDockId: toDockId },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('charts.logs.move', {fromDock: dockId, toDock: toDockId})
  };
}

export function remove(chartId: string, dockId: string, contracts: string[]): ChartRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: { id: chartId, dockId: dockId, contracts: contracts },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('charts.logs.remove', {dock: dockId})
  };
}

export function setPeriodType(
  chartId: string,
  periodType: string,
  dockId: string
): ChartSetPeriodTypeAction {
  return {
    type: ActionTypes.SET_PERIOD_TYPE,
    payload: { id: chartId, periodType: periodType, dockId: dockId }
  };
}

export function setContractId(
  chartId: string,
  contractId: string,
  dockId: string,
  ohlcPeriod: OhlcPeriod
): ChartSetContractIdAction {
  return {
    type: ActionTypes.SET_CONTRACT_ID,
    payload: { id: chartId, contractId: contractId, dockId: dockId, ohlcPeriod: ohlcPeriod }
  };
}

export function setContract(
  chartId: string,
  contract: Contract,
  dockId: string
): ChartSetContractAction {
  return {
    type: ActionTypes.SET_CONTRACT,
    payload: { id: chartId, contract, dockId }
  };
}

export function setGroupTypes(
  chartId: string,
  groupTypes: string[],
  dockId: string
): ChartSetGroupTypesAction {
  return {
    type: ActionTypes.SET_GROUP_TYPES,
    payload: { id: chartId, groupTypes: groupTypes, dockId: dockId }
  };
}

export function chartSubscribe(
  contractId: string,
  periodSeconds: number,
  chartId: string
): ChartSubscribeAction {
  return {
    type: ActionTypes.SUBSCRIBE_CHART,
    payload: {
      contractId, periodSeconds, chartId
    }
  };
}

export function chartUnsubscribe(
  contractId: string,
  periodSeconds: number,
  chartId: string
): ChartUnsubscribeAction {
  return {
    type: ActionTypes.UNSUBSCRIBE_CHART,
    payload: {
      contractId, periodSeconds, chartId
    }
  };
}

export function chartSetViews(
  views: any[]
): ChartSetViewsAction {
  return {
    type: ActionTypes.SET_VIEWS,
    payload: {views}
  };
}

export function chartSetThemes(
  themes: any
): ChartSetThemesAction {
  return {
    type: ActionTypes.SET_THEMES,
    payload: {themes}
  };
}

export function chartSetCurrentTheme(
  id: string,
  theme: string
): ChartSetCurrentThemeAction {
  return {
    type: ActionTypes.SET_CURRENT_THEME,
    payload: {id, theme}
  };
}

export function chartSetLayout(
  id: string,
  layout: any
): ChartSetLayoutAction {
  return {
    type: ActionTypes.SET_LAYOUT,
    payload: {id, layout}
  };
}

export function chartSetDrawings(
  contractName: string,
  drawings: any
): ChartSetDrawingsAction {
  return {
    type: ActionTypes.SET_DRAWINGS,
    payload: {contractName, drawings}
  };
}

export function chartRestoreDrawings(
  drawings: any
): ChartRestoreDrawingsAction {
  return {
    type: ActionTypes.RESTORE_DRAWINGS,
    payload: {drawings}
  };
}

export type Action =
  | ChartLoadAction
  | ChartCreateAction
  | ChartMoveAction
  | ChartRemoveAction
  | ChartSetPeriodTypeAction
  | ChartSetContractAction
  | ChartSetContractIdAction
  | ChartSetGroupTypesAction
  | ChartSubscribeAction
  | ChartUnsubscribeAction
  | ChartSetViewsAction
  | ChartSetThemesAction
  | ChartSetCurrentThemeAction
  | ChartSetLayoutAction
  | ChartSetDrawingsAction
  | ChartRestoreDrawingsAction;
