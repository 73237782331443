import { PriceAlarm, PriceAlarmFormData } from '../models/priceAlarms';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD = '[PriceAlarm] Load price alarms',
  CREATE = '[PriceAlarm] Create price alarm',
  MODIFY = '[PriceAlarm] Modify price alarm',
  DELETE = '[PriceAlarm] Delete price alarm',
  TRIGGER = '[PriceAlarm] trigger price alarm',
  OPEN_FORM = '[PriceAlarm] open price alarm form',
  CLOSE_FORM = '[PriceAlarm] close price alarm form',
  SUBMIT_FORM = '[PriceAlarm] submit price alarm form',
  CLEAR_FORM = '[PriceAlarm] clear price alarm form',
  SUCCESS = '[PriceAlarm] price alarm success'
}

export interface LoadPriceAlarmAction extends LogAction {
  type: ActionTypes.LOAD;
  payload: PriceAlarm[];
}

export interface CreatePriceAlarmAction extends LogAction {
  type: ActionTypes.CREATE;
  payload: PriceAlarm;
}

export interface ModifyPriceAlarmAction extends LogAction {
  type: ActionTypes.MODIFY;
  payload: PriceAlarm;
}

export interface DeletePriceAlarmAction extends LogAction {
  type: ActionTypes.DELETE;
  payload: PriceAlarm[];
}

export interface TriggerPriceAlarmAction extends LogAction {
  type: ActionTypes.TRIGGER;
  payload: PriceAlarm;
}

export interface OpenPriceAlarmFormAction {
  type: ActionTypes.OPEN_FORM;
  payload: PriceAlarmFormData;
}

export interface ClosePriceAlarmFormAction {
  type: ActionTypes.CLOSE_FORM;
}

export interface OpenPriceAlarmFormAction {
  type: ActionTypes.OPEN_FORM;
  payload: PriceAlarmFormData;
}

export interface ClosePriceAlarmFormAction {
  type: ActionTypes.CLOSE_FORM;
}

export interface SubmitPriceAlarmFormAction {
  type: ActionTypes.SUBMIT_FORM;
  payload: PriceAlarmFormData;
  correlationId: string;
}

export interface ClearPriceAlarmFormAction {
  type: ActionTypes.CLEAR_FORM;
}

export interface PriceAlarmSuccessAction extends LogAction {
  type: ActionTypes.SUCCESS;
  payload: string;
}

export function loadPriceAlarms(payload: PriceAlarm[]): LoadPriceAlarmAction {
  return {
    type: ActionTypes.LOAD,
    payload: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('priceAlarm.log.load', {
      
    })
  };
}

export function createPriceAlarm(payload: PriceAlarm): CreatePriceAlarmAction {
  return {
    type: ActionTypes.CREATE,
    payload: payload,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('priceAlarm.log.create', {
      
    })
  };
}

export function modifyPriceAlarm(payload: PriceAlarm): ModifyPriceAlarmAction {
  return {
    type: ActionTypes.MODIFY,
    payload: payload,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('priceAlarm.log.modify', {
      
    })
  };
}

export function deletePriceAlarms(payload: PriceAlarm[]): DeletePriceAlarmAction {
  return {
    type: ActionTypes.DELETE,
    payload: payload,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('priceAlarm.log.delete', {
      
    })
  };
}

export function triggeredPriceAlarm(payload: PriceAlarm): TriggerPriceAlarmAction {
  return {
    type: ActionTypes.TRIGGER,
    payload: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('priceAlarm.log.triggered', {
      contract: payload.contract, price: payload.triggeredBy
    }),
    confirmationRequired: true
  };
}

export function openPriceAlarmForm(payload: PriceAlarmFormData): OpenPriceAlarmFormAction {
  return {
    type: ActionTypes.OPEN_FORM,
    payload: payload
  };
}

export function closePriceAlarmForm(): ClosePriceAlarmFormAction {
  return {
    type: ActionTypes.CLOSE_FORM
  };
}

export function submitPriceAlarmForm(payload: PriceAlarmFormData, correlationId: string): SubmitPriceAlarmFormAction {
  return {
    type: ActionTypes.SUBMIT_FORM,
    payload: payload,
    correlationId: correlationId
  };
}

export function clearPriceAlarmForm(): ClearPriceAlarmFormAction {
  return {
    type: ActionTypes.CLEAR_FORM
  };
}

export function successPriceAlarm(message: string): PriceAlarmSuccessAction {
  return {
    type: ActionTypes.SUCCESS,
    payload: message,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('priceAlarm.success')
  };
}

export type Action =
  LoadPriceAlarmAction |
  CreatePriceAlarmAction |
  ModifyPriceAlarmAction |
  DeletePriceAlarmAction |
  TriggerPriceAlarmAction |
  ClosePriceAlarmFormAction |
  OpenPriceAlarmFormAction |
  ClearPriceAlarmFormAction | 
  SubmitPriceAlarmFormAction |
  PriceAlarmSuccessAction;