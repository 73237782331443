export default function register() {
const serviceWorker = navigator.serviceWorker;
if(serviceWorker){
  serviceWorker.register('./cfg/firebase.js')
  .then(function (registration) {
    console.log("Registration successful, scope is:", registration.scope);
  })
  .catch(function (err) {
    console.log("Service worker registration failed, error:", err);
  });
}
}