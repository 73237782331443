import { Size } from '../../shared/utils/models/grid';
import { ComponentType } from '../../shared/ui/models/component';
import { IDock } from '../../shared/dock/models/dock';
import { IQuadrant } from '../../analyticsPanel/models/analyticsPanel';

export interface IDashboardQuadrant extends IQuadrant {
  size: Size;
  id: number;
  dock?: IDock;
  isColliding?: boolean;
  active?: boolean;
  collidingAllowed?: boolean;
}
  
export class DashboardQuadrant implements IDashboardQuadrant {
  components: any[];
  dock: IDock;
  id: number;
  component: ComponentType;
  size: Size = { width: 50, height: 50 };
  active: boolean = true;
  
  constructor(id: number, component?: ComponentType, size?: Size, dock?: IDock, active?: boolean) {
    this.id = id;
    this.components = [component];
    this.size = size ? size : this.size;
    this.dock = dock ? dock : this.dock;
    this.active = active === undefined ? true : active;
  }
  setSize(size: Size) {
    this.size = size;
  }

  setDock(dock: IDock) {
    this.dock = dock;
  }
}
  