import * as React from 'react';
import * as Rx from 'rxjs';
import { Position, Size } from '../../shared/utils/models/grid';
import SideBar from '../../shared/sidebar/components/Sidebar';
import AnalyticsPanel from '../../analyticsPanel/containers/AnalyticsPanel';
import Dock from '../../shared/dock/container/Dock';
import {
  Dock as DockModel,
  DockType,
  getDockType,
  IDock,
  Pinning
} from '../../shared/dock/models/dock';
import { Bounds, Collider, Side, DropBox } from '../../shared/utils/helper/collider';
import { ColSizes, Grid } from '../../shared/utils/components/grid';
import { DashboardComponent, DashboardMeta } from '../models/dashboard';

import { ITab, Tab } from '../../shared/ui/models/tab';
import UITabView from '../../shared/ui/components/table/components/uiTabComponent';
import { DockCreateAction } from '../../shared/dock/actions/dock';
import { ComponentType } from '../../shared/ui/models/component';
import { IMarket, Market } from '../../shared/ui/models/market';
import UIMarketView from '../../shared/ui/components/market/components/uiMarketComponent';
import { Chart, IChart } from '../../shared/ui/models/chart';
import UITradesChartComponent from '../../shared/ui/components/chart/components/uiTradesChartComponent';
import { IFavorite } from '../../shared/favorite/models/favorite';
import { ITableColumn } from '../../shared/ui/models/table';
import {
  getMarketEntities,
  getChartEntites
} from '../../shared/ui/selectors/ui';
import { store } from '../../main/store/store';
import { I18n } from 'react-redux-i18n';
import { CancelDragArea } from './cancelDragArea';
import { distinctUntilChanged } from 'rxjs/operators';
import { config } from '../../main/config';
import { getDockById } from '../selectors/dashboard';
import { Debouncer } from '../../shared/utils/components/debounce';
import QuadrantPanel from './QuadrantPanel';
import DashboardQuadrant from './DashboardQuadrant';
import { IDashboardQuadrant } from '../models/quadrants';
import ContextMenu from './contextMenu'
import OrderSidebarComponent from '../../orders/components/orderSidebar';
interface Props {
  docks: IDock[];
  activeDock: IDock;
  inactiveDocks: IDock[];
  collidingDocks: IDock[];
  collidingQuadrants: IDashboardQuadrant[];
  enabled: boolean;
  gridQuadrantsActive: boolean;
  load: (meta: DashboardMeta) => void;
  resize: (meta: DashboardMeta) => void;
  dockCreate: (dock: IDock) => DockCreateAction;
  docksSave: (docks: IDock[]) => void;
  dockCollideStart: (id: string, isRemoving: boolean) => void;
  dockCollideEnd: (id: string) => void;
  quadrantCollideStart: (id: string, activeDock: IDock) => void;
  quadrantCollideStop: (id: string) => void;
  dockPinStart: (id: string, pinning: Pinning) => void;
  dockPinEnd: (id: string) => void;
  dockRemove: (id: string) => void;
  tabCreate: (tab: ITab) => void;
  marketCreate: (market: IMarket) => void;
  chartCreate: (chart: IChart) => void;
  connectDocks: (fromId: string, toId: string, type: DockType) => void;
  warn: (notification: string) => void;
}

interface State {
  contextMenu: {
    position: Position;
    isOpen: boolean;
  };
  logModalVisible: boolean;
  cancelDragBoxVisible: boolean;
}

export default class DashboardPanelComponent extends React.Component<
  Props,
  State
> {
  collider: Rx.Subject<any> = new Rx.Subject();
  pinner: Rx.Subject<any> = new Rx.Subject();
  subsriptions: Rx.Subscription[];
  pinDetectionOffset: number = Grid.gutter * 2;
  pinOffset: number = 0;
  containerWidth: number;
  timeouts: any[];
  grid: Grid;
  cancelDragAreaRef: React.RefObject<CancelDragArea>;
  quadrantRefs: React.RefObject<DashboardQuadrant>[];

  // TODO: temporary helper object !!! refactor over redux scope
  private _activeDockBounds: Bounds = Collider.createBounds(0, 0, 0, 0);
  constructor(props: Props) {
    super(props);

    this.onWindowResize = this.onWindowResize.bind(this);

    this.onContextMenu = this.onContextMenu.bind(this);
    this.onContextMenuDismiss = this.onContextMenuDismiss.bind(this);
    this.onNewDock = this.onNewDock.bind(this);
    this.onDocksSave = this.onDocksSave.bind(this);
    this.onDockDrag = this.onDockDrag.bind(this);
    this.onDockDragFinish = this.onDockDragFinish.bind(this);
    this.onDockResize = this.onDockResize.bind(this);
    this.onDocksConnect = this.onDocksConnect.bind(this);
    this.onDockRemove = this.onDockRemove.bind(this);

    this.onNewTab = this.onNewTab.bind(this);
    this.onSingleDragFromSidemenu = this.onSingleDragFromSidemenu.bind(this);
    this.onFavoriteSelectDrag = this.onFavoriteSelectDrag.bind(this);
    this.onRecentActionsDrag = this.onRecentActionsDrag.bind(this);
    this.toggleLogModal = this.toggleLogModal.bind(this);

    // Setup internal state
    this.state = {
      contextMenu: {
        position: { x: 0, y: 0 },
        isOpen: false
      },
      logModalVisible: false,
      cancelDragBoxVisible: false
    };

    // Setup grid
    this.grid = new Grid();
    this.containerWidth = this.grid.fullWidth;
    this.cancelDragAreaRef = React.createRef();
    this.quadrantRefs = [null!, null!, null!, null!];
    this.timeouts = [];
  }

  componentDidMount() {
    const gridSize = {
      width: this.grid.fullWidth,
      height: this.grid.fullHeight
    };

    this.props.load({ size: gridSize, cols: Grid.cols });

    this.subsriptions = [
      this.collider
        .pipe(
          distinctUntilChanged(
            (x, y) => JSON.stringify(x) === JSON.stringify(y)
          )
        )
        .subscribe((ids: string[]) => {
          this._updateCollisionsState(ids);
        }),
      this.pinner
        .pipe(
          distinctUntilChanged(
            (x, y) => JSON.stringify(x) === JSON.stringify(y)
          )
        )
        .subscribe((docks: IDock[]) => {
          this._updatePinsState(docks);
        })
    ];
    
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillReceiveProps(newProps: Props) {
    // Reset collider when dock is removed from epic
    if (newProps.docks.length !== this.props.docks.length) {
      this.collider.next([]);
    }
  }

  onWindowResize(e: any) {
    // Setup grid
    this.grid = new Grid();
    this.containerWidth = this.grid.fullWidth;

    const gridSize = {
      width: this.grid.fullWidth,
      height: this.grid.fullHeight
    };

    this.props.resize({ size: gridSize, cols: Grid.cols });
  }

  onContextMenu(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  onContextMenuDismiss() {
    this.setState({
      ...this.state,
      contextMenu: {
        ...this.state.contextMenu,
        isOpen: false
      }
    });
  }

  __getCoordinatesFromEvent = (currentEvent) => {
    return currentEvent.type == 'touchmove' 
          ? (currentEvent.touches.length ? {x : currentEvent.touches[0].clientX, y: currentEvent.touches[0].clientY} : {x: 0, y: 0}) 
          : {x: currentEvent.clientX, y: currentEvent.clientY};
  }

  onSingleDragFromSidemenu(e: any, component: DashboardComponent) {
    e.preventDefault();
    const itemId = component.args && component.args[0].id;
    const itemIds =
      component.dockType === DockType.MarketIntraday ||
      component.dockType === DockType.Market ||
      component.dockType === DockType.ProductMarket
        ? getMarketEntities(store.getState()).filter(m => getDockById(store.getState(), m.dockId) !== null).map(m => m.itemId)
        : getChartEntites(store.getState())
            .filter(c => getDockById(store.getState(), c.dockId) !== null)
            .map((c: any) => c.instrumentId)
            .filter(id => id !== undefined);
   
    if (!component.args) {
      return;
    }        
    let instrumentId = component && component.type === ComponentType.Product ? component.args[0].product.instrumentId : component.args[0].id;
    if (
      (component.dockType === DockType.MarketIntraday ||
        component.dockType === DockType.Market ||
        component.dockType === DockType.ProductMarket) &&
        itemIds.indexOf(itemId) > -1
    ) {
      this.props.warn(I18n.t('error.marketIsAlreadyActive'));
    } else {
      this.setState({
        ...this.state,
        cancelDragBoxVisible: true
      });
      let currentEvent = e;
      const moveListener = (ev: any) => { 
        if (ev && ev.persist) { 
          ev.persist();
        }
        currentEvent = ev; 
      };
      if (currentEvent.type === 'mousemove') {
        document.addEventListener('mousemove', moveListener);
      } 
      this.timeouts.push(setTimeout( () => {
        const {x, y} = this.__getCoordinatesFromEvent(currentEvent);
        const payload = this.onNewDock(
          component.dockType,
          {
            x,
            y
          },
          true,
          true,
          undefined,
          undefined,
          currentEvent.type === 'touchend'
        );
        if (payload) {
          switch (component.dockType) {
            case DockType.Tabs:
              this.onNewTab(payload.id, component.name, component.type, true);
              break;
            case DockType.Market:
            case DockType.ProductMarket:
              this.onNewMarket(
                payload.id,
                component.name,
                component.type,
                itemId,
                instrumentId
              );
              break;
              case DockType.MarketIntraday:
              this.onNewMarket(
                payload.id,
                component.name,
                component.type,
                itemId,
                instrumentId
              );
              break;
            case DockType.ChartMarket:
              this.onNewChart(
                payload.id,
                component.name,
                component.args && component.args[0], // instrumentID
                component.args && component.args[1], // selectedPeriodType
                component.args && component.args[2], // contractId
                component.args && component.args[3] ? component.args[3] : ['lastPrices'] // groupTypes
              );
              break;
            default:
              break;
          }
        }
      }, 500));
    }
  }

  onFavoriteSelectDrag(e: any, favorite: IFavorite) {
    const itemIds =
      favorite.entityType === DockType.MarketIntraday ||
      favorite.entityType === DockType.Market ||
      favorite.entityType === DockType.ProductMarket
        ? getMarketEntities(store.getState()).map(m => m.itemId)
        : getChartEntites(store.getState())
            .map((c: any) => c.instrumentId)
            .filter(id => id !== undefined);
    let itemsAlreadyActive = [];
    for (let entity of favorite.entities as any) {
      if (itemIds.indexOf(entity.itemId) > -1) {
        itemsAlreadyActive.push(entity.instrumentTitle || entity.title);
      }
    }
    if (
      itemsAlreadyActive.length > 0 &&
      (favorite.entityType === DockType.MarketIntraday ||
        favorite.entityType === DockType.Market ||
        favorite.entityType === DockType.ProductMarket)
    ) {
      this.props.warn(
        I18n.t(favorite.entityType === DockType.ProductMarket ? 'error.favouriteProductMarketIsAlreadyActive' : 'error.favouriteMarketIsAlreadyActive', {
          items: itemsAlreadyActive.join(', ')
        }));
    } else {
      e.preventDefault();
      this.setState({
        ...this.state,
        cancelDragBoxVisible: true
      });
      let currentEvent = e;
      const mouseMoveListener = (ev: any) => { currentEvent = ev; };
      const mouseMove = document.addEventListener('mousemove', mouseMoveListener);
      setTimeout( () => {
        const dock = this.onNewDock(
          favorite.entityType,
          {
            x: currentEvent.clientX,
            y: currentEvent.clientY
          },
          true,
          true,
          undefined,
          favorite.name,
          currentEvent.type === 'touchend'
        );
        if (dock) {
          for (let entity of favorite.entities as any) {
            if (favorite.entityType === DockType.Market || favorite.entityType === DockType.ProductMarket) {
              this.onNewMarket(
                dock.id,
                entity.instrumentTitle,
                entity.componentType,
                entity.itemId,
                entity.instrumentId,
                entity.columns,
                entity.hiddenColumnNames,
                entity.hiddenExpiryKeys,
                entity.isHeadlinesVisible
              );
            } else if (favorite.entityType === DockType.MarketIntraday) {
              this.onNewMarket(
                dock.id,
                entity.instrumentTitle,
                entity.componentType,
                entity.instrumentId,
                entity.instrumentId,
                entity.columns,
                entity.hiddenColumnNames,
                entity.hiddenExpiryKeys,
                entity.isHeadlinesVisible
              );
            } else if (
              favorite.entityType === DockType.ChartMarket
            ) {
              this.onNewChart(
                dock.id,
                entity.title,
                entity.instrumentId,
                entity.selectedPeriodType,
                entity.contractId,
                entity.groupTypes
              );
            }
          }
        }
      }, 500);
    }
  }

  onRecentActionsDrag(
    e: any,
    components: { type: ComponentType; title: string; args?: any[] }[]
  ) {
    e.preventDefault();

    const dockType = getDockType(ComponentType.Trade);
    this.setState({
      ...this.state,
      cancelDragBoxVisible: true
    });
    const payload = this.onNewDock(
      dockType,
      {
        x: e.clientX,
        y: e.clientY
      },
      true,
      true,
      undefined,
      undefined,
      e.type === 'touchend'
    );

    for (let component of components) {
      this.onNewTab(payload.id, component.title, component.type, true);
    }
  }

  onNewDock(
    type: DockType,
    position: Position,
    ableToDelete?: boolean,
    autoDragging?: boolean,
    id?: string,
    favoriteName?: string,
    touchScreen?: boolean
  ): IDock {
    const colSize = type === DockType.ChartMarket ? ColSizes.SIX : ColSizes.FOUR;
    const dockSize = type === DockType.ChartMarket ?  this.grid.getDockSizeByColSizeAndHeight(colSize, 350) : this.grid.getDockSizeByColSize(colSize);
    const dockPosition = this.grid.getDockPosition(position, dockSize);
    const newDock: IDock = new DockModel(
      type,
      dockPosition,
      dockSize,
      autoDragging,
      ableToDelete,
      id,
      true,
      favoriteName,
      0,
      undefined,
      touchScreen
    );

    this.setState({
      ...this.state,
      contextMenu: {
        ...this.state.contextMenu,
        isOpen: false
      }
    });

    this._activeDockBounds = Collider.createBounds(
      newDock.position.x,
      newDock.position.y,
      newDock.size.width,
      newDock.size.height
    );

    this.props.dockCreate(newDock);
    return newDock;
  }

  onNewTab(
    dockId: string,
    title: string,
    type: ComponentType,
    displayActions: boolean
  ) {
    const newTab: ITab = new Tab(dockId, title, type, displayActions);
    this.props.tabCreate(newTab);
  }

  onNewMarket(
    dockId: string,
    title: string,
    type: ComponentType,
    itemId: string,
    instrumentId: string,
    columns?: ITableColumn[],
    hiddenColumnNames?: string[],
    hiddenExpiryKeys?: string[],
    isHeadlinesVisible?: boolean
  ) {
    // repairs saved columns according to current configuration. 
    // Only column vsibility personalization should be allowed
    let newColumns = undefined;
    if (columns) {
      newColumns = [];
      const configColumnMap: {[name: string]: ITableColumn} = config.ui.market.columns.reduce(
        (map: {[name: string]: ITableColumn}, col: ITableColumn) => {
          return {...map, [col.group + '-' + col.name]: col};
        }
      , {});
 
      for (let i = 0; i < columns.length; i++) {
        const key = columns[i].group + '-' + columns[i].name;
        if (configColumnMap[key]) {
          newColumns.push({...configColumnMap[key], rowVisible: columns[i].rowVisible});
        }      
      }
    }
    const market: IMarket = new Market(
      dockId,
      title,
      type,
      itemId,
      instrumentId,
      newColumns,
      hiddenColumnNames,
      hiddenExpiryKeys,
      [],
      isHeadlinesVisible
    );
    this.props.marketCreate(market);
  }

  onNewChart(
    dockId: string,
    title: string,
    instrumentId: string,
    selectedPeriodType?: string,
    contractId?: string,
    groupTypes?: ('bidPrices' | 'askPrices' | 'lastPrices')[]
  ) {

    this.setState({
      ...this.state,
      cancelDragBoxVisible: true
    });

    const chart: IChart = new Chart(
      dockId,
      title,
      instrumentId,
      instrumentId,
      selectedPeriodType,
      contractId,
      groupTypes
    );
    this.props.chartCreate(chart);
  }

  onDocksSave() {
    const { docks } = this.props;

    this.setState({
      ...this.state,
      contextMenu: {
        ...this.state.contextMenu,
        isOpen: false
      }
    });

    let toSaveDocks = docks.reduce((newDocks: IDock[], dock: IDock) => {
      return newDocks.concat(
        Object.assign({}, dock, {
          position: this.grid.convertDockPositionToPercentage(dock.position)
        })
      );
    }, []);

    this.props.docksSave(toSaveDocks);
  }

  onDockDrag(position: Position) {
    const { activeDock, inactiveDocks } = this.props;
    this._activeDockBounds = Collider.createBounds(
      position.x,
      position.y,
      activeDock.touchScreen ? 1 : activeDock.size.width, // with touch events only exact touch position is relevant
      activeDock.touchScreen ? 1 : activeDock.size.height
    );

    let collidingObjects: any[] = inactiveDocks;
    const cancelDragBox =
      this.cancelDragAreaRef.current &&
      this.cancelDragAreaRef.current!.state.box !== null
        ? this.cancelDragAreaRef.current.state.box
        : null;
    
    if (cancelDragBox) {
      collidingObjects = [...collidingObjects, cancelDragBox];
    }
    if (this.quadrantRefs && this.props.gridQuadrantsActive) {
      const dashboardQuadrants = this.quadrantRefs.map(ref => {
        if (ref && ref.current) {
          if (activeDock.touchScreen) {
            // for touch event whole quadrant area is a collision box
            return ref.current.getFullQuadrantBoxPosition();
          } 
          return ref.current!.state.box! != null ? ref.current.state.box : null
        } 
        return null;
      });
      collidingObjects = [...collidingObjects, ...dashboardQuadrants.filter(q => q !== null)];
    }
    this.collider.next(
      Collider.detectCollisions<IDock | DropBox>(
        this._activeDockBounds,
        collidingObjects
      ).map(d => d.id)
    );
    this.pinner.next(
      Collider.detectCollisions<IDock>(
        this._activeDockBounds,
        inactiveDocks,
        this.pinDetectionOffset
      )
    );
  }

  onDockDragFinish(position: Position) {
    this.setState({
      ...this.state,
      cancelDragBoxVisible: false
    });
  }

  onDockResize(size: Size) {
    const { activeDock, inactiveDocks } = this.props;

    this._activeDockBounds = Collider.createBounds(
      activeDock.position.x,
      activeDock.position.y,
      size.width,
      size.height
    );

    this.collider.next(
      Collider.detectCollisions<IDock>(
        this._activeDockBounds,
        inactiveDocks
      ).map(d => d.id)
    );
  }

  onDocksConnect(toConnectDockId: string) {
    const { activeDock } = this.props;
    this.props.connectDocks(activeDock.id, toConnectDockId, activeDock.type);
  }

  onDockRemove(dockId: string) {
    this.setState({
      ...this.state,
      cancelDragBoxVisible: false
    });
    this.props.dockRemove(dockId);
  }

  toggleLogModal() {
    this.setState(prevState => {
      return {
        ...prevState,
        logModalVisible: !this.state.logModalVisible
      };
    });
  }

  render(): JSX.Element {
    const { docks, collidingDocks, enabled } = this.props;
    const { maxColWidth, minColWidth } = this.grid;
    const { contextMenu, cancelDragBoxVisible } = this.state;
    // on grid quadrants display only dragged docks, otherwise display all
    const _docks = docks.filter(dock => !this.props.gridQuadrantsActive  || dock.disableRendering).map(dock => {
      return React.createElement(Dock, {
        key: dock.id,
        data: dock,
        minWidth: minColWidth,
        maxWidth: maxColWidth,
        onDrag: this.onDockDrag,
        onDragFinish: this.onDockDragFinish,
        onResize: this.onDockResize,
        onDocksConnect: this.onDocksConnect,
        isGridQuadrantsActive: this.props.gridQuadrantsActive,
        children: (
          <React.Fragment key={`dashboardDock-${dock.id}`}>
            {dock.type === DockType.Tabs ? (
              <UITabView dockId={dock.id} />
            ) : dock.type === DockType.Market ||
              dock.type === DockType.MarketIntraday ||
              dock.type === DockType.ProductMarket ? (
              <UIMarketView
                dockId={dock.id}
                dockType={dock.type}
                onCreateChart={this.onSingleDragFromSidemenu}
              />
            ) : dock.type === DockType.ChartMarket ? (
              <UITradesChartComponent dockId={dock.id} dockType={dock.type} />
            ) : null}
          </React.Fragment>
        ),
        touchScreen: dock.touchScreen
      });
    });

    const watermarkName = config.branding.watermark;
    const watermark = `${config.subfolder}/logo/${watermarkName}`;
    return (
      <div
        className="home__container h-100 position-relative"
        id="home__container"
        data-test="dashboard-panel-home-container"
      >
        <div
          className="modal fade disconnect-fade show"
          role="dialog"
          style={{ display: enabled ? 'none' : 'block' }}
        />
        <SideBar
          key={'sidebar'}
          onTabCreate={this.props.tabCreate}
          onRecentActionsDrag={this.onRecentActionsDrag}
          onSingleDragFromSidemenu={this.onSingleDragFromSidemenu}
          onFavoriteSelectDrag={this.onFavoriteSelectDrag}
        />
        <OrderSidebarComponent />
        <AnalyticsPanel className="analytics-panel" />
        { this.props.gridQuadrantsActive ? (
          <QuadrantPanel
            active={true}
            isLocked={false}
            quadrantRefs={this.quadrantRefs}
            grid={this.grid}
            docks={_docks}
            className="analytics-panel dashboard-quadrant-panel"
            onSingleDragFromSidemenu={this.onSingleDragFromSidemenu}
          />
        ): (
          <div
            className="docks__wrapper"
            style={{ width: this.containerWidth + 'px' }}
          >
            {_docks}
            {contextMenu.isOpen && collidingDocks.length === 0 ? (
              <ContextMenu
                position={contextMenu.position}
                isOpen={contextMenu.isOpen}
                onSave={this.onDocksSave}
                dismiss={this.onContextMenuDismiss}
              />
            ) : null}

            <CancelDragArea
              ref={this.cancelDragAreaRef}
              id="cancelDragArea"
              position={{ x: 35, y: this.grid.fullHeight - 65 }}
              size={{ width: 65, height: 65 }}
              visible={cancelDragBoxVisible}
            />
            <img hidden={!watermarkName} src={watermark} className="watermark" />
          </div>
          )
        };
      </div>
    );
  }

  componentWillUnmount() {
    this.subsriptions.map(subs => subs.unsubscribe());
    this.timeouts.forEach(timeout => clearTimeout(timeout));
  }

  private _updatePinsState(docks: IDock[]) {
    let { activeDock } = this.props;
    let { width, height } = this._activeDockBounds;
    if (docks.length && activeDock && !activeDock.isColliding) {
      let cDock = Collider.createBoundsFromBox(docks[0]);
      switch (Collider.getCollideSide(cDock, this._activeDockBounds)) {
        case Side.LEFT:
          this.props.dockPinStart(activeDock.id, {
            axis: 'x',
            value: cDock.xMax + this.pinOffset
          });
          break;
        case Side.TOP:
          this.props.dockPinStart(activeDock.id, {
            axis: 'y',
            value: cDock.yMax + this.pinOffset
          });
          break;
        case Side.RIGHT:
          this.props.dockPinStart(activeDock.id, {
            axis: 'x',
            value: cDock.x - (width + this.pinOffset)
          });
          break;
        case Side.BOTTOM:
          this.props.dockPinStart(activeDock.id, {
            axis: 'y',
            value: cDock.y - (height + this.pinOffset)
          });
          break;
        default:
          break;
      }
    } else {
      if (activeDock) {
        this.props.dockPinEnd(activeDock.id);
      }
    }
  }

  private _updateCollisionsState(dockIds: string[]) {
    const { activeDock, collidingDocks, collidingQuadrants } = this.props;
    const collidingDockIds = collidingDocks.map(dock => dock.id);
    if (dockIds.length) {
      // Check if active element already set as colliding if not set to start collide
      if (activeDock && collidingDockIds.indexOf(activeDock.id) === -1) {
        this.props.dockCollideStart(
          activeDock.id,
          dockIds.indexOf('cancelDragArea') !== -1
        );
      } else {
        this.props.dockCollideStart(
          activeDock ? activeDock.id : '',
          dockIds.indexOf('cancelDragArea') !== -1
        );
      }

      /// Check if inactive element already colliding, if not set to start collide
      dockIds.forEach(id => {
        if (collidingDockIds.indexOf(id) === -1) {
          if (id !== 'cancelDragArea' && id.indexOf('quadrant-box') === -1) {
            this.props.dockCollideStart(id, false);
          }
          else if (id.indexOf('quadrant-box') > -1) {
            this.props.quadrantCollideStart(id, activeDock);
          }
        }
      });
      collidingQuadrants.forEach(q => {
        const boxId = 'quadrant-box' + (q.id);
        if (dockIds.indexOf(boxId) === -1) {
          this.props.quadrantCollideStop(boxId);
        }
      });

      // Check if some dock(except active) stop colliding if yes set to end collide
      collidingDockIds.forEach(id => {
        if (dockIds.indexOf(id) === -1 && activeDock.id !== id) {
          this.props.dockCollideEnd(id);
        } else if (
          dockIds.indexOf('cancelDragArea') !== -1 &&
          activeDock.id !== id
        ) {
          this.props.dockCollideEnd(id);
        }
      });
    } else {
      collidingDockIds.forEach(dockId => {
        this.props.dockCollideEnd(dockId);
      });
      collidingQuadrants.forEach(q => {
        const boxId = 'quadrant-box' + (q.id);
        this.props.quadrantCollideStop(boxId);
      });
    }
  }
}
