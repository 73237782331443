import * as React from 'react';

import { I18n } from 'react-redux-i18n';
import NotificationsConfigModalComponent from '../../notifications/components/NotificationsConfigModal';
import SettingsConfigModal from '../../settings/components/SettingsConfigModal';
import withAutoClose from '../../autoclose/components/Autoclose';
import BulkOrderUploadModal from '../../../bulkOrders/components/BulkOrderUploadModal';
import { isBasketOrderUploadEnabled } from '../../../requests/selectors/requests';
import VersionInfoModal from '../../notifications/components/VersionInfoModal';
import PasswordChangeModal from '../../passwordChange/components/PasswordChangeModal';
import { MemoTranslate } from '../../i18n/components/memoTranslate';
import { config } from '../../../main/config';
import store from '../../../main/store/store';
import MappedInfoModal from '../../notifications/components/MappedInfoModal';

interface Props {
  expanded: boolean; // from withAutoclose
  bulkOrderUploadEnabled: boolean;
  atLeastOneSettingEnabled: boolean;
  passwordExpired: boolean;
}
interface State {
  logModalOpen: boolean;
  notificationModalOpen: boolean;
  settingsModalOpen: boolean;
  bulkOrderUploadModalOpen: boolean;
  versionInfoModalOpen: boolean;
  passwordChangeModalOpen: boolean;
  mappedInstrumentsModalOpen: boolean;
}

export class SidebarContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      logModalOpen: false,
      notificationModalOpen: false,
      settingsModalOpen: false,
      bulkOrderUploadModalOpen: false,
      versionInfoModalOpen: false,
      passwordChangeModalOpen: props.passwordExpired,
      mappedInstrumentsModalOpen: false
    };
  }

  render(): JSX.Element {
    const { expanded, atLeastOneSettingEnabled, passwordExpired} = this.props;
    const {
      logModalOpen,
      notificationModalOpen,
      settingsModalOpen,
      bulkOrderUploadModalOpen,
      versionInfoModalOpen,
      passwordChangeModalOpen,
      mappedInstrumentsModalOpen
    } = this.state;
    return (
      <React.Fragment>
        <div>
          <i
            className={`oi oi-ellipses white`}
            title={I18n.t('sidebar.more')}
          />
          <div
            className="sidebar-context-menu"
            style={{ display: expanded ? 'block' : 'none' }}
          >
            <ul>
              {atLeastOneSettingEnabled ? (
                <li
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      settingsModalOpen: true
                    })
                  }
                >
                  <MemoTranslate value="sidebar.settings" />
                </li>
              ) : null}
              {/* Temporary disabled notification */}
              {/* <li
                onClick={() =>
                  this.setState({
                    ...this.state,
                    notificationModalOpen: !this.state.notificationModalOpen,
                    isOpen: false
                  })
                }
              >
                <Translate value="sidebar.notifications" />
              </li> */}
              {this.props.bulkOrderUploadEnabled ? (
                <li
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      bulkOrderUploadModalOpen: true
                    })
                  }
                >
                  <MemoTranslate value="sidebar.bulkOrderUpload" />
                </li>
              ) : null}
              <li
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      mappedInstrumentsModalOpen: true
                    })
                  }
                ><MemoTranslate value="sidebar.mappedInstruments" />
              </li>
              { !config.ssoEnabled ? (
                <li
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      passwordChangeModalOpen: true
                    })
                  }
                ><MemoTranslate value="sidebar.passwordChange" />
                </li>
              ) : null }
              <li
                onClick={() =>
                  this.setState({
                    ...this.state,
                    versionInfoModalOpen: true
                  })
                }
              >
                <MemoTranslate value="sidebar.versionInfo" />
              </li>
            </ul>
          </div>
        </div>

        {notificationModalOpen ? (
          <NotificationsConfigModalComponent
            closeModal={() =>
              this.setState({ ...this.state, notificationModalOpen: false })
            }
            modalVisible={notificationModalOpen}
          />
        ) : null}
        {versionInfoModalOpen ? (
          <VersionInfoModal
            closeModal={() =>
              this.setState({ ...this.state, versionInfoModalOpen: false })
            }
            modalVisible={versionInfoModalOpen}
          />
        ) : null}
        {settingsModalOpen ? (
          <SettingsConfigModal
            closeModal={() =>
              this.setState({ ...this.state, settingsModalOpen: false })
            }
            modalVisible={settingsModalOpen}
          />
        ) : null}
        {mappedInstrumentsModalOpen ? (
          <MappedInfoModal
            closeModal={() =>
              this.setState({ ...this.state, mappedInstrumentsModalOpen: false })
            }
            modalVisible={mappedInstrumentsModalOpen}
          />
        ) : null}
        {isBasketOrderUploadEnabled(store.getState()) && bulkOrderUploadModalOpen ? (
          <BulkOrderUploadModal
            closeModal={() =>
              this.setState({ ...this.state, bulkOrderUploadModalOpen: false })
            }
            modalVisible={bulkOrderUploadModalOpen}
          />
        ) : null}
        {passwordChangeModalOpen && !config.ssoEnabled ? (
          <PasswordChangeModal
            closeModal={() =>
              this.setState({ ...this.state, passwordChangeModalOpen: passwordExpired })
            }
            modalVisible={passwordChangeModalOpen}
            resetPasswordNeeded={passwordExpired}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withAutoClose(SidebarContextMenu);
