import * as React from 'react';
import { gedPeriodTypesForCharts } from '../../../../../orderbook/selectors/orderbooks';
import { Chart } from '../../../models/chart';
import {
  remove,
  setPeriodType,
  setContractId,
  setGroupTypes} from '../../../actions/chart';
import { DockType } from '../../../../dock/models/dock';
import store from '../../../../../main/store/store';
import ContractChart from '../../market/components/ContractChart';
import ChartActions from './chartActions';
import { getOrderbookContracts } from '../../../../../orderbook/selectors/contracts';
import orderBookStore from '../../../../../orderbook/store/orderbooks';
import { I18n, Translate } from 'react-redux-i18n';
import { LogLevel } from '../../../../logger/models/logger';
import { log } from '../../../../logger/actions/logger';
import { findAllContractsForPeriodTypeInChart, findAllContractsForPeriodType, findNextContractIdInChart, findContractIdAfterCurrentContract } from '../helper/helper';
import { IFavorite } from '../../../../../shared/favorite/models/favorite';
import { OhlcPeriod } from '../../../../../orderbook/models/charts';
import { Contract, ContractState } from '../../../../../orderbook/models/contracts';

interface ChartBlockProps {
  chart: Chart;
  periodTypes: string[];
  siblings: number;
  onChartSetPeriodType: (chartId: string, periodType: string) => void;
  onChartSetContract: (chartId: string, contractId: string, dockId?: string, ohlcPeriod?: OhlcPeriod) => void;
  onChartSetGroupTypes: (chartId: string, groupTypes: string[]) => void;
  drawings: {[contractName: string]: any}
}
interface ChartBlockState {
  contracts: {[key: string]: Contract};
}

class ChartBlock extends React.Component<ChartBlockProps, ChartBlockState> {
  constructor(props: ChartBlockProps) {
    super(props);
    this._getContractId = this._getContractId.bind(this);
    this.onChartPeriodChange = this.onChartPeriodChange.bind(this);
    this.onContractNoLongerActive = this.onContractNoLongerActive.bind(this);
    const contracts = getOrderbookContracts(orderBookStore.getState());
    this.state = {
      contracts
    };
  }

  componentWillMount() {
    const { chart, periodTypes } = this.props;
    if (chart.selectedPeriodType === '' && periodTypes) {
      const flatPeriodTypes = periodTypes.flatMap(p => p);
      this.props.onChartSetPeriodType(chart.id, flatPeriodTypes[0]);
    }

    if (
      chart.selectedPeriodType &&
      chart.contractId === '' &&
      chart.contractMatrix
    ) {
      const contractId = this._getContractId(chart.selectedPeriodType);
      this.props.onChartSetContract(chart.id, contractId);
    }
  }

  componentWillReceiveProps(newProps: ChartBlockProps) {
    const { chart } = this.props;
    if (chart.selectedPeriodType === '' && newProps.periodTypes) {
      const flatPeriodTypes = newProps.periodTypes.flatMap(p => p);
      this.props.onChartSetPeriodType(chart.id, flatPeriodTypes[0]);
    }
    // set initial contract id to the first contract in the list
    const contracts = getOrderbookContracts(orderBookStore.getState());
    this.setState((prevState) => {
      return {
        ...prevState,
        contracts: contracts
      };
    });

    if (
      newProps.chart.selectedPeriodType &&
      chart.contractMatrix &&
      !chart.contractId
    ) {
      const contractId = this._getContractId(newProps.chart.selectedPeriodType);
      this.props.onChartSetContract(chart.id, contractId);
    } else {
      if ( !contracts[chart.contractId] 
        || contracts[chart.contractId].id.localeCompare(chart.contractId) !== 0 
        || contracts[chart.contractId].state === ContractState.INACTIVE) {
        if (this.props.onChartSetContract && chart.contractMatrix) {
          this.props.onChartSetContract(chart.id, findContractIdAfterCurrentContract(chart, chart.contractMatrix, contracts), chart.dockId, chart.ohlcPeriod);
        }
      }
    }
  }
  findAllContractsForPeriodType(
    periodType: string
  ): { contractId: string; expiryName: string }[] {
    return findAllContractsForPeriodTypeInChart(periodType, this.props.chart.contractMatrix[this.props.chart.instrumentId]);
  }

  _getContractId(periodType: string): string {
    const contracts = this.findAllContractsForPeriodType(periodType);
    return contracts.length > 0 ? contracts[0].contractId : findNextContractIdInChart(this.props.chart, this.props.chart.contractMatrix);
  }

  informUser(notification: string, level: LogLevel) {
    store.dispatch(log(notification, level));
  }

  onContractNoLongerActive(contracts: { contractId: string; expiryName: string; }[]) {
    const { chart } = this.props;
    this.informUser(
      I18n.t('charts.noLongerActive'),
      LogLevel.WARN
    );
    return this.props.onChartSetContract(chart.id, contracts[0].contractId);
  }

  /**
   * Increase or decrease the currently selected period
   * @param numberOfPeriods number to be changed
   * @param increasePeriod increase or decrease period
   */
  onChartPeriodChange(numberOfPeriods: number, increasePeriod: boolean, dockId: string) {
    const { chart } = this.props;
    const contracts = findAllContractsForPeriodType(chart, chart.selectedPeriodType);
    if (contracts) {
      let currentContractIndex = contracts.map(con => con.contractId).indexOf(chart.contractId);
      if (currentContractIndex < 0) { // contract no longer active => select first active contract
        return this.onContractNoLongerActive(contracts);
      }
      let wantedContractIndex;
      if (increasePeriod) {
        wantedContractIndex = currentContractIndex + +numberOfPeriods;
        currentContractIndex = Math.min(wantedContractIndex, contracts.length - 1);
      } else {
        wantedContractIndex = currentContractIndex - numberOfPeriods;
        currentContractIndex = Math.max(wantedContractIndex, 0);
      }
      const contract = contracts[currentContractIndex];
      if (!contract || wantedContractIndex !== currentContractIndex) { // contract can't be further changed because it's not yet/no longer active
        this.informUser(
            I18n.t('charts.noMoreActiveContracts'),
            LogLevel.WARN
        );
      }
      if (contract) {
        this.props.onChartSetContract(chart.id, contract.contractId, dockId);
      }
    }
  }

  render() {
    const { chart, siblings, drawings } = this.props;
    const { contracts } = this.state;
    return (
      <div className="m_chart">
        <div className="m_chart__header">
          <div className="d-flex">
            <h3>{contracts[chart.contractId] ? contracts[chart.contractId].name  : chart.contractId}</h3>
          </div>
        </div>
        <div className="m_chart__content">
          <ContractChart
            chart={chart}
            siblings={siblings}
            chartSetGroupTypes={this.props.onChartSetGroupTypes}
            onPeriodChange={this.onChartPeriodChange}
            onContractChange={this.props.onChartSetContract}
            drawings={drawings}
          />
        </div>
      </div>
    );
  }
}

interface ChartBookViewProps {
  dockId: string;
  dockType: DockType;
  charts: Chart[];
  instrumentIds: string[];
  favoriteName: string;
  modificationAllowed: boolean;
  favorites: IFavorite[];
  drawings: any;
}
interface ChartBookViewState {}

export default class UIChartBookView extends React.Component<
  ChartBookViewProps,
  ChartBookViewState
> {
  updateInterval = undefined;
  constructor(props: ChartBookViewProps) {
    super(props);

    this._setPeriodType = this._setPeriodType.bind(this);
    this._remove = this._remove.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  _setPeriodType(chartId: string, periodType: string, dockId: string) {
    store.dispatch(setPeriodType(chartId, periodType, dockId));
  }

  _setContractId(chartId: string, contractId: string, dockId?: string, ohlcPeriod?: OhlcPeriod) {
    store.dispatch(setContractId(chartId, contractId, dockId, ohlcPeriod));
  }

  _setContractGroupTypes(
    chartId: string,
    groupTypes: string[],
    dockId: string
  ) {
    store.dispatch(setGroupTypes(chartId, groupTypes, dockId));
  }

  _remove(chartId: string, dockId: string) {
    const contracts = this.props.charts.filter(c => c.id === chartId).map(c => c.contractId);
    store.dispatch(remove(chartId, dockId, contracts));
  }

  render() {
    const {
      dockId,
      dockType,
      charts,
      favoriteName,
      modificationAllowed,
      favorites,
      drawings
    } = this.props;

    const periodTypes = gedPeriodTypesForCharts(orderBookStore.getState(), dockId);
    const marketActions = (
      <div className="market__actions ml-auto">
            <ChartActions
              dockId={dockId}
              dockType={dockType}
              charts={charts}
              periodTypes={periodTypes}
              favoriteName={favoriteName}
              handlePeriodTypeChange={(chartId: string, periodType: string) =>
                this._setPeriodType(chartId, periodType, dockId)
              }
              handleContractChange={(chartId: string, contractId: string) =>
                this._setContractId(chartId, contractId, dockId)
              }
              findAllContractsForPeriodType={findAllContractsForPeriodType}
              modificationAllowed={modificationAllowed}
              favorites={favorites}
            />
          </div>
    );
    
    const tradeCharts = charts.reduce(
      (acc: any, chart: Chart, index: number) => {
        return acc.concat(
          <ChartBlock
            key={chart.id + '-' + index}
            chart={chart}
            siblings={charts.length}
            periodTypes={periodTypes[chart.instrumentId]}
            onChartSetPeriodType={(chartId: string, periodType: string) =>
              this._setPeriodType(chartId, periodType, dockId)
            }
            onChartSetContract={(chartId: string, contractId: string, dockId: string, ohlcPeriod?: OhlcPeriod) =>
              this._setContractId(chartId, contractId, dockId, ohlcPeriod)
            }
            onChartSetGroupTypes={(chartId: string, groupTypes: string[]) => {
              this._setContractGroupTypes(chartId, groupTypes, dockId);
            }}
            drawings={drawings}
          />
        );
      },
      []
    );
    return (
      <React.Fragment>
        <div key="trades-charts" className="chartbook h-100">
          {tradeCharts}
        </div>
        {marketActions}
          
        </React.Fragment>
    );
  }
}
