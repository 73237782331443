import * as React from 'react';
import { openOrderForm } from '../actions/orderForm';
import OrderFormData, { OrderFormMode } from '../models/formData';
import store from '../../main/store/store';
import { getOrderEntities } from '../selectors/orders';
import UIMarketContextMenu from '../../shared/ui/components/market/components/uiMarketContextMenu';
import { MarketActionType } from '../../shared/ui/models/market';
import { isMatchPriceFeedOrdersEnabled, isAnonymizeMarketDataEnabled, isMatchOnlyModeEnabled } from '../../requests/selectors/requests';
import { createModifyOrderFormDataFromOrder, createMatchOrderFormDataFromOrder, createDeleteOrderFormDataFromOrder } from '../helper/orders';
import { config } from '../../main/config';

export const MeetOrderTableRowPopover = (params: any) => {
    const { data, hidePopover, visible, position } = params;
    if (!data || !data.id) {
        return (<div>Error occurred</div>);
    }

    const order = getOrderEntities(store.getState())[data.id];

    const matchPriceFeedOrdersEnabled = isMatchPriceFeedOrdersEnabled(store.getState());

    const anonymizeMarketDataEnabled = isAnonymizeMarketDataEnabled(store.getState());

    const matchOnlyModeEnabled = isMatchOnlyModeEnabled(store.getState());

    const openForm = (formData: OrderFormData | null) => {
        if (formData) {
            store.dispatch(openOrderForm(formData));
        }
        hidePopover();
    };

    const onActionSelect = (e: any, type: MarketActionType) => {
        if (data) {
            switch (type) {
                case MarketActionType.MODIFY_ORDER:
                    openForm(createModifyOrderFormDataFromOrder(order));
                    break;
                case MarketActionType.CANCEL_ORDER:
                    openForm(createDeleteOrderFormDataFromOrder(order));
                    break;
                    case MarketActionType.NEW_ORDER:
                    openForm({
                        orderId: 0,
                        contractId: data.contract,
                        mode: OrderFormMode.ENTER,
                        buy: order.buy,
                        price: data.limit,
                        quantity: data.remainingQuantity,
                        restriction: data.restriction,
                        revisionNo: 0,
                        seenOrderToBeModified: null,
                        associatedContracts: []
                    });
                    break;
                    case MarketActionType.MATCH_ORDER:
                    openForm(createMatchOrderFormDataFromOrder(order, matchPriceFeedOrdersEnabled, anonymizeMarketDataEnabled, matchOnlyModeEnabled));
                    break;
                default:
                    break;
            }
        }
    };
    const readonly: boolean = config.readonlyMarketsheet;

    const required: { [x: string]: boolean } = {};
    if (!matchOnlyModeEnabled) {
        required[MarketActionType.NEW_ORDER] = !readonly;
    }
    
    required[MarketActionType.MODIFY_ORDER] = !readonly;
    required[MarketActionType.CANCEL_ORDER] = !readonly;

    return ( visible && !readonly ? (
        <UIMarketContextMenu
            position={position}
            isOpen={visible}
            onAction={onActionSelect}
            dismiss={hidePopover}
            requiredActions={required}
            optinalActions={[]}
        />
    ) : null);
};