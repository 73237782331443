import '../../shared/utils/wdyr';
import { connect } from 'react-redux';
import { State } from '../reducers/rootReducer';
import MainContainer from '../components/MainContainer';
import { getAuthorizedStatus } from '../../authentication/selectors/authetication';
import { getLocale } from '../../shared/i18n/selectors/translations';
import { getConnectedStatus } from '../../authentication/selectors/connection';
import { sendLogToServer } from '../../shared/logger/actions/logger';

const mapStateToProps = (state: State) => ({
    isAuthorized: getAuthorizedStatus(state),
    isConnected: getConnectedStatus(state),
    locale: getLocale(state)
});

const mapDispatchToProps = {
    sendLogToServer: sendLogToServer
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);