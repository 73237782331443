import * as React from 'react';
import { Chart } from '../../../models/chart';
import { Translate, I18n } from 'react-redux-i18n';
import MarketChart from '../../../../chart/containers/MarketChart';
import { config } from '../../../../../main/config';
import { OhlcPeriod } from '../../../../../orderbook/models/charts';
import { MemoTranslate } from '../../../../i18n/components/memoTranslate';
import { Provider } from 'react-redux';
import orderBookStore from '../../../../../orderbook/store/orderbooks';

const PriceCheckbox = ({
  type,
  chartId,
  groupTypes,
  onChangeGroupType
}: {
  type: string;
  chartId: string;
  groupTypes: string[];
  onChangeGroupType: (e: any) => void;
}) => {
  return (
    <div className="form-check d-flex align-self-center">
      <input
        name={type}
        id={`${chartId}_${type}`}
        type="checkbox"
        checked={groupTypes.indexOf(type) > -1}
        onChange={onChangeGroupType}
        className="horizontal"
      />
      <label 
        htmlFor={`${chartId}_${type}`}
        data-toggle="tooltip"
        title={I18n.t(`charts.${type}`)}
      >
        <Translate value={`charts.short.${type}`} />
      </label>
    </div>
  );
};

interface ContractChartProps {
  chart: Chart;
  siblings: number;
  chartSetGroupTypes: (chartId: string, groupTypes: string[]) => void;
  onPeriodChange: (numberOfPeriods: number, increasePeriod: boolean, dockId: string) => void;
  onContractChange: (chartId: string, contractId: string, dockId: string, ohlcPeriod: OhlcPeriod) => void;
  drawings: {[contractName: string]: any};
}

interface ContractChartState {
  increasePeriod: boolean;
}

export default class ContractChart extends React.Component<
  ContractChartProps,
  ContractChartState
> {
  constructor(props: ContractChartProps) {
    super(props);
    this.changeGroupType = this.changeGroupType.bind(this);
    this.onChangeContractPeriod = this.onChangeContractPeriod.bind(this);
    this.state = {
      increasePeriod: true
    };
  }

  changeGroupType(event: any) {
    const { chart } = this.props;
    const item = event.target.name;
    const isChecked = event.target.checked;
    const newGroupTypes = isChecked
      ? chart.groupTypes.concat([item])
      : chart.groupTypes.filter(name => name !== item);
    this.props.chartSetGroupTypes(chart.id, newGroupTypes);
  }

  toggleIncreasePeriod(increasePeriodToggled: boolean) {
    this.setState({
      ...this.state,
      increasePeriod: increasePeriodToggled
    });
  }

  /**
   * Trigger increasing or decreasing of periods with specified number. See MEET-673.
   * @param e 
   */
  onChangeContractPeriod(e: any) {
    let numberOfPeriods = e.target.textContent;
    this.props.onPeriodChange(numberOfPeriods, this.state.increasePeriod, this.props.chart.dockId);
  }

  setZoom = (period: number) => {
    this.props.onContractChange(this.props.chart.id, this.props.chart.contractId, this.props.chart.dockId, period);
  }

  render() {
    const { chart, siblings, drawings } = this.props;
    return (
      <React.Fragment>
        <Provider store={orderBookStore}>
          <MarketChart
            dockId={chart.dockId}
            groupTypes={chart.groupTypes}
            siblings={siblings}
            chart={chart}
            drawings={drawings}
          /> 
        </Provider>
      </React.Fragment>
    );
  }
}
