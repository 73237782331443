import { StompService, StompClient } from '../../main/services/stompService';
import { InquireInstrumentsRequest } from '../models/instruments';
import { Observable, of } from 'rxjs';
import RestService from '../../main/services/restService';

export default class InstrumentService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  // unused
  public sendInstrumentsInquiry(
    message: InquireInstrumentsRequest
  ): Observable<any> {
    return of(
      this.sendMessage('/app/im/instruments', JSON.stringify(message))
    );
  }

  // unused
  public subscribeInstruments(): Observable<any> {
    return this.subscribe('/user/topic/im/instruments');
  }

  public subscribeInstrumentsMap(): Observable<any> {
    return this.restService.restFetch('/data/instruments');
  }
}
