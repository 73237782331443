import { v1 } from 'uuid';
import * as ViewAActions from '../actions/profile';
import { ViewStatus } from '../models/profile';
import * as Connection from '../../authentication/actions/connection';

export interface State {
  views: { viewId: string; name: string; default: boolean; }[];
  activeViewId: string | null;
  defaultView: boolean;
  error: any;
  visibleViewIds: string[];
  viewStatus: ViewStatus;
  serverProfileVersion: string;
}

export const initialState: State = {
  views: [],
  activeViewId: v1(),
  defaultView: true,
  error: null,
  visibleViewIds: [],
  viewStatus: ViewStatus.UNITIALIZED,
  serverProfileVersion: 'unset'
};

export function reducer(
  state: State = initialState,
  action: ViewAActions.Action | Connection.Action
) {
  switch (action.type) {
    case ViewAActions.ActionTypes.LOAD: {
      return {
        ...state,
        viewStatus: ViewStatus.REQUESTED
      };
    }

    case ViewAActions.ActionTypes.LOAD_SUCCESS: {
      const view = action.payload;
      return {
        ...state,
        activeViewId: view.viewId,
        defaultView: view.defaultView,
        viewStatus: ViewStatus.LOADED
      };
    }

    case ViewAActions.ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        viewStatus: ViewStatus.LOADED
      };
    }

    case ViewAActions.ActionTypes.LOAD_AVAILABLE_VIEWS: {
      const views = action.payload;
      if (!views) {
        return state;
      }
      const activeView = views.find(v => v.default);

      return {
        ...state,
        activeViewId: (activeView && activeView.viewId) || state.activeViewId,
        defaultView: true,
        views: views,
      };
    }

    case ViewAActions.ActionTypes.REMOVE: {
      const viewId = action.payload;
      return {
        ...state,
        views: state.views.filter(view => view.viewId !== viewId),
        visibleViewIds: state.visibleViewIds.filter(viewId2 => viewId !== viewId2)
      };
    }

    case ViewAActions.ActionTypes.TOGGLE_VISIBILITY: {
      const { viewId, isVisible } = action.payload;
      const newVisibleViewIds = isVisible ? [...state.visibleViewIds, viewId] : state.visibleViewIds.filter(id => id !== viewId);
      return {
        ...state,
        visibleViewIds: newVisibleViewIds
      };
    }

    case ViewAActions.ActionTypes.LOAD_VISIBLE_VIEWS: {
      return {
        ...state,
        visibleViewIds: action.payload
      };
    }

    case ViewAActions.ActionTypes.RESET_ACTIVE_VIEW: {
      return {
        ...state,
        activeViewId: null
      };
    }

    case ViewAActions.ActionTypes.SET_PROFILE_VERSION: {
      return {
        ...state,
        serverProfileVersion: action.payload
      };
    }

    case Connection.ActionTypes.RECONNECTION_SUCCESS: {
      let status = ViewStatus.UNITIALIZED;
      // user already requested a view - inital view was already loaded
      if (state.viewStatus === ViewStatus.REQUESTED) {
        status = ViewStatus.LOADED;
      } else if (state.viewStatus === ViewStatus.LOADED) {
        status = ViewStatus.LOADED;
      } else if (state.viewStatus === ViewStatus.UNITIALIZED) {
        status = ViewStatus.UNITIALIZED;
      }

      return {
        ...state,
        viewStatus: status
      };
    }

    default:
      return state;
  }
}
