import * as Connection from '../actions/connection';
import * as Authentication from '../actions/authentication';

export interface State {
    connected: boolean;
    connecting: boolean;
    error: any;
    lastHeartbeat: number;
    attempt: number;
    ssoCodeSent: boolean;
    ssoExpiration: number;
    takeoverConfirmShow: boolean;
}

export const initialState: State = {
    connected: false,
    connecting: false,
    error: null,
    lastHeartbeat: 0,
    attempt: 0,
    ssoCodeSent: false,
    ssoExpiration: 0,
    takeoverConfirmShow: false
};

export function reducer(state: State = initialState, action:
    Connection.Action | Authentication.Action) {
    switch (action.type) {

        case Connection.ActionTypes.CONNECTION_START: {

            return {
                ...state,
                connecting: true,
                connected: false
            };
        }

        case Connection.ActionTypes.CONNECTION_SUCCESS: {

            return {
                ...state,
                connecting: false,
                connected: true,
                error: null,
                lastHeartbeat: new Date().getTime(),
                attempt: 0
            };
        }

        case Connection.ActionTypes.RECONNECTION_SUCCESS: {

            return {
                ...state,
                connecting: false,
                connected: true,
                error: null,
                lastHeartbeat: new Date().getTime(),
                attempt: 0
            };
        }

        case Connection.ActionTypes.CONNECTION_LOST: {
            return {
                ...state,
                connecting: false,
                connected: false,
                error: action.error
            };
        }

        case Connection.ActionTypes.CONNECTION_FAILED: {
            return {
                ...state,
                connecting: false,
                connected: false,
                error: action.error
            };
        }

        case Connection.ActionTypes.CONNECTION_PROBLEM: {
            return {
                ...state,
                connecting: false,
                connected: false,
                error: action.error
            };
        }

        case Connection.ActionTypes.CONNECTED_AFTER_PROBLEM: {
            return {
                ...state,
                connecting: false,
                connected: true,
                error: ''
            };
        }

        case Connection.ActionTypes.DISCONNECT: {
            return {
                ...state,
                reconnectionAdepts: 0,
                connected: false,
                lastHeartbeat: 0,
                attempt: 0
            };
        }

        case Authentication.ActionTypes.AUTHENTICATION_VERIFY: {
            return {
                ...state,
                connecting: true,
                attempt: state.attempt + 1
            };
        }

        case Authentication.ActionTypes.AUTHENTICATION_REQUEST: {
            return {
                ...state,
                attempt: state.attempt + 1
            };
        }

        case Authentication.ActionTypes.AUTHENTICATION_LOGOUT: {
            return {
                ...state,
                attempt: 0
            };
        }

        case Connection.ActionTypes.CLEAR_FAILED_MESSAGES: {
            return {
                ...state,
                messageQueue: []
            };
        }

        case Connection.ActionTypes.SSO_CONNECTION_START: {

            return {
                ...state,
                ssoCodeSent: !!action.payload.code,
                ssoExpiration: new Date().getTime() + action.payload.ssoToken?.refreshExpiresIn * 1000
            };
        }

        case Connection.ActionTypes.SSO_EXPIRATION: {
            return {
                ...state,
                ssoExpiration: action.payload.expiration
            };
        }

        case Connection.ActionTypes.TAKEOVER_SHOW: {

            return {
                ...state,
                takeoverConfirmShow: action.payload
            };
        }

        default: 
            return state;
    }
}