import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { load, save, connectDocks, resize } from '../actions/dashboard';
import { create, collideStart, collideEnd, pinStart, pinEnd } from '../../shared/dock/actions/dock';
import { create as tabCreate } from '../../shared/ui/actions/tab';
import { create as marketCreate } from '../../shared/ui/actions/market';
import { create as chartCreate } from '../../shared/ui/actions/chart';
import { getAll, getActive, getAllInactive, getCollidingDocks } from '../selectors/dashboard';

import DashboardPanel from '../components/dashboardPanel';
import { getActiveViewId } from '../selectors/profile';
import { getConnectedStatus } from '../../authentication/selectors/connection';
import { getSidebarIsLocked } from '../../shared/settings/selectors/selector';
import { warn } from '../../shared/logger/actions/logger';
import { isProfileModificationAllowed } from '../../authentication/selectors/authetication';
import { quadrantCollideStart, quadrantCollideStop } from '../actions/quadrants';
import { getCollidingQuadrants, getQuadrantGridActive } from '../selectors/quadrantPanel';

const mapStateToProps = (state: State) => ({
    profileId: getActiveViewId(state),
    docks: getAll(state),
    activeDock: getActive(state),
    inactiveDocks: getAllInactive(state),
    collidingDocks: getCollidingDocks(state),
    collidingQuadrants: getCollidingQuadrants(state),
    enabled: getConnectedStatus(state),
    isSidebarLocked: getSidebarIsLocked(state),
    modificationAllowed: isProfileModificationAllowed(state),
    gridQuadrantsActive: getQuadrantGridActive(state)
});

const mapDispatchToProps = {
    load: load,
    resize: resize,
    dockCreate: create,
    docksSave: save,
    dockCollideStart: collideStart,
    dockCollideEnd: collideEnd,
    quadrantCollideStart: quadrantCollideStart,
    quadrantCollideStop: quadrantCollideStop,
    dockPinStart: pinStart,
    dockPinEnd: pinEnd,
    connectDocks: connectDocks,
    tabCreate: tabCreate,
    marketCreate: marketCreate,
    chartCreate: chartCreate,
    warn: warn
}; 

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(DashboardPanel);