import { StompService, StompClient } from '../../../main/services/stompService';
import { ITableSort, TablePaginatedRequest, ITableSearchTag, CreateCsvRequest, IColumnName, TradeTableRequest } from '../models/table';
import { Observable, of } from 'rxjs';
import { ComponentType } from '../models/component';
import RestService from '../../../main/services/restService';
import { I18n } from 'react-redux-i18n';

export default class TableService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

    public sendPaginatedTableRequest(from: number, sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filter: {[key: string]: boolean}): Observable<any> {
      let request: TablePaginatedRequest = {
        columns: sorting.map((sort: ITableSort) => {
          return {
            column: sort.columnName, 
            sort: <'ASC' | 'DESC'> (sort.direction === 1 ?  'ASC' : 'DESC')
          };
        }),
        from: from,
        type: type,
        id: id,
        tags: this.getTagsIncludingTableSpecificFilters(tags, filter)
      };
      return this.restService.restPost('/data/tables/paginated', request);
      
    }

    public sendTradeTableRequest(limit: number, days: number, sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filter: {[key: string]: boolean}): Observable<any> {
      let request: TradeTableRequest = {
        columns: [],
        from: 0,
        limit: limit,
        days: days,
        type: type,
        id: id,
        tags: this.getTagsIncludingTableSpecificFilters(tags, filter)
      };
      return this.restService.restPost('/data/tables/trade', request);
      
    }

    public sendCreateCsvRequest
    (visibleColumns: IColumnName[], hidden: number[], sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filter: {[key: string]: boolean}): Observable<any> {
      let request: CreateCsvRequest = {
        columnSorting: sorting ? sorting.map((sort: ITableSort) => {
          return {
            column: sort.columnName, 
            sort: <'ASC' | 'DESC'> (sort.direction === 1 ?  'ASC' : 'DESC')
          };
        }) : [],
        type: type,
        id: id,
        tags: this.getTagsIncludingTableSpecificFilters(tags, filter),
        hidden: hidden ? hidden : [],
        visibleColumns: visibleColumns ? visibleColumns : []
      };
      return this.restService.restPostOctetStreamRequest('/data/csv/download', request, I18n.t(`recentAction.${type}`));
    }

    public sendCleanupTableRequest(id?: string): Observable<any> {
        return of(
            this.sendMessage('/app/im/tables/cleanup', JSON.stringify({id: id}))
        );
    }

    private getTagsIncludingTableSpecificFilters(tags: ITableSearchTag[], filter: { [key: string]: boolean }) {
      // send table specific filter to the BE for special filtering (trades of the day)
      const tableFilters = filter ? Object.keys(filter).filter(f => filter[f]) : [];
      let tableTags = tags ? tags.map((tag: ITableSearchTag) => tag.name) : [];
      tableTags = tableTags.concat(tableFilters);
      return tableTags;
    }
  }