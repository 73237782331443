import * as React from 'react';
import { Provider, connect } from 'react-redux';
import orderBookStore from '../../../../../orderbook/store/orderbooks';
import { State } from '../../../../../main/reducers/rootReducer';
import UIBookView from './uiBookComponent';
import {
  getMarketsForDock,
  getMarketHiddenExpiries,
  getMarketExpandedExpiries,
  getMarketExpiryRowsLength,
  isMarketHeadlinesVisible,
  getVisibleColumnsCount
} from '../../../selectors/ui';
import { DashboardComponent } from '../../../../../dashboard/models/dashboard';
import { IMarket } from '../../../models/market';
import { getColumnWidth, getDockFavoriteName, getDockSize } from '../../../../../dashboard/selectors/dashboard';
import { getIsBorderTableActive, getFontColorsForMarket, getIsSeparateCellsActive } from '../../../../settings/selectors/selector';
import { isMatchPriceFeedOrdersEnabled, isAnonymizeMarketDataEnabled, isMatchOnlyModeEnabled, isQuoteRequestEnabled } from '../../../../../requests/selectors/requests';
import { getUserPermissions, isProfileModificationAllowed, getUser } from '../../../../../authentication/selectors/authetication';
import { getGeneralSettings } from 'src/js/shared/settings/selectors/settings';

interface MarketViewProps {
  dockId: string;
  markets: IMarket[];
  hiddenExpiries: string[];
  expandedExpiries: { [key: string]: any };
  expiryRowsLength: { [key: string]: number };
  favoriteName: string;
  isTableBorderActive: boolean;
  isSeparateCells: boolean;
  onCreateChart: (e: any, component: DashboardComponent) => void;
  matchPriceFeedOrdersEnabled: boolean;
  anonymizeMarketDataEnabled: boolean;
  matchOnlyModeEnabled: boolean;
  colors: { [key: string]: string };
  isHeadlinesVisible: boolean;
  permissions: string[];
  quoteRequestsEnabled: boolean;
  modificationAllowed: boolean;
  user: any;
  columnWidth: string;
  dockSize: {width: number; height: number, top: number; left: number};
  visibleColumnCount: number;
  settings: { [key: string]: any };
}
interface BookViewState {}

export class UIMarketView extends React.Component<
  MarketViewProps,
  BookViewState
> {
  constructor(props: MarketViewProps) {
    super(props);
  }
  
  render() {
    const {
      dockId,
      markets,
      hiddenExpiries,
      expandedExpiries,
      expiryRowsLength,
      favoriteName,
      isTableBorderActive,
      isSeparateCells,
      colors
    } = this.props;
    return (
      <Provider store={orderBookStore}>
        <UIBookView
          dockId={dockId}
          markets={markets}
          hiddenExpiries={hiddenExpiries}
          expandedExpiries={expandedExpiries}
          expiryRowsLength={expiryRowsLength}
          favoriteName={favoriteName}
          isTableBorderActive={isTableBorderActive}
          isSeparateCells={isSeparateCells}
          onCreateChart={this.props.onCreateChart}
          matchPriceFeedOrdersEnabled={this.props.matchPriceFeedOrdersEnabled}
          anonymizeMarketDataEnabled={this.props.anonymizeMarketDataEnabled}
          matchOnlyModeEnabled={this.props.matchOnlyModeEnabled}
          colors={colors}
          isHeadlinesVisible={this.props.isHeadlinesVisible}
          permissions={this.props.permissions}
          quoteRequestsEnabled={this.props.quoteRequestsEnabled}
          modificationAllowed={this.props.modificationAllowed}
          user={this.props.user}
          columnWidth={this.props.columnWidth}
          dockSize={this.props.dockSize}
          columnCount={this.props.visibleColumnCount}
          settings={this.props.settings}
        />
      </Provider>
    );
  }
}

const mapStateToProps = (state: State, props: MarketViewProps) => ({
  markets: getMarketsForDock(state, props.dockId),
  hiddenExpiries: getMarketHiddenExpiries(state, props.dockId),
  expandedExpiries: getMarketExpandedExpiries(state, props.dockId),
  expiryRowsLength: getMarketExpiryRowsLength(state, props.dockId),
  favoriteName: getDockFavoriteName(state, props.dockId),
  isTableBorderActive: getIsBorderTableActive(state),
  isSeparateCells: getIsSeparateCellsActive(state),
  matchPriceFeedOrdersEnabled: isMatchPriceFeedOrdersEnabled(state),
  anonymizeMarketDataEnabled: isAnonymizeMarketDataEnabled(state),
  matchOnlyModeEnabled: isMatchOnlyModeEnabled(state),
  quoteRequestsEnabled: isQuoteRequestEnabled(state),
  permissions: getUserPermissions(state),
  colors: getFontColorsForMarket(state),
  isHeadlinesVisible: isMarketHeadlinesVisible(state, props.dockId),
  modificationAllowed: isProfileModificationAllowed(state),
  user: getUser(state),
  columnWidth: getColumnWidth(state, props.dockId),
  dockSize: getDockSize(state, props.dockId),
  visibleColumnCount: getVisibleColumnsCount(state, props.dockId),
  settings: getGeneralSettings(state)
});

const mapDispatchToProps = {};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UIMarketView);
