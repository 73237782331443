import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../../main/reducers/rootReducer';

import Tabs from '../../../../tabs/components/tabs';
import TabPane from '../../../../tabs/components/tabPane';
import { ITab } from '../../../models/tab';
import { getFilteredTabsForDock } from '../../../selectors/ui';
import TradeTableEnhanced from '../../../../../trade/components/TradeTableEnhanced';
import RequestsTable from '../../../../../requests/containers/RequestsTable';
import OrdersTable from '../../../../../orders/containers/OrdersTable';
import { ComponentType } from '../../../models/component';
import { I18n } from 'react-redux-i18n';
import { getLocale } from '../../../../i18n/selectors/translations';
import LogsTable from '../../../../logger/containers/logsTable';
import TradeReportsTable from '../../../../../trade/containers/TradeReportsTable';
import PriceAlarmOverview from '../../../../../priceAlarm/components/PriceAlarmOverview';
import { config } from '../../../../../main/config';
import TradesTable from 'src/js/trade/containers/TradesTable';
import OwnTradesTable from 'src/js/trade/containers/OwnTradesTable';

interface TabViewProps {
  dockId: string;
  tabs: ITab[];
  locale: string;
  onTabDragComplete: (e: any, title: string) => void;
  filteredTabTypes: ComponentType[];
}
interface TabViewState { }

type ComponentTypeOptions = {
  [key in ComponentType]: any;
};

const Components: ComponentTypeOptions = {
  Order: OrdersTable,
  Trade: config.useDB ? TradeTableEnhanced : TradesTable,
  Owntrade: config.useDB ? TradeTableEnhanced : OwnTradesTable,
  Request: RequestsTable,
  Log: LogsTable,
  TradeReport: TradeReportsTable,
  PriceAlarm: PriceAlarmOverview,
  Instrument: null,
  InstrumentIntraday: null,
  Product: null,
  MarketChart: null,
  BulkError: null,
  BulkOrder: null
};

export class UITabView extends React.Component<TabViewProps, TabViewState> {
  _createComponent(id: string, type: ComponentType, props: any) {
    const component = Components[type];
    return React.createElement(component, {
      key: id,
      parentId: id,
      type: type,
      ...props
    });
  }

  render() {
    const { tabs, dockId } = this.props;
    const tabsOrdered = [...tabs];
    tabsOrdered.sort((tabA, tabB) => tabA.meta.ordering - tabB.meta.ordering);
    const components = tabsOrdered.map(tab => {
      return React.createElement(TabPane, {
        key: tab.id,
        tabId: tab.id,
        dockId: tab.dockId,
        type: tab.type,
        title: I18n.t(tab.title),
        displayActions: tab.displayActions,
        displayFilter: tab.meta.displayFilter,
        displaySearch: tab.meta.displaySearch,
        onDragComplete: this.props.onTabDragComplete,
        isActive: tab.meta.isActive,
        children: this._createComponent(tab.id, tab.type, {
          displayFilter: tab.meta.displayFilter,
          displaySearch: tab.meta.displaySearch,
          displayActions: tab.displayActions,
          isActive: tab.meta.isActive
        })
      });
    });

    if (!components.length) {
      return null;
    }
    let indexActiveTab = tabsOrdered.findIndex(t => t.meta.isActive);
    if (indexActiveTab < 0) {
      indexActiveTab = 0;
    }
    return <Tabs dockId={dockId} defaultActiveTabIndex={tabs.length === 0 ? -1 : indexActiveTab}>{components}</Tabs>;
  }
}

const mapStateToProps = (state: State, props: any) => ({
  tabs: getFilteredTabsForDock(state, props.dockId, props.filteredTabTypes),
  locale: getLocale(state)
});

const mapDispatchToProps = {};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITabView);
