import * as React from 'react';

import { Translate, Localize, I18n } from 'react-redux-i18n';
import * as Orderbooks from '../../../../../orderbook/models/orderbooks';
import { PeriodType } from '../../../../../orderbook/models/contracts';
import { getPathIfNotFindNatively, pushDockLower, pullDockHigher } from '../../../../utils/helper/eventUtils';
import UIDepthPopover from './uiDepthPopover';
import { Provider } from 'react-redux';
import { store } from '../../../../../main/store/store';
import { hashFn } from '../../../../utils/selectors/agrSelector';
import {useMemo, useRef} from "react";

const ExpandedEntry = ({ name, count, expiryType, compactColumnsEnabled }: { name: string; count: number, expiryType: string; compactColumnsEnabled: boolean }) => {
  if (count < 1) {
    return null;
  }
  let rows = [];
  for (let i = 1; i <= count - 1; i++) {
    rows.push(
      <tr key={'empty-row-expiry' + i}>
        <td className={`${compactColumnsEnabled ? ' compact' : ''}`} />
      </tr>
    );
  }
  return (
    <table className="inner">
      <tbody>
        <tr>
          <td className={`${compactColumnsEnabled ? ' compact' : ''}`} title={`${expiryType} ${name}`}>
            <label>{name}</label>
          </td>
        </tr>
        {rows}
      </tbody>
    </table>
  );
};

interface ExpiryTableRowProperties {
  dockSize: {width: number; height: number, top: number; left: number};
  row: {code: string; depth: number; name: string; periodType: PeriodType};
  expanded: boolean;
  presetDepth: number;
  compactColumnsEnabled: boolean;
  expiryTitle: string;
  onExpiryClick: (row: any) => void;
  onRightClick: (e: any, row: any) => void;
}

function ExpiryTableRowComponent (props: ExpiryTableRowProperties) {
  const {row, expanded,
    presetDepth, compactColumnsEnabled, onExpiryClick, onRightClick,
    expiryTitle
  } = props;
  const ref = useRef<HTMLTableRowElement>();
  const savedDepth = useRef(row.depth);
  const isVisible = (dockSize, elementRef) => {
    return props.dockSize === undefined
        || elementRef.current?.offsetTop > dockSize.top && elementRef.current?.offsetTop < (dockSize.top + dockSize.height);
  }

  const carretDirection = expanded ? 'up' : 'down';
  let depth = row.depth;
  if (presetDepth && presetDepth < depth) {
    depth = presetDepth;
  }

  useMemo(() => {
    if (isVisible(props.dockSize, ref)) {
      savedDepth.current = depth;
    }

  }, [depth, ref, props.dockSize]);

  return  (
    <tr key={row.code} ref={ref}>
      <td className={`expiry-${row.periodType} expiryType`}><label>{expiryTitle}</label></td>
      <td
          className={`expiry-${row.periodType} parentTd${compactColumnsEnabled ? ' compact' : ''}`}
          onClick={() => savedDepth.current > 1 && onExpiryClick(row)}
          onContextMenu={(e) => onRightClick(e, row.code)}
          title={`${row.periodType} ${row.name}`}
      >
        {savedDepth.current > 1 && expanded ? (
            <ExpandedEntry name={row.name} count={savedDepth.current} expiryType={row.periodType} compactColumnsEnabled={compactColumnsEnabled}/>
        ) : (
            <label>{row.name}</label>
        )}
        {row.depth > 1 && (
            <div className={`carret ml-auto pr-3 ${carretDirection}`}>
              <div className="bar" />
              <div className="bar" />
            </div>
        )}
        <div key="expiryLayer" className={`layer expiry-${row.periodType}`} />
      </td>
    </tr>);
}
const ExpiryTableRow = React.memo(ExpiryTableRowComponent, function arePropsEquals(prevProps, nextProps) {
  const result = prevProps.row?.depth === nextProps.row?.depth
      && prevProps.presetDepth === nextProps.presetDepth
      && prevProps.expanded === nextProps.expanded
      && prevProps.dockSize?.top === nextProps.dockSize?.top
      && prevProps.dockSize?.left === nextProps.dockSize?.left
      && prevProps.dockSize?.height === nextProps.dockSize?.height
      && prevProps.dockSize?.width === nextProps.dockSize?.width;
  return result
});

interface UIMarketExpiryTableProps {
  dockId: string;
  data: any;
  expandedExpiries: { [key: string]: any };
  isTableBorderActive: boolean;
  onExpiryClick: (expiry: any) => void;
  isHeadlinesVisible: boolean;
  presetDepths: { [expiryCode: string]: number };
  compactColumnsEnabled: boolean;
  dockSize: {width: number; height: number, top: number; left: number};
}
interface UIMarketExpiryTableState {
  openExpiryCodes: string[];
  depthPopover: {
    position: {
      x: number,
      y: number
    },
    show: boolean,
    expiryCode: string
  };
}

export class UIMarketExpiryTableComponent extends React.Component<
  UIMarketExpiryTableProps,
  UIMarketExpiryTableState
> {
  constructor(props: UIMarketExpiryTableProps) {
    super(props);

    this.state = {
      openExpiryCodes: [],
      depthPopover: {
        position: { x: 0, y: 0 },
        show: false,
        expiryCode: ''
      }
    };

    this.onExpiryClick = this.onExpiryClick.bind(this);
    this.onRightClick = this.onRightClick.bind(this);
  }

  onExpiryClick(expiry: any) {
    this.props.onExpiryClick(expiry);
  }

  shouldComponentUpdate(nextProps: UIMarketExpiryTableProps, nextState: UIMarketExpiryTableState) {
    return !areEqual(this.props, nextProps, this.state, nextState);
  }

  onRightClick(event: any, expiryCode: string) {
    event.preventDefault();
    event.persist();
    const nativeEvent = event.nativeEvent;
    const eventPath =
      (nativeEvent.composedPath && nativeEvent.composedPath()) ||
      nativeEvent.path ||
      getPathIfNotFindNatively(nativeEvent);
    const parentTd = eventPath.find((el: Element) =>
      el.classList.contains('parentTd')
    );
    const target = !!parentTd ? parentTd : event.currentTarget;
    const dockTarget = eventPath.find((el: any) =>
      el.className.split(' ').includes('react-resizable')
    );
    let contextOffsetLeft = target.offsetLeft + target.clientWidth;
    if (contextOffsetLeft > dockTarget.clientWidth) {
      contextOffsetLeft = dockTarget.clientWidth - 2;
    }
    const contextOffsetTop = (!!dockTarget
      ? target.offsetTop - dockTarget.scrollTop
      : target.offsetTop);
    const offsetTop = contextOffsetTop;
    const topBoundaryCheck = dockTarget.getBoundingClientRect()['top'] + offsetTop < 0 ? -offsetTop : 0;
    const position = { x: contextOffsetLeft, y: offsetTop + topBoundaryCheck };
    pullDockHigher(eventPath);
    this.setState((prevState) => {
      return {
        ...prevState,
        depthPopover: {
          position: position,
          show: true,
          expiryCode: expiryCode
        }
      };
    });
  }

  onDismiss = () => {
    pushDockLower();
    this.setState((prevState) => {
      return {
        ...prevState,
        depthPopover: {
          position: { x: 0, y: 0 },
          show: false,
          expiryCode: ''
        }
      };
    });
  }

  render() {
    const { data, expandedExpiries, isTableBorderActive, isHeadlinesVisible, compactColumnsEnabled } = this.props;
    const records: any = [];
    const sortedPeriodTypes = Object.keys(PeriodType);
    const { depthPopover } = this.state;

    const whichPeriodType = (s: string) => sortedPeriodTypes.indexOf(s.replace(/\d+$/, ''));
    const sorted = Object.keys(data).filter(key => data[key].length > 0)
      .sort((e1: string, e2: string) => whichPeriodType(e1) - whichPeriodType(e2));
    sorted
      .forEach((key, index) => {
        let tableBody = [];

        // intraday sheets don't use period types as keys
        let expiryTitle;
        const periodTypeKey = key.replace(/\d+$/, '');
        if (!Orderbooks.periodTypes[periodTypeKey]) {
          expiryTitle = (
            <Localize value={parseInt(key, 10)} dateFormat="date.long" />
          );
        } else {
          expiryTitle = <span>{I18n.t(Orderbooks.periodTypes[periodTypeKey].key)} {key.replace(periodTypeKey, '')}</span>;
        }

        tableBody = data[key].map((row: any) => {
          return <ExpiryTableRow key={row.code}
              dockSize={this.props.dockSize}
              row={row}
              expanded={expandedExpiries[row.code]}
              presetDepth={this.props.presetDepths[row.code]}
              compactColumnsEnabled={this.props.compactColumnsEnabled}
              expiryTitle={expiryTitle}
              onExpiryClick={this.props.onExpiryClick}
              onRightClick={this.onRightClick} />
        });

        records.push(
          <React.Fragment key={key}>
            {index > 0 ? <tbody className="empty-row"><tr><td colSpan={2}>&nbsp;</td></tr></tbody> : null}
            <tbody>{tableBody}</tbody>
          </React.Fragment>
        );
      });

    return (
      <div data-test="orderbook-expiry" className="expiry">
        <div className="expiry-header">
          
        </div>
        <table
          data-test="expiry-table"
          className={`table ${isTableBorderActive ? `with-border ` : ` `}
            ${isHeadlinesVisible ? '' : `hidden-headlines`}
             ${isTableBorderActive ? `with-border ` : ` `}
          `}
        >
          <thead>
            <tr>
              <th colSpan={2}><Translate value="marketsheet.expiry" tag={'label'} /></th>
            </tr>
          </thead>
          {records}
        </table>
        <Provider store={store}>
          <UIDepthPopover
            isOpen={depthPopover.show}
            position={depthPopover.position}
            dockId={this.props.dockId}
            dismiss={this.onDismiss}
            expiryCode={depthPopover.expiryCode}
            depths={this.props.presetDepths}
          />
        </Provider>
      </div>
    );
  }
}

function areEqual(prevProps: UIMarketExpiryTableProps, nextProps: UIMarketExpiryTableProps, 
  prevState: UIMarketExpiryTableState, nextState: UIMarketExpiryTableState) {
  return hashFn(prevProps.data) === hashFn(nextProps.data)
    && hashFn(Object.keys(prevProps.expandedExpiries)) === hashFn(Object.keys(nextProps.expandedExpiries))
    && prevProps.isHeadlinesVisible === nextProps.isHeadlinesVisible
    && prevProps.isTableBorderActive === nextProps.isTableBorderActive
    && hashFn(prevProps.presetDepths) === hashFn(nextProps.presetDepths)
    && hashFn(prevProps.presetDepths) === hashFn(nextProps.presetDepths)
    && prevState.depthPopover.show === nextState.depthPopover.show
    && prevState.depthPopover.position.x === nextState.depthPopover.position.x
    && prevState.depthPopover.position.y === nextState.depthPopover.position.y
    && prevState.depthPopover.expiryCode === nextState.depthPopover.expiryCode
    && prevProps.compactColumnsEnabled === nextProps.compactColumnsEnabled
    && prevProps.dockSize?.top === nextProps.dockSize?.top
    && prevProps.dockSize?.left === nextProps.dockSize?.left
    && prevProps.dockSize?.height === nextProps.dockSize?.height
    && prevProps.dockSize?.width === nextProps.dockSize?.width;;
}

export const UIMarketExpiryTable = UIMarketExpiryTableComponent;