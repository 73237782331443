import * as React from 'react';
import ReactTags from 'react-tag-autocomplete';

interface TableSearchBarProps {
  isAllowed: boolean;
  rows: any[];
  tags: any[];
  searchableColumns: any[];
  onSearchTags: (tags: any[]) => void;
}
interface TableSearchBarState {
  suggestions: { [id: string]: any }[];
}

export default class TableSearchBar extends React.Component<
  TableSearchBarProps,
  TableSearchBarState
> {
  constructor(props: TableSearchBarProps) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);

    this.state = {
      suggestions: []
    };
  }

  componentWillMount() {
    const { rows, tags, searchableColumns } = this.props;
    this.setState({
      ...this.state,
      suggestions: rows.reduce((acc: any, r: any) => {
        if (
          searchableColumns.reduce(
            (result: boolean, c: string) =>
              result ||
              (acc.find((a: any) => a.name === r[c]) &&
                tags.find((t: any) => t.name === r[c])),
            false
          )
        ) {
          return acc;
        } else {
          const suggestionRows = searchableColumns.reduce(
            (suggested: any[], c: string) => [
              ...suggested,
              { id: r.$index, name: r[c] + '' }
            ],
            []
          );
          return [...acc, ...suggestionRows];
        }
      }, [])
    });
  }

  componentWillReceiveProps(newProps: TableSearchBarProps) {
    this.setState({
      ...this.state,
      suggestions: newProps.rows.reduce((acc: any, row: any) => {
        if (
          this.props.searchableColumns.reduce(
            (result: boolean, c: string) =>
              result ||
              (acc.find((a: any) => a.name === row[c]) ||
                !!newProps.tags.find((t: any) => result || t.name === row[c])),
            false
          )
        ) {
          return acc;
        } else {
          const suggestionRows = this.props.searchableColumns.reduce(
            (suggested: any[], c: string) => [
              ...suggested,
              { id: row.$index, name: row[c] + '' }
            ],
            []
          );
          return [...acc, ...suggestionRows];
        }
      }, [])
    });
  }

  handleDelete(i: any) {
    const tags = this.props.tags.slice(0);
    tags.splice(i, 1);
    this.props.onSearchTags(tags);
  }

  handleAddition(tag: any) {
    const tags = [...this.props.tags, tag];
    this.props.onSearchTags(tags);
  }

  render() {
    const { suggestions } = this.state;
    const { tags } = this.props;
    if (!this.props.isAllowed) {
      return null;
    }
    return (
      <div className="search">
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          placeholder={'Search'}
          autoresize={false}
          allowNew={true}
        />
      </div>
    );
  }
}
