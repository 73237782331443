import { Size } from '../../shared/utils/models/grid';
import { ComponentType } from '../../shared/ui/models/component';

export interface IQuadrant {
  id: number;
  size: Size;
  components: ComponentType[];

  setSize(size: Size): void;
}

export class Quadrant implements IQuadrant {
  id: number;
  components: ComponentType[];
  size: Size = { width: 50, height: 50 };
  
  constructor(id: number, components: ComponentType[], size?: Size) {
    this.id = id;
    this.components = components;
    this.size = size ? size : this.size;
  }

  setSize(size: Size) {
    this.size = size;
  }
}
