import { getAuthorizedStatus } from '../selectors/authetication';
import store from '../../main/store/store';
import { StompService, StompClient } from '../../main/services/stompService';
import { map } from 'rxjs/operators';
import {connectionProblem, connectedAfterProblem, connectionStart} from '../actions/connection';
import { Observable } from 'rxjs';
import { getConnectedStatus } from '../selectors/connection';

const stompService = new StompService(StompClient);
/**
 * Send heartbeats using default setInterval function
 */
class HeartbeatSender {
  handler: any;

  start(interval: number): Observable<any> {
    const heartbeatInterval = this.getInterval(interval);
    return Observable.create((observer: any) => {
      this.handler = setInterval(() => {
        queueMicrotask(() => {
          if (getAuthorizedStatus(store.getState())) {
            stompService.sendHeartbeat().pipe(
              map(result => {
                if (!result) {
                  observer.next(connectionProblem('error.Connection to backend failed'));
                  observer.next(connectionStart(store.getState().auth?.loginData));
                } else if (!getConnectedStatus(store.getState())) {
                  observer.next(connectedAfterProblem());
                }
              }
            )).subscribe();
          }
        });
      }, heartbeatInterval);
    });
    
  }
  
  stop() {
    try {
      clearInterval(this.handler);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * the real interval is smaller than the required one to prevent missing heartbeats during heavy load when events in js event queue might be delayed
   */
  getInterval(interval: number) {
    return Math.min(interval / 2, 60000);
  }

}
export const heartbeatSender = new HeartbeatSender();