import { getPriceAlarmFormData } from '../selectors/priceAlarms';
import { getLocale } from '../../shared/i18n/selectors/translations';
import { State } from '../../main/reducers/rootReducer';
import { formatPriceAlarmFormData } from '../../shared/utils/formatters';
import PriceAlarmFormComponent from '../components/PriceAlarmForm';
import { formComponent } from '../../shared/form/components/FormComponent';
import { submitPriceAlarmForm, clearPriceAlarmForm, successPriceAlarm } from '../actions/priceAlarms';
import { handleInvalidInput as invalidInput } from '../../shared/validation/action';
import { connect } from 'react-redux';

const mapStateToProps = (state: State) => {
  return {
    data: getPriceAlarmFormData(state),
    locale: getLocale(state)
  };
};

const mapDispatchToProps = {
  handleSubmit: submitPriceAlarmForm,
  handleClear: clearPriceAlarmForm,
  handleInvalidInput: invalidInput,
  formatFormData: formatPriceAlarmFormData,
  handleSuccess: successPriceAlarm
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(formComponent(PriceAlarmFormComponent));
