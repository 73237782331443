import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { convertRgba2Hex, convertHex2Rgba } from '../../utils/helper/color';
import { config } from '../../../main/config';
import { ColorPicker } from './ColorPicker';

const InputFormGroup = ({ name, values, onChange, onReset }: any) => {
  let specificName = '';
  let translatableName = name;
  if (name.indexOf('counterparty-') === 0) {
    specificName = name.replace(/counterparty\-/, '').toUpperCase();
    translatableName = 'counterparty';
  }
  return (
    <div className="form-group row">
      <label className="col-5 col-form-label">
        <Translate value={`settings.${translatableName}`} /> {specificName}
      </label>
      <div className="col-3">
        <div className="input-group">
          <ColorPicker
            name={name}
            color={values.background}
            onSave={(colorRGBA: string) =>
              onChange(name + '.background', colorRGBA, true)
            }
          />
        </div>
      </div>
      {values.font !== 'none' ? (
        <React.Fragment>
          <div className="col-3">
            <div className="input-group">
              <ColorPicker
                name={name}
                color={values.font}
                onSave={(colorRGBA: string) =>
                  onChange(name + '.font', colorRGBA, true)
                }
              />
            </div>
          </div>
          <div className="col-1" title={I18n.t('settings.reset')}>
            <i
              className="oi oi-action-undo active"
              onClick={(e: any) => onReset(name)}
            />
          </div>
        </React.Fragment>
      ) : name === 'borderColor' ? (
        <React.Fragment>
          <div className="col-3  d-flex justify-content-center ">
            <div className="form-check d-flex align-self-center">
              <input
                id={name}
                name={name}
                type="checkbox"
                checked={values.active === 'active'}
                onChange={e =>
                  onChange(
                    name + '.active',
                    e.target.checked ? 'active' : '',
                    false
                  )
                }
              />
              <label htmlFor={name}>&nbsp;</label>
            </div>
          </div>
          <div className="col-1">
            <i
              className="oi oi-action-undo active"
              onClick={(e: any) => onReset(name)}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="col-3" />
          <div className="col-1">
            <i
              className="oi oi-action-undo active"
              onClick={(e: any) => onReset(name)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export interface Props {
  colors: { [key: string]: { [x: string]: string } };
  onSubmit: (settings: any) => void;
  onChange: (settings: any) => void;
}

export interface State {
  localColors: { [key: string]: { [x: string]: string } };
}

export default class SettingsConfigFormComponent extends React.Component<
  Props,
  State
> {
  ref: any;
  constructor(props: Props) {
    super(props);

    this.ref = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnReset = this.handleOnReset.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.setState({
      localColors: this.props.colors
    });
  }



  handleInputChange(
    target: string,
    value: string | number,
    fromPicker: boolean = false
  ) {
    const currentTarget = target.split('.');
    let newColor = '';
    if (fromPicker) {
      newColor = value as string;
    } else {
      if (currentTarget[2] === 'opacity') {
        const alpha = (value as number) / 100;
        const localColor = convertRgba2Hex(
          this.state.localColors[currentTarget[0]]['background']
        );
        newColor = convertHex2Rgba(localColor.color, alpha);
      } else if (currentTarget[1] === 'background') {
        const val = value as string;
        const localColor = convertRgba2Hex(
          this.state.localColors[currentTarget[0]]['background']
        );
        newColor = convertHex2Rgba(val, localColor.alpha);
      } else if (currentTarget[1] === 'active') {
        newColor = value as string;
      } else {
        const val = value as string;
        newColor = convertHex2Rgba(val, 1);
      }
    }

    this.ref.current.focus();
    const newSettings = {
      ...this.state.localColors,
      [currentTarget[0]]: {
        ...this.state.localColors[currentTarget[0]],
        [currentTarget[1]]: newColor
      }
    };
    this.props.onChange({colors: newSettings});

    this.setState({
      ...this.state,
      localColors: newSettings
    });
  }

  handleOnReset(target: string) {
    const defaultColors: {
      [key: string]: { background: string; font: string };
    } = config.branding.colors;

    const selectedColor = defaultColors[target];
    this.setState({
      ...this.state,
      localColors: {
        ...this.state.localColors,
        [target]: {
          ...this.state.localColors[target],
          ...selectedColor
        }
      }
    });
  }

  onResetAll() {
    const defaultColors: {
      [key: string]: { background: string; font: string };
    } = config.branding.colors;
    this.setState({
      ...this.state,
        localColors: {...defaultColors}
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.props.onSubmit({colors : this.state.localColors});
  }

  handleKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  render() {
    const { localColors } = this.state;
    const inputs = Object.keys(localColors).filter(key => config.branding.colors.hasOwnProperty(key)).map(key => (
      <InputFormGroup
        key={key}
        name={key}
        values={localColors[key]}
        onChange={this.handleInputChange}
        onReset={this.handleOnReset}
      />
    ));
    return (
      <div>
        <form
          id="desing-settings"
          style={{ position: 'relative' }}
          onSubmit={this.handleSubmit}
        >
          <div className="settings-scroll">
            <div className="form-group row">
              <label className="col-sm-5 col-form-label">
                <Translate value="settings.color.elementName" />
              </label>
              <label className="col-sm-3 col-form-label  text-center">
                <Translate value="settings.color.background" />
              </label>
              <label className="col-sm-3 col-form-label  text-center">
                <Translate value="settings.color.font" />
              </label>
              <label className="col-sm-1 col-form-label  text-left" style={{paddingLeft: '0px'}} title={I18n.t('settings.resetAll')}>
                  <i
                    className="oi oi-action-undo active"
                    onClick={(e: any) => this.onResetAll()}
                  />
              </label>
            </div>
            {inputs}
          </div>
          <div className="d-flex">
                  
             
            <button
              className="btn btn-approval w150 ml-auto"
              type="submit"
              ref={this.ref}
              autoFocus={true}
            >
              <Translate value="settings.save" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}
