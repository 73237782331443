import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import {
  ActionTypes as Connection,
  connectionSuccess,
  heartbeatReceived,
  heartbeatSettingsLoadSuccess,
  disconnect,
  closeAndClean,
  connectionProblem,
  connectionFailed,
  takeoverShow
} from '../actions/connection';
import {
  ActionTypes as Authentication,
  loginSuccess,
  loginFailure,
  loginRequest,
  logout,
  imLoginRequest,
  loginVerify,
  reloginSuccess,
  delayLogin
} from '../actions/authentication';
import * as Rx from 'rxjs';

import { getLoginData, getAuthorizedStatus } from '../selectors/authetication';
import { StompService, StompClient } from '../../main/services/stompService';
import { State } from '../../main/reducers/rootReducer';
import {
  HeartbeatSettingsResponse,
  LoadSettingsResponse
} from '../../main/models/application';
import * as Settings from '../../main/actions/settings';
import * as Logger from '../../shared/logger/actions/logger';
import { getReconnectionAttempts, getLastHeartbeat, getAuthError } from '../selectors/connection';
import { config } from '../../main/config';
import { filter, map, catchError, mergeMap, switchMap, takeUntil, flatMap } from 'rxjs/operators';
import connectionStore from '../store/connection';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import history from '../../main/history';
import { heartbeatSender } from '../helper/heartbeatSender';
const stompService = new StompService(StompClient);

export const connection: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_START),
    map(action => action.payload),
    flatMap((data: any) => {
      return stompService.connect(data).pipe(
        map((frame: any) => {
          if (frame.username) {
            stompService.sendMessage('/app/login', JSON.stringify(frame));
            return imLoginRequest(frame);
          } else {
            return connectionSuccess();
          }
        }),
        catchError((error: any) => Rx.of(connectionFailed(error.message || 'Internal server error occures.')))
      );
    }
    )
  );
};

export const successfulConnection: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      if (!config.ssoEnabled) {
        return stompService.subscribe('/user/topic/im/login').pipe(
          map((content: any) => {
            if (content.connected) {
              stompService.authorize();
              return loginSuccess(content);
            }
            return loginFailure(content);
          }),
          catchError(error => {
            return Rx.of(loginFailure(error));
          })/*,
            takeUntil(actions$.pipe(filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS)))*/
        );
      } 
      return Rx.empty();
    })
  );
};

export const sessionTakeoverConfirmation: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
        return stompService.subscribe('/user/topic/im/existingsession').pipe(
          map((content: any) => {
            if (content.otherSessions) {
              return takeoverShow(true);
            }
            return takeoverShow(false);
          }),
          takeUntil(actions$.pipe(filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS)))
        );
     
    })
  );
};

export const sessionTakeoverResponse: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.TAKEOVER_CONFIRM),
    switchMap((content) => {
        stompService.sendConfirmation(content.payload);
        return Rx.empty();
     
    })
  );
};

export const successfulReconnection: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.RECONNECTION_SUCCESS),
    switchMap(() => 
      stompService
      .subscribe('/user/topic/im/login').pipe(
        takeUntil(actions$.pipe(filter(action => action.type === Authentication.RELOGIN_SUCCESS))),
        map((content: any) => {
          if (content.connected) {
            stompService.authorize();
            return reloginSuccess(content.currentUserId);
          }
          return loginFailure(content);
        }),
        catchError(error => {
          return Rx.of(loginFailure(error));
        })
      )
    )
  );
};

export const setLoginDelay: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => 
      stompService
      .subscribe('/user/topic/im/delay').pipe(
        // takeUntil(actions$.pipe(filter(action => action.type === Connection.CONNECTION_SUCCESS))),
        map((content: any) => {
         return delayLogin(content.throttlingDelayInMillis);
         // return Rx.empty();
        })
      )
    )
  );
};

export const settingsLoad: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS || action.type === Authentication.AUTHENTICATION_VERIFY),
    switchMap(() => stompService.receiveSettings().pipe(
      map((content: any) => Settings.loadSettings(content)),
      takeUntil(
        actions$.pipe(filter(action => action.type === Connection.CONNECTION_LOST || action.type === Connection.DISCONNECT))
      )
    ))
  );
};

export const userPermissionsLoad: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS || action.type === Authentication.AUTHENTICATION_VERIFY),
    switchMap(() => stompService.receiveUserPermissions().pipe(
      map((content: any) => Settings.loadClientConfiguration(content.userPermissions)),
      takeUntil(
        actions$.pipe(filter(action => action.type === Connection.CONNECTION_LOST || action.type === Connection.DISCONNECT))
      )
    ))
  );
};

export const receiveUnspecifiedMessages: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS 
      || action.type === Authentication.RELOGIN_SUCCESS),
    mergeMap(() => stompService.receiveUnspecifiedMessages().pipe(
      map((content: any) => Logger.logUnspecifiedMessage(content))
    ))
  );
};

export const immediateDisconnect: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS 
      || action.type === Connection.CONNECTION_START 
      || action.type === Authentication.AUTHENTICATION_VERIFY
    ),
    switchMap(() => {
      return stompService
        .subscribe('/user/topic/disconnect').pipe(
          mergeMap((content: any) => {
            // StompService.client.disconnect();
            if (config.ssoEnabled) {
              // for sso connection set message to be shown on logout screen
              return Rx.concat(
                Rx.of(loginFailure(content.message)),
                Rx.of(disconnect()),
                Rx.of(closeAndClean())
              );
            }
            return Rx.concat(
              Rx.of(disconnect()),
              Rx.of(closeAndClean())
            );
          }),
          takeUntil(
            actions$.pipe(filter(action => action.type === Connection.CONNECTION_LOST 
              || action.type === Connection.DISCONNECT))
          )
        );
    })
  );
};

export const disconnected: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.DISCONNECT),
    switchMap(() => {
      if (!getAuthorizedStatus(state.value)) {
        if (config.ssoEnabled) {
          const authError = getAuthError(state.value);
          stompService.httpLogout().then(() => {
            history.replace('/logout' + (authError ? ('?msg=' + authError) : ''));
          });
        } else {
          stompService.httpLogout().then(() => {
            location.reload();
          });
        }
      } else {
        return Rx.of(logout('error.Disconnect'));
      }
      return Rx.empty();
    })
  );
};

export const reconnection: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_LOST),
    switchMap((content) => {
      return Rx.timer(5000, 5000).pipe(
      takeUntil(actions$.pipe(
        filter(action => action.type === Connection.CONNECTION_SUCCESS
          || action.type === Connection.RECONNECTION_SUCCESS
          || action.type === Authentication.AUTHENTICATION_SUCCESS
          || action.type === Authentication.RELOGIN_SUCCESS
          || action.type === Authentication.AUTHENTICATION_LOGOUT)
      )),
      map(() => {
        const loginData = getLoginData(state.value);
        if (loginData && getReconnectionAttempts(state.value) < config.reconnectionAttempts ) {
          return getAuthorizedStatus(state.value)
              ? loginVerify(loginData)
              : loginRequest(loginData);
        }
        return logout(content.error);
      })
    ); })
  );
};

export const heartbeat: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.HEARTBEAT_SETTINGS),
    map(action => action.payload),
    mergeMap((settings: HeartbeatSettingsResponse) => {
        return Rx.merge(Rx.interval(settings.heartbeatInterval).pipe(
          takeUntil(actions$.pipe(filter(action => action.type === Authentication.AUTHENTICATION_LOGOUT || action.type === Connection.CONNECTION_LOST))),
          mergeMap(() => {
            const now = new Date().getTime();
            if (
              now - getLastHeartbeat(connectionStore.getState()) >
              settings.heartbeatInterval *
                settings.acceptableMissingHeartbeatCount
            ) {
              if (config.ssoEnabled && !getAuthorizedStatus(state.value)) {
                return Rx.of(logout('error.ssoLogin'));
              }
              return Rx.of(logout(
                'Missing heartbeats since ' +
                  new Date(getLastHeartbeat(connectionStore.getState())) + ' (' 
                  + (new Date().getTime() - getLastHeartbeat(connectionStore.getState())) + 'ms)'
              ));
            }
            
            return Rx.empty();
          })
        ),  heartbeatSender.start(settings.heartbeatInterval).pipe(map(action => connectionStore.dispatch(action))));
      }
    )
  );
};

export const heartbeatReceive: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS || action.type === Authentication.RELOGIN_SUCCESS),
    mergeMap(() => stompService.receiveHeartbeats().pipe(
      map((content: any) => {
        connectionStore.dispatch(heartbeatReceived(content));
        return heartbeatReceived(content);
      }
    )))
  );
};

export const connectionMessages: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS || action.type === Authentication.RELOGIN_SUCCESS),
    mergeMap(() => stompService.receiveConnectionMessages().pipe(
      map(() => connectionProblem('error.Connection issues with Internal Market occurred'))
    ))
  );
};

export const loadClientSettings: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Settings.ActionTypes.LOAD_SETTINGS),
    map(action => action.payload),
    mergeMap((content: LoadSettingsResponse) => {
      if (!!content && !!content.settings && !!content.settings.clientJson) {
        return Rx.of(
            Settings.loadClientConfiguration(
              JSON.parse(content.settings.clientJson)
            ),
            Settings.loadConfigurationSuccess()
          );
      }
      return Rx.empty();
    }),
    takeUntil(
      actions$.pipe(filter(action => action.type === Connection.DISCONNECT || action.type === Connection.CONNECTION_LOST))
    ),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const heartbeatSettingsReceive: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS || action.type === Connection.RECONNECTION_SUCCESS),
    switchMap(() => stompService.subscribe('/user/topic/im/heartbeat/settings').pipe(
      map((content: HeartbeatSettingsResponse) => {
        return heartbeatSettingsLoadSuccess(content);
      }),
      takeUntil(
        actions$.pipe(filter(action => action.type === Connection.CONNECTION_LOST || action.type === Connection.DISCONNECT))
      )
    ))
  );
};

export const connectionEpic = combineEpics(
  connection,
  successfulConnection,
  sessionTakeoverConfirmation,
  sessionTakeoverResponse,
  reconnection,
  heartbeat,
  heartbeatReceive,
  heartbeatSettingsReceive,
  immediateDisconnect,
  disconnected,
  settingsLoad,
  loadClientSettings,
  successfulReconnection,
  receiveUnspecifiedMessages,
  connectionMessages,
  setLoginDelay,
  userPermissionsLoad
);
