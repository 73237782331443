import * as React from 'react';
import { IQuadrant } from '../models/analyticsPanel';
import { QuadrantsCheck, setResizers, updateQuardrantsSizes, updateQuardrantsSizesOnMove } from '../helper/helper';

interface ResizerProps {
  quadrants: IQuadrant[];
  axises: {
    x: number;
    y: number;
  };
  activeQuadrants: IQuadrant[];
  isLocked: boolean;
  onQuadrantsResize: (
    quadrants: IQuadrant[],
    axisX: number,
    axisY: number
  ) => void;
  locked?: boolean;
  quadrantLayout: number[];
}

interface ResizerState {
  controlledQuadrants: IQuadrant[];
  isResizing: boolean;
  resizers: {
    x: { position: number; size: number; visible: boolean };
    y: { position: number; size: number; visible: boolean };
  };
}

export function withResizer(WrappedComponent: new (...props: any[]) => React.Component<any, any, any>) {

  const Resizers = ({
    options,
    onResize
  }: {
    options: {
      x: { visible: boolean; position: number; size: number };
      y: { visible: boolean; position: number; size: number };
    };
    onResize(e: any): void;
  }) => {
    const { x, y } = options;
    return (
      <React.Fragment>
        {y.visible ? (
          <div
            style={{ left: `${x.position}%`, top: `${x.size}%`,  height: `calc(${x.size > 0 ? 100 - x.size : 100}% - 10px)`  }}
            className="resizer resizer-y"
            onMouseDown={onResize}
          />
        ) : null}
        {x.visible ? (
          <div
            style={{ top: `calc(${y.position}% - 10px)`, width: `${y.size}%` }}
            className="resizer resizer-x"
            onMouseDown={onResize}
          />
        ) : null}
      </React.Fragment>
    );
  };

  return class extends React.Component<
    ResizerProps & any,
    ResizerState
  > {
    private qc: QuadrantsCheck;
    private ref: any;

    componentWillMount() {
      const { quadrants, axises, activeQuadrants } = this.props;
      this.qc = new QuadrantsCheck(
        activeQuadrants.reduce((acc: any, quadrant: IQuadrant) => {
          return {
            ...acc,
            [quadrant.id]: quadrant
          };
        }, {})
      );
      this.setState({
        ...this.state,
        resizers: setResizers(axises.x, axises.y, this.qc),
        controlledQuadrants: quadrants
      });
    }

    componentWillReceiveProps(newProps: ResizerProps) {
      const {quadrants, axises, quadrantLayout} = newProps;
      const quadrantMap = newProps.activeQuadrants.reduce((acc: any, quadrant) => {
        return {
          ...acc,
          [quadrant.id]: quadrant
        };
      }, {});
      this.qc = new QuadrantsCheck(
        quadrantMap
      );
      
      const quadrantsUpdated = updateQuardrantsSizes(quadrantMap, axises.x, axises.y, quadrantLayout);
      const controlledQuadrants = quadrants.map(q =>  {
        return {
          ...q,
          size: (quadrantsUpdated[q.id]) ? quadrantsUpdated[q.id].size : {width: 0, height: 0}
        }
      });
      this.setState({
        ...this.state,
        resizers: setResizers(newProps.axises.x, newProps.axises.y, this.qc),
        controlledQuadrants: controlledQuadrants
      });
    }

    _onResizeStart(e: any) {
      const { isLocked } = this.props;
      if (!isLocked) {
        document.addEventListener('mousemove', this._onResize, false);
        document.addEventListener('mouseup', this._onResizeEnd, false);
      }
      this.setState((prevState) => {
        return {
          ...prevState,
          isResizing: true
        };
      });
    }

    _onResize(e: any) {
      const height = this.ref.current.offsetHeight / 100;
      const width = this.ref.current.offsetWidth / 100;
      const newX = e.clientX / width;
      const newY = e.clientY / height;
      if (newX >= 25 && newX <= 75 && (newY >= 25 && newY <= 75)) {
        this.setState({
          ...this.state,
          resizers: setResizers(newX, newY, this.qc),
          controlledQuadrants: updateQuardrantsSizesOnMove(
            this.state.controlledQuadrants,
            newX,
            newY,
            this.qc,
            this.props.quadrantLayout
          )
        });
      }
    }

    _onResizeEnd(e: any) {
      const { controlledQuadrants, resizers } = this.state;
      document.removeEventListener('mousemove', this._onResize);
      document.removeEventListener('mouseup', this._onResizeEnd);
      this.props.onQuadrantsResize(
        controlledQuadrants,
        resizers.x.position,
        resizers.y.position
      );
      this.setState((prevState) => {
        return {
          ...prevState,
          isResizing: false
        };
      });
    }
    
    constructor(props: ResizerProps) {
      super(props);
  
      this._onResizeStart = this._onResizeStart.bind(this);
      this._onResize = this._onResize.bind(this);
      this._onResizeEnd = this._onResizeEnd.bind(this);
      
      this.ref = React.createRef();
      this.state = {
        controlledQuadrants: [],
        resizers: {
          x: { position: 50, size: 100, visible: true },
          y: { position: 50, size: 50, visible: true },
        },
        isResizing: false
      };
    }
  
    render() {
      const { activeQuadrants } = this.props;
      const { resizers } = this.state;
      return (
        <div
          className={`${this.props.className || ''} ${activeQuadrants ? `` : `hidden`}`}
          ref={this.ref}
        >
          <WrappedComponent 
            {...this.props}
            {...this.state}
          />
          <Resizers options={resizers} onResize={this._onResizeStart} />
        </div>
      );
    }
  };
}