import { IOrder, EnterOrderRequest } from '../models/orders';
import { LogLevel } from '../../shared/logger/models/logger';
import { LogAction } from '../../main/middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { formatOrderRequestValues } from '../../shared/utils/formatters';
export enum ActionTypes {
  LOAD = '[Orders] Load',
  LOAD_SUCCESS = '[Orders] Load success',
  LOAD_FAILURE = '[Orders] Load failure',
  ENTER_ORDER = '[Orders] Enter',
  BULK_ENTER_ORDER = '[Orders] Bulk enter',
  ENTER_ORDER_COMPLETE = '[Orders] Enter complete',
  ORDER_SUCCESS = '[Orders] Order success',
  MODIFY_ORDER = '[Orders] Modify',
  MODIFY_ORDER_COMPLETE = '[Orders] Modify complete',
  CANCEL_ORDER = '[Orders] Cancel',
  CANCEL_ORDER_COMPLETE = '[Orders] Cancel complete',
  ORDER_FAILURE = '[Orders] Order failure',
  REMOVE_ORDERS = '[Orders] Remove',
  REMOVE_ALL_ORDERS = '[Orders] Remove all',
  ORDER_SANITY_CHECK_LIMIT = '[Orders] Sanity check limit'
}

export interface OrdersLoadAction {
  type: ActionTypes.LOAD;
}

export interface OrdersLoadActionSuccess {
  type: ActionTypes.LOAD_SUCCESS;
  payload: IOrder[];
}

export interface OrdersLoadActionFailure extends LogAction {
  type: ActionTypes.LOAD_FAILURE;
  error: any;
}

export interface OrderEnterAction extends LogAction {
  type: ActionTypes.ENTER_ORDER;
  payload: any;
}

export interface BulkOrderEnterAction extends LogAction {
  type: ActionTypes.BULK_ENTER_ORDER;
  payload: EnterOrderRequest[];
}

export interface OrderEnterCompleteAction {
  type: ActionTypes.ENTER_ORDER_COMPLETE;
  payload: any;
}

export interface OrderSuccessAction extends LogAction {
  type: ActionTypes.ORDER_SUCCESS;
}

export interface OrderModifyAction extends LogAction {
  type: ActionTypes.MODIFY_ORDER;
  payload: any;
}

export interface OrderModifyCompleteAction {
  type: ActionTypes.MODIFY_ORDER_COMPLETE;
  payload: any;
}

export interface OrderCancelAction extends LogAction {
  type: ActionTypes.CANCEL_ORDER;
  payload: any;
}

export interface OrderCancelCompleteAction {
  type: ActionTypes.CANCEL_ORDER_COMPLETE;
  payload: any;
}

export interface OrderFailureAction extends LogAction {
  type: ActionTypes.ORDER_FAILURE;
  error: any;
  correlationId: string;
}

export interface OrderRemoveAction {
  type: ActionTypes.REMOVE_ORDERS;
  payload: IOrder[];
}

export interface OrderRemoveAllAction {
  type: ActionTypes.REMOVE_ALL_ORDERS;
}

export function ordersLoad(): OrdersLoadAction {
  return {
    type: ActionTypes.LOAD
  };
}

export function orderEnter(payload: any): OrderEnterAction {
  const {price, quantity, qtyUnit} = formatOrderRequestValues(payload.contractId, payload);
  return {
    type: ActionTypes.ENTER_ORDER,
    payload: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('order.log.enter', {
      contract: payload.contractId, 
      quantity: quantity + ' ' + qtyUnit, 
      limit: price,
      qtyUnit: qtyUnit
    })
  };
}

export function bulkEnterOrders(payload: EnterOrderRequest[]): BulkOrderEnterAction {
  return {
    type: ActionTypes.BULK_ENTER_ORDER,
    payload: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('bulk.order.enter', {
      count: payload.length
    })
  };
}

export function orderEnterComplete(payload: any,  correlationId: string, request: any): OrderEnterCompleteAction {
  return {
    type: ActionTypes.ENTER_ORDER_COMPLETE,
    payload: payload
  };
}

export function orderSuccess(message: string): OrderSuccessAction {
  return {
    type: ActionTypes.ORDER_SUCCESS,
    logLevel: LogLevel.INFO,
    logMessage: message
  };
}
export function orderModify(payload: any, contractId: string): OrderModifyAction {
  const {price, quantity, qtyUnit} = formatOrderRequestValues(contractId, payload);
  return {
    type: ActionTypes.MODIFY_ORDER,
    payload: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('order.log.modify', {
      orderId: payload.orderId, 
      quantity: quantity, 
      limit: price,
      qtyUnit: qtyUnit
    })
  };
}

export function orderModifyComplete(payload: any, request: any): OrderModifyCompleteAction {
  return {
    type: ActionTypes.MODIFY_ORDER_COMPLETE,
    payload: payload
  };
}

export function orderCancel(payload: any): OrderCancelAction {
  return {
    type: ActionTypes.CANCEL_ORDER,
    payload: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('order.log.cancel', {orderId: payload.orderId})
  };
}

export function orderCancelComplete(payload: any, request: any): OrderCancelCompleteAction {
  return {
    type: ActionTypes.CANCEL_ORDER_COMPLETE,
    payload: payload
  };
}

export function ordersLoadSuccess(payload: IOrder[]) {
  return {
    type: ActionTypes.LOAD_SUCCESS,
    payload: payload
  };
}

export function ordersLoadFailure(error: any): OrdersLoadActionFailure {
  return {
    type: ActionTypes.LOAD_FAILURE,
    error: error,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('order.log.failure.load')
  };
}

export function orderFailure(error: any, correlationId: string, request: any): OrderFailureAction {
  return {
    type: ActionTypes.ORDER_FAILURE,
    error: error,
    correlationId: correlationId,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('order.log.failure.action', {logMessage: request.logMessage})
  };
}

export function ordersRemove(payload: IOrder[]) {
  return {
    type: ActionTypes.REMOVE_ORDERS,
    payload: payload
  };
}

export function ordersRemoveAll() {
  return {
    type: ActionTypes.REMOVE_ALL_ORDERS
  };
}

export type Action =
  | OrdersLoadAction
  | OrdersLoadActionSuccess
  | OrdersLoadActionFailure
  | OrderEnterAction
  | OrderEnterCompleteAction
  | OrderModifyAction
  | OrderModifyCompleteAction
  | OrderCancelAction
  | OrderCancelCompleteAction
  | OrderFailureAction
  | OrderRemoveAction
  | OrderRemoveAllAction
  | BulkOrderEnterAction
  ;
