import * as React from 'react';
import { State } from '../../../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import {
  ITableRow,
  ITableColumn,
  TableAction,
  ITable,
  ITableSearchTag,
  ITableSort,
  IColumnName
} from '../../../models/table';
import { CSVLink } from 'react-csv';
import { I18n } from 'react-redux-i18n';
import { ComponentType } from '../../../models/component';
import { getTableForTab } from '../../../selectors/ui';
import store from '../../../../../main/store/store';
import { LogLevel } from '../../../../logger/models/logger';
import { log } from '../../../../logger/actions/logger';
import { triggerDownloadCsv } from '../../../actions/table';
import { config } from '../../../../../main/config';

interface DownloadLinkProps {
  parentId: string;
  componentType: ComponentType;
  name: string;
  table: ITable | null;
  informUser: (notification: string, level: LogLevel) => void;
  triggerDownloadCsv: (visibleColumns: IColumnName[], hidden: number[], sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filter: {[key: string]: boolean}) => void;
}
interface DownloadLinkState {
  data: any;
}

class DownloadLink extends React.Component<
  DownloadLinkProps,
  DownloadLinkState
> {
  private csvLink: any = React.createRef();
  constructor(props: DownloadLinkProps) {
    super(props);
    this.handleStartDownload = this.handleStartDownload.bind(this);

    this.state = {
      data: []
    };
  }

  handleStartDownload(e: any) {
    e.stopPropagation();
    const { componentType, parentId } = this.props;
    const state = store.getState();
    const table = getTableForTab(state, parentId, componentType)!;
    const visibleColumns = table.columns.filter(
      (c: any) => table.hiddenColumnNames.indexOf(c.name) === -1
    );
    // trigger BE download
    const visibleColumnNames: IColumnName[] = visibleColumns.filter(col => col.name !== '$index').map(c => {
      return {
        name: c.name,
        title: I18n.t(c.title)
      };
    });
    let configKey;
    switch (table.type) {
      case ComponentType.Trade: configKey = 'tradeTable'; break;
      case ComponentType.Owntrade: configKey = 'ownTradeTable'; break;
      case ComponentType.TradeReport: configKey = 'tradeReportingTable'; break;
      case ComponentType.Order: configKey = 'orderTable'; break;
      default: configKey = undefined;
    }
    this.props.triggerDownloadCsv(visibleColumnNames, table.hiddenRows, table.sorting.length === 0 && configKey ? config.ui[configKey].defaultSorting : table.sorting, componentType, parentId, table.tags, table.filters);
  }

  downloadCsv(e: any) {
    const comp = I18n.t(`recentAction.${this.props.name}`);
    e.stopPropagation();
    this.props.informUser(I18n.t('table.downloadingCsv', { content: I18n.t(comp) }), LogLevel.INFO);
  }

  render() {
    const { name, table } = this.props;
    const { data } = this.state;

    if (!table || table.actions.indexOf(TableAction.DOWNLOAD_CSV) === -1) {
      return null;
    }

    return (
      <div>
        <i
          className={`oi oi-cloud-download hover-primary`}
          title={I18n.t('modal.download')}
          onClick={this.handleStartDownload}
        />
        <CSVLink
          ref={this.csvLink}
          data={data}
          separator={';'}
          onClick={(e: any) => this.downloadCsv(e)}
          filename={name + '.csv'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: State, props: DownloadLinkProps) => ({
  table: getTableForTab(state, props.parentId, props.componentType)
});

const mapDispatchToProps = {
  informUser: log,
  triggerDownloadCsv: triggerDownloadCsv
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(DownloadLink);
