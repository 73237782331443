import { IFavorite } from '../models/favorite';
import * as React from 'react';
import ModalWindow from '../../modalWindow/components/modalWindow';
import FavoriteForm from './favoriteForm';
import { FavoriteList, FavoriteSelectableList } from './favoriteList';
import { MemoTranslate } from '../../i18n/components/memoTranslate';

interface FavoriteSelectProps {
  selectEntities: IFavorite[];
  favorites: { [id: string]: IFavorite };
  activeMarketInstruments: string[];
  activeChartInstruments: string[];
  activeMarketProducts: string[];
  onSelectFavorite: (e: any, favorite: IFavorite) => void;
  toggleFavorite: (favorite: IFavorite, isFavorite: boolean) => void;
  onFavoriteChangeVisibility: (favoriteId: string, isVisible: boolean) => void;
  modificationEnabled: boolean;
}

interface FavoriteSelectState {
  isModalOpen: boolean;
  isMoreOpen: boolean;
}

export default class FavoriteSelect extends React.Component<
  FavoriteSelectProps,
  FavoriteSelectState
  > {
  constructor(props: FavoriteSelectProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      isMoreOpen: false
    };

    this.handleNewFavorite = this.handleNewFavorite.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleNewFavorite() {
    this.setState({
      ...this.state,
      isModalOpen: true
    });
  }

  handleFormSubmit(selectedEntity: IFavorite) {
    this.setState({
      ...this.state,
      isModalOpen: false
    });
    this.props.toggleFavorite(selectedEntity, true);
  }

  render(): JSX.Element {
    const { favorites, selectEntities, activeChartInstruments, activeMarketInstruments, activeMarketProducts } = this.props;
    const favoritesSorted = Object.keys(favorites).sort((a: string, b: string) => a.localeCompare(b)).map(key => favorites[key]);
    let favoritesArray: IFavorite[] = (Object as any)
      .keys(favoritesSorted)
      .map((key: any) => favoritesSorted[key]);
    // make new favorite to be first in list
    for (let i = 0; i < favoritesArray.length; i++) {
      if (favoritesArray[i].isNew) {
        const newOne = favoritesArray.splice(i, 1);
        favoritesArray = newOne.concat(favoritesArray);
        break;
      }
    }
    return this.props.modificationEnabled ? (
      <div className="sidebar__favorite-views">
        <div className="subtitle">
          <MemoTranslate value="favorite.title" tag={'h2'} />
        </div>
        <ModalWindow
          id="favorite"
          onModalClose={() =>
            this.setState({ ...this.state, isModalOpen: false })
          }
          isOpen={this.state.isModalOpen}
          title={'favorite.title'}
        >
          <FavoriteForm
            entities={selectEntities}
            onSubmit={this.handleFormSubmit}
          />
        </ModalWindow>
        <ModalWindow
          id="favorite-more"
          onModalClose={() =>
            this.setState({ ...this.state, isMoreOpen: false })
          }
          isOpen={this.state.isMoreOpen}
          title={'favorite.title'}
        >
          <FavoriteSelectableList
            key="fav-list-modal"
            favorites={favoritesArray}
            activeChartInstruments={activeChartInstruments}
            activeMarketInstruments={activeMarketInstruments}
            activeMarketProducts={activeMarketProducts}
            onSelect={(e: any, favorite: IFavorite) => {
              this.setState({ ...this.state, isMoreOpen: false });
              return this.props.onSelectFavorite(e, favorite);
            }}
            onClose={() => this.setState({ ...this.state, isMoreOpen: false })}
            onFavoriteRemove={favorite => this.props.toggleFavorite(favorite, false)}
            onFavoriteChangeVisibility={this.props.onFavoriteChangeVisibility}
          />
        </ModalWindow>
        <FavoriteList
          key="fav-list"
          favorites={favoritesArray}
          onSelect={(e: any, favorite: IFavorite) =>
            this.props.onSelectFavorite(e, favorite)
          }
          activeChartInstruments={this.props.activeChartInstruments}
          activeMarketInstruments={this.props.activeMarketInstruments}
          activeMarketProducts={this.props.activeMarketProducts}
          openMore={() => this.setState({ ...this.state, isMoreOpen: true })}
        />
      </div>
    ) : <div/>;
  }
}
