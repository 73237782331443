import { State } from '../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import { formComponent } from '../../shared/form/components/FormComponent';
import { QuoteRequestFormComponent } from '../components/QuoteRequestForm';
import { getSelectedContract, getQuoteRequestFormData } from '../selectors/requests';
import { clearQuoteRequestForm, submitQuoteRequestForm, quoteRequestSuccess } from '../actions/requests';
import { handleInvalidInput } from '../../shared/validation/action';
import { getBusinessUnits } from '../../businessUnitPortfolios/selectors/businessUnits';

const mapStateToProps = (state: State) => {
    return {
      contract: getSelectedContract(state),
      form: getQuoteRequestFormData(state),
      businessUnits: getBusinessUnits(state)
    };
  };
  
const mapDispatchToProps = {
    handleSubmit: submitQuoteRequestForm,
    handleInvalidInput: handleInvalidInput,
    handleClear: clearQuoteRequestForm,
    handleSuccess: quoteRequestSuccess
};

export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(formComponent(QuoteRequestFormComponent));