import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import store from '../../../../../main/store/store';
import { Popover } from 'react-bootstrap';
import ChartFavorite from './chartFavorite';
import ChartsFilter from './chartFilter';
import UIDropdownMenu from '../../actionIcons/DropdownMenu';
import ActionIcon from '../../actionIcons/ActionIcon';
import { remove } from '../../../actions/chart';
import { DockType } from '../../../../dock/models/dock';
import { getOrderbookContracts } from '../../../../../orderbook/selectors/contracts';
import orderBookStore from '../../../../../orderbook/store/orderbooks';
import { setBlockWidth } from '../../../../dock/actions/dock';
import { Chart } from '../../../models/chart';
import { IFavorite } from '../../../../../shared/favorite/models/favorite';

interface ChartActionsProps {
  dockId: string;
  dockType: DockType;
  charts: Chart[];
  periodTypes: { [key: string]: string[] };
  favoriteName: string;
  handlePeriodTypeChange: (chartId: string, periodType: string) => void;
  handleContractChange: (chartId: string, contractId: string) => void;
  findAllContractsForPeriodType: (chart: Chart, periodType: string) => any;
  modificationAllowed: boolean;
  favorites: IFavorite[];
}
interface ChartActionsState {}

export default class ChartActions extends React.Component<
  ChartActionsProps,
  ChartActionsState
> {
  constructor(props: ChartActionsProps) {
    super(props);

    this._setChartWidth = this._setChartWidth.bind(this);
    this._remove = this._remove.bind(this);
  }

  _setChartWidth(dockId: string, blockWidth: string) {
    store.dispatch(setBlockWidth(dockId, blockWidth));
  }

  _remove(chartId: string, dockId: string) {
    const contracts = this.props.charts.filter(c => c.id === chartId).map(c => c.contractId);
    store.dispatch(remove(chartId, dockId, contracts));
  }

  render() {
    const { dockId, dockType, charts, periodTypes, favoriteName, favorites } = this.props;

    const closeItems = charts.map(chart => {
      const contract = getOrderbookContracts(orderBookStore.getState())[chart.contractId];
      return {
        key: chart.id,
        caption: I18n.t('market.closeMarket', { title: contract ? contract.name : chart.title }),
        onClick: () => this._remove(chart.id, chart.dockId)
      };
    });
    if (charts.length > 1) {
      closeItems.push({
        key: 'all',
        caption: I18n.t('market.closeAll'),
        onClick: () =>
          charts.forEach(chart => {
            return this._remove(chart.id, chart.dockId);
          })
      });
    } else if (charts.length === 0) {
      closeItems.push({
        key: 'all',
        caption: I18n.t('market.closeAll'),
        onClick: () => this._remove('0', dockId)
      });
    }
    const closeComponent = (
      <UIDropdownMenu items={closeItems} dockId={dockId} />
    );

    const resizeOptions = ['def', '400', '500', '600', '700', '800'].map(
      opt => {
        if (opt === 'def') {
          return {
            key: 'def',
            caption: I18n.t('general.Default Width'),
            onClick: () => this._setChartWidth(dockId, 'instrument-w-default')
          };
        } else {
          return {
            key: opt,
            caption: I18n.t('general.Width', { width: opt }),
            onClick: () => this._setChartWidth(dockId, `instrument-w-${opt}`)
          };
        }
      }
    );

    const resizeComponent = (
      <UIDropdownMenu items={resizeOptions} dockId={dockId} />
    );

    return (
      <ul>
        {
          this.props.modificationAllowed ?
            <ActionIcon
              iconName="oi-x"
              title={I18n.t('modal.close')}
              component={closeComponent}
            /> : null
        }
        {/*<ActionIcon
          iconName="oi-resize-width"
          title={I18n.t('modal.width')}
          component={resizeComponent}
        />
         <ActionIcon
          iconName="oi-wrench"
          title={I18n.t('modal.filter')}
          component={filterComponent}
        /> */}
        {
          this.props.modificationAllowed ?
            <ChartFavorite
              dockId={dockId}
              dockType={dockType}
              charts={charts}
              favoriteName={favoriteName}
              favorites={favorites}
            /> : null
        }
      </ul>
    );
  }
}
