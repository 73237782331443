import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Position, Size } from '../../utils/models/grid';
import { IDock, DockType } from '../models/dock';
import DraggableCore from 'react-draggable';
import { Resizable } from 'react-resizable';
import { Collider } from '../../utils/helper/collider';
import { getMarketsForDock, getChartsForDock } from '../../ui/selectors/ui';
import store from '../../../main/store/store';
import { Chart } from '../../ui/models/chart';
import { config } from '../../../main/config';
import { Grid } from '../../utils/components/grid';
import { I18n } from 'react-redux-i18n';
import { IDashboardQuadrant } from '../../../dashboard/models/quadrants';
import { Debouncer } from '../../utils/components/debounce';
import { scrollContent } from '../actions/dock';

const debouncer = new Debouncer();

const ResizableContent = ({ box, disableRendering, isBeingDragged, isRemoving, dockInfo, children, dockInfoString, type, dockId }: any) => {
  
  const _handleScroll = (e) => {
    debouncer.debounce(() => {
      store.dispatch(scrollContent(dockId, {width: box.size.width, height: box.size.height, scrollLeft: e.target?.scrollLeft, scrollTop: e.target?.scrollTop}));
    }, 500);
  };

  return (
    <div
      className="box react-resizable"
      onScroll={_handleScroll}
      style={{
        width: box.size.width + 'px',
        height: box.size.height + 'px'
      }}
      key={dockInfoString}
    >
      {(disableRendering) || (isBeingDragged && type !== DockType.ChartMarket) ? (
        isRemoving ? (
          <div className="align-center text-center">
            <h4>{I18n.t('notifications.Dock will be removed')}</h4>
          </div>
        ) : <div className="align-center text-center">
          <h3>{dockInfo}</h3>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

interface Props {
  data: IDock;
  minWidth: number;
  maxWidth: number;
  bounds: any;
  collidingDocks: IDock[];
  collidingQuadrants: IDashboardQuadrant[],
  onDrag: (position: Position) => void;
  onDragFinish: (position: Position) => void;
  onResize: (size: Size) => void;
  setActive: (dockId: string, isBeingDragged: boolean) => void;
  setInactive: (
    dockId: string,
    position: Position,
    size: Size
  ) => void;
  remove: (dockId: string) => void;
  onDocksConnect: (collidingDockId: string) => void;
  warn: (notification: string) => void;
  contentNamesForDock: string[];
  modificationAllowed: boolean;
  addDockToQuadrant: (quadrantBoxId: number, dock: IDock) => void;
  isGridQuadrantsActive: boolean;
  touchScreen?: boolean;
}

interface State {
  cursorPosition: Position;
  repairedPosition: Position | undefined;
  box: {
    size: Size;
  };
  shadow: {
    size: Size;
    position: Position;
    isPinning: boolean;
  };
  axis: 'x' | 'y' | 'both';
}

export default class DockComponent extends React.Component<Props, State> {

  dragableOpts = {
    enableUserSelectHack: false
  };
  private ref: any;
  private timeouts: any[];

  constructor(props: Props) {
    super(props);

    const { size } = this.props.data;

    // Local state
    this.state = {
      cursorPosition: this.props.data.position,
      repairedPosition: undefined,
      box: {
        size: size
      },
      shadow: {
        size: size,
        position: { x: 0, y: 0 },
        isPinning: false
      },
      axis: 'both'
    };

    // Listeners
    this._setDockPosition = this._setDockPosition.bind(this);

    // Drag handlers
    this._onDragStart = this._onDragStart.bind(this);
    this._onDrag = this._onDrag.bind(this);
    this._onDragStop = this._onDragStop.bind(this);

    // Resize handlers
    this._onResizeStart = this._onResizeStart.bind(this);
    this._onResize = this._onResize.bind(this);
    this._onResizeStop = this._onResizeStop.bind(this);
    this._onResizerOver = this._onResizerOver.bind(this);

    this.timeouts = [];
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { id, autoDragging } = this.props.data;
    if (!this.props.modificationAllowed) {
      return;
    }
    if (autoDragging) {
      if (!this.props.touchScreen) {
        document.addEventListener('mousemove', this._setDockPosition, false);
      } else {
        document.addEventListener('touchend', this._setDockPosition, false); 
        document.addEventListener('touchstart', this._onTouchStart , false); 

        const node = ReactDOM.findDOMNode(this) as Element;
        node.classList.add('react-draggable-dragging');
      }
      const parent = document.getElementById('dock-' + id);
      const element = parent
        ? parent.getElementsByClassName('cursor')[0]
        : null;
      if (!this.props.touchScreen) {
        const dragEvent = new Event(
          'mousedown',
          Object.assign({}, { target: element, bubbles: true })
        );

        if (element) {
          element.dispatchEvent(dragEvent);
        }
      }
    }
    (ReactDOM.findDOMNode(this) as Element)
      .getElementsByClassName('react-resizable')[0]
      .addEventListener('mouseover', this._onResizerOver, false);
  }

  componentWillReceiveProps(newProps: Props) {
    this.setState({
      ...this.state,
      box: {
        size: newProps.data.size
      },
      shadow: {
        ...this.state.shadow,
        size: newProps.data.size
      }
    });
  }

  _onTouchStart = (e) => {
    this._onDrag(e, {x: e.touches[0].clientX, y: e.touches[0].clientY});
    this._onDragStop(e, {x: e.touches[0].clientX, y: e.touches[0].clientY});
    document.removeEventListener('touchstart', this._onTouchStart);
  };

  _onResizerOver(e: any) {
    const { position, size } = this.props.data;
    const recBounds = Collider.createBounds(
      position.x,
      position.y,
      size.width,
      size.height
    );

    var mouseX = e.clientX - 10;
    var mouseY = e.clientY;

    var topEdgeDist = Math.abs(recBounds.y - mouseY);
    var bottomEdgeDist = Math.abs(recBounds.yMax - mouseY);
    var leftEdgeDist = Math.abs(recBounds.x - mouseX);
    var rightEdgeDist = Math.abs(recBounds.xMax - mouseX);

    var min = Math.min(
      topEdgeDist,
      bottomEdgeDist,
      leftEdgeDist,
      rightEdgeDist
    );

    let axis = this.state.axis;
    switch (min) {
      case leftEdgeDist:
      case rightEdgeDist:
        axis = bottomEdgeDist < 20 ? 'both' : 'x';
        break;
      case topEdgeDist:
      case bottomEdgeDist:
        axis = rightEdgeDist < 10 ? 'both' : 'y';
        break;
      default:
        break;
    }

    this.setState({
      ...this.state,
      axis: axis
    });
  }

  _onResizeStart() {
    this.props.setActive(this.props.data.id, false);
    const node = ReactDOM.findDOMNode(this) as Element;
    node
      .getElementsByClassName('react-resizable')[0]
      .removeEventListener('mouseover', this._onResizerOver, false);
    node.classList.add('react-draggable-resizing');
    (node.parentElement as Element).classList.add('collision-disallowed');
  }

  _onResize(e: MouseEvent, ui: any) {
    e.preventDefault();
    const { data } = this.props;
    const { position } = this.props.data;
    const grid = new Grid();
    const recalcultedWidth = grid.recalculateDockWidthOnResize(
      position,
      ui.size.width,
      data.type
    );

    const recalcultedHeight = grid.recalculateDockHeightOnResize(
      position,
      ui.size.height
    );

    this.setState({
      ...this.state,
      shadow: {
        ...this.state.shadow,
        size: {
          height: recalcultedHeight,
          width: recalcultedWidth
        }
      }
    });
    this.props.onResize({ width: recalcultedWidth, height: recalcultedHeight });
  }

  _onResizeStop(e: MouseEvent, ui: any) {
    e.preventDefault();
    const { data } = this.props;
    const { id, size, position, isColliding } = this.props.data;

    const grid = new Grid();
    const recalcultedWidth = grid.recalculateDockWidthOnResize(
      position,
      ui.size.width,
      data.type
    );

    const recalcultedHeight = grid.recalculateDockHeightOnResize(
      position,
      ui.size.height
    );

    const node = ReactDOM.findDOMNode(this) as Element;
    node.classList.remove('react-draggable-resizing');
    (node.parentElement as Element).classList.remove('collision-disallowed');

    let newSize = {
      width: recalcultedWidth,
      height: recalcultedHeight
    };

    if (isColliding) {
      this.setState({
        ...this.state,
        box: {
          size: size
        },
        shadow: {
          ...this.state.shadow,
          size: size,
          position: { x: 0, y: 0 }
        }
      });

      this.props.onResize(size);
      this.props.setInactive(id, position, size);
    } else {
      this.setState({
        ...this.state,
        box: {
          size: newSize
        },
        shadow: {
          ...this.state.shadow,
          size: newSize
        }
      });
      this.props.setInactive(id, position, newSize);
    }

    (ReactDOM.findDOMNode(this) as Element)
      .getElementsByClassName('react-resizable')[0]
      .addEventListener('mouseover', this._onResizerOver, false);
  }

  _onDragStart(e: any, ui: any) {
    if (e.persist) {
      e.persist();
    }
    this.props.setActive(this.props.data.id, true);
    document
      .getElementsByClassName('sidebar')[0]
      .classList.add('react-draggable-transparent-selection');
  }

  _onDrag(e: any, ui: any) {
    e.preventDefault();
    let { pinning } = this.props.data;
    let { isPinning } = this.state.shadow;
    if (pinning) {
      this.setState({
        ...this.state,
        repairedPosition: undefined,
        shadow: {
          ...this.state.shadow,
          position: Collider.pinCalculation(pinning, ui),
          isPinning: true
        }
      });
    } else if (isPinning) {
      this.setState({
        ...this.state,
        repairedPosition: undefined,
        shadow: {
          ...this.state.shadow,
          position: Collider.pinCalculation(pinning, ui),
          isPinning: false
        }
      });
    } else if (this.state.repairedPosition !== undefined) {
      this.setState({
        ...this.state,
        repairedPosition: undefined
      });
    }

    this.props.onDrag({ x: ui.x, y: ui.y });
  }

  _handleCollision(ui) {
    const {
      id,
      position,
      size,
      ableToDelete
    } = this.props.data;
    if (ableToDelete) {
      this.timeouts = [
        ...this.timeouts,
        setTimeout(() => this.props.remove(id), 0)
      ];
      this.props.setInactive(id, { x: ui.x, y: ui.y }, size);
      this.props.warn(I18n.t('error.dockAutoDelete'));
    } else {
      this.props.onDrag(position);
      this.props.setInactive(id, position, size);
      this.setState({
        ...this.state,
        shadow: {
          ...this.state.shadow,
          position: { x: 0, y: 0 }
        }
      });
    }
  }

  _onDragStop(e: MouseEvent, ui: any) {
    e.preventDefault();
    const {
      isColliding,
      isRemoving,
      id,
      position,
      size,
      pinning,
      type,
      ableToDelete
    } = this.props.data;
    const { collidingDocks, collidingQuadrants } = this.props;
    document
      .getElementsByClassName('sidebar')[0]
      .classList.remove('react-draggable-transparent-selection');

    if (isColliding && collidingDocks.length) {
      if (collidingDocks.length === 1) {
        if (type === collidingDocks[0].type || (type.includes('Chart') && collidingDocks[0].type.includes('Chart'))) {
          this.props.onDocksConnect(collidingDocks[0].id);
        } else {
          this._handleCollision(ui);
        }
      } else {
        this._handleCollision(ui);
      }
    } else if (isColliding && collidingQuadrants.length > 0) {
      this.props.addDockToQuadrant(collidingQuadrants[0].id, this.props.data);
      // this.props.setInactive(id, position, collidingQuadrants[0].size);

      this.setState({
        ...this.state,
        shadow: {
          ...this.state.shadow,
          size: {width: 100, height: 100},
          position: { x: 0, y: 0 }
        }
      });

    } else if (pinning) {
      this.props.setInactive(
        id,
        Collider.pinObject(pinning, ui),
        size
      );
    } else if (isRemoving) {
      /// Need to wait for Draggable event end
      this.timeouts = [
        ...this.timeouts,
        setTimeout(() => this.props.remove(id), 0)
      ];
      this.props.setInactive(id, { x: ui.x, y: ui.y }, size);
    } else {
      this.props.setInactive(id, { x: ui.x, y: ui.y }, size);
    }
    // dock dropped when nothing is colliding - outside of quadrant grid collision area
    if (this.props.isGridQuadrantsActive && collidingDocks.length === 0 && collidingQuadrants.length === 0) {
      this.props.remove(id);
    }
    

    this.setState({
      ...this.state,
      shadow: {
        ...this.state.shadow,
        position: { x: 0, y: 0 }
      }
    });

    this.props.onDragFinish(position);
  }

  getDockInfo() {
    const names: string[] = this.props.contentNamesForDock;
    return names.map((name, index) => (
      <div key={index}>{name}</div>
    ));
  }

  getDockInfoString() {
    const names: string[] = this.props.contentNamesForDock;
    return names.reduce((acc, name, index) => {
      return acc += index + ':' + name + '_';
    }, '');
  }

  render() {
    let dragHandlers = {
      onStart: this._onDragStart,
      onStop: this._onDragStop,
      onDrag: this._onDrag
    };

    let resizeHandlers = {
      onResizeStart: this._onResizeStart,
      onResizeStop: this._onResizeStop,
      onResize: this._onResize
    };

    const { children, bounds, collidingDocks } = this.props;

    const {
      id,
      position,
      isColliding,
      isRemoving,
      autoDragging,
      type,
      disableRendering,
      parts,
      blockWidth,
      isBeingDragged,
      active
    } = this.props.data;
    const { cursorPosition, box, shadow, axis, repairedPosition } = this.state;
    const collidingClass = isColliding
      ? this._getCollidingClass(type, parts, collidingDocks, isRemoving, isBeingDragged)
      : '';
    const shadowStyle = {
      width: shadow.size.width,
      height: shadow.size.height,
      transform:
        'translate(' + shadow.position.x + 'px,' + shadow.position.y + 'px)'
    };
    const dockPosition = autoDragging ? cursorPosition : (repairedPosition ? repairedPosition : position);
    const cursorClass = 'cursor-' + axis;
    const yScrollClass = type === DockType.MarketIntraday ? 'y-scroll' : '';
    return (
      <DraggableCore
        handle=".cursor"
        bounds={bounds ? bounds : 'parent'}
        position={dockPosition}
        enableUserSelectHack={false}
        {...dragHandlers}
        ref={this.ref}
        disabled={!this.props.modificationAllowed}
      >
        <div
          data-test="dock-wrapper"
          className={'box dock__wrapper no-cursor ' + collidingClass + ' ' + blockWidth + ' ' + yScrollClass}
          id={'dock-' + id}
        >
          <div className="dock__content">
            {this.props.modificationAllowed ? <span className="cursor" /> : null}
            {this.props.modificationAllowed ?
              <Resizable
                className={`box ${cursorClass}`}
                width={box.size.width}
                height={box.size.height}
                axis={axis}
                draggableOpts={this.dragableOpts}
                {...resizeHandlers}
                disabled={!this.props.modificationAllowed}
                key={'resizable-' + id}
              >
                <ResizableContent
                  box={box}
                  disableRendering={disableRendering}
                  isBeingDragged={isBeingDragged}
                  active={active}
                  isRemoving={isRemoving}
                  children={children}
                  dockInfo={this.getDockInfo()}
                  dockInfoString={this.getDockInfoString()}
                  key={'resizableContent-' + id}
                  type={type}
                  dockId={id}
                />
              </Resizable>
              :
              <div>
                <ResizableContent
                  box={box}
                  disableRendering={disableRendering}
                  isBeingDragged={isBeingDragged}
                  active={active}
                  isRemoving={isRemoving}
                  children={children}
                  dockInfo={this.getDockInfo()}
                  dockInfoString={this.getDockInfoString()}
                  key={'resizable-' + id}
                  type={type}
                />
              </div>
            }
          </div>
          <div className="dock__shadow" style={shadowStyle} />
        </div>
      </DraggableCore>
    );
  }

  componentWillUnmount() {
    this.timeouts.map(t => clearTimeout(t));
    document.removeEventListener('touchstart', this._onTouchStart);
  }

  _setDockPosition(e: any) {
   const {x, y} = e.type == 'touchend' 
          ? (e.changedTouches.length ? {x : e.changedTouches[0].clientX, y: e.changedTouches[0].clientY} : {x: 0, y: 0}) 
          : {x: e.clientX, y: e.clientY};
    this.setState({
      ...this.state,
      cursorPosition: {
        x: x - this.props.data.size.width / 2,
        y: y - 15
      }
    });
    if (e.type === 'mousemove') {
      document.removeEventListener('mousemove', this._setDockPosition);
    } else if (e.type === 'touchend') {
      document.removeEventListener('touchend', this._setDockPosition);
    }
  }

  _getCollidingClass(
    type: string,
    parts: number,
    colidingDocks: IDock[],
    isRemoving: boolean,
    isDragging: boolean
  ): string {
    if (isRemoving) {
      return 'isColliding removing';
    }
    if (colidingDocks.length) {
      
      if (colidingDocks.length === 1) {
        const colidingDock = colidingDocks[0];
        if (type === DockType.Market || type === DockType.MarketIntraday || type === DockType.ProductMarket) {
          if (parts + colidingDock.parts > config.maxConnectedMarkets) {
            return 'isColliding disallowed';
          }

          const marketItemIds = getMarketsForDock(
            store.getState(),
            this.props.data.id
          ).map(market => market.itemId);
          const collidingMarkets = getMarketsForDock(
            store.getState(),
            colidingDock.id
          );
          for (let i = 0; i < collidingMarkets.length; i++) {
            if (
              marketItemIds.indexOf(collidingMarkets[i].itemId) > -1
            ) {
              return 'isColliding disallowed';
            }
          }
        }
        if (type === DockType.ChartMarket) {
          if (parts + colidingDock.parts > config.maxConnectedMarkets) {
            return 'isColliding disallowed';
          }
          const marketCharts = getChartsForDock(
            store.getState(),
            this.props.data.id
          ).map((chart: Chart) => chart.contractId);
          const collidingCharts = getChartsForDock(
            store.getState(),
            colidingDock.id
          ).map((chart: Chart) => chart.contractId);
          for (let i = 0; i < collidingCharts.length; i++) {
            if (marketCharts.indexOf(collidingCharts[i]) > -1) {
              return 'isColliding disallowed';
            }
          }
        }
        if ((type === colidingDock.type || (type.includes('Chart') && colidingDock.type.includes('Chart')) && (isDragging || colidingDocks[0].isBeingDragged))) {
          return 'isColliding allowed';
        } else {
          return 'isColliding disallowed';
        }
      } else {
        return 'isColliding disallowed';
      }
    }
    return '';
  }
}
