const Keycloak = require('keycloak-js');
import * as Rx from 'rxjs';
import { v1 } from 'uuid';

// for public client - uses keycloak library
// this function is able to get token after redirect
export function createKeycloakObjectFromUrl(redirectUrl: string) {
    return Rx.Observable.create(
        (observer: Rx.Observer<any>) => {
        const url = redirectUrl.replace(/\/realms(.*)/, '');
        const jsonFromUrl = redirectUrl.replace(/http(.+)\/realms\/([^\/]+)\/(.+)?client_id=(.*)/g, '{"base":"http$1","realm":"$2","clientId":"$4"}');
        const params = JSON.parse(jsonFromUrl);
        const kc = Keycloak({
            'enable-cors': true,
            'cors-allowed-methods': 'GET, HEAD, OPTIONS, POST',
            url: params.base,
            realm: params.realm,
            clientId: params.clientId
        });
        kc.init({ onLoad: 'login-required' }).success((authenticated: boolean) => {
            observer.next({
                ssoToken: {
                    accessToken: kc.token,
                    refreshToken: kc.refreshToken,
                    expiresIn: kc.tokenParsed.exp,
                    refreshExpiresIn: kc.refreshTokenParsed.exp 
                },
                username: kc.tokenParsed.preferred_username
            });
        }).error((error: any) => {
            observer.error(error);
        });
    });
}

// for confidential client - uses fragments of keycloak library
// this function can only get authorization code after redirect, which must be sent to keycloak to get token
export function redirectAndReadData(redirectUrl: string) {
    // read current URL
    var callback = parseCallbackUrl(window.location.href);

    if (callback) {
        // if it's redirected from keycloak
        window.history.replaceState(window.history.state, '', callback.newUrl);
    } else {
        // redirect to keycloak
        window.location.replace(redirectUrl + '&response_type=code&redirect_uri=' + getLocationHrefWithLoginPath());
    }
    return Rx.empty();
}

/**
 * creates request with available data from frame object returned by stompService connect
 * @param frame 
 */
export function createRequest(frame: any) {
    if (!frame.code && !frame.ssoToken) {
        return {username: frame.username, correlationId: v1()};
    } else if (!frame.code && frame.ssoToken) {
        return {username: frame.username, correlationId: v1(), token: frame.ssoToken, accessToken: frame.ssoToken.accessToken};
    } else {
        return {code: frame.code, redirectUri: getLocationHrefWithLoginPath()};
    }
}

function getLocationHrefWithLoginPath() {
    const locationHref = window.location.href;
    if (!locationHref.match(/(.+)\/login\/?$/)) {
        return locationHref.replace(/\/?$/, '/login');
    }
    return locationHref;
}

export function readSSOData() {
    return parseCallbackUrl(window.location.href);
}

/**
 * Parses URL and retrieves data from callback URL after redirect from keycloak
 * URL can look like host?state=XXX&code=YYY or host#error=XXX
 * @param url 
 * @return object with parsed parameters
 */
function parseCallbackUrl(url: string) {
    const supportedParams = ['code', 'state', 'session_state', 'error', 'error_description', 'error_uri'];

    const queryIndex = url.indexOf('?');
    const fragmentIndex = url.indexOf('#');

    let newUrl;
    let parsed;
    newUrl = url.substring(0, queryIndex);
    if (queryIndex < 0 && fragmentIndex > -1) {
        parsed = parseCallbackParams(url.substring(fragmentIndex + 1), supportedParams);
    } else {
        parsed = parseCallbackParams(url.substring(queryIndex + 1, fragmentIndex !== -1 ? fragmentIndex : url.length), supportedParams);
    }
    if (parsed.paramsString !== '') {
        newUrl += '?' + parsed.paramsString;
    }
    if (fragmentIndex !== -1) {
        newUrl += url.substring(fragmentIndex);
    }
    if (fragmentIndex > 0 || queryIndex > 0) {
        window.history.replaceState(window.history.state, '', newUrl);
    }
    if (parsed && parsed.oauthParams) {
        if ((parsed.oauthParams.code && parsed.oauthParams.session_state) || parsed.oauthParams.error) {
            parsed.oauthParams.newUrl = newUrl;
            return parsed.oauthParams;
        }
      
    }
}

/**
 * Split param string param1=value&param2=value into object 
 * @param paramsString 
 * @param supportedParams 
 */
function parseCallbackParams(paramsString: string, supportedParams: string[]): any {
    let p = paramsString.split('&');
    let result: {
        [key: string]: any
    } = {
        paramsString: '',
        oauthParams: {}
    };
    for (var i = 0; i < p.length; i++) {
        var t = p[i].split('=');
        if (supportedParams.indexOf(t[0]) !== -1) {
            result.oauthParams[t[0]] = t[1];
        } else {
            if (result.paramsString !== '') {
                result.paramsString += '&';
            }
            result.paramsString += p[i];
        }
    }
    return result;
}

/**
 * Decode SSO token to object
 * @param str
 */
export function decodeToken(str: string): any {
    str = str.split('.')[1];

    str = str.replace('/-/g', '+');
    str = str.replace('/_/g', '/');
    switch (str.length % 4) {
        case 0:
            break;
        case 2:
            str += '==';
            break;
        case 3:
            str += '=';
            break;
        default:
            throw 'Invalid token';
    }

    str = (str + '===').slice(0, str.length + (str.length % 4));
    str = str.replace(/-/g, '+').replace(/_/g, '/');

    str = decodeURIComponent(escape(atob(str)));

    const parsed = JSON.parse(str);
    return parsed;
}
