import { connect } from 'react-redux';
import { State } from '../../../main/reducers/rootReducer';
import { active, inactive, remove } from '../actions/dock';
import DockContainer from '../components/dockComponent';
import { getCollidingDocksOnDockId, getContentNamesForDockId } from '../../../dashboard/selectors/dashboard';
import { warn } from '../../logger/actions/logger';
import { isProfileModificationAllowed } from '../../../authentication/selectors/authetication';
import { getCollidingQuadrants } from '../../../dashboard/selectors/quadrantPanel';
import { addDockToQuadrant } from '../../../dashboard/actions/quadrants';

const mapStateToProps = (state: State, props: any) => ({
  collidingDocks: getCollidingDocksOnDockId(state, props.data.id),
  collidingQuadrants: getCollidingQuadrants(state),
  contentNamesForDock: getContentNamesForDockId(state, props.data.id, props.data.type),
  modificationAllowed: isProfileModificationAllowed(state)
});

const mapDispatchToProps = {
  setActive: active,
  setInactive: inactive,
  remove: remove,
  warn: warn,
  addDockToQuadrant: addDockToQuadrant
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(DockContainer);
