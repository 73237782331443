import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { Translate, I18n } from 'react-redux-i18n';
import { v1 } from 'uuid';
import { save, load, remove, toggleVisibility } from '../actions/profile';
import { DashboardView } from '../models/profile';
import {
  getAvailableViews,
  getActiveViewId,
  getVisibleViewIds
} from '../selectors/profile';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { config } from '../../main/config';
import { isProfileModificationAllowed, isProfileAdmin } from '../../authentication/selectors/authetication';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import { getQuadrantGridActive } from '../selectors/quadrantPanel';

const lastViewName = 'profile.views.Last Dashboard';
const emptyViewName = 'profile.views.New Dashboard';
const lastViewId = 'lastview';
const emptyViewId = 'emptyView';

const TranslateViewName = (viewName: string) => {
  return viewName === lastViewName || viewName === emptyViewName
    ? I18n.t(viewName)
    : viewName;
};

const ProfileList = ({ views, activeViewId, onItemClick, openMore, modificationAllowed }: any) => {
  const profilesList = views.map((view: DashboardView) => {
    const label = <label className="dashboard-name">{TranslateViewName(view.name)}</label>;
    return (
      <li key={view.viewId} className="select-item" onClick={() => onItemClick(view.viewId)}>
        <div className="left-container"><span className="oi oi-list" /> {label}</div>
        <span
          className={`oi oi-star float-right ${
            view.viewId === activeViewId ? `active` : ``
            }`}
        />
      </li>
    );
  });
  if (modificationAllowed) {
    profilesList.push(
      <li key="profiles-more" className="more" onClick={e => openMore()}>
        <MemoTranslate value="sidebar.more" />
      </li>
    );
  }
  return (
    <div className="sidebar__profile-views__list">
      <ul>{profilesList}</ul>
    </div>
  );
};

const ConfirmationReplaceBlock = ({ viewName, onSuccess, onCancel }: any) => {
  return (
    <div className="confirmation-block">
      <div>
        <p>
          <Translate
            value="profile.modal.replaceQuestion"
            name={TranslateViewName(viewName)}
          />
        </p>
      </div>
      <div className="modal-footer d-flex">
        <button
          className="btn btn-approval w150 ml-auto"
          onClick={onSuccess}
          autoFocus={true}
        >
          <MemoTranslate value="profile.modal.replace" />
        </button>
      </div>
    </div>
  );
};

interface ViewItem {
  viewId: string;
  name: string;
  visibleBefore: boolean;
  visibleAfter: boolean;
  deleteAfter: boolean;
}

interface ProfileSelectableListProps {
  views: { viewId: string; name: string; default: boolean }[];
  visibleViewIds: string[];
  activeViewId: string;
  profileAdmin: boolean;
  onClose: () => void;
  onViewRemove: (viewId: string, viewName?: string) => void;
  onViewChangeVisibility: (viewId: string, isVisible: boolean) => void;
  onViewLoad: (viewId: string) => void;
}

interface ProfileSelectableListState {
  items: ViewItem[];
}

export class ProfileSelectableList extends React.Component<
  ProfileSelectableListProps,
  ProfileSelectableListState
  > {
  constructor(props: ProfileSelectableListProps) {
    super(props);

    const { views, visibleViewIds } = this.props;
    this.state = {
      items: views.map(view => ({
        viewId: view.viewId,
        name: view.name,
        visibleBefore: visibleViewIds.indexOf(view.viewId) !== -1,
        visibleAfter: visibleViewIds.indexOf(view.viewId) !== -1,
        deleteAfter: false
      }))
    };
  }

  handleSave() {
    this.state.items.forEach(item => {
      if (item.deleteAfter) {
        this.props.onViewRemove(item.viewId, item.name);
      } else if (item.visibleBefore !== item.visibleAfter) {
        this.props.onViewChangeVisibility(item.viewId, item.visibleAfter);
      }
    });
    this.props.onClose();
  }

  handleToggleVisible(viewId: string) {
    this.setState(prevState => ({
      ...prevState,
      items: prevState.items.map(item => item.viewId === viewId ? {
        ...item,
        visibleAfter: !item.visibleAfter
      } : item)
    }));
  }

  handleDelete(viewId: string) {
    this.setState(prevState => ({
      ...prevState,
      items: prevState.items.map(item => item.viewId === viewId ? {
        ...item,
        deleteAfter: true
      } : item)
    }));
  }

  handleOpen(viewId: string) {
    this.props.onViewLoad(viewId);
  }

  render() {
    const { items } = this.state;
    const { profileAdmin } = this.props;
    const profileTableBody = items
      .filter(item => !item.deleteAfter)
      .map(item => (
        <tr key={item.viewId}>
          <td onClick={() => this.handleOpen(item.viewId)}>
            <div className="select-item">
              <div className="left-container">
                <span className="oi oi-list" />
                <span className="dashboard-name" > <label className="dashboard-name">{TranslateViewName(item.name)}</label> </span>
              </div>
              <span
                className={`oi oi-star ${
                  item.viewId === this.props.activeViewId ? `active` : ``
                  }`}
              />
            </div>
          </td>
          <td className="text-center">
            <input
              type="checkbox"
              id={item.viewId}
              checked={item.visibleAfter}
              disabled={!item.visibleAfter && (!profileAdmin && items.filter(i => i.visibleAfter && !i.deleteAfter).length >= config.sidebarDashboardsListLimit)}
              onChange={() => this.handleToggleVisible(item.viewId)}
            />
            <label htmlFor={item.viewId}>&nbsp;</label>
          </td>
          <td className="text-center">
            {item.viewId === 'emptyView' ? (
              <MemoTranslate value="profile.modal.deleteUnsupported" />
            ) : (
                <i
                  className="oi oi-trash ml-auto failed"
                  onClick={() => this.handleDelete(item.viewId)}
                  title={I18n.t('profile.modal.delete')}
                />
              )}
          </td>
        </tr>
      ));

    return (
      <div className="sidebar__profile-views__borderless-list">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>
                <MemoTranslate value="profile.modal.name" />
              </th>
              <th className="text-center">
                <MemoTranslate value="profile.modal.listed" />
              </th>
              <th className="text-center">
                <MemoTranslate value="profile.modal.delete" />
              </th>
            </tr>
          </thead>
          <tbody>
            {profileTableBody}
          </tbody>
        </table>
        <div className="d-flex">
          <button
            className="btn btn-approval w150 ml-auto"
            onClick={() => this.handleSave()}
          >
            <MemoTranslate value="profile.modal.save" />
          </button>
        </div>
      </div>
    );
  }
}

interface ProfileFormProps {
  viewNames: string[];
  onSubmit: (name: string) => void;
}
interface StateFormProps {
  inputValue: string;
  isConfirmationOpen: boolean;
  matchingViewNames: string[];
}

export class ProfileForm extends React.Component<
  ProfileFormProps,
  StateFormProps
  > {
  reservedNames = [
    TranslateViewName('profile.views.Last Dashboard'),
    TranslateViewName('profile.views.New Dashboard')
  ];

  constructor(props: ProfileFormProps) {
    super(props);

    this.state = {
      inputValue: '',
      isConfirmationOpen: false,
      matchingViewNames: []
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectMatching = this.selectMatching.bind(this);

    this.onConfirmationSuccess = this.onConfirmationSuccess.bind(this);
  }

  handleChange(event: any) {
    event.preventDefault();
    const target = event.target;
    let value = target.value;
    const matching = this.props.viewNames.filter((name: string) => {
      return value.length > 0 
      && name.toLowerCase().indexOf(value.toLowerCase()) === 0
      && this.reservedNames.indexOf(name) === -1;
    });
    this.setState(state => ({
      ...state,
      inputValue: value,
      matchingViewNames: matching
    }));
  }

  handleSubmit(event: any) {
    event.preventDefault();
    const { inputValue } = this.state;
    if (!!this.props.viewNames.find(name => name === inputValue)) {
      this.setState(state => ({
        ...state,
        isConfirmationOpen: true
      }));
    } else {
      this.props.onSubmit(inputValue);
    }
  }

  onConfirmationSuccess() {
    this.props.onSubmit(this.state.inputValue);
    this.setState(state => ({
      ...state,
      isConfirmationOpen: false
    }));
  }

  onConfirmationCancel() {
    this.setState(state => ({
      ...state,
      isConfirmationOpen: false
    }));
  }

  selectMatching(name: string) {
    this.setState(state => ({
      ...state,
      inputValue: name,
      matchingViewNames: []
    }));
  }

  render() {
    const { inputValue, isConfirmationOpen } = this.state;
    const valid = inputValue.length > 0
      && inputValue !== I18n.t('profile.views.New Dashboard')
      && inputValue !== I18n.t('profile.views.Last Dashboard');
    const expanded = this.state.matchingViewNames.length > 0;
    if (isConfirmationOpen) {
      return (
        <ConfirmationReplaceBlock
          viewName={inputValue}
          onSuccess={this.onConfirmationSuccess}
          onCancel={this.onConfirmationCancel}
        />
      );
    }

    const dropdownItems = this.state.matchingViewNames.map((name: string) => {
      return (
        <li  
          key={`profile-${name}`}
          onClick={(e) => this.selectMatching(name)}
        >
          <span className="oi oi-list" />
          <span className="dashboard-name" > 
            <label>{TranslateViewName(name)}</label>
          </span>
        </li>
      );
    });

    return (
      <form>
        <div className="form-group row">
          <div className="col">
            <input
              className="form-control"
              name="profile"
              onChange={this.handleChange}
              type="text"
              value={inputValue}
              required={true}
              autoFocus={true}
              autoComplete="off"
              placeholder={I18n.t('profile.modal.name')}
            />
            {!valid && inputValue.length > 0 &&
              <p className="reserved-name">
                <MemoTranslate value="profile.modal.reservedNameMessage" />
              </p>
            }
          </div>
          <div className={'profile__dropdown ' + (expanded ? 'visible' : '')}>
            <div className="profile__results">
              <ul>{dropdownItems}</ul>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-approval w150 ml-auto"
            onClick={this.handleSubmit}
            disabled={!valid}
          >
            <MemoTranslate value="profile.modal.save" />
          </button>
        </div>
      </form>
    );
  }
}

const NewProfileModal = ({
  viewNames,
  isModalOpen,
  onModalClose,
  onFormSubmit
}: any) => {
  return (
    <ModalWindow
      id="profile"
      onModalClose={() => onModalClose()}
      isOpen={isModalOpen}
      title={'profile.modal.title'}
    >
      <ProfileForm onSubmit={onFormSubmit} viewNames={viewNames} />
    </ModalWindow>
  );
};

const Color = (active: boolean) => {
  return active ? 'active' : 'white';
};

const ProfileChangeIcons = ({
  currentDashboardSaved,
  saveDashboard,
  lastDashboardActive,
  viewLoad
}: any) => {
  return (
    <div className="profile-views__icons">
    <span>&nbsp;&nbsp;</span>
    <span
      // save current Dashboard
      data-toggle="tooltip"
      title={I18n.t('profile.modal.title')}
      className={`ml-auto oi oi-check ${Color(currentDashboardSaved)}`}
      onClick={() => saveDashboard()}
    />
    <span
      // load "Last Dashboard" view
      data-toggle="tooltip"
      data-test="last-dashboard-icon"
      title={I18n.t('profile.views.Last Dashboard')}
      className={`ml-auto oi oi-clock ${Color(lastDashboardActive)}`}
      onClick={() => viewLoad(lastViewId)}
    />
    <span
      // open "New Dashboard" view
      data-toggle="tooltip"
      data-test="create-dashboard"
      title={I18n.t('profile.views.New Dashboard')}
      className="ml-auto oi oi-plus white"
      onClick={() => viewLoad(emptyViewId)}
    />
  </div>
  );
};

interface ProfileViewsProps {
  views: { viewId: string; name: string; default: boolean }[];
  activeViewId: string;
  visibleViewIds: string[];
  viewSave: (view: DashboardView) => void;
  viewLoad: (viewId: string) => void;
  viewRemove: (viewId: string, viewName?: string) => void;
  viewChangeVisibility: (viewId: string, isVisible: boolean) => void;
  modificationAllowed: boolean;
  isProfileAdmin: boolean;
  isGridQuadrantsActive: boolean;
}
interface StateViewsProps {
  isModalOpen: boolean;
  isMoreOpen: boolean;
}

export class ProfileViews extends React.Component<
  ProfileViewsProps,
  StateViewsProps
  > {
  constructor(props: ProfileViewsProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      isMoreOpen: false
    };

    this.handleNewProfile = this.handleNewProfile.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleViewLoad = this.handleViewLoad.bind(this);
  }

  handleNewProfile() {
    this.setState(state => ({
      ...state,
      isModalOpen: true
    }));
  }

  handleFormSubmit(viewName: string) {
    this.setState(state => ({
      ...state,
      isModalOpen: false
    }));

    const { views } = this.props;
    const existingView = views.find(v => v.name === viewName);

    const view: DashboardView = {
      viewId: existingView ? existingView.viewId : v1(),
      name: viewName,
      defaultView: true,
      data: {}
    };
    this.props.viewSave(view);
  }

  handleViewLoad(view: string) {
    this.props.viewLoad(view);
  }

  render(): JSX.Element {
    const { views, activeViewId, visibleViewIds } = this.props;
    const { isModalOpen, isMoreOpen } = this.state;
    const lastDashboardActive = activeViewId === lastViewId;
    let profileListViews = views
      .filter(v => visibleViewIds.indexOf(v.viewId) !== -1);
    const lastViewPosition = profileListViews.map(view => view.viewId).indexOf(lastViewId);
    if (lastViewPosition > -1) {
      const lastView = profileListViews.splice(lastViewPosition, 1);
      profileListViews.push(lastView[0]);
    }  
    // TODO: specification - setting views to be visible and then hiding them anyway has no sense

    const currentDashboardSaved = profileListViews.find(
      v => v.viewId === activeViewId
    )
      ? true
      : false;

    return (
      <div className="sidebar__profile-views">
        <div className="subtitle d-flex justify-content-between">
          <div>
            <MemoTranslate value="profile.views.title" tag={'h2'} />
          </div>
          {this.props.modificationAllowed ?
            <ProfileChangeIcons
              currentDashboardSaved={currentDashboardSaved}
              saveDashboard={() =>
                this.setState(state => ({
                  ...state,
                  isModalOpen: true
                }))}
              lastDashboardActive={lastDashboardActive}
              viewLoad={this.handleViewLoad}
            />
            : null
          }
        </div>
        {isModalOpen ? (
          <NewProfileModal
            viewNames={views.map(v => v.name)}
            onModalClose={() =>
              this.setState(state => ({ ...state, isModalOpen: false }))
            }
            isModalOpen={isModalOpen}
            onFormSubmit={this.handleFormSubmit}
          />
        ) : null}
        <ModalWindow
          id="profile-views"
          onModalClose={() =>
            this.setState(state => ({ ...state, isMoreOpen: false }))
          }
          isOpen={isMoreOpen}
          title="profile.views.title"
        >
          <ProfileSelectableList
            key="view-list-modal"
            views={views}
            visibleViewIds={visibleViewIds}
            onClose={() =>
              this.setState(state => ({ ...state, isMoreOpen: false }))
            }
            onViewRemove={this.props.viewRemove}
            onViewChangeVisibility={this.props.viewChangeVisibility}
            onViewLoad={this.props.viewLoad}
            activeViewId={activeViewId}
            profileAdmin={this.props.isProfileAdmin}
          />
        </ModalWindow>
        <div>
              <ProfileList
                key="view-list"
                activeViewId={activeViewId}
                views={profileListViews}
                onItemClick={(itemId: string) => this.props.viewLoad(itemId)}
                openMore={() =>
                  this.setState(state => ({
                    ...state,
                    isMoreOpen: true
                  }))
                }
                modificationAllowed={this.props.modificationAllowed}
              />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  views: getAvailableViews(state),
  activeViewId: getActiveViewId(state),
  visibleViewIds: getVisibleViewIds(state),
  modificationAllowed: isProfileModificationAllowed(state),
  isProfileAdmin: isProfileAdmin(state),
  isGridQuadrantsActive: getQuadrantGridActive(state)
});

const mapDispatchToProps = {
  viewSave: save,
  viewLoad: load,
  viewRemove: remove,
  viewChangeVisibility: toggleVisibility
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ProfileViews);
