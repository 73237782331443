import { PriceAlarmFormData, PriceAlarmFormMode, PriceAlarmType, PriceAlarm } from '../models/priceAlarms';

export function createPriceAlarmFormData(contract: string, price: number, columnGroup: string): PriceAlarmFormData {
  const formData: PriceAlarmFormData = {
      contractId: contract,
      mode: PriceAlarmFormMode.CREATE,
      type: columnGroup === 'bidPrices' ? PriceAlarmType.BID_PRICE : (columnGroup === 'askPrices' ? PriceAlarmType.ASK_PRICE : PriceAlarmType.LAST_PRICE_LESS),
      price: price,
      priceAlarmId: '',
      expires: undefined,
      customer: '',
      description: ''
  };

  return formData;
}

export function createModifyPriceAlarmFormData(priceAlarm: PriceAlarm): PriceAlarmFormData {
  const formData: PriceAlarmFormData = {
    contractId: priceAlarm.contract,
    mode: PriceAlarmFormMode.MODIFY,
    type: priceAlarm.priceAlarmType,
    price: priceAlarm.price,
    priceAlarmId: priceAlarm.id,
    expires: priceAlarm.expires ? new Date(priceAlarm.expires) : undefined,
    customer: priceAlarm.customer,
    description: priceAlarm.description
  };

  return formData;
}

export function createCancelPriceAlarmFormData(priceAlarm: PriceAlarm): PriceAlarmFormData {
  const formData: PriceAlarmFormData = {
    contractId: priceAlarm.contract,
    mode: PriceAlarmFormMode.CANCEL,
    type: priceAlarm.priceAlarmType,
    price: priceAlarm.priceLevel,
    priceAlarmId: priceAlarm.id,
    expires: priceAlarm.expires ? new Date(priceAlarm.expires) : undefined,
    customer: priceAlarm.customer,
    description: priceAlarm.description
  };
  return formData;
}