import { getPathIfNotFindNatively, findRootElement } from '../../../../utils/helper/eventUtils';
import { I18n } from 'react-redux-i18n';
import { IMarket } from '../../../models/market';

const getOverlayPosition = (dockTarget: any, target: any, contextHeight: number, expandedRowHeight: number) => {
    const homeContainer = findRootElement(dockTarget, 'docks__wrapper');
    if (!homeContainer) {
        return 'bottom';
    }
    const screenHeight = homeContainer.getBoundingClientRect()['bottom'];
    const targetY = target.getBoundingClientRect()['top'];
    return screenHeight < targetY + contextHeight + expandedRowHeight ? 'top' : 'bottom';
};

const getContextMenuPositionForTargets = (dockTarget: any, target: any, x: number, y: number) => {
    const xPosition = (!!dockTarget
        ? x - dockTarget.getBoundingClientRect()['x'] 
        : target.offsetLeft); 
    
    const yPosition = (!!dockTarget
        ? y - dockTarget.getBoundingClientRect()['y'] 
        : target.offsetTop); 
    return { x: xPosition, y: yPosition };
};

export const getContextMenuPosition = (e: any, logFatal: any, typeName: string) => {
    const nativeEvent = e.nativeEvent;
    const eventPath =
        (nativeEvent.composedPath && nativeEvent.composedPath()) ||
        nativeEvent.path ||
        getPathIfNotFindNatively(nativeEvent);
    if (
        eventPath &&
        eventPath.find((el: any) => el.className.match(typeName))
    ) {
        const parentTd = eventPath.find((el: Element) =>
            el.classList.contains('parentTd')
        );
        const dockTarget = eventPath.find((el: any) =>
            el.className.split(' ').includes('react-resizable')
        );
        const dockWrapperTarget = dockTarget.closest('.dock__wrapper');
        if (dockWrapperTarget) {
            (dockWrapperTarget as HTMLElement).style.zIndex = '3'; // to have the market context menu not get stuck behind docked tiles
        }
        const target = !!parentTd ? parentTd : e.currentTarget;
        return getContextMenuPositionForTargets(dockTarget, target, e.pageX, e.pageY);
    } else {
        logFatal(I18n.t('error.No path information'));
    }
    return null;
};

export const getMarketContextMenuPosition = (e: any, logFatal: any, actionData: any) => {
    return getContextMenuPosition(e, logFatal, 'instrument');
};

export const getOverlayPlacement = (dockTarget: any, index: any, height: number) => {
    if (!dockTarget || !(dockTarget instanceof HTMLElement)) {
        return 'bottom';
    }
    const indexString: string = '' + index;
    const columnTarget = dockTarget.querySelector(`.row-${indexString.replace(/\//g, '-')}`);
    if (columnTarget) {
        return getOverlayPosition(dockTarget, columnTarget, height, 0);
    }
    return 'bottom';
};

export const getWidthFromColumnWidthCode = (code: string) => {
    try {
        return code === 'instrument-w-default' ? 0 : parseInt(code.replace('instrument-w-', ''));
    } catch (e) {
        return 0;
    }
}

export const getColumnsToDisplay = (market: IMarket, compactColumnsEnabled: boolean) => {
    const hiddenColumns = market ? [...market.hiddenColumnNames] : [];
    const columns = market
      ? market.columns.filter(
        (col: any) =>
          hiddenColumns.indexOf(col.group + '__' + col.name) === -1
          && (!compactColumnsEnabled || !col.combine)
      )
      : [];

    if (compactColumnsEnabled) {
      let compactColumns = [];
      for (let colIndex = 0; colIndex < market.columns.length; colIndex++) {
        const col = market.columns[colIndex];
        if (col.combine && compactColumns.indexOf('combine__'+col.group) === -1) {
          compactColumns.push('combine__'+col.group);
          columns.push(col);
        }
      }
    }
    return columns;
}

export const getColumns = (market: IMarket, compactColumnsEnabled: boolean) => {
    const order = ['price', 'quantity', 'counterparty'];

    const columnMap = market.columns.reduce((combined, c) => {
        if (market.hiddenColumnNames.indexOf(c.group + '__' + c.name) === -1) {
            if (c.combine && compactColumnsEnabled) {
                combined[c.group] = combined[c.group] ? [...combined[c.group], c].sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name)) : [c];
            } else {
                combined[c.title] = [c];
            }
        }
        return combined;
    }, 
    new Map());
    return columnMap;
}