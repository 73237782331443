import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { getUploadedBulkOrderById } from '../selectors/bulkOrders';
import store from '../../main/store/store';
import orderbooksStore from '../../orderbook/store/orderbooks';
import OrderFormData, { OrderFormMode } from '../../orders/models/formData';
import { Contract } from '../../orderbook/models/contracts';
import { basketOrderModify } from '../actions/bulkOrders';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { getOrderbookContracts } from '../../orderbook/selectors/contracts';
import BasketOrderFormComponent from '../containers/BasketOrderFormComponent';
import { Restriction } from '../../orders/models/orders';
import { Validation, ValidationResult } from '../../shared/validation/model';
import ValidationService from '../../shared/validation/service';
import { receiveMessage } from '../../shared/messenger/actions/messenger';

interface BulkPopoverProps {
    data: any; 
    hidePopover: () => void; 
    visible: boolean;
}

interface BulkPopoverState {
    error: boolean;
    success: boolean;
    popoverVisible: boolean;
    submitted: boolean;
    order: any;
    formData: OrderFormData;
}

export class BulkOrderFormComponent extends React.Component<BulkPopoverProps, BulkPopoverState> {
    actionCallback: (param: any) => void;

    static getDerivedStateFromProps(props: BulkPopoverProps, state: BulkPopoverState) {
        let order = state.order;
        if (!order || props.data.id !== order.id) {
            order = getUploadedBulkOrderById(
            store.getState(), props.data.id);
        }
        if (!order) {
            return {
                state
            };
        }
        return {
            ...state,
            order: order,
            formData: {
                price: order.limit,
                quantity: order.quantity,
                restriction: order.restriction,
                contractId: order.contract,
                mode: OrderFormMode.MODIFY,
                buy: order.buy,
                orderId: order.orderId,
                revisionNo: 0,
                seenOrderToBeModified: null
            },
            submitted: false
        };
        
    }

    constructor(props: BulkPopoverProps) {
        super(props);
        
        this.state = {
            error: false,
            success: false,
            popoverVisible: false,
            submitted: false,
            order: undefined,
            formData: {
                price: 0,
                quantity: 0,
                restriction: Restriction.NONE,
                contractId: '',
                mode: OrderFormMode.MODIFY,
                buy: true,
                orderId: 0,
                revisionNo: 0,
                seenOrderToBeModified: null,
                associatedContracts: []
            }
        };
    }

    submitHandler = (form: OrderFormData, newCorrelationId: string, validations: Validation) => {
        form.restriction = Restriction.NONE;
        const validationService = new ValidationService();
        const result: boolean = Object.keys(form).reduce((res: boolean, key: string) => {
            let validationValue: ValidationResult = validationService.format(
                validations.rules[key],
                form[key]
            );
            if (!validationValue.valid) {
                store.dispatch(receiveMessage(newCorrelationId, validationValue.message ? I18n.t(validationValue.message) : null, true));
            }
            return res && validationValue.valid;
        }, true);
        if (result) {
            store.dispatch(basketOrderModify({
                ...this.state.order,
                quantity: form.quantity,
                limit: form.price,
                buy: form.buy
            }));
            this.hidePopover();
        }
    }

    handleAction = (options: any) => {
        if (options.actionCallback) {
            this.actionCallback = options.actionCallback;
        }

        if (options.state) {
            this.setState( (prevState: BulkPopoverState) => {
                return {
                    ...prevState,
                    error: options.state.error,
                    success: options.state.success,
                    popoverVisible: options.state.modalVisible,
                    submitted: options.state.submitted && !options.state.error
                };
            });
            
            if (options.state.success) {
                this.hidePopover();
            }
        }
        
    }

    handleClear = () => {
        this.setState((prevState: BulkPopoverState) => {
            return {
                ...prevState,
                submitted: false,
                error: false,
                success: false,
                formData: {
                    price: this.state.order.limit,
                    quantity: this.state.order.quantity,
                    restriction: this.state.order.restriction,
                    contractId: this.state.order.contract,
                    mode: OrderFormMode.MODIFY,
                    buy: this.state.order.buy,
                    orderId: this.state.order.orderId,
                    revisionNo: 0,
                    seenOrderToBeModified: null,
                    associatedContracts: []
                }
            };
        });
    }

    handleKeyPress = (target: any) => {
        if (target.charCode === 13) {
            if (!this.state.submitted) {
                this.actionCallback('submit');
                
                this.setState((prevState: BulkPopoverState) => {
                    return {
                        ...prevState,
                        submitted: true
                    };
                });
            }
        }
    }

    hidePopover() {
        this.actionCallback('close');
        this.setState(prevState => {
            return {
                ...prevState,
                submitted: false
            };
        });
        this.props.hidePopover();
    }

    render() {
        if (!this.props.data || !this.props.data.id) {
            return (<div><Translate value="error.general" /></div>);
        }

        if (!this.state.order || !this.state.order.contract) {
            return (<div><Translate value="error.general" /></div>);
        }

        let contract: Contract = getOrderbookContracts(orderbooksStore.getState())[this.state.order.contract];
        return (this.props.visible ? (
            <div
                    id="orderForm"
                    onKeyPress={this.handleKeyPress}
            >
                <ModalWindow
                    id="meet-bulk__popover"
                    onModalClose={() => this.hidePopover()}
                    isOpen={this.props.visible}
                    title={I18n.t('bulk.title')}
                >
                    { 
                        <div >
                            <BasketOrderFormComponent 
                                actionHandler={this.handleAction} 
                                handleSubmit={this.submitHandler} 
                                handleClear={this.handleClear} 
                                handleSuccess={() => null} 
                                data={this.state.formData} 
                                contract={contract}  
                                onSanityCheck={(activated: boolean, warning: string[]) => null}
                                selectedContractId={contract?.id}
                            />

                            <div className="modal-footer">
                                <button
                                    style={{ display: this.state.success ? 'none' : 'block' }}
                                    type="submit"
                                    className="btn btn-approval w150"
                                    onClick={
                                        () => {
                                            if (!this.state.submitted) {
                                                this.setState((prevState: BulkPopoverState) => {
                                                    return {
                                                        ...prevState,
                                                        submitted: true
                                                    };
                                                });
                                                this.actionCallback('submit');
                                            }
                                        }
                                    }
                                    disabled={this.state.submitted}
                                
                                >{I18n.t('order.modify')}
                                </button>
                            </div>
                    </div>
                    }
                </ModalWindow>
            </div>
        ) : null);
    }
}

// functional component for table showPopover function
export const BulkOrderRowPopover = (params: any) => {
    return (<BulkOrderFormComponent data={params.data} hidePopover={params.hidePopover} visible={params.visible} />);
};