import * as React from 'react';

import LoginFormData from '../models/login';
import {
  Consumer as ThemeConsumer,
  Provider as ThemeProvider
} from '../../shared/context/themeContext';
import history from '../../main/history';
import { I18n, Translate } from 'react-redux-i18n';
import { config } from '../../main/config';
import store from '../../main/store/store';
import { delayLogin } from '../actions/authentication';
import { ReactReduxContext } from 'react-redux';
import { isThisSecond } from 'date-fns/esm';

interface Props {
  isAuthorized: boolean;
  isLoginPending: boolean;
  error: any;
  handleSubmit: (form: LoginFormData) => void;
  loginError: any;
  pendingLogin: (message: string) => void;
  reactivateSubmitButtonAfterTimeout: (message: string) => void;
  timeUntilNextLoginAttempt: number;
  takeoverConfirm: (payload: boolean) => void;
  showTakeoverConfirm: boolean;
}

interface State extends LoginFormData {
  userNameIsFocused: boolean;
  passwordIsFocused: boolean;
  themeContextValue: {
    selectedTheme: string;
    setTheme: any;
  };
  isButtonTimeoutRunning: boolean;
  currentLoginDelay: number;
  takeoverWaiting: boolean;
}

export function NotificationMessage(props: any): JSX.Element | null {
  if (!props.message) {
    return null;
  }
  // message after server disconnect is not translated
  let message = props.message;
  if (!message) {
    message = props.message.replace(/\.$/, '');
    message = message.substring(message.lastIndexOf('.') + 1);
  }
  let level = props.level ? props.level : 'danger';
  return (
    <div className={'alert alert-' + level} role="alert" data-test="login-notification-message">
      {message}
    </div>
  );
}

export default class LoginForm extends React.Component<Props, State> {
  private usernameInput: any;
  private passwordInput: any;
  private timeouts: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      token: '',
      profile: I18n.t('login.profile', { number: 1 }),
      userNameIsFocused: false,
      passwordIsFocused: false,
      themeContextValue: {
        selectedTheme: 'theme-estar',
        setTheme: this.setTheme
      },
      isButtonTimeoutRunning: false,
      currentLoginDelay: props.timeUntilNextLoginAttempt,
      takeoverWaiting: false
    };

    this.timeouts = [];

    this.usernameInput = React.createRef();
    this.passwordInput = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isPendingLogin = this.isPendingLogin.bind(this);
    this.reactivateSubmitButtonAfterTimeout = this.reactivateSubmitButtonAfterTimeout.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.confirmTakeover = this.confirmTakeover.bind(this);
  }

  componentDidMount() {
    if (this.props.isAuthorized) {
      history.replace('/');
    }
  }

  componentDidUpdate() {
    if (this.props.isAuthorized) {
      history.replace('/');
    }
    if (this.state.isButtonTimeoutRunning && this.props.timeUntilNextLoginAttempt !== this.state.currentLoginDelay) {
      this.updateTimer();
    }
  }

  handleInputChange(event: any) {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  }

  reactivateSubmitButtonAfterTimeout() {
    if (this.state.isButtonTimeoutRunning) {
      this.setState(prevState => {
        return {
          ...prevState,
          isButtonTimeoutRunning: false
        };
      });
    }
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.updateTimer();
    this.props.handleSubmit(this.state);
  }

  updateTimer() {
    const timeoutTime = this.props.timeUntilNextLoginAttempt;
    this.setState(prevState => {
      return {
        ...prevState,
        isButtonTimeoutRunning: true,
        currentLoginDelay: timeoutTime
      };
    });
    this.timeouts.map((t: any) => clearTimeout(t));
    this.timeouts = [
      ...this.timeouts,
      setTimeout(() => {
        if (timeoutTime > 0) {
            store.dispatch(delayLogin(0));
        }
        this.reactivateSubmitButtonAfterTimeout();
      } , 2000 + timeoutTime)
    ];
  }

  setTheme(theme: string) {
    this.setState({
      themeContextValue: {
        ...this.state.themeContextValue,
        selectedTheme: theme
      }
    });
  }

  isPendingLogin() {
    let loginPending = this.props.isLoginPending;
    return loginPending;
  }

  getNotificationMessage() {
    let error = this.props.error;
    let pending = this.props.isLoginPending;
    let pendingMessage = I18n.t('login.log.Pending');
    let message = '';
    if (error) {
      message += (error.indexOf('.') > 0 ? I18n.t(error) : error);
      if (pending) {
        message += ' - ' + pendingMessage;
      }
      return message;
    }
    if (pending) {
      return pendingMessage;
    }
    return null;
  }

  getNotificationLevel() {
    return this.props.error ? 'danger' : 'info';
  }

  isSubmitDisabled() {
    let missingInput =
      this.state.username.length === 0 || this.state.password.length === 0;
    return (
      missingInput || (this.isPendingLogin() && this.state.isButtonTimeoutRunning)
    );
  }

  confirmTakeover(confirm: boolean) {
    this.props.takeoverConfirm(confirm);
    if (confirm) {
      this.setState((prevState) => {
        return {
          ...prevState,
          takeoverWaiting: true
        }
      });
    }
  }

  render() {
    const form = this.state;
    const logoName = config.branding.logo;
    const logo = `${config.subfolder}/logo/${logoName}`;

    const takeoverConfirm = (
      <div className="login-error">
        <Translate value={this.state.takeoverWaiting ? 'login.takeOverWaiting' : 'login.takeOverConfirm' } /> 
        <div hidden={this.state.takeoverWaiting} className="btn-group">
            <button data-test="takeover-session-confirm" className="btn btn-approval w150 ml-auto" onClick={e => this.confirmTakeover(true)}>OK</button><button className="btn btn-secondary w150 ml-auto" onClick={e => this.props.takeoverConfirm(false)}>Cancel</button>
        </div>
      </div>);

    return (
      <ThemeProvider value={this.state.themeContextValue}>
        <ThemeConsumer>
          {({ selectedTheme }) => (
            <div className={selectedTheme + ' h-100'}>
             <div className="login-container row justify-content-center h-100">
                <div className="login-form center-block" hidden={config.ssoEnabled}>
                { (this.props.showTakeoverConfirm) ?
                      takeoverConfirm : (<React.Fragment>
                  <form
                    className="d-flex justify-content-start"
                    data-test="login-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="logo">
                      <img src={logo} alt="Logo" />
                      <div className="login-title" hidden={!config.branding.title}>{config.branding.title}</div>
                    </div>
                    <NotificationMessage
                      message={this.getNotificationMessage()}
                      level={this.getNotificationLevel()}
                    />
                   
                      <div className="form-group">
                        <input
                          ref={this.usernameInput}
                          name="username"
                          type="text"
                          data-test="login-user-name"
                          value={form.username}
                          id=".username"
                          placeholder={I18n.t('login.Username')}
                          className="form-control"
                          onChange={this.handleInputChange}
                          autoFocus={true}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          ref={this.passwordInput}
                          name="password"
                          type="password"
                          data-test="login-password"
                          value={form.password}
                          id=".password"
                          placeholder={I18n.t('login.Password')}
                          className="form-control"
                          onChange={this.handleInputChange}
                        />
                        {this.props.loginError && !this.isPendingLogin() && (
                          <small className="login-error" data-test="login-error-message">
                            {this.props.loginError.exception}
                          </small>
                        )}
                      </div>
                      <div className="d-flex justify-content-end mt-auto w-100">
                        <input
                          type="submit"
                          className="btn btn-primary w150 login-button"
                          data-test="login-submit"
                          value={I18n.t('login.Login')}
                          disabled={this.isSubmitDisabled()}
                        />
                      </div>
                      
                    
                  </form>
                  </React.Fragment>)}
                </div>
                <div className="login-form center-block" hidden={!config.ssoEnabled}>
                  { (this.props.showTakeoverConfirm) ?
                    takeoverConfirm : (<><Translate value="login.ssoLogin" /><div className="spinner-login"><div className="spinner-login spinner-border text-secondary" role="status">
                      <span className="sr-only"></span>
                    </div></div></>)
                  }
                  
                </div>
              </div>
            </div>
          )}
        </ThemeConsumer>
      </ThemeProvider>
    );
  }

  componentWillUnmount() {
    this.timeouts.map((t: any) => clearTimeout(t));
  }
}
