import * as React from 'react';

import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { I18n } from 'react-redux-i18n';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { QuoteRequestFormData, QuoteRequestMode } from '../models/request';
import { getQuoteRequestFormData, isQuoteRequestFormVisible, getSelectedContractId } from '../selectors/requests';
import QuoteRequestForm from '../containers/quoteRequestForm';
import { openQuoteRequest, hideQuoteRequest } from '../actions/requests';
import { FormState } from '../reducers/quoteRequestForm';

interface QuoteRequestModalProps {
    sideBarVisible: boolean;
    modalVisible: boolean;
    showModal: (formData: QuoteRequestFormData) => void;
    hideModal: () => void;
    selectedContractId: string;
    formData: FormState;
}

interface QuoteRequestModalState {
    modalVisible: boolean;
    submitted: boolean;
    success: boolean;
    error: boolean;
}

export class QuoteRequestModalComponent extends React.Component<QuoteRequestModalProps, QuoteRequestModalState> {
    actionCallback: (action: string) => void;

    constructor(props: QuoteRequestModalProps) {
        super(props);
        this.state = {
            modalVisible: props.modalVisible,
            submitted: false,
            success: false,
            error: false
        };
       // this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    /*showModal(event: any) {
        this.props.showModal({ price: 0, quantity: 0, restriction: Restriction.NONE, buy: true, contractId: '', mode: OrderFormMode.ENTER, orderId: 0, revisionNo: 0 });
    }*/

    hideModal() {
        this.actionCallback('close');
        this.props.hideModal();
        this.setState(prevState => {
            return {
                ...prevState,
                submitted: false
            };
        });
    }
    onSubmit(event: any) {
        this.props.hideModal();
    }

    handleKeyPress(target: any) {
        if (target.charCode === 13) {
            if (!this.state.submitted) {
                this.actionCallback('submit');
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        submitted: true
                    };
                });
            }
        }
    }

    handleAction(options: any) {
        if (options.actionCallback) {
            this.actionCallback = options.actionCallback;
            this.actionCallback = this.actionCallback.bind(this);
        }
        if (options.state) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    error: options.state.error,
                    success: options.state.success,
                    modalVisible: options.state.modalVisible,
                    submitted: this.state.submitted && !options.state.error
                };
            });
            if (options.state.success) {
                this.hideModal();
            }
        }
    }

    getModeData(props: QuoteRequestModalProps): any {
        let title = '';
        let button = '';
        switch (props.formData.data.mode) {
            case QuoteRequestMode.ANSWER:
            {
                title = 'quoteRequest.answer';
                button = 'quoteRequest.form.answerSubmit';
            }
            break;
            case QuoteRequestMode.CANCEL: 
            {
                title = 'quoteRequest.cancel';
                button = 'quoteRequest.form.cancelSubmit';
            }
            break;
            case QuoteRequestMode.ENTER:
            default: {
                title = 'quoteRequest.enter';
                button = 'quoteRequest.form.createSubmit';
            }
            
        }
        return { title: title, button: button };
    }

    render() {
        const { sideBarVisible, modalVisible } = this.props;
        const { title, button } = this.getModeData(this.props);
        return (
            <div
                id="quoteRequestForm"
                style={
                    { position: 'absolute', bottom: '3.75px', right: sideBarVisible ? '330px' : '7.5px'}}
                onKeyPress={this.handleKeyPress}
            >
                <ModalWindow
                    id="quoteRequestModal"
                    onModalClose={() =>
                        this.hideModal()
                    }
                    isOpen={modalVisible}
                    title={`${I18n.t(title)} `}
                >
                    <QuoteRequestForm  actionHandler={this.handleAction} />
                    <div className="modal-footer">
                        <button
                            style={{ display: this.state.success ? 'none' : 'block' }}
                            type="submit"
                            className="btn btn-approval w150"
                            onClick={
                                () => {
                                    if (!this.state.submitted) {
                                        this.actionCallback('submit');
                                        this.setState((prevState) => {
                                            return {
                                                ...prevState,
                                                submitted: true
                                            };
                                        });
                                    }
                                }
                            }
                            disabled={this.state.submitted}
                            autoFocus={(!this.state.submitted && this.props.formData.data.mode === QuoteRequestMode.CANCEL)}
                        >{I18n.t(button)}
                        </button>
                    </div>
                </ModalWindow>
            </div>
        );
    }

}

const mapStateToProps = (state: State) => ({
    modalVisible: isQuoteRequestFormVisible(state),
    selectedContractId: getSelectedContractId(state),
    formData: getQuoteRequestFormData(state)
});

const mapDispatchToProps = {
    showModal: openQuoteRequest,
    hideModal: hideQuoteRequest
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(QuoteRequestModalComponent);
