import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { ITable } from '../../shared/ui/models/table';
import { IOrder } from '../models/orders';
import { sortTable } from '../../shared/utils/helper/sorter';
import { tableVersionSelectorCreator, argsSelectorCreator } from '../../shared/utils/selectors/agrSelector';

import orderBookStore from '../../orderbook/store/orderbooks';
import { SanityCheck, SanityCheckScope } from '../models/sanityChecks';
import { config } from '../../main/config';

const getOrdersState = (state: State) => state.orders;
const getOrderFormState = (state: State) => state.orders.orderForm;

export const getOrderEntities = createSelector(
  getOrdersState,
  s => s.entities
);
export const getOrderDataVersion = createSelector(
  getOrdersState,
  s => ({ version: s.dataVersion })
);
export const getOrderIds = createSelector(
  getOrdersState,
  s => s.ids
);
export const getAllOrders = createSelector(
  getOrderEntities,
  getOrderIds,
  (entities, ids) => ids.map(id => entities[id])
);
export const getSelectedContractId = createSelector(
  getOrderFormState,
  s => s.selectedContractId
);
export const getSelectedContract = createSelector(
  getOrderFormState,
  s => {
    const t = orderBookStore.getState().orderbook.contracts.entities;
    return !!t[s.selectedContractId]
      ? t[s.selectedContractId]
      : { name: 'N/A' };
  }
);
export const getPreselectedContractId = createSelector(
  getOrderFormState,
  s => s.preselectedContractId
);
export const isOrderFormVisible = createSelector(
  getOrderFormState,
  s => s.visible
);
export const isOrderSubmitDisabled = createSelector(
  getOrderFormState,
  s => s.disabled
);
export const getOrderFormData = createSelector(
  getOrderFormState,
  s =>  s.data
);

export const getOrderRows = tableVersionSelectorCreator(
  [
    getAllOrders,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getOrderDataVersion
  ],
  (orders, scoped) => {
    const orderTable = scoped.table;
    if (orderTable) {
      const _orders = orders.map((order: IOrder) => {
        return orderTable.columns.reduce(
          (result: any, col) => {
            if (col.name === 'date') {
              const d = new Date(order[col.originalName]);
              d.setHours(0);
              d.setMinutes(0);
              d.setSeconds(0);
              d.setMilliseconds(0);
              return { ...result, [col.name]: d.getTime() };
            }
            return { ...result, [col.name]: order[col.originalName] };
          },
          { id: order.id }
        );
      });
      return sortTable(_orders, orderTable.sorting.length === 0 ? config.ui.orderTable.defaultSorting : orderTable.sorting, orderTable.hiddenColumnNames);
    }
    return [];
  }
);

/*export const isOwnOrder = argsSelectorCreator([getOrdersState, (state: State, id: number) => id],
  (state, id) => state.ids.indexOf(id) > -1
);*/

export const getSearchPhrase = createSelector(
  getOrderFormState,
  s => s.phrase
);

export const getPreviewPrices = createSelector(
  getOrderFormState,
  s => s.preview
);

export const getSanityLimits = argsSelectorCreator(
  [
    getOrderFormState, (state: State, productId: string) => productId
  ], 
  (s, productId) => {
    if (!s.sanityChecks || !productId) {
      return null;
    }
    let selectedChecks = [];
    for (let i = 0; i < s.sanityChecks.length; i++) {
      const sanityCheck:SanityCheck = s.sanityChecks[i];
      if (sanityCheck.scope === SanityCheckScope.GENERAL || sanityCheck.productId === productId) {
        selectedChecks.push(sanityCheck);
      }
    }
    return selectedChecks;
  }
);
