import * as Connection from '../actions/connection';
import * as Dock from '../../shared/dock/actions/dock';
export interface ConnectionState {
    lastHeartbeat: number;
    messageQueue: any[];
    connecting: boolean;
    connected: boolean;
}

export const initialState: ConnectionState = {
    lastHeartbeat: 0,
    messageQueue: [],
    connecting: false,
    connected: false
};

export function connectionReducer(connectionState: ConnectionState = initialState, action: Connection.Action) {
    switch (action.type) {

        case Connection.ActionTypes.HEARTBEAT_RECEIVED: {
            return {
                ...connectionState,
                connecting: false,
                connected: true,
                lastHeartbeat: new Date().getTime()
            };
        }
        case Connection.ActionTypes.ENQUEUE_FAILED_MESSAGE: {
            return {
                ...connectionState,
                messageQueue: connectionState.messageQueue ? connectionState.messageQueue.concat([action.payload]) : [action.payload]
            };
        }
    
        default: {
            return {...connectionState};
        }
    }
}

export const state = (connectionState: ConnectionState, action: Connection.Action) => {
    return connectionReducer(connectionState, action);
};