const jsSHA = require('jssha');
/**
 * encrypt password in credentials object
 * Using same process as backend crypto service: SHA-512 of string password+username, result in base64
 * @param credentials 
 */
export const encryptPassword = (credentials: {[key: string]: any, password: string, username: string}): any => {
    const sha = new jsSHA('SHA-512', 'TEXT');
    sha.update(credentials.password + credentials.username);
    const password = sha.getHash('B64');
    const returnObj = {...credentials, password: password};

    return returnObj;
};