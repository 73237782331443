import { PriceAlarmFormData, PriceAlarmFormMode, PriceAlarmType } from '../models/priceAlarms';
import * as React from 'react';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { I18n } from 'react-redux-i18n';
import { State } from '../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import { openPriceAlarmForm, closePriceAlarmForm } from '../actions/priceAlarms';
import { isPriceAlarmFormVisible, getPriceAlarmFormData } from '../selectors/priceAlarms';
import PriceAlarmFormComponent from '../containers/priceAlarmFormContainer';

interface PriceAlarmModalProps {
  sideBarVisible: boolean;
  modalVisible: boolean;
  showModal: (formData: PriceAlarmFormData) => void;
  hideModal: () => void;
  selectedContractId: string;
  formData: PriceAlarmFormData;
  disabled: boolean;
}

interface PriceAlarmModalState {
  modalVisible: boolean;
  submitted: boolean;
  success: boolean;
  error: boolean;
}

export class PriceAlarmModalComponent extends React.Component<PriceAlarmModalProps, PriceAlarmModalState> {
  actionCallback: (action: string) => void;

  constructor(props: PriceAlarmModalProps) {
      super(props);
      this.state = {
          modalVisible: props.modalVisible,
          submitted: false,
          success: false,
          error: false
      };
      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.handleAction = this.handleAction.bind(this);
      this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  showModal(event: any) {
      this.props.showModal({ mode: PriceAlarmFormMode.CREATE, priceAlarmId: '', expires: undefined, price: 0, type: PriceAlarmType.LAST_PRICE_LESS, contractId: '', customer: '', description: ''  });
  }

  hideModal() {
      this.actionCallback('close');
      this.props.hideModal();
      this.setState(prevState => {
          return {
              ...prevState,
              submitted: false
          };
      });
  }
  onSubmit(event: any) {
      this.props.hideModal();
  }

  handleKeyPress(target: any) {
      if (target.charCode === 13) {
          if (!this.state.submitted && !this.props.disabled) {
              this.actionCallback('submit');
              
              this.setState((prevState) => {
                  return {
                      ...prevState,
                      submitted: true
                  };
              });
          }
      }
  }

  handleAction(options: any) {
      if (options.actionCallback) {
          this.actionCallback = options.actionCallback;
          this.actionCallback = this.actionCallback.bind(this);
      }
      if (options.state) {
          this.setState(prevState => {
              return {
                  ...prevState,
                  error: options.state.error,
                  success: options.state.success,
                  modalVisible: options.state.modalVisible,
                  submitted: this.state.submitted && !options.state.error
              };
          });
          if (options.state.success) {
              this.hideModal();
          }
      }
  }

  getModeData(props: PriceAlarmModalProps): any {
      let title = '';
      let button = '';
      switch (props.formData.mode) {
          case PriceAlarmFormMode.MODIFY: {
              title = 'priceAlarm.modify';
              button = 'priceAlarm.form.modifySubmit';
              break;
          }
          case PriceAlarmFormMode.CANCEL: {
              title = 'priceAlarm.delete';
              button = 'priceAlarm.form.deleteSubmit';
              break;
          }
          case PriceAlarmFormMode.CREATE:
          default: {
              title = 'priceAlarm.enter';
              button = 'priceAlarm.form.createSubmit';
          }
      }
      return { title: title, button: button };
  }

  render() {
      const { sideBarVisible, modalVisible } = this.props;
      const { title, button } = this.getModeData(this.props);
      const submitDisabled = this.state.submitted || this.props.disabled;

      return (
          <div
              id="priceAlarmForm"
              style={
                  { position: 'absolute', bottom: '3.75px', right: sideBarVisible ? '330px' : '7.5px'}}
              onKeyPress={this.handleKeyPress}
          >
              <ModalWindow
                  id="order"
                  onModalClose={() =>
                      this.hideModal()
                  }
                  isOpen={modalVisible}
                  title={`${I18n.t(title)} `}
              >
                  <PriceAlarmFormComponent actionHandler={this.handleAction} />

                  <div className="modal-footer">
                      <button
                          style={{ display: this.state.success ? 'none' : 'block' }}
                          type="submit"
                          className="btn btn-approval w300"
                          onClick={
                              () => {
                                  if (!submitDisabled) {
                                      this.actionCallback('submit');
                                      this.setState((prevState) => {
                                          return {
                                              ...prevState,
                                              submitted: true
                                          };
                                      });
                                  }
                              }
                          }
                          disabled={submitDisabled}
                      >{I18n.t(button)}
                      </button>
                  </div>
              </ModalWindow>
          </div>
      );
  }

}

const mapStateToProps = (state: State) => ({
  modalVisible: isPriceAlarmFormVisible(state),
  formData: getPriceAlarmFormData(state),
  disabled: false
});

const mapDispatchToProps = {
  showModal: openPriceAlarmForm,
  hideModal: closePriceAlarmForm
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(PriceAlarmModalComponent);