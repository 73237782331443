import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import * as Rx from 'rxjs';
import ContractsService from '../services/contracts';
import { State } from '../../main/reducers/rootReducer';
import * as models from '../models/contracts';
import { ActionTypes as Authentication } from '../../authentication/actions/authentication';
import { ActionTypes as Connection } from '../../authentication/actions/connection';
import {
  ActionTypes,
  contractsLoad,
  contractsMapLoadSuccess,
  contractsLoadFailure,
  staticDataLoaded,
  contractsStateChangeReceived,
  persistentContractResponseReceived
} from '../actions/contracts';
import * as Products from '../actions/products';
import * as Instruments from '../../instruments/actions/instruments';
import {
  isStaticDataLoaded,
  isContractDataLoaded
} from '../selectors/orderbooks';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import RequestsService from '../../requests/services/requests';
import { GenericRequest } from '../../main/models/application';
import {
  filter,
  map,
  switchMap,
  catchError,
  takeUntil,
  mergeMap
} from 'rxjs/operators';
import orderBookStore from '../store/orderbooks';

const contractsService = new ContractsService();

const requestsService = new RequestsService();

export const loadContractsMap: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD_CONTRACTS  || action.type === Products.ActionTypes.SUBSCRIBE_PRODUCTS_RESPONSE),
    switchMap((act) => {
      const orderBookstate = orderBookStore.getState();

      if (!isStaticDataLoaded(orderBookstate) || act.type === Products.ActionTypes.SUBSCRIBE_PRODUCTS_RESPONSE) {
        contractsService.sendContractsInquiry(<
          models.InquireContractsRequest
        > {});
      }
      if (!isContractDataLoaded(orderBookstate)) {
        return contractsService.subscribeContractMap().pipe(
          map((content: any) => {
            return orderBookStore.dispatch(
              contractsMapLoadSuccess(content.contractMap)
            );
          }),
          takeUntil(
            actions$.pipe(
              filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST)
            )
          )
        );
      }
      return Rx.EMPTY;
    }),
    catchError(error => {
      return Rx.of(orderBookStore.dispatch(contractsLoadFailure(error)));
    })
  );
};

export const connection: any = (
  actions$: ActionsObservable<any>,
  appstate: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.AUTHENTICATION_SUCCESS ||
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    map(action => action.payload),
    mergeMap((data: any) => {
      if (!isStaticDataLoaded(orderBookStore.getState())) {
        return Rx.of(orderBookStore.dispatch(contractsLoad())).pipe(
          takeUntil(
            actions$.pipe(
              filter(
                action => action.type === Authentication.AUTHENTICATION_LOGOUT
              )
            )
          )
        );
      }
      return Rx.empty();
    })
  );
};

export const allStaticDataLoaded: any = (
  actions$: ActionsObservable<any>,
  store: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action =>
        [
          ActionTypes.LOAD_CONTRACTS_MAP_SUCCESS,
          Products.ActionTypes.LOAD_PRODUCTS_SUCCESS,
          Instruments.ActionTypes.LOAD_INSTRUMENTS_SUCCESS
        ].indexOf(action.type) > -1
    ),
    switchMap((action: any) => {
      const orderBookstate = orderBookStore.getState();
      if (isStaticDataLoaded(orderBookstate)) {
        return Rx.of(orderBookStore.dispatch(staticDataLoaded()));
      }
      return Rx.empty();
    })
  );
};

export const subscribeContractStateChange: any = (
  actions$: ActionsObservable<any>,
  appstate: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.AUTHENTICATION_SUCCESS ||
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    map(action => action.payload),
    switchMap(() => {
      requestsService.sendRequestsInquiry(<GenericRequest> {
        requestType: 'SUBSCRIBE_CONTRACTS_STATE_CHANGE_REQUEST'
      });

      return contractsService.subscribeContractsStateChange().pipe(
        map((content: any) => {
          return orderBookStore.dispatch(contractsStateChangeReceived(content.contracts));
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        ),
        catchError(error => {
          return Rx.of(receiveMessage('', error, true));
        })
      );
    })
  );
};

export const subscribePersistentContracts: any = (
  actions$: ActionsObservable<any>,
  appstate: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.AUTHENTICATION_SUCCESS ||
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    map(action => action.payload),
    switchMap(() => {
      requestsService.sendRequestsInquiry(<GenericRequest> {
        requestType: 'SUBSCRIBE_PERSISTENT_CONTRACTS_REQUEST'
      });

      return contractsService.subscribePersistentContracts().pipe(
        map((content: any) => {
          return orderBookStore.dispatch(persistentContractResponseReceived(
            content.contract,
            content.action
          ));
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        ),
        catchError(error => {
          return Rx.of(receiveMessage('', error, true));
        })
      );
    })
  );
};

export const contractsEpic = combineEpics(
  connection,
  loadContractsMap,
  allStaticDataLoaded,
  subscribeContractStateChange,
  subscribePersistentContracts
);
