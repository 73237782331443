import * as React from 'react';
import { connect } from 'react-redux';
import { setOwnTradeLimit, setAllTradeLimit } from '../actions/trade';
import TradesTable from '../containers/TradesTable';
import { ComponentType } from '../../shared/ui/models/component';
import { getLimitOwnTrades, getLimitAllTrades, getAllTrades, getOwnTrades } from '../selectors/trade';
import { State } from '../../main/reducers/rootReducer';
import { config } from 'src/js/main/config';
import OwnTradesTable from '../containers/OwnTradesTable';
import {I18n} from "react-redux-i18n";
interface Props {
  limitOwnTrades: (limit: number, days: number) => void;
  limitAllTrades: (limit: number, days: number) => void;
  type: ComponentType;
  ownTradesLimits: {limit: number, days: number};
  allTradesLimits: {limit: number, days: number};
}

interface TradeTableEnhancedComponentState {

}

export class TradeTableEnhancedComponent extends React.Component<Props, TradeTableEnhancedComponentState> {
  constructor(props: Props) {
    super(props);
  }

  setLimitCount = (count: number) => {
    switch (this.props.type) {
      case ComponentType.Trade:
        this.props.limitAllTrades(count, this.props.allTradesLimits.days);
        break;
      case ComponentType.Owntrade:
        this.props.limitOwnTrades(count, this.props.ownTradesLimits.days);
        break;
      default:
        return;
    }
  }

  setLimitDays = (days: number) => {
    const isUninitializedLimit = this.props.allTradesLimits.limit === 0;
    const ignoreValue = 0;
    switch (this.props.type) {
      case ComponentType.Trade:
        this.props.limitAllTrades(isUninitializedLimit ? config.tradeLimit : ignoreValue, days);
        break;
      case ComponentType.Owntrade:
        this.props.limitOwnTrades(isUninitializedLimit ? config.tradeLimit : ignoreValue, days);
        break;
      default:
        return;
    }
  }

  clearLimits = () => {
    const resetLimit = -1;
    switch (this.props.type) {
      case ComponentType.Trade:
        this.props.limitAllTrades(resetLimit, 0);
        break;
      case ComponentType.Owntrade:
        this.props.limitOwnTrades(resetLimit, 0);
        break;
      default:
        return;
    }
  }

  render() {
    let activeDays = 0;
    let currentLimit = 0;
    if (this.props.type === ComponentType.Trade) {
      activeDays = this.props.allTradesLimits.days;
      currentLimit = this.props.allTradesLimits.limit;
    } else if (this.props.type === ComponentType.Owntrade) {
      activeDays = this.props.ownTradesLimits.days;
      currentLimit = this.props.ownTradesLimits.limit;
    }

    return (
      <div className="trade-db-controls">
        <div>
          <ul className="trade-limit-actions">
            <li className={activeDays === 365 ? 'active' : ''} onClick={e => this.setLimitDays(365)}>365 days</li>
            <li className={activeDays === 30 ? 'active' : ''} onClick={e => this.setLimitDays(30)}>30 days</li>
            <li className={activeDays === 0 ? 'active' : ''} onClick={e => this.setLimitDays(0)}>Max</li>

            <li className="trade-limit-setting" title={I18n.t('table.clearLimit')} onClick={e => this.clearLimits()}><i className={`oi oi-action-undo`} /></li>
            <li className="trade-limit-setting" title={I18n.t('table.increaseLimit')} onClick={e => this.setLimitCount(1000)}><i className={`oi oi-plus`} /></li>
            <li className="trade-limit-setting" title={I18n.t('table.limit')}>{config.tradeLimit} ({currentLimit})</li>
            
          </ul>
        </div>
        {this.props.type === ComponentType.Trade ? <TradesTable {...this.props}  /> : <OwnTradesTable {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  ownTradesLimits: getLimitOwnTrades(state),
  allTradesLimits: getLimitAllTrades(state)
});

const mapDispatchToProps = {
  limitOwnTrades: setOwnTradeLimit,
  limitAllTrades: setAllTradeLimit
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(TradeTableEnhancedComponent);