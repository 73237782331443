import { Chart } from '../../../models/chart';
import { ComponentType } from '../../../models/component';
import { Contract } from '../../../../../orderbook/models/contracts';

export function findAllContractsForPeriodTypeInChart(periodType: string, contractMatrixForInstrument: any) {
  if (!contractMatrixForInstrument) {
    return [];
  }
  if (contractMatrixForInstrument.intraday) {
    let result: { contractId: string; expiryName: string }[] = [];

    for (const periodTypeKey of Object.keys(
      contractMatrixForInstrument.expiries
    )) {
      const expiries: any[] =
        contractMatrixForInstrument.expiries[periodTypeKey];
      const contractsWithNames = expiries
        .filter(expiry => expiry.type === periodType)
        .map(expiry => ({
          contractId:
            contractMatrixForInstrument.expiryToContract[expiry.code],
          expiryName: expiry.name
        }));
      result = [...result, ...contractsWithNames];
    }

    return result;
  } else {
    const expiries: any[] = contractMatrixForInstrument.expiries ? contractMatrixForInstrument.expiries[periodType] : [];
    const contracts = expiries ? expiries.map(expiry => ({
      contractId: contractMatrixForInstrument.expiryToContract[expiry.code],
      expiryName: expiry.name
    })) : [];
    return contracts;
  }
}

export function findNextContractInChart(chart: any, contractMatrix: any) {
  if (chart.type === ComponentType.MarketChart) {
    return null;
  }  
  const contracts = findAllContractsForPeriodTypeInChart(chart.selectedPeriodType, contractMatrix[chart.instrumentId]);
  const currentContractIndex = contracts.map(c => c.contractId).indexOf(chart.contractId);
  const newContract = contracts[currentContractIndex === -1 ? 0 : currentContractIndex];
  return newContract;
}

export function findNextContractIdInChart(chart: any, contractMatrix: any) {
  // no update for the instrument
  if (!contractMatrix[chart.instrumentId]) {
    return chart.contractId;
  }
  const contract = findNextContractInChart(chart, contractMatrix);
  return contract ? contract.contractId : null;
}

export function findContractIdAfterCurrentContract(chart: any, contractMatrix: any, contractsMap: { [key: string]: Contract }) {
  // no update for the instrument
  if (!contractMatrix || contractMatrix && !contractMatrix[chart.instrumentId]) {
    return chart.contractId;
  }
  const contracts = findAllContractsForPeriodTypeInChart(chart.selectedPeriodType, contractMatrix[chart.instrumentId]);
  const currentContractIndex = contracts.map(c => c.contractId).indexOf(chart.contractId);
  const contractIds = contracts.map(c => c.contractId);
  for (let i = currentContractIndex + 1; i < contracts.length; i++) {
    let current = contractsMap[contractIds[i]];
    if (current && current.state === 'ACTIVE') {
      return current.id;
    }
  }
  return null;
}

export function findAllContractsForPeriodType(
  chart: Chart,
  periodType: string
): { contractId: string; expiryName: string }[] {
  const { contractMatrix, instrumentId } = chart;
  if (!contractMatrix) {
    return [];
  }
  const contractMatrixForInstrument = contractMatrix[instrumentId];
  
  if (contractMatrixForInstrument.intraday) {
    let result: { contractId: string; expiryName: string }[] = [];

    for (const periodTypeKey of Object.keys(
      contractMatrixForInstrument.expiries
    )) {
      const expiries: any[] =
        contractMatrixForInstrument.expiries[periodTypeKey];
      const contractsWithNames = expiries
        .filter(expiry => expiry.type === periodType)
        .map(expiry => ({
          contractId: contractMatrixForInstrument.expiryToContract[expiry.code],
          expiryName: expiry.originalName
        }));
      result = [...result, ...contractsWithNames];
    }

    return result;
  } else {
    const expiries: any[] = contractMatrixForInstrument.expiries[periodType];
    const contracts = expiries.map(expiry => ({
      contractId: contractMatrixForInstrument.expiryToContract[expiry.code],
      expiryName: expiry.name
    }));
    return contracts;
  }
}