import * as React from 'react';
import { IQuadrant } from '../models/analyticsPanel';
import AnalyticsQuadrant from './AnalyticsQuadrant';
import {
  QuadrantsCheck
} from '../helper/analyticsPanel';

interface Props {
  quadrants: IQuadrant[];
  axises: {
    x: number;
    y: number;
  };
  active: boolean;
  activeQuadrants: IQuadrant[],
  isLocked: boolean;
  controlledQuadrants: IQuadrant[];
}
interface State {
  controlledQuadrants: IQuadrant[];
}

export default class AnalyticsQuadrantsPanel extends React.Component<
  Props,
  State
> {
  private qc: QuadrantsCheck;
  private ref: any;
  constructor(props: Props) {
    super(props);

    this.ref = React.createRef();
    this.state = {
      controlledQuadrants: []
    };
  }

  componentWillMount() {
    const { quadrants, axises, active } = this.props;
    this.qc = new QuadrantsCheck(
      quadrants.reduce((acc: any, quadrant) => {
        return {
          ...acc,
          [quadrant.id]: quadrant
        };
      }, {})
    );
    this.setState({
      ...this.state,
      controlledQuadrants: quadrants
    });
  }

  render() {
    const { active } = this.props;
    const { controlledQuadrants } = this.props;
    const quadrantsList = controlledQuadrants
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
      .map(quadrant => (
        <AnalyticsQuadrant key={quadrant.id} quadrant={quadrant} />
      ));

    return (
      <div
        className={`analytics-quadrants-panel ${active ? `` : `hidden`}`}
        ref={this.ref}
      >
        {quadrantsList}
      </div>
    );
  }
}