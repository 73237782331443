import * as React from 'react';

import UITableFilter from './uiTableFilter';
import {
  BuySellFormatter,
  TablePriceFormatter,
  TableQuantityFormatter,
  TableLogLevelFormatter,
  TimeFormatter,
  DateFormatter,
  PaidGivenFormatter,
  BasketOrderStatusFormatter,
  BasketOrderDeleteFormatter,
  OrderContractFormatter,
  DateTimeFormatter,
  PriceAlarmDeleteFormatter,
  PriceAlarmStatusFormatter,
  PriceAlarmLongStringFormatter
} from '../tableFormatters';

import {
  ITable,
  ITableSort,
  ITableRow,
  ITableSearchTag,
  ITableColumn,
  TableAction
} from '../../../models/table';
import UITableActions from '../actions/uiTableActions';
import { connect } from 'react-redux';
import { getLocale } from '../../../../i18n/selectors/translations';
import { State } from '../../../../../main/reducers/rootReducer';
import MeetTable  from '../../../../table/components/MeetTable';
import TableSearchBar from './uiTableSearchBar';
import { getPathIfNotFindNatively, pushDockLower, pullDockHigher } from '../../../../utils/helper/eventUtils';
import { triggerTableSpecificAction } from '../../../actions/table';
import { MemoTranslate } from '../../../../../shared/i18n/components/memoTranslate';
import { getDockByElementId } from '../../../../../dashboard/selectors/dashboard';
import { IDock } from '../../../../../shared/dock/models/dock';
import store from '../../../../../main/store/store';
import { getQuadrantGridActive } from '../../../../../dashboard/selectors/quadrantPanel';

export interface TableProps {
  parentId: string;
  displayFilter: boolean;
  displaySearch: boolean;
  displayActions: boolean;
  table: ITable;
  rows: ITableRow[];
  sort: (id: string, sorting: ITableSort[]) => void;
  triggerFilter: (id: string, displayFilter: boolean) => void;
  triggerColumnNames: (id: string, hiddenColumnNames: string[]) => void;
  triggerHeadlines: (id: string, showHeadlines: boolean) => void;
  triggerShowHidden: (id: string, showHidden: boolean) => void;
  triggerSearchTags: (id: string, tags: ITableSearchTag[]) => void;
  hideRows: (id: string, hiddenRows: number[]) => void;
  showRows: (id: string, rows: number[]) => void;
  pinRows: (id: string, pinnedRows: number[]) => void;
  unpinRows: (id: string, rows: number[]) => void;
  onClickCell: (id: string, key: string) => void | undefined;
  locale: string;
  triggerTableSpecificAction: (id: string, tableSpecificAction: string, enabled: boolean) => void | undefined;
  isGridLayoutActive: boolean;
}
export interface TableState {
  rows: ITableRow[];
  allRows: ITableRow[];
  selection: number[];
  selectedRows: any[];
  tags: any[];
  rowPopover: {
    visible: boolean;
    position: { x: number | null; y: number };
    data: any;
    popoverComponent?: (params: any) => JSX.Element;
  };
  lastSelectedRow: number | undefined;
}

export class UITable extends React.Component<TableProps, TableState> {
  constructor(props: TableProps) {
    super(props);

    this.state = {
      rows: [],
      allRows: [],
      tags: [],
      selection: [],
      selectedRows: [],
      rowPopover: {
        visible: false,
        position: { x: 0, y: 0 },
        data: {},
        popoverComponent: undefined
      },
      lastSelectedRow: undefined
    };

    this.changeSorting = this.changeSorting.bind(this);
    this.changeVisibility = this.changeVisibility.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.changeSearchTags = this.changeSearchTags.bind(this);
    this.onHideRows = this.onHideRows.bind(this);
    this.onShowRows = this.onShowRows.bind(this);
    this.onPinRows = this.onPinRows.bind(this);
    this.onUnpinRows = this.onUnpinRows.bind(this);
    this.onShowAllRows = this.onShowAllRows.bind(this);
    this.showHidden = this.showHidden.bind(this);
    this.showUnhidden = this.showUnhidden.bind(this);
    this.hideFilter = this.hideFilter.bind(this);
    this.hidePopover = this.hidePopover.bind(this);
    this.cellClickAction = this.cellClickAction.bind(this);
    this.isSelectionAllowed = this.isSelectionAllowed.bind(this);

    this._applyStateChanges = this._applyStateChanges.bind(this);
    this.onExpandedRowIdsChange = this.onExpandedRowIdsChange.bind(this);
  }

  componentWillMount() {
    const { rows, table } = this.props;
    if (rows && table) {
      this._applyStateChanges(rows, table);
    }
  }

  componentWillReceiveProps(newProps: TableProps) {
    const { rows, table } = newProps;
    this._applyStateChanges(rows, table);
  }

  changeSorting(sorting: ITableSort[]) {
    const { id } = this.props.table;
    this.props.sort(id, sorting);
  }

  changeSearchTags(tags: ITableSearchTag[]) {
    const { id } = this.props.table;
    this.props.triggerSearchTags(id, tags);
  }

  cellClickAction(
    rowIndex: string,
    columnKey: string,
    row: ITableRow,
    event: any,
    type: 'left' | 'right'
  ) {
    const { table } = this.props;
    if ((!table.cellActionClick && type !== 'right') || (table.cellActionClick && table.cellActionClick !== type && table.cellActionClick !== 'both')) {
      // right click is default cell action event type
      // don't do anything if actual click event type does not match with configured table cell action type
      return;
    }
    if (!!table.cellActionMapping && table.cellActions) {
      if ('*' in table.cellActionMapping) {
        table.cellActions[table.cellActionMapping['*']](
          rowIndex,
          columnKey,
          this,
          row,
          event,
          type
        );
      }
      if (
        columnKey in table.cellActionMapping &&
        table.cellActionMapping[columnKey] in table.cellActions
      ) {
        table.cellActions[table.cellActionMapping[columnKey]](
          rowIndex,
          columnKey,
          this,
          row,
          event,
          type
        );
      }
    }
  }

  handleShiftSelection(row: ITableRow, relevantRows: ITableRow[]) {
    const { lastSelectedRow } = this.state;
    let newlySelectedRowId = row.$index;
    let newSelection: number[] = [];
    let foundFirstSelection, foundLastSelection = false;
    for (let i = 0; i < relevantRows.length; i++) {
      let id = relevantRows[i].$index;
      if (!foundFirstSelection && !foundLastSelection && (id === lastSelectedRow || id === newlySelectedRowId)) { // find first selection
        foundFirstSelection = true;
        newSelection = [...newSelection, relevantRows[i].$index];
      } else if (foundFirstSelection && !foundLastSelection) {
        if (id === lastSelectedRow || id === newlySelectedRowId) {
          foundLastSelection = true;
        }
        newSelection = [...newSelection, relevantRows[i].$index];
      }
    }
    this._applyStateChanges(
      this.props.rows,
      this.props.table,
      lastSelectedRow,
      newSelection,
      newSelection.map(id => this.state.allRows.find(r => r.$index === id))
    );
  }

  handleSelection(rowIndex: number, e: any, row: ITableRow) {
    const { lastSelectedRow, selection } = this.state;
    const { table, rows } = this.props;
    let selectedRows = selection;
    let newlySelectedRowId = row.$index;
    let newlySelectedRow = lastSelectedRow;
    let relevantRows: ITableRow[] = [];
    if (table.pinnedRows.indexOf(newlySelectedRowId) !== -1) {
      relevantRows = rows.filter(r => table.pinnedRows.indexOf(r.$index) !== -1);
    } else {
      relevantRows = rows.filter(r => table.pinnedRows.indexOf(r.$index) === -1);
    } 
    if (lastSelectedRow && relevantRows.map(r => r.$index).indexOf(lastSelectedRow) === -1) { // can't select pinned and unpinned at the same time
      selectedRows = [];
      newlySelectedRow = undefined;
    }
    if (e && e.shiftKey && lastSelectedRow && selectedRows.length !== 0) {
      return this.handleShiftSelection(row, relevantRows);
    } else {
      if (e && !(e.ctrlKey || e.keyCode === 224 || e.keyCode === 17 || e.keyCode === 91)) {
        // without pressing ctrl/cmd and shift only the newly selected row should be selected
        selectedRows = [];
      }
      newlySelectedRow = newlySelectedRowId;
      selectedRows = [...selectedRows, rowIndex];
      this._applyStateChanges(
        this.props.rows,
        this.props.table,
        newlySelectedRow,
        selectedRows,
        selectedRows.map(id => relevantRows.find(r => r.$index === id))
      );
    }
  }

  handleUnselection(rowIndex: number, e: any, row: ITableRow) {
    const { selection, lastSelectedRow } = this.state;
    let selectedRows = selection;
    let newlySelectedRow = lastSelectedRow;
    // unselect single row
    if (e && (e.ctrlKey || e.keyCode === 224 || e.keyCode === 17 || e.keyCode === 91)) {
      selectedRows = selectedRows.filter(id => rowIndex !== id);
    } else { // unselect all
      if (selectedRows.length === 1) {
        selectedRows = [];
      } else {
        selectedRows = [rowIndex];
        newlySelectedRow = row.$index;
      }
    }
    if (selectedRows.length === 0) {
      newlySelectedRow = undefined;
    }
    this._applyStateChanges(
      this.props.rows,
      this.props.table,
      newlySelectedRow,
      selectedRows,
      selectedRows.map(id => this.state.allRows.find(r => r.$index === id))
    );
  }

  isSelectionAllowed(row: ITableRow, selectedRows: number[]) {
    const lastSelectedRow = this.state.lastSelectedRow;
    if (!lastSelectedRow) {
      return true;
    }
    const newlyPinnedRow = this.state.rows.find(r => r.$index === row.$index);
    const lastPinnedRow = this.state.rows.find(r => r.$index === lastSelectedRow);
    // to be selected row can only be pinned/unpinned when the already selected rows are also pinned/unpinned
    return (newlyPinnedRow === null && lastPinnedRow === null) || (newlyPinnedRow !== null && lastPinnedRow !== null);
  }

  changeSelection(rowIndex: number, e: any, row: ITableRow) {
    const { selection } = this.state;
    let selectedRows = selection;
    if (!this.isSelectionAllowed(row, selectedRows)) {
      return;
    }
    // unselect row(s)
    if (selectedRows.indexOf(rowIndex) !== -1 && e && !e.shiftKey) {
      return this.handleUnselection(rowIndex, e, row);
    } else { // select row(s)
      return this.handleSelection(rowIndex, e, row);
    }
  }

  onPinRows(selectedRows: number[]) {
    const { rows, table } = this.props;
    this._applyStateChanges(rows, table, undefined, [], []);
    this.props.pinRows(table.id, selectedRows);
  }

  onUnpinRows(selectedRows: any[]) {
    const { rows, table } = this.props;
    this._applyStateChanges(rows, table, undefined, [], []);
    this.props.unpinRows(table.id, selectedRows);
  }

  onHideRows(selectedRows: any[]) {
    const { rows, table } = this.props;
    this._applyStateChanges(rows, table, undefined, [], []);
    this.props.hideRows(table.id, selectedRows);
  }

  onShowRows(selectedRows: any[]) {
    const { rows, table } = this.props;
    this._applyStateChanges(rows, table, undefined, [], []);
    this.props.showRows(table.id, selectedRows);
  }

  onShowAllRows() {
    const { rows, table } = this.props;
    this._applyStateChanges(rows, table, undefined, [], []);
    this.props.showRows(table.id, table.hiddenRows);
  }

  showHidden() {
    const { table } = this.props;
    this.props.triggerShowHidden(table.id, true);
  }

  showUnhidden() {
    const { table } = this.props;
    this.props.triggerShowHidden(table.id, false);
  }

  isColumnNameTagged(tags: ITableSearchTag[], columnName: string) {
    return tags.filter((tag: ITableSearchTag) => 
      columnName && columnName.includes(tag.name.trim().toLowerCase())
    ).length > 0;
  }

  getFilteredRows(tags: ITableSearchTag[], rows: ITableRow[], searchableColumns: ITableColumn[]) {
    return rows.filter((_r: any) =>
      searchableColumns
        .reduce(
          (result: boolean, column: ITableColumn) =>
            result || this.isColumnNameTagged(tags, (_r[column.name] + '').toLowerCase()),
          false
        )
    );
  }

  _applyStateChanges(
    rows: ITableRow[],
    table: ITable | undefined,
    lastSelectedRow?: number,
    selection?: number[],
    selectedRows?: any
  ) {
    if (table) {
      this.setState(prevState => {
        const _selection =
          selection !== undefined ? selection : prevState.selection;
        const _selectedRows =
          selection !== undefined ? selectedRows : prevState.selectedRows;
        const _rows = table.showingHidden
          ? rows.filter(
            (row: any) =>
              table.hiddenRows
                .indexOf(row.$index) !== -1
          )
          : rows.filter(
              (row: any) =>
                table.hiddenRows
                  .indexOf(row.$index) === -1
            );

        return {
          ...prevState,
          selection: _selection,
          selectedRows: _selectedRows,
          tags: table && table.tags,
          allRows: rows,
          rows:
            table && table.tags && table.tags.length
              ? this.getFilteredRows(table.tags, _rows, table.columns
                .filter(c => c.searchable))
              : _rows,
          lastSelectedRow: lastSelectedRow
        };
      });
    }
  }

  changeVisibility(name: string, value: boolean) {
    const { table } = this.props;
    const hiddenColumnNames = table.hiddenColumnNames;
    const index = hiddenColumnNames.indexOf(name);
    const newHiddenColNames =
      index === -1
        ? [...hiddenColumnNames, name]
        : [
            ...hiddenColumnNames.slice(0, index),
            ...hiddenColumnNames.slice(index + 1)
          ];

    if (name === table.id + '-headlines') {
      this.props.triggerHeadlines(table.id, value);
    } else {
      this.props.triggerColumnNames(table.id, newHiddenColNames);
    }
  }
  hideFilter() {
    const { parentId } = this.props;
    this.props.triggerFilter(parentId, false);
  }

  hidePopover() {
    pushDockLower();
    this.setState(prevState => {
      return {
        ...prevState,
        rowPopover: {
          ...prevState.rowPopover,
          visible: false
        }
      };
    });
  }
  showPopover(
    popoverComponent: (params: any) => JSX.Element,
    row: ITableRow,
    event: any
  ) {
    const nativeEvent = event.nativeEvent;
    const eventPath =
      (nativeEvent.composedPath && nativeEvent.composedPath()) ||
      nativeEvent.path ||
      getPathIfNotFindNatively(nativeEvent);

    pullDockHigher(eventPath);

    let positionX = event.clientX;
    let positionY = event.clientY;
    const analyticsElement = this.props.parentId.indexOf('analytics-') > -1;
    const dockWrapperTarget = event.nativeEvent.target.closest(analyticsElement ? '.analytics-quadrant' : '.dock__wrapper');
    if (dockWrapperTarget) {
      const dock: IDock | undefined = getDockByElementId(store.getState(), dockWrapperTarget.id);
      if (dock && !this.props.isGridLayoutActive) {
        positionX -= dock.position.x;
        positionY -= dock.position.y;
      } else if (dock && this.props.isGridLayoutActive) {
        positionX -= dockWrapperTarget.offsetLeft;
        positionY -= dockWrapperTarget.offsetTop;
      } else if (!dock && analyticsElement) {
        positionX -= dockWrapperTarget.offsetLeft;
        positionY -= dockWrapperTarget.offsetTop;
      }
    } else {
      const recentActionsTarget = event.nativeEvent.target.closest('.sidebar__recent-actions');
      if (recentActionsTarget) {
        positionX -= recentActionsTarget.getBoundingClientRect()['left'];
        positionY -= recentActionsTarget.getBoundingClientRect()['top'];
      }
    }
   
    this.setState(prevState => {
      return {
        ...prevState,
        rowPopover: {
          position: {
            x: positionX,
            y: positionY
          },
          visible: !prevState.rowPopover.visible,
          data: row,
          popoverComponent: popoverComponent
        }
      };
    });
  }

  onExpandedRowIdsChange(expandedRowIds: any) {
    // console.log(expandedRowIds);
  }

  render(): JSX.Element | null {
    const { table, displayFilter, displaySearch, displayActions } = this.props;
    if (!table) {
      return null;
    }
    const {
      actions,
      columns,
      hiddenColumnNames,
      showHeadlines,
      sorting,
      hiddenRows,
      showingHidden,
      filters,
      id,
      pinnedRows
    } = table;
    const {
      selection,
      selectedRows,
      rows,
      allRows,
      tags,
      rowPopover
    } = this.state;

    const pinningAllowed = actions.indexOf(TableAction.PINNING_ROWS) !== -1;

    const dataFormatters = {
      time: (value: any) => <TimeFormatter value={value} />,
      date: (value: any) => <DateFormatter value={value} />,
      timestamp: (value: any) => <TimeFormatter value={value} />,
      validFrom: (value: any) => <DateTimeFormatter value={value} />,
      validTo: (value: any) => <DateTimeFormatter value={value} />,
      buySell: (value: any) => <BuySellFormatter value={value} />,
      paidGiven: (value: any) => <PaidGivenFormatter value={value} />,
      buy: (value: any) => <BuySellFormatter value={value} />,
      price: (value: any, contextData: any) => (
        <TablePriceFormatter value={value} contextData={contextData} />
      ),
      limit: (value: any, contextData: any) => (
        <TablePriceFormatter value={value} contextData={contextData} />
      ),
      quantity: (value: any, contextData: any) => (
        <TableQuantityFormatter value={value} contextData={contextData} />
      ),
      remainingQuantity: (value: any, contextData: any) => (
        <TableQuantityFormatter value={value} contextData={contextData} />
      ),
      orderedQuantity: (value: any, contextData: any) => (
        <TableQuantityFormatter value={value} contextData={contextData} />
      ),
      level: (value: any, contextData: any) => (
        <TableLogLevelFormatter value={value} contextData={contextData} />
      ),
      status: (value: any, contextData: any) => (
        <BasketOrderStatusFormatter value={value} contextData={contextData} />
      ),
      delete: (value: any) => <BasketOrderDeleteFormatter value={value} />,
      contract: (value: any, contextData: any) => (
        <OrderContractFormatter value={value} contextData={contextData} />
      ),
      statusMessage: (value: any, contextData: any) => (
        <MemoTranslate value={value} />
      ),
      meetState: (value: any, contextData: any) => (
        <MemoTranslate value={`quoteRequest.state.${value}`} />
      ),
      priceAlarmDelete: (value: any, contextData: any) => (
        <PriceAlarmDeleteFormatter value={value} />
      ),
      priceAlarmStatus: (value: any, contextData: any) => (
        <PriceAlarmStatusFormatter value={value} />
      ),
      expiry: (value: any, contextData: any) => (
        <span>{value ? value.name : ''}</span>
      ),
      expires: (value: any, contextData: any) => { 
        return (<span>{!value ? <MemoTranslate value="priceAlarm.gtc" /> : <DateFormatter value={new Date(value)} />}</span>); 
      },
      priceAlarmType: (value: any, contextData: any) => { 
        return <MemoTranslate value={`priceAlarm.priceAlarmType.${value}`} />; 
      },
      customer: (value: any) => {
        return <PriceAlarmLongStringFormatter value={value} />;
      },
      description: (value: any) => {
        return <PriceAlarmLongStringFormatter value={value} />;
      }
    };

    const tablePopover = (
      <React.Fragment>
        {this.state.rowPopover.popoverComponent === null ||
        this.state.rowPopover.popoverComponent === undefined ? (
          <div style={{display: 'none'}}>&nbsp;</div>
        ) : (
          this.state.rowPopover.popoverComponent({
            data: rowPopover.data,
            hidePopover: this.hidePopover,
            position: rowPopover.position,
            visible: rowPopover.visible
          })
        )}
      </React.Fragment>
    );

    return (
      <>
        {tablePopover}
        <div className={`uitable ${showHeadlines ? '' : `hidden-headlines`} ${table.type}`}>
          <MeetTable
            cols={columns}
            pinnedRows={pinnedRows}
            rows={rows}
            allRows={allRows}
            sorting={sorting}
            selectedRows={selection}
            hiddenColumnNames={hiddenColumnNames}
            onSelectionChange={this.changeSelection}
            onClickCell={(cellId: string, key: string, row: ITableRow, event: any) =>
              this.cellClickAction(cellId, key, row, event, 'left')
            }
            onContextCell={(cellId: string, key: string, row: ITableRow, event: any) =>
              this.cellClickAction(cellId, key, row, event, 'right')
            }
            onSortingChange={this.changeSorting}
            dataFormatters={dataFormatters}
            table={this.props.table}
            popoverComponent={tablePopover}
            pinningAllowed={pinningAllowed}
          />

          {displaySearch ? (
            <TableSearchBar
              isAllowed={actions.indexOf(TableAction.SEARCH) !== -1}
              rows={allRows}
              tags={tags}
              searchableColumns={columns
                .filter(c => c.searchable)
                .map(c => c.name)}
              onSearchTags={this.changeSearchTags}
            />
          ) : null}

          {displayFilter ? (
            <UITableFilter
              columns={columns}
              hiddenColumnNames={hiddenColumnNames}
              isVisible={displayFilter}
              isHeadlinesVisible={showHeadlines}
              onCheckboxChange={this.changeVisibility}
              onClickOutside={this.hideFilter}
              filters={filters}
              toggleTableSpecificFilter={(filter: string, enabled: boolean) => this.props.triggerTableSpecificAction(id, filter, enabled)}
              tableId={id}
            />
          ) : null}
          {displayActions ? (
            <UITableActions
              actions={actions}
              hiddenMode={showingHidden}
              selection={selection}
              selectedRows={selectedRows}
              rows={rows}
              hiddenRows={hiddenRows}
              showHidden={this.showHidden}
              showUnhidden={this.showUnhidden}
              onHideSelectedRows={this.onHideRows}
              onShowSelectedRows={this.onShowRows}
              onShowAllRows={this.onShowAllRows}
              onSearchTags={this.changeSearchTags}
              onPinSelectedRows={this.onPinRows}
              onUnpinSelectedRows={this.onUnpinRows}
              pinnedRows={pinnedRows}
            />
          ) : null}
          
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: State) => ({
  locale: getLocale(state),
  isGridLayoutActive: getQuadrantGridActive(state)
});

const mapDispatchToProps = {
  triggerTableSpecificAction: triggerTableSpecificAction
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITable);
