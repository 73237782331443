export interface SaveDashboardViewRequest {
  jsonData: string;
  defaultView: boolean;
  name: string;
  viewId: string;
}

export interface LoadDashboarViewResponse {
  view: DashboardView;
}

export interface DashboardView {
  defaultView: boolean;
  viewId: string;
  name: string;
  data: any;
}
export interface LoadDashboardViewRequest {
  viewId: string | null;
}

export interface RemoveDashboardViewRequest {
  viewId: string;
}
export enum ViewStatus {
  UNITIALIZED = 0,
  REQUESTED = 1,
  LOADED = 2
}
