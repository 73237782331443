import * as React from 'react';
import { IQuadrant } from '../models/analyticsPanel';
import UITabView from '../../shared/ui/components/table/components/uiTabComponent';

interface Props {
  quadrant: IQuadrant;
}
interface State {}

export default class AnalyticsQuadrant extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { quadrant } = this.props;
    let style = {
      height: `${quadrant.size.height}%`,
      width: `${quadrant.size.width}%`,
      transform: 'translate(0px, 0px)'
    };

    return (
      <div className={`analytics-quadrant q-${quadrant.id}`} style={style}>
        <UITabView
          dockId={'analyticsPanel-Q' + quadrant.id}
          onTabDragComplete={(e: any, title: string): any => null}
        />
      </div>
    );
  }
}