import * as React from 'react';
import { Market } from '../../shared/ui/models/market';
import { ComponentType } from '../../shared/ui/models/component';
import { Contract } from '../../orderbook/models/contracts';
import { config } from '../../main/config';
import { Translate } from 'react-redux-i18n';
import { ExpiryRow } from '../../shared/ui/components/market/components/TableRows';
import { getLocalizationSettings } from '../../shared/utils/formatters';
import OrderFormData, { OrderFormMode } from '../models/formData';
import { CancelOrderRequest, ModifyOrderRequest, EnterOrderRequest } from '../models/orders';
import { v1 } from 'uuid';
import { createOrderRequest } from '../helper/orders';
import { getColumns } from '../../shared/ui/components/market/helper/helper';
import { Debouncer } from '../../shared/utils/components/debounce';

interface OrderbookPreviewProps {
    contract: Contract;
    colors: any;
    prices: {orders: {[contractId: string]: any}, trades: {[contractId: string]: any}};
    formData: OrderFormData;
    subscribe: (contractId: string) => void;
    unsubscribe: (contractId: string) => void;
    updateOrder: (request: ModifyOrderRequest | EnterOrderRequest | CancelOrderRequest, mode: OrderFormMode) => void;
    isSeparateCells: boolean;
} 

interface OrderbookPreviewState {
    order: ModifyOrderRequest | EnterOrderRequest | CancelOrderRequest;
}
const debouncer = new Debouncer()

export default class OrderbookPreview extends React.Component<OrderbookPreviewProps, OrderbookPreviewState> {
    static correlationId: string = v1();
    static getDerivedStateFromProps(props: OrderbookPreviewProps, state: OrderbookPreviewState) {
        const mode = props.formData.mode;
        const order: any = state.order;
        let updatedOrder: any = null;
        if (mode === OrderFormMode.ENTER || mode === OrderFormMode.MATCH) {
            if (order.limit !== props.formData.price 
            || order.quantity !== props.formData.quantity
            || order.restriction !== props.formData.restriction
            || order.buy !== props.formData.buy) {
                updatedOrder = createOrderRequest(
                    props.formData, 
                    OrderbookPreview.correlationId);
                debouncer.debounce(() => {
                    props.updateOrder(updatedOrder, mode);
                }, 3000);
                return {
                    ...state,
                    order: updatedOrder
                };
            }
        } else if (mode === OrderFormMode.MODIFY) {
            if (order.limit !== props.formData.price 
            || order.quantity !== props.formData.quantity
            || order.restriction !== props.formData.restriction) {
                updatedOrder = createOrderRequest(
                    props.formData, 
                    OrderbookPreview.correlationId);
                debouncer.debounce(() => {
                    props.updateOrder(updatedOrder, mode);
                }, 3000);
                return {
                    ...state,
                    order: updatedOrder
                };
            }
        }
        return state;
    }

    constructor(props: OrderbookPreviewProps) {
        super(props);
        this.state = {
            order: createOrderRequest(props.formData, OrderbookPreview.correlationId)
        };
    }

    componentDidMount() {
        this.props.subscribe(this.props.contract.id);
        const updatedOrder = createOrderRequest(
            this.props.formData, 
            OrderbookPreview.correlationId);
        this.props.updateOrder(updatedOrder, this.props.formData.mode);
    }

    componentWillUnmount() {
        this.props.unsubscribe(this.props.contract.id);
    }

    render() {
        const {contract, colors, prices, isSeparateCells} = this.props;
        const market = new Market(
            'orderForm',
            'title',
            ComponentType.Instrument,
            contract.instrumentId,
            contract.instrumentId,
            config.ui.market.columns
        );

        if (!contract || !contract.expiry) {
            return (<div className="orderbook">&nbsp;</div>);
        }

        const row = {
            code: contract.expiry.code,
            title: contract.expiry.name,
            periodType: contract.expiry.type,
            depth: Object.keys(prices.orders) && contract.expiry.code in prices.orders
                ? Math.max(
                    prices.orders[contract.expiry.code].bidPrices 
                    ? prices.orders[contract.expiry.code].bidPrices.length : 0, 
                    prices.orders[contract.expiry.code].askPrices 
                    ? prices.orders[contract.expiry.code].askPrices.length : 0
                ) 
                : 0
        };

        const columnMap = getColumns(market, false);
        const columns = Object.keys(columnMap).map((columnTitle: string) => columnMap[columnTitle][0]);
      
        const headerRow = Object.keys(columnMap).map((columnTitle: any, index: any) => {
            let column = columnMap[columnTitle][0];
            return (
                <th key={'opreview' + column.group + column.name + index} className={'column ' + column.group + ' ' + column.originalName}>
                    <label className="entry"><Translate value={column.title} /></label>
                </th>
            );
        });
        const askPrices =  prices.orders[row.code] ? prices.orders[row.code].askPrices : undefined;
        const bidPrices =  prices.orders[row.code] ? prices.orders[row.code].bidPrices : undefined;
        let trades: any[] = [];
        if (prices.trades[row.code]) {
            if (Array.isArray(prices.trades[row.code])) {
                trades = prices.trades[row.code].map((t: any) => t.execPrice);
            } else {
                trades = [prices.trades[row.code].execPrice];
            }
        }
        let isExpanded = (askPrices && askPrices.length > 1) || (bidPrices && bidPrices.length > 1) || (trades && trades.length > 1);

        return (
        <div className="orderbook">
            <div className="instrument">
              <Translate value="order.form.preview" tag="h4" />
              <table className="meet-table">
                <thead>
                  <tr>{headerRow}</tr>
                </thead>
                <tbody>
                  <ExpiryRow
                    key="opreview"
                    instrumentId={market.itemId}
                    market={market}
                    columns={columns}
                    askPrices={askPrices}
                    bidPrices={bidPrices}
                    trades={trades}
                    contractId={contract.id}
                    period={contract.expiry.type}
                    row={row}
                    expiriesExpanded={isExpanded}
                    hasData={false}
                    onContextMenu={() => false}
                    onClickAction={() => false}
                    localizeOptions={getLocalizationSettings()}
                    colors={colors}
                    presetDepths={{[contract.expiry.code]: 8}}
                    compactColumnsEnabled={false}
                    contract={contract}
                    dockSize={undefined}
                  />
                </tbody>
              </table>
            </div>
          </div>
        );
    }
}