import { createSelector } from 'reselect';
import { OrderbooksState } from '../reducers/combinedReducer';

const getOrderbookContractsState = (state: OrderbooksState) => state.orderbook.contracts;

export const getOrderbookContractIds = createSelector(
  [getOrderbookContractsState],
  s => {
    return s.ids;
  }
);

export const getOrderbookContracts = createSelector(
  [getOrderbookContractsState],
  s => {
    return s.entities;
  }
);

export const getOrderbookContractEntities = createSelector(
  [getOrderbookContractIds, getOrderbookContracts],
  (ids, entities) => ids.map(id => entities[id])
);

export const getContractNameToIdMap = createSelector(
  [getOrderbookContractIds, getOrderbookContracts],
  (ids, entities) => ids.reduce((acc: any, id: string) => { acc[entities[id].name] = id; return acc;}, {})
);

export const getSearchResults = createSelector(
  [getOrderbookContracts, (state: OrderbooksState, phrase: string) => phrase],
  (entities, phrase) => {
    if (!phrase || phrase.length < 3) {
      return [];
    }
    return Object.keys(entities).reduce((acc: any[], id: string) => {
      return entities[id].name.toLowerCase().indexOf(phrase.toLowerCase()) >
        -1 || entities[id].id === phrase
        ? acc.concat(entities[id])
        : acc;
    }, []);
  }
);
