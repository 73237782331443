import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { State } from '../../../main/reducers/rootReducer';
import { filter, map, mergeMap, switchMap, takeUntil, debounce, catchError } from 'rxjs/operators';
import { ComponentType } from '../models/component';
import { getTables, getTableEntites } from '../selectors/ui';
import { ActionTypes as Table, paginatedResult, tableLoadFailure, paginatedRequest } from '../actions/table';
import { ActionTypes as Authentication } from '../../../authentication/actions/authentication';
import { ActionTypes as Connection } from '../../../authentication/actions/connection';
import { ActionTypes as Trade } from '../../../trade/actions/trade';
import TableService from '../services/tableService';
import * as Rx from 'rxjs';
import { ITable, ITableSearchTag } from '../models/table';
import { getTradeIds } from '../../../trade/selectors/trade';
import { config } from '../../../main/config';
import { timer, Observer } from 'rxjs';
import { tradeInquireNotify } from '../../../trade/actions/trade';
import { FilterType } from '../models/tableSpecificFilters';
import { ActionTypes as ContractActions } from '../../../orderbook/actions/contracts';

const tableService = new TableService();

export const tableSort: any = (
    actions$: ActionsObservable<any>, 
    state: StateObservable<State>) => {
    return actions$.pipe(
      filter(
        action =>
          action.type === Table.SORT
      ),
      debounce(val => timer(val * 200)),
      map(actions => actions.payload),
      mergeMap((data: any) => {
        const tables: any = getTables(state.value);
        if (tables[data.id]) {
          return tableService.sendPaginatedTableRequest(0, data.sorting, tables[data.id].type, data.id, tables[data.id].tags, tables[data.id].filter).pipe(
            map((content) => {
              return paginatedResult(content, tables[data.id].type, false);
            }),
            catchError((error) => Rx.of(tableLoadFailure(error)))
          );
        }
        return Rx.empty();
      })
    );
  };
  
export const tablePaginatedRequest: any = (
    actions$: ActionsObservable<any>, state: StateObservable<State>) => {
    return actions$.pipe(
      filter(action => action.type === Table.PAGINATED_REQUEST),
      map(actions => actions.payload),
      switchMap(data => {
        const mapFn = map((content) => {
          return paginatedResult(content, data.type, false);
        });
        
        if (data.type === ComponentType.Trade && (state.value.trades.days > 0 || state.value.trades.limit > 0) && (config.tradeLimitMax === 0 || state.value.trades.ids.length < config.tradeLimitMax)) { 
          return tableService.sendTradeTableRequest(state.value.trades.limit, state.value.trades.days, data.sorting, data.type, data.id, [], {}).pipe(
            mapFn,
            catchError((error) => Rx.of(tableLoadFailure(error)))
          );
        } else if (data.type === ComponentType.Owntrade && (state.value.ownTrades.days > 0 || state.value.ownTrades.limit > 0)) { 
          return tableService.sendTradeTableRequest(state.value.ownTrades.limit, state.value.ownTrades.days, data.sorting, data.type, data.id, [], {}).pipe(
            mapFn,
            catchError((error) => Rx.of(tableLoadFailure(error)))
          );
        }
        return tableService.sendPaginatedTableRequest(data.from, data.sorting, data.type, data.id, [], {}).pipe(
          mapFn,
          catchError((error) => Rx.of(tableLoadFailure(error)))
        );
      })
    );
};

export const tableChangedLimits: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Trade.TRADE_LIMIT),
    mergeMap(action => {
      const tables: ITable[] = getTableEntites(state.value).filter(t => action.own ? ComponentType.Owntrade === t.type : ComponentType.Trade === t.type);
      return Rx.concat(tables.map(t => paginatedRequest(0, t.sorting, t.type, t.id)));
    })
  );
};

export const tableChangedColumns: any = (actions$: ActionsObservable<any>, state: StateObservable<State>) => {
  return actions$.pipe(
    filter(action => action.type === Table.TRIGGER_COLUMN_NAMES),
    map(actions => actions.payload),
    mergeMap((data: any) => {
      const mapFn = map((content) => {
        return paginatedResult(content, data.type, false);
      });
      
      const tables: any = getTables(state.value);
      const table = tables[data.id];
      return tableService.sendPaginatedTableRequest(table.from, table.sorting, table.type, table.id, [], {}).pipe(
        mapFn,
        catchError((error) => Rx.of(tableLoadFailure(error)))
      );      
    })
  );
}

export const tableLoad: any = (actions$: ActionsObservable<any>) => {
    return actions$.pipe(
      filter(
        action =>
          action.type === Table.LOAD ||
          action.type === Table.CREATE ||
          action.type === ContractActions.LOAD_CONTRACTS_SUCCESS
      ),
      map(actions => actions.payload),
      mergeMap((data: ITable[]) => {
        return Rx.Observable.create((observer: Observer<any>) => {
          for (let i = 0; i < data.length; i++) {
              if (!data[i].externallyPaginated) {
                continue;
              }
              observer.next(tableService.sendPaginatedTableRequest(0, data[i].sorting, data[i].type, data[i].id, data[i].tags, data[i].filters).pipe(
                map((content) => {
                  return paginatedResult(content, data[i].type, true);
                }),
                catchError((error) => Rx.of(tableLoadFailure(error)))
              )
            );
          }
        }).pipe(switchMap((content: any) => {
          return content;
        }));
      })
    );
};

export const reconnection: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    map(actions => actions.payload),
    mergeMap(() => {
      const tables: any = getTables(state.value);
      return Rx.Observable.create((observer: Rx.Observer<any>) => {
        const ids = Object.keys(tables);
        for (let i = 0; i < ids.length; i++) {
          observer.next(tableService.sendPaginatedTableRequest(0, tables[ids[i]].sorting,  tables[ids[i]].type, ids[i], tables[ids[i]].tags, tables[ids[i]].filters).pipe(
            map((content) => {
              return paginatedResult(content, tables[ids[i]].type, true);
            }),
            catchError((error) => Rx.of(tableLoadFailure(error)))
          ));
        }
      }).pipe(switchMap((content: any) => {
        return content;
      }));
        
    })
  );
};

export const tableSpecificFilter: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Table.TRIGGER_TABLE_SPECIFIC_FILTER
    ),
    map(actions => actions.payload),
    mergeMap((data: { id: string; filter: FilterType, enable: boolean }) => {
      const tables: any = getTables(state.value);
      if (tables[data.id]) {
          const table = tables[data.id];
          let allFilter: {[key: string]: boolean}  = Object.assign({}, table.filter);
          allFilter[data.filter] = data.enable;
          return tableService.sendPaginatedTableRequest(
              0, 
              table.sorting, 
              table.type, 
              table.id,
              table.tags,
              allFilter
          ).pipe(
            map(content => {
              return paginatedResult(content, table.type, false);
            }),
            catchError((error) => Rx.of(tableLoadFailure(error)))
          );
      }
      
      return Rx.empty();
    })
  );
};

export const tableSearch: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>) => {
    return actions$.pipe(
      filter(
        action =>
          action.type === Table.TRIGGER_SEARCH_TAGS
      ),
      map(actions => actions.payload),
      mergeMap((data: {id: string, tags: ITableSearchTag[]}) => {
        const tables: any = getTables(state.value);
        if (tables[data.id]) {
            const table = tables[data.id];
            
            return tableService.sendPaginatedTableRequest(
                0, 
                table.sorting, 
                table.type, 
                table.id,
                data.tags,
                table.filter
            ).pipe(
              map(content => {
                return paginatedResult(content, table.type, false);
              }),
              catchError((error) => Rx.of(tableLoadFailure(error)))
            );
        }
        
        return Rx.empty();
      })
    );
};

export const tableRemove: any = (
    actions$: ActionsObservable<any>) => {
    return actions$.pipe(
      filter(
        action =>
          action.type === Table.REMOVE
      ),
      map(actions => actions.payload),
      mergeMap((data: string) => {
        tableService.sendCleanupTableRequest(
            data
        );
      
        return Rx.empty();
      })
    );
};

export const tablePaginatedCleanup: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS || action.type === Authentication.RELOGIN_SUCCESS),
      map(actions => actions.payload),
      switchMap(() => 
        Rx.interval(10000).pipe(
            takeUntil(actions$.pipe(filter(action => action.type === Authentication.AUTHENTICATION_LOGOUT || action.type === Connection.CONNECTION_LOST))),
            mergeMap(() => {
                // currently checks only trades
                const tradeCount = getTradeIds(state.value).length;
                if (tradeCount > config.tablePageSize * 3) {
                  return Rx.of(tradeInquireNotify());
                }
                return Rx.empty();
            })
        )
      )
    );
};

export const paginationEpics = combineEpics(
    // tableSort,
    reconnection,
    tablePaginatedRequest,
    tableLoad,
    tableSearch,
    tablePaginatedCleanup,
    tableRemove,
    tableSpecificFilter,
    tableChangedLimits,
    tableChangedColumns
);
  