import { State } from '../../../main/reducers/rootReducer';
import { getFavoriteState } from '../selectors/favorite';
import { toggleFavorite, toggleFavoriteVisibility } from '../actions/favorite';
import { connect } from 'react-redux';
import FavoriteSelect from '../components/favoriteSelect';
import { getMarketEntities, getDashboardMarketInstruments, getDashboardChartInstruments, getDashboardMarketProducts } from '../../ui/selectors/ui';
import { isProfileModificationAllowed } from '../../../authentication/selectors/authetication';

const mapStateToProps = (state: State) => ({
  selectEntities: getMarketEntities(state),
  favorites: getFavoriteState(state),
  activeMarketInstruments: getDashboardMarketInstruments(state),
  activeChartInstruments: getDashboardChartInstruments(state),
  activeMarketProducts: getDashboardMarketProducts(state),
  modificationEnabled: isProfileModificationAllowed(state)
});

const mapDispatchToProps = {
  toggleFavorite: toggleFavorite,
  onFavoriteChangeVisibility: toggleFavoriteVisibility
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(FavoriteSelect);