import * as React from 'react';
import { Position } from '../../shared/utils/models/grid';
import { Translate } from 'react-redux-i18n';

interface Props {
  position: Position;
  isOpen: boolean;
  onSave: () => void;
  dismiss: () => void;
}
interface State {}

export default class ContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.dismiss);
  }

  onSave(e: React.MouseEvent<HTMLLIElement>) {
    e.preventDefault();
    this.props.onSave();
  }

  dismiss(e: any) {
    e.preventDefault();
    var element = document.getElementById('context-menu');

    if (element && e.target !== element && !element.contains(e.target)) {
      this.props.dismiss();
    }
  }

  render(): JSX.Element {
    const { position, isOpen } = this.props;
    const style = {
      top: position.y + 'px',
      left: position.x + 'px',
      display: isOpen ? 'block' : 'none'
    };

    return (
      <div id="context-menu" style={style}>
        <ul>
          <li onClick={this.onSave}><Translate value="context.saveView" /></li>
        </ul>
      </div>
    );
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.dismiss);
  }
}
