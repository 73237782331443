import { State } from '../../../main/reducers/rootReducer';
import { createSelector } from 'reselect';

export const getSettingsState = (state: State) => state.settings;
export const getSettingsColors = createSelector(
  getSettingsState,
  s => s.colors
);

export const getGeneralSettings = createSelector(
  getSettingsState,
  s => s.general
);

export const getSidebarSettings = createSelector(
  getSettingsState,
  s => s.sidebar
);

export const getIsBorderTableActive = createSelector(
  getSettingsState,
  s => {
    return s.colors.borderColor.active !== '';
  }
);

export const getIsSeparateCellsActive = createSelector(
  getSettingsState,
  s => {
    return !!s.colors.separateCells?.active; // if enabled value is 'active', otherwise value is empty
  }
);

export const getSidebarIsLocked = createSelector(
  getSidebarSettings,
  s => {
    return s.locked;
  }
);

export const getFontColorsForMarket = createSelector(
  getSettingsColors,
  colors => {
    return Object.keys(colors).reduce(
      (acc: { [key: string]: string }, key: string) => {
        return {
          ...acc,
          [key]: colors[key].font
        };
      },
      {}
    );
  }
);
