import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import {
  ActionTypes as Connection,
  connectionLost,
  connectionSuccess,
  ssoConnectionStart,
  ssoExpiration} from '../actions/connection';
import {
  loginSuccess,
  loginFailure,
  imLoginRequest} from '../actions/authentication';
import * as Rx from 'rxjs';

import { StompService, StompClient } from '../../main/services/stompService';
import { State } from '../../main/reducers/rootReducer';
import { isSsoCodeSent } from '../selectors/connection';
import { config } from '../../main/config';
import { filter, map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { redirectAndReadData, createRequest, decodeToken, readSSOData } from '../keycloak/helper';
import { getLoginData } from '../selectors/authetication';
const stompService = new StompService(StompClient);

export const ssoStart: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.APPLICATION_START),
    switchMap(() => {
      if (config.ssoEnabled) {
        const pathName = window.location.pathname;
        if (pathName !== '/' && pathName !== '/login') {
          return Rx.empty();
        }
        const data = readSSOData();
        if (data && data.code)  {
          return Rx.of(ssoConnectionStart({
            code: data.code
          }));
        } else if (data && data.error) {
          return Rx.of(loginFailure(data.error_description));
        } 
        const loginData = getLoginData(state.value);
        if (loginData) {
          // relogin logic
          return Rx.of(ssoConnectionStart(loginData));
        } else {
          redirectAndReadData(config.redirectUrl);
        }
      }

      return Rx.empty();
    })
  );
};

export const ssoConnection: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.SSO_CONNECTION_START),
    map(action => action.payload),
    mergeMap((data: any) => {
        if (stompService.disconnecting) {
          // retry until stompService is fully disconnected since last logout
          return Rx.Observable.create((observer: any) => {
            setTimeout(
              () => observer.next(ssoConnectionStart(data)), 1000
            );
          }).pipe(map((action) => {
            return action;
          }));
        }
        return stompService.connect(data).pipe(
          map((frame: any) => {
            if (frame.username) {
              if (frame.ssoToken) {
                // third request with sso token or login after refresh
                const tokenLoginRequest = createRequest(frame);
                stompService.sendMessage('/app/tokenlogin', JSON.stringify(tokenLoginRequest));
                return imLoginRequest(frame);
              } else if (frame.code) {
                // second request with authorization code
                const authorizationCodeRequest = createRequest(frame);
                stompService.sendMessage('/app/authcode', JSON.stringify(authorizationCodeRequest));
                return imLoginRequest(frame);
              }
              return {};
            } else {
              return connectionSuccess();
            }
          }),
          catchError((error: any) => Rx.of(connectionLost(error.message || 'Internal server error occures.')))
        );
      }
    )
  );
};

export const ssoGetToken: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    map(action => action.payload),
    mergeMap(() => 
      stompService.subscribe('/user/topic/im/authcode').pipe(
        map((content: any) => {
          if (content.token) {
            stompService.sendLogout();
            return ssoConnectionStart({
              ssoToken: content.token
            });
          }
          return Rx.empty();
        }),
        catchError(error => {
          return Rx.of(loginFailure(error));
        })
      )
    )
  );
};

export const successfulSsoConnection: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      if (config.ssoEnabled) {
        return stompService.subscribe('/user/topic/im/tokenlogin').pipe(
          switchMap((content: any) => {
            if (content.redirectUrl && !isSsoCodeSent(state.value)) {
              redirectAndReadData(content.redirectUrl);
            } else if (content.redirectUrl && !isSsoCodeSent(state.value)) {
              return Rx.of(loginFailure('cannot verify'));
            }
            return Rx.empty();
          }),
          catchError(error => {
            return Rx.of(loginFailure(error));
          })
        ); 
      }
      return Rx.empty();
    }
  ));
};

export const ssoLoginSubscribe: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      // subscribe for final login response
      if (config.ssoEnabled) {
        return stompService.subscribe('/user/topic/im/login').pipe(
          map((content: any) => {
            if (content.connected && content.userPermissions !== null) {
              stompService.authorize();
              return loginSuccess(content);
            }
            return loginFailure(content);
          }),
          catchError(error => {
            return Rx.of(loginFailure(error));
          })
        );
      } 
      return Rx.empty();
    })
  );
};

export const ssoTokenExpireSubscribe: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      if (config.ssoEnabled) {
        return stompService.subscribe('/user/topic/im/ssoexpiration').pipe(
          map((content: any) => {
            return ssoExpiration(content.expiration);
          })
        );
      } 
      return Rx.empty();
    })
  );
};

export const ssoConnectionEpic = combineEpics(
  ssoStart,
  ssoConnection,
  ssoGetToken,
  successfulSsoConnection,
  ssoLoginSubscribe,
  ssoTokenExpireSubscribe
);
