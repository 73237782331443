import * as React from 'react';
import { ComponentType } from '../../shared/ui/models/component';
import AnalyticsSettings from './AnalyticsSettings';
import { IQuadrant } from '../models/analyticsPanel';
import AnalyticsQuadrantsPanel from './AnalyticsQuadrantsPanel';
import { I18n } from 'react-redux-i18n';

interface Props {
  quadrants: IQuadrant[];
  axises: { x: number; y: number };
  isLocked: boolean;
  height: number;
  expanded: boolean; // from autocloses
  tabRemove: (tabId: string, dockId: string, type: ComponentType) => void;
  setQuadrant: (quadrant: IQuadrant) => void;
  updateQuadrant: (quadrant: IQuadrant) => void;
  quadrantsResize: (
    quadrants: IQuadrant[],
    axisX: number,
    axisY: number
  ) => void;
  triggerPanelLock: (locked: boolean, height: number) => void;
  reset: () => void;
  quoteRequestsEnabled: boolean;
  isTradeReportingUser: boolean;
  controlledQuadrants: IQuadrant[]; // from resizer HOC
  onQuadrantsResize: (
    quadrants: IQuadrant[],
    axisX: number,
    axisY: number
  ) => void;
}
interface State {
  height: number;
  lastHeight: number;
  settingsVisible: boolean;
}
export default class AnalyticsPanelComponent extends React.Component<
  Props,
  State
> {
  private settingsPanelRef: any;
  constructor(props: Props) {
    super(props);

    this.onTriggerPanelLock = this.onTriggerPanelLock.bind(this);
    this.onSettingsClick = this.onSettingsClick.bind(this);

    this._onDoubleClick = this._onDoubleClick.bind(this);
    this._onDragStart = this._onDragStart.bind(this);
    this._onDrag = this._onDrag.bind(this);
    this._onDragEnd = this._onDragEnd.bind(this);

    this.state = {
      height: 0,
      lastHeight: 0,
      settingsVisible: false
    };

    this.settingsPanelRef = React.createRef();
  }

  componentWillReceiveProps(newProps: Props) {
    if (this.props.height !== newProps.height && newProps.isLocked) {
      const maxHeight = window.innerHeight - 140;
      this.setState({
        ...this.state,
        height: newProps.height > maxHeight ? maxHeight : newProps.height
      });
    } else if (!newProps.expanded && !newProps.isLocked) {
      this.setState({
        ...this.state,
        height: 0,
        lastHeight: this.state.height,
        settingsVisible: false
      });
    }
  }

  onTriggerPanelLock(locked: boolean) {
    const { height } = this.state;
    this.props.triggerPanelLock(locked, height);
  }

  onSettingsClick(visible: boolean) {
    if (this.settingsPanelRef.current) {
      this.settingsPanelRef.current.handleTriggerVisibility(visible);
    }
  }

  _onDoubleClick(e: any) {
    const { isLocked } = this.props;
    if (!isLocked) {
      
      this.setState({
        ...this.state,
        height: this.state.lastHeight
      });
      setTimeout(() => {
        this.rerenderTable();
      }, 500);
    }
    
  }

  _onDragStart(e: any) {
    const { isLocked } = this.props;
    if (!isLocked) {
      document.addEventListener('mousemove', this._onDrag, false);
      document.addEventListener('mouseup', this._onDragEnd, false);
    }
  }

  _onDrag(e: any) {
    if (e.clientY < window.innerHeight - 140) {
      this.setState({
        ...this.state,
        height: e.clientY
      });
      this.rerenderTable();
    }
  }

  _onDragEnd(e: any) {
    document.removeEventListener('mousemove', this._onDrag);
    document.removeEventListener('mouseup', this._onDragEnd);

    this.rerenderTable();
  }

  rerenderTable = () => {
    const analytics: any = document.getElementsByClassName('analytics-panel')[0];
    const tables = analytics.querySelectorAll('tbody');
    tables.forEach((table: any) => {
      const tempElement = document.createElement('tr');
      table.appendChild(tempElement);
      
      setTimeout(() => {
        table.removeChild(tempElement);
      });
      }, 200
    );
  }

  render() {
    const { quadrants, axises, isLocked, controlledQuadrants } = this.props;
    const { height, settingsVisible } = this.state;
    const style = { height: `${height}px` };
    const analyticsIsActive = height > 100;
    return (
      <React.Fragment>
        <div className="analytics-panel__content" style={style}>
          {quadrants.length ? (
            <AnalyticsQuadrantsPanel
              quadrants={quadrants}
              activeQuadrants={quadrants}
              axises={axises}
              active={analyticsIsActive}
              isLocked={isLocked}
              controlledQuadrants={controlledQuadrants}
            />
          ) : analyticsIsActive ? (
            <div className="info-panel">
              <div className="quadrants-preview">
                <div className="quadrant d-flex justify-content-center align-items-center">
                  1. Quadrant (Q1)
                </div>
                <div className="quadrant  d-flex justify-content-center align-items-center">
                  2. Quadrant (Q2)
                </div>
                <div className="quadrant d-flex justify-content-center align-items-center">
                  3. Quadrant (Q3)
                </div>
                <div className="quadrant d-flex justify-content-center align-items-center">
                  4. Quadrant (Q4)
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {analyticsIsActive ? (
          <AnalyticsSettings
            ref={this.settingsPanelRef}
            quadrants={quadrants}
            isLocked={isLocked}
            setQuadrant={this.props.setQuadrant}
            updateQuadrant={this.props.updateQuadrant}
            triggerPanelLock={this.onTriggerPanelLock}
            tabRemove={this.props.tabRemove}
            reset={this.props.reset}
            onVisibilityChange={(visible: boolean) =>
              this.setState({
                ...this.state,
                settingsVisible: visible
              })
            }
            quoteRequestsEnabled={this.props.quoteRequestsEnabled}
            isTradeReportingUser={this.props.isTradeReportingUser}
          />
        ) : null}
        <div className="icons-panel d-flex">
          <div
            className={`drag-icon ${!analyticsIsActive ? `rounded` : ``}`}
            title={I18n.t('analytics.title')}
            onMouseDown={this._onDragStart}
            onDoubleClick={this._onDoubleClick}
          >
            <i className="oi oi-graph" />
          </div>
          {analyticsIsActive ? (
            <div className="active-background">
              <div
                className={`settings-icon ${settingsVisible ? `active` : ``}`}
                title={I18n.t('analytics.settings')}
                onClick={() => this.onSettingsClick(!settingsVisible)}
              >
                <i className="oi oi-plus" />
              </div>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
