import { ActionTypes } from '../../dashboard/actions/profile';
import { ActionTypes as Tabs } from '../../shared/ui/actions/tab';
import { ActionTypes as Table } from '../../shared/ui/actions/table';
import { getActiveViewId } from '../../dashboard/selectors/profile';
import { Store } from 'redux';
import { State } from '../reducers/rootReducer';

export const viewMiddleware: any = (store: Store<State>) => (next: any) => (
  action: any
) => {
  const activeViewId = getActiveViewId(store.getState());
  if (activeViewId && 'type' in action 
    && action.type.indexOf(Tabs.CHANGE_TAB) === -1 
    && action.type.indexOf(Table.TABLE_LOAD_FAILURE) === -1 
    && action.type.indexOf(Table.PAGINATED_REQUEST) === -1) {
    const changeInUI =
        (action.type.indexOf('[UI]') > -1 && action.type.indexOf('Load') === -1)
        || (action.type.indexOf('[Quadrant]') > -1 && action.type.indexOf('Load') === -1);
    const changeInDock = action.type.indexOf('[Dock]') > -1;
    if (changeInUI || changeInDock) {
      store.dispatch({
        type: ActionTypes.RESET_ACTIVE_VIEW
      });
    }
  }
  return next(action);
};
