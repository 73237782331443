import * as React from 'react';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import { saveConfig } from '../actions/notifications';
import ModalWindow from '../../modalWindow/components/modalWindow';
import { getVersion } from '../../logger/selectors/logger';
import { config } from '../../../main/config';

export interface VersionInfoProps {
    version: string;
    modalVisible: boolean;
    closeModal: () => void;
}

export interface VersionInfoState {
    modalVisible: boolean;
}

export class VersionInfoModalComponent extends React.Component<
    VersionInfoProps,
    VersionInfoState
    > {
    constructor(props: VersionInfoProps) {
        super(props);
        this.state = {
            modalVisible: props.modalVisible
        };
    }

    render() {
        const { modalVisible, version } = this.props;
        const copyright = config.branding.company.name;
        const year = new Date().getFullYear();
        const linkToWebsite = config.branding.company.link;
        return (
            <div className="meet-settings__modal">
                <ModalWindow
                    id="version-info"
                    onModalClose={() =>
                        this.props.closeModal()
                    }
                    isOpen={modalVisible}
                    title={'info.title'}
                >
                    <div>
                        <p>
                            {version}
                        </p>
                        <label hidden={linkToWebsite !== null}>
                            {/* <Translate value="info.copyright" /> */}
                            &copy; {year} {copyright}
                        </label>
                        <label hidden={linkToWebsite === null}>
                            {/* <Translate value="info.copyright" /> */}
                            <a href={linkToWebsite === null ? '#' : linkToWebsite}>&copy; {year} {copyright}</a>
                        </label>
                    </div>
                    <div className="modal-footer" />
                </ModalWindow>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    version: getVersion(state)
});

const mapDispatchToProps = {
    saveConfig: saveConfig
};

export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(VersionInfoModalComponent);
