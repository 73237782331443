import { Size, Position } from '../../utils/models/grid';
import { ComponentType } from '../../ui/models/component';
import { v1 } from 'uuid';

export enum DockType {
  Tabs = 'Tabs',
  Market = 'Market',
  ProductMarket = 'ProductMarket',
  MarketIntraday = 'MarketIntraday',
  ChartMarket = 'ChartMarket'
}

export const getDockType = (type: ComponentType): DockType => {
  switch (type) {
    case ComponentType.Order:
    case ComponentType.Owntrade:
    case ComponentType.Trade:
    case ComponentType.TradeReport:
    case ComponentType.PriceAlarm: {
      return DockType.Tabs;
    }

    case ComponentType.Product: {
      return DockType.ProductMarket;
    }
    case ComponentType.Instrument: {
      return DockType.Market;
    }

    case ComponentType.InstrumentIntraday: {
      return DockType.MarketIntraday;
    }

    case ComponentType.MarketChart: {
      return DockType.ChartMarket;
    }

    default:
      return DockType.Tabs;
  }
};

export interface Directions {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface Pinning {
  axis: 'x' | 'y';
  value: number;
}

export interface IDock {
  id: string;
  profileId: string;
  type: DockType;
  size: Size;
  position: Position;
  isColliding: boolean;
  isRemoving: boolean;
  pinning: Pinning | null;
  autoDragging: boolean;
  ableToDelete: boolean;
  disableRendering: boolean;
  favoriteName: string;
  parts: number;
  blockWidth: string;
  isBeingDragged: boolean;
  touchScreen: boolean;
  active: boolean;
  scrollState?: {top: number; left: number};
}

export class Dock implements IDock {
  id: string;
  profileId: string;
  type: DockType;
  size = {
    width: 0,
    height: 0
  };
  position = {
    x: 0,
    y: 0
  };
  isColliding = false;
  isRemoving = false;
  pinning = null;
  autoDragging = false;
  ableToDelete = false;
  disableRendering = false;
  favoriteName = '';
  parts = 0;
  blockWidth = 'instrument-w-default';
  isBeingDragged = false;
  touchScreen = false;
  active = false;
  scrollState = {top: 0, left: 0};
  
  constructor(
    type: DockType,
    position?: Position,
    size?: Size,
    autoDragging?: boolean,
    ableToDelete?: boolean,
    id?: string,
    disableRendering?: boolean,
    favoriteName?: string,
    parts?: number,
    blockWidth?: string,
    touchScreen?: boolean
  ) {
    this.id = id ? id : v1();
    this.type = type.endsWith('Chart') ? DockType.ChartMarket : type;
    this.size = size ? size : this.size;
    this.position = position ? position : this.position;
    this.autoDragging = autoDragging ? autoDragging : this.autoDragging;
    this.ableToDelete = ableToDelete ? ableToDelete : this.autoDragging;
    this.disableRendering = disableRendering
      ? disableRendering
      : this.disableRendering;
    this.favoriteName = favoriteName ? favoriteName : this.favoriteName;
    this.parts = parts ? parts : 0;
    this.blockWidth = blockWidth ? blockWidth : this.blockWidth;
    this.touchScreen = touchScreen;
  }
}
