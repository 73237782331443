import * as React from 'react';
import { openQuoteRequest, acceptQuoteRequest, declineQuoteRequest } from '../actions/requests';
import store from '../../main/store/store';
import UIMarketContextMenu from '../../shared/ui/components/market/components/uiMarketContextMenu';
import { MarketActionType } from '../../shared/ui/models/market';
import { getRequestEntities } from '../../requests/selectors/requests';
import { IRequest, QuoteRequestFormData, QuoteRequestState } from '../models/request';
import { createAnswerFormDataFromQuoteRequest, createCancelFormDataFromQuoteRequest, openEnterQuoteRequestFormFromQuoteRequest } from '../helper/helper';
import { getUser } from '../../authentication/selectors/authetication';
import { v1 } from 'uuid';

export const MeetQuoteRequestTableRowPopover = (params: any) => {
    const { data, hidePopover, visible, position } = params;
    if (!data || !data.id) {
        return (<div>Error occurred</div>);
    }

    const quoteRequest: IRequest = getRequestEntities(store.getState())[data.id];
    const user = getUser(store.getState());
    const openForm = (formData: QuoteRequestFormData | null) => {
        if (formData) {
            store.dispatch(openQuoteRequest(formData));
        }
        hidePopover();
    };

    const onSubmitAccept = (e: any) => {
        e.preventDefault();
        if (data) {
            store.dispatch(acceptQuoteRequest({
                id: quoteRequest.id, 
                businessUnitId: quoteRequest.requesterBusinessUnitId,
                portfolioId: quoteRequest.requesterPortfolioId,
                correlationId: v1()
            }));
        }
    };

    const onSubmitDecline = (e: any) => {
        e.preventDefault();
        if (data) {
            store.dispatch(declineQuoteRequest({
                id: quoteRequest.id,
                correlationId: v1()
            }));
        }
    };

    const onActionSelect = (e: any, type: MarketActionType) => {
        if (data) {
            switch (type) {
                case MarketActionType.ANSWER_QUOTE_REQUEST:
                    openForm(createAnswerFormDataFromQuoteRequest(quoteRequest));
                    break;
                case MarketActionType.CANCEL_QUOTE_REQUEST:
                    openForm(createCancelFormDataFromQuoteRequest(quoteRequest));
                    break;
                case MarketActionType.NEW_QUOTE_REQUEST:
                    openForm(openEnterQuoteRequestFormFromQuoteRequest(quoteRequest, []));
                    break;
                case MarketActionType.ACCEPT_QUOTE_REQUEST:
                    onSubmitAccept(e);
                    break;
                case MarketActionType.REJECT_QUOTE_REQUEST:
                    onSubmitDecline(e);
                    break;
                default:
                    break;
            }
        }
    };

    if (visible) {
        const style: any = position ? {
            display: 'block'
          } : {};
        if (quoteRequest) {
            const required: { [x: string]: boolean } = {};
        
            const optional: MarketActionType[] = [];
            optional.push(MarketActionType.NEW_QUOTE_REQUEST);
            if ((user.currentUserId === quoteRequest.requesterId && quoteRequest.state === QuoteRequestState.ACTIVE)
                || (user.currentUserGroupId == quoteRequest.requesterGroupId)
            ) {
                optional.push(MarketActionType.CANCEL_QUOTE_REQUEST);
            }
            if (user.currentUserId !== quoteRequest.requesterId && quoteRequest.state === QuoteRequestState.ACTIVE) {
                optional.push(MarketActionType.ANSWER_QUOTE_REQUEST);
            }
            if (user.currentUserId === quoteRequest.requesterId && quoteRequest.state === QuoteRequestState.ANSWERED) {
                optional.push(MarketActionType.ACCEPT_QUOTE_REQUEST);
                optional.push(MarketActionType.REJECT_QUOTE_REQUEST);
            }

            return (
                <div className="request-popover" style={style}>
                    <UIMarketContextMenu
                        position={position}
                        isOpen={visible}
                        onAction={onActionSelect}
                        dismiss={hidePopover}
                        requiredActions={required}
                        optinalActions={optional}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
    return null;
};