import { Contract } from '../models/contracts';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD_CONTRACTS = '[Contracts] Load',
  LOAD_CONTRACTS_SUCCESS = '[Contracts] Load success',
  LOAD_CONTRACTS_FAILURE = '[Contracts] Load failure',
  PROCESS_CONTRACTS_SUCCESS = '[Conctracts] Process success',
  SEARCH_CONTRACTS = '[Contracts] Search',
  SEARCH_CONTRACTS_RESULTS = '[Contracts] Search result',
  SELECT_CONTRACT = '[Contracts] Select',
  LOAD_CONTRACTS_MAP_SUCCESS = '[Contracts] Map load success',
  STATIC_DATA_LOADED = '[Contracts] Static data loaded',
  CONTRACTS_STATE_CHANGED = '[Contracts] Contracts state change received',
  CONTRACTS_UPDATED = '[Contracts] Contracts updated',
  PERSISTENT_CONTRACT_RECEIVED = '[Contracts Persistent contract received'
}

export interface ContractsLoadAction {
  type: ActionTypes.LOAD_CONTRACTS;
}

export interface ContractsLoadActionSuccess extends LogAction {
  type: ActionTypes.LOAD_CONTRACTS_SUCCESS;
  payload: Contract[];
}

export interface ContractsLoadActionFailure extends LogAction {
  type: ActionTypes.LOAD_CONTRACTS_FAILURE;
  payload: string;
}

export interface ContractsProcessSuccess {
  type: ActionTypes.PROCESS_CONTRACTS_SUCCESS;
  payload: { [key: string]: Contract };
}

export interface ContractsSearch {
  type: ActionTypes.SEARCH_CONTRACTS;
  payload: string;
}

export interface ContractsSearchResult {
  type: ActionTypes.SEARCH_CONTRACTS_RESULTS;
  payload: Contract[];
}

export interface ContractSelectAction {
  type: ActionTypes.SELECT_CONTRACT;
  payload: Contract;
}

export interface ContractMapLoadSuccess {
  type: ActionTypes.LOAD_CONTRACTS_MAP_SUCCESS;
  payload: { [id: string]: Contract };
}

export interface StaticDataLoadedAction extends LogAction {
  type: ActionTypes.STATIC_DATA_LOADED;
}

export interface ContractsStateChangedAction extends LogAction {
  type: ActionTypes.CONTRACTS_STATE_CHANGED;
  contracts: Contract[];
}

export interface PersistentContractReceivedAction extends LogAction {
  type: ActionTypes.PERSISTENT_CONTRACT_RECEIVED;
  contract: any;
  action: any;
}

export type Action =
  | ContractsLoadAction
  | ContractsLoadActionSuccess
  | ContractsLoadActionFailure
  | ContractsProcessSuccess
  | ContractsSearch
  | ContractsSearchResult
  | ContractSelectAction
  | ContractMapLoadSuccess
  | StaticDataLoadedAction
  | ContractsStateChangedAction
  | PersistentContractReceivedAction;

export function contractsLoad(): ContractsLoadAction {
  return {
    type: ActionTypes.LOAD_CONTRACTS
  };
}

export function contractsLoadSuccess(
  payload: Contract[]
): ContractsLoadActionSuccess {
  return {
    type: ActionTypes.LOAD_CONTRACTS_SUCCESS,
    payload: payload,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('contract.log.Profile successfully loaded.')
  };
}

export function contractsLoadFailure(payload: string
): ContractsLoadActionFailure {
  return {
    type: ActionTypes.LOAD_CONTRACTS_FAILURE,
    payload: payload,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('contract.log.loadFailure')
  };
}

export function contractsMapLoadSuccess(
  payload: { [id: string]: Contract }
): ContractMapLoadSuccess {
  return {
    type: ActionTypes.LOAD_CONTRACTS_MAP_SUCCESS,
    payload: payload
  };
}

export function contractsProcessSuccess(payload: {
  [key: string]: Contract;
}): ContractsProcessSuccess {
  return {
    type: ActionTypes.PROCESS_CONTRACTS_SUCCESS,
    payload: payload
  };
}

export function contractSearch(payload: string) {
  return {
    type: ActionTypes.SEARCH_CONTRACTS,
    payload: payload
  };
}

export function contractSearchResults(payload: Contract[]) {
  return {
    type: ActionTypes.SEARCH_CONTRACTS_RESULTS,
    payload: payload
  };
}

export function contractSelect(payload: Contract) {
  return {
    type: ActionTypes.SELECT_CONTRACT,
    payload: payload
  };
}

export function staticDataLoaded(): StaticDataLoadedAction {
  return {
    type: ActionTypes.STATIC_DATA_LOADED,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('contract.log.allLoaded')
  };
}

export function contractsStateChangeReceived(payload: any): ContractsStateChangedAction {
  return {
    type: ActionTypes.CONTRACTS_STATE_CHANGED,
    contracts: payload,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('contract.log.contractStateChangeReceived')
  };
}

export function persistentContractResponseReceived(contract: any, action: any): PersistentContractReceivedAction {
  return {
    type: ActionTypes.PERSISTENT_CONTRACT_RECEIVED,
    contract: contract,
    action: action,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('contract.log.persistentContractReceived', {contract: contract.id, action: action})
  };
}
