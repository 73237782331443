import * as React from 'react';
import Dropzone from 'react-dropzone';
import {error} from '../../shared/logger/actions/logger';
import {I18n, Translate} from 'react-redux-i18n';
import {createNotification} from "../../shared/notifications/actions/notifications";
import {v1} from "uuid";
import {NotificationType} from "../../shared/notifications/models/notification";
import store from "../../main/store/store";

export interface FileUploadProps {
    onUpload: (file: any) => void;
    error: (message) => void;
}

export interface FileUploadState {
}

export class FileUpload extends React.Component<
    FileUploadProps,
    FileUploadState
    > {
    constructor(props: FileUploadProps) {
        super(props);
    }

    onDrop = (acceptedFiles: any[], rejectedFiles: any[]) => {
        if (rejectedFiles.length > 0) {
            store.dispatch(createNotification({id: v1(), type: NotificationType.WARNING, message: I18n.t('bulk.invalidFile')}))
            return error(I18n.t('bulk.invalidFile'));
        }
        if (acceptedFiles.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                const fileAsBinaryString = reader.result;
                return this.props.onUpload(fileAsBinaryString);

            };
            reader.onabort = () => {
                return error(I18n.t('bulk.invalidFile'));
            };
            reader.onerror = () => {
                return error(I18n.t('bulk.invalidFile'));
            };

            reader.readAsBinaryString(acceptedFiles[0]);
        }
        return null;
    }

    render() {
        return (
            <Dropzone onDrop={this.onDrop} accept={['.csv']}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <div
                            {...getRootProps()}
                            className={'dropzone dropzone--isActive:' + { isDragActive }}
                        >
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <Translate value="bulk.dropFile" /> :
                                    <Translate value="bulk.dropOrLoadFile" />
                            }
                        </div>
                    );
                }}
            </Dropzone>
        );
    }
}